import React, {useContext, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Container from "@material-ui/core/Container";

import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import {Redirect} from "react-router-dom";
import {CircularProgress,} from "@material-ui/core";
import {LanguageContext, Text} from "../../providers/LanguageProvider";
import {UserContext} from "../../providers/UserInfoContext";
import {login} from "./SignIn";

import TokenService from "../../../../token.service";
import usersApi from "../../../../api/users";


const useStyles = makeStyles((theme) => ({

    error: {
        color: "red",
    },

    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },

}));


export default function SignUp( ) {
    const classes = useStyles();
    const [registered, setRegistered] = useState(false);

    const {userLanguage, tt, userLanguageChange} =
        useContext(LanguageContext);


    const {user, userChange} = useContext(UserContext);

    const [showCircularProgress, setCircularProgress] = useState(false);
    const [generalError, setError] = useState("");

    const [form, setFormState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });


    const [errors, setErrors] = useState({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        passwordConfirm: null,
    });


    const handleInputChange = (e) => {
        setFormState({...form, [e.target.name]: e.target.value});
    };

    useEffect(() => {
        if (user) {
            setFormState({
                ...form,
                lastName: user.lastName,
                firstName: user.firstName,
                email: user.email,
            });
        }
    }, [user]);

    const validate = () => {
        const nameFormat = /^[a-z,.'-]+$/i;
        // eslint-disable-next-line no-useless-escape
        const nameError = tt('signInUp.errors.invalidName');
        const emailFormat =
            /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        const passwordFormat = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
        const _errors = {};

        if (form.firstName && !form.firstName.match(nameFormat)) {
            _errors["firstName"] = nameError;
        }

        if (form.lastName && !form.lastName.match(nameFormat)) {
            _errors["lastName"] = nameError;
        }

        if (form.email && !form.email.match(emailFormat)) {
            _errors["email"] = tt('signInUp.errors.invalidEmail');
        }


        if (!form.password.match(passwordFormat)) {
            _errors["password"] = tt('signInUp.errors.passwordFormat');
        }
        if (form.password && form.password !== form.passwordConfirm) {
            _errors["passwordConfirm"] =
                tt('signInUp.errors.passwordMatch');
        }


        setErrors(_errors);

        return Object.keys(_errors).length === 0;
    };

    const handleSubmit = (e) => {
        // setSubmitted(true);

        e.preventDefault();
        let valid = validate();

        if (valid) {
            setCircularProgress(true);
            setError("");


            usersApi
                .createUser(form)
                .then((res) => {
                    let newUser = res.data;
                    login(form.email, form.password)
                        .then((resp) => {
                            // eslint-disable-next-line no-undef
                            let idToken = resp.data.accessToken;
                            let refreshToken = resp.data.refreshToken;
                            TokenService.updateLocalRefreshToken(refreshToken);
                            TokenService.updateLocalAccessToken(idToken);
                            usersApi
                                .me()
                                .then((u) => {
                                    userLanguageChange(newUser.language);
                                    userChange(newUser);
                                    setRegistered(true);
                                })
                                .catch((e) => {

                                });
                        })
                        .catch((e) => {

                        });
                })
                .catch((e) => {
                    if (e.response) {
                        if (e.response.status === 400) {
                            setError("This email has already been taken");
                        } else {
                            setError("An error occurred while processing your request");
                        }
                    } else {
                        setError("An error occurred while creating your account");
                    }
                    setCircularProgress(false);
                });

        }
    };

    if (registered && user) {
        return <Redirect to={{pathname: "/journey", state: {user: user}}}/>;
    }

    return (

            <Container component="main" maxWidth="xs">

                {showCircularProgress ? (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                overflow: "hidden",
                                position: "relative",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "50%",
                            }}
                        >
                            <CircularProgress
                                style={{width: "55px", height: "55px", color: "#208899"}}
                                disableShrink
                            />
                        </div>
                    </div>
                ) : (
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={handleInputChange}
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    value={form.firstName || ""}
                                    id="firstName"
                                    label={<Text tid="signInUp.firstName"/>}
                                    error={errors.firstName}
                                    helperText={errors.firstName}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={handleInputChange}
                                    required
                                    variant="outlined"
                                    fullWidth
                                    id="lastName"
                                    label={<Text tid="signInUp.lastName"/>}
                                    name="lastName"
                                    autoComplete="lname"
                                    error={errors.lastName}
                                    helperText={errors.lastName}
                                    value={form.lastName || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label={<Text tid="signInUp.email"/>}
                                    name="email"
                                    error={errors.email}
                                    helperText={errors.email}
                                    value={form.email || ""}
                                    disabled={!!(user)}
                                />
                            </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        onChange={handleInputChange}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        name="password"
                                        label={<Text tid="signInUp.password"/>}
                                        type="password"
                                        id="password"
                                        placeholder="8 characters, at least one letter and one digit"
                                        autoComplete="current-password"
                                        error={errors.password}
                                        helperText={errors.password}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        onChange={handleInputChange}
                                        required
                                        fullWidth
                                        variant="outlined"
                                        name="passwordConfirm"
                                        label={<Text tid="signInUp.confirmPassword"/>}
                                        type="password"
                                        id="passwordConfirm"
                                        autoComplete="current-password"
                                        error={errors.passwordConfirm}
                                        helperText={errors.passwordConfirm}
                                    />
                                </Grid>


                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"

                                >
                                    {<Text tid="signInUp.signUpButton"/>}
                                </Button>
                            </Grid>


                        </Grid>


                        {generalError && (
                            <Grid container justify="center">
                                <div className={classes.error}>{generalError}</div>
                            </Grid>
                        )}
                    </form>
                )}
            </Container>

    );
}

