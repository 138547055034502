import React, {useContext, useState} from "react";

import {makeStyles, useTheme} from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import styles from "./headerLinksStyle";

import Toolbar from "../modules/components/Toolbar";
import {languageOptions} from "../../../../languages";
import {LanguageContext, Text} from "../../providers/LanguageProvider";
import {TextField} from "@material-ui/core";


import SignoutDialog from "../common/SignoutDialog";

const useStyles = makeStyles(styles);

function AppToolbar(props) {
  const classes = useStyles();
  const theme = useTheme()
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  // set selected language by calling context method

  const [openConfirmationMsg, setConfirmationMsg] = useState(false);

  const handleClosingOpenConfirmation = (signOut) => {
    setConfirmationMsg(false);
    if (signOut) {
      props.history.push({ pathname: "/signOut" });
    }
  };

  return (
    <Toolbar>

      <div className={classes.manager}>
        <Button
          ariant="contained"
          style={{ color: "black", textTransform: "none", fontSize: "20px" }}
          onClick={() => {
            setConfirmationMsg(true);
            // props.history.push({ pathname: "/signOut" });
          }}
        >
          <Text tid="welcome.signOut" />
        </Button>
      </div>
      <div style={{ marginRight: "5px", display: "inline-block" }}>
        <TextField
          fullWidth
          name="status"
          onChange={(e) => userLanguageChange(e.target.value)}
          required
          select
          InputProps={{
            className: classes.root,
            disableUnderline: true,
          }}
          style={{ width: "100%", color: theme.palette.primary.main  }}
          // eslint-disable-next-line react/jsx-sort-props
          SelectProps={{ native: true }}
          value={userLanguage}
        >
          {Object.entries(languageOptions).map(([id, name]) => (
            <option
              style={{ color: "white", fontSize: "17px" }}
              key={id}
              value={id}
            >
              {name}
            </option>
          ))}
        </TextField>


        <SignoutDialog open={openConfirmationMsg} handleClose={(e)=>setConfirmationMsg(false)} onConfirm={()=>handleClosingOpenConfirmation(true)} />


      </div>
    </Toolbar>
  );
}

export default withRouter(AppToolbar);
