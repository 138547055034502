import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

SocialTaskDialog.propTypes = {
    socialProvincialTask: PropTypes.object.isRequired,
    provincialSection: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    closeButtonLabel: PropTypes.string,
    title: PropTypes.string
};

export default function SocialTaskDialog(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Dialog
                onClose={props.onClose}
                aria-labelledby="soc-task-info-dialog"
                fullWidth={true}
                fullScreen={mobile}
                maxWidth={'xl'}
                open={props.open}>
                <DialogTitle
                    onClose={props.onClose}>{props.title || 'Social Task'}</DialogTitle>
                <DialogContent dividers>
                    {props.children}

                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                            variant={'contained'}
                            onClick={props.onClose}
                            color="primary">
                        {props.closeButtonLabel || 'Close'}
                    </Button>
                </DialogActions>
            </Dialog>

        </>

    )

}