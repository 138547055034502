import React, {useContext, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import img from "../../public/pics/backgroundHomePage.svg";
import img2 from "./pubic/mediamodifier_cropped_image.png";
import Grid from "@material-ui/core/Grid";
import Typography from "../modules/components/Typography";

import {Hidden} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import AOS from "aos";
import {LanguageContext} from "../../providers/LanguageProvider";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },
  background: {
    overflow: "hidden",
    backgroundImage: `url(${img})`,
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${img2})`,
    },
    [theme.breakpoints.between(319,770)]: {
      backgroundImage: `url(${img})`,
    },
    [theme.breakpoints.down(600)]: {
      backgroundImage: `url(${img2})`,
    },
    backgroundPosition: "center",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
  },
  logoIcon: {
    height: 120,
    width: 120,
    color: "#008295",
  },
  button: {
    minWidth: 200,

    fontSize: 23,
    [theme.breakpoints.down("sm")]: {
      fontSize: 19,
      minWidth: 150,
    },
    padding:"5px 40px",
    textTransform: "none",

  },
  contents: {
    overflow:"hidden",
    position: "absolute",
    top:0,
    right:0,
    bottom:0,
    left:0,
    display:"flex",
    alignItems: "center"
  },
}));
const Enter = (props) => {
  const classes = useStyles();
  const {userLanguageChange } = useContext(LanguageContext);
  useEffect(() => {
    localStorage.clear();
    AOS.init({ duration: 1000 });
    // AOS.refresh();
  }, []);

  function setLanguage(languageCode) {

    userLanguageChange(languageCode)

  }
  return (
      <section className={classes.root}>
        <div className={classes.background} />
        <div className={classes.contents} >
          <Grid
              container
              direction={"column"}
              justify={"center"}
              alignItems={"center"}
              // className={classes.contents}
              spacing={2}
          >
            <Grid item xs={12}>
              <Grid container direction={"row"} style={{width:"100%",height:"100%", flexWrap:"unset"}}>
                <Typography variant="h2" style={{ fontWeight: "bold",color: "#000000b8" }}>
                  Allo
                </Typography>
                <Typography variant="h2" style={{ color: "#000000b8" }}>
                  {"Canada"}
                </Typography>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} data-aos="fade-up">
              <Typography align={"center"} variant={"h6"} style={{ color: "#000000b8" }}>
                Is a centralized platform for asylum seekers and refugees to know their rights and find resources
              </Typography>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={6} data-aos="fade-up">
              <Typography align={"center"} variant={"body1"} style={{ color: "#000000b8" }}>
              At AlloCanada, we acknowledge that the territory upon which it was created is located on unceded Indigenous land. The Kanien’kehá:ka Nation is recognized as the custodians of the lands and waters on which this website was founded. We understand we are guests in Tiohtià:ke/Montréal, the land of many diverse First Nations and AlloCanada is grateful to have the opportunity to work in this territory. To learn about the colonized land you are presently located on, go to  <Link target="_blank" href= "https://native-land.ca/"> Native Land </Link> and head to AlloCanada’s about us page for more Indigenous resources. 
                </Typography>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} data-aos="fade-up">
                <Typography align={"center"} variant={"body1"} style={{ color: "#000000b8",fontSize: "12px", padding: "1px" }}>
                  Is a centralized platform for asylum seekers and refugees to know their rights and find resources
                </Typography>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} data-aos="fade-up">
                <Typography align={"center"} variant={"body1"} style={{ color: "#000000b8",fontSize: "10px", padding: "1px"}}>
                At AlloCanada, we acknowledge that the territory upon which it was created is located on unceded Indigenous land. The Kanien’kehá:ka Nation is recognized as the custodians of the lands and waters on which this website was founded. We understand we are guests in Tiohtià:ke/Montréal, the land of many diverse First Nations and AlloCanada is grateful to have the opportunity to work in this territory. To learn about the colonized land you are presently located on, go to  <Link target="_blank" href= "https://native-land.ca/"> Native Land </Link> and head to AlloCanada’s about us page for more Indigenous resources. 
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12}>
              <Grid container direction={"row"} spacing={2} justify={"center"}>
                <Grid item>
                  <Button
                      href={"/welcome"}
                      color="primary"
                      variant="contained"
                      size="large"
                      className={classes.button}
                      component="a"
                      onClick={() => {
                        setLanguage("eng");
                      }}
                  >
                    Enter
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                      href={"/welcome"}
                      color="primary"
                      variant="contained"
                      size="large"
                      className={classes.button}
                      component="a"
                      onClick={() => {
                        setLanguage("fre");
                      }}
                  >
                    Entrer
                  </Button>
                </Grid>
              </Grid>
              <Grid container direction={"row"} spacing={2} justify={"center"}>
                <Grid item>
                  <Button
                      href={"/welcome"}
                      color="primary"
                      variant="contained"
                      size="large"
                      className={classes.button}
                      component="a"
                      onClick={() => {
                        setLanguage("spa");
                      }}
                  >
                    Entrar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                      href={"/welcome"}
                      color="primary"
                      variant="contained"
                      size="large"
                      className={classes.button}
                      component="a"
                      onClick={() => {
                        setLanguage("ara");
                      }}
                  >
                    دخول
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </section>

  );
};

export default Enter;
