import React, {useContext} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import TimelineIcon from "@material-ui/icons/Timeline";
import {LanguageContext} from "../../providers/LanguageProvider";
import DeleteIcon from "@material-ui/icons/Delete";
import {Divider} from "@material-ui/core";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

JourneyMenu.propTypes = {
    handleTimelineClick: PropTypes.func.isRequired,
    restartJourneyClick: PropTypes.func.isRequired

};

export default function JourneyMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {tt} = useContext(LanguageContext);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleTimelineClick = (e) => {
        setAnchorEl(null)
        props.handleTimelineClick(e)

    }

    const restartJourneyClick = (e) =>{

        setAnchorEl(null)
        props.restartJourneyClick(e)

    }

    return (
        <>
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"

                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/*<StyledMenuItem onClick={handleTimelineClick}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <PersonIcon fontSize="small"/>*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary={rawDictionary.journey.menu.profile.label}/>*/}
                {/*</StyledMenuItem>*/}

                <StyledMenuItem onClick={handleTimelineClick}>
                    <ListItemIcon>
                        <TimelineIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText primary={tt('journey.menu.timeline.label')} />
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem onClick={restartJourneyClick}>
                    <ListItemIcon>

                            <DeleteIcon color={'secondary'}  />

                    </ListItemIcon>
                    <ListItemText>
                        {tt('journey.menu.restart.label')}
                    </ListItemText>
                </StyledMenuItem>


            </StyledMenu>


        </>
    );
}