import React from 'react';
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

TaskSummary.propTypes = {
    content: PropTypes.string.isRequired
};


const useStyles = makeStyles((theme) => ({
    content: {
        margin: 12

    }

}));


export default function TaskSummary(props) {
    const classes = useStyles()
    const {content} = props
    return (
        <div className={classes.content}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={content}/>
                </Grid>
            </Grid>
        </div>

    )
}