import mapStyles from "./styles";

export const CANADA = {lat: 58.130366, lng: -106.346771,};
export const googleMapOptions = {

    styles: mapStyles,
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    zoomControlOptions: {position: 8},

};

