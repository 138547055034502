import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import {withRouter} from "react-router-dom";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import {Text} from "../../providers/LanguageProvider";

function DeleteCategoryDialog(props) {
  const { onClose, open, index } = props;
  const [confirmationMsg, setConfirmationMsg] = React.useState(false);

  const handleCancel = () => {
    onClose(false, index);
  };

  const handleYes = () => {
    onClose(true, index);
  };

  const deleteUser = async (e) => {
    e.preventDefault();
    let config = {headers: {"Authorization": `Bearer ${localStorage.token }`}}
    return await axios
        .delete(`/users/me`, config)
        .then((res) => {

          setConfirmationMsg(true);

        })
        .catch(e => {

          if(e.response && e.response.status === 401){

          }


          props.onErrorDeleteUser(e)


        });
  };



  return (
    <div>
      {props.deleteUser ? (
        <Dialog
          open={props.openDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            <Text tid="DeleteCategoryDialog.deleteUser.title" />
          </DialogTitle>
          <Divider />
          <DialogContent style={{ padding: "8px 50px" }}>
            {confirmationMsg ? (
              <Grid
                container
                justify={"center"}
                direction={"column"}
                spacing={3}
                alignItems={"center"}
                style={{ overflowY: "hidden" }}
              >
                <Grid item>
                  <SentimentVeryDissatisfiedIcon
                    style={{ width: 100, height: 100, color: "#298" }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant={"h5"}>
                    <Text tid="DeleteCategoryDialog.deleteUser.message" />
                  </Typography>
                </Grid>
                <Grid item>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        props.handleClosingDeleteDialog(true);
                      }}
                    >
                      <Text tid="DeleteCategoryDialog.deleteUser.close" />
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            ) : (
              <div>
                <DialogContentText id="alert-dialog-description">
                  <Text tid="DeleteCategoryDialog.deleteUser.confirmationMsg" />
                </DialogContentText>
                <DialogActions>
                  <Button
                    onClick={() => props.handleClosingDeleteDialog()}
                    color="primary"
                  >
                    <Text tid="DeleteCategoryDialog.deleteUser.no" />
                  </Button>
                  <Button onClick={deleteUser} color="primary" autoFocus>
                    <Text tid="DeleteCategoryDialog.deleteUser.yes" />
                  </Button>
                </DialogActions>
              </div>
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open || false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Text tid="DeleteCategoryDialog.removeBookmarkItem.title" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Text tid="DeleteCategoryDialog.removeBookmarkItem.message" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              <Text tid="DeleteCategoryDialog.removeBookmarkItem.no" />
            </Button>
            <Button onClick={handleYes} color="primary" autoFocus>
              <Text tid="DeleteCategoryDialog.removeBookmarkItem.yes" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
export default withRouter(DeleteCategoryDialog);
