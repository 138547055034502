import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {LanguageContext} from "../../providers/LanguageProvider";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => ({

    input: {
        width: 55,
    },
}));

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};



const PrettoSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },

})(Slider);


ProminentSlider.propTypes = {

    handleSliderChange: PropTypes.func.isRequired,
    handleInputSliderChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    range: PropTypes.number.isRequired,
    includeInput: PropTypes.bool
}
ProminentSlider.defaultProps = {
    includeInput: true,
    range: 10

}
export default function ProminentSlider(props) {
    const classes = useStyles();

    const {tt, userLanguageISO639} = useContext(LanguageContext);
    const marks = [
        {
            value: 10,
            label: tt('journey.tasks.orgFinder.slider.rangeKmSuffix', (10).toLocaleString(userLanguageISO639)),
        },
        {
            value: 25,
            label: tt('journey.tasks.orgFinder.slider.rangeKmSuffix', (25).toLocaleString(userLanguageISO639)),
        },
        {
            value: 50,
            label: tt('journey.tasks.orgFinder.slider.rangeKmSuffix', (50).toLocaleString(userLanguageISO639)),
        },
        {
            value: 100,
            label: tt('journey.tasks.orgFinder.slider.rangeKmSuffix',  (100).toLocaleString(userLanguageISO639)),
        },

    ];


    function valuetext(value) {
        return `${value} km`;
    }

    return (
        <div className={classes.root}>

            <Typography id="input-slider" gutterBottom>
                {tt('journey.tasks.orgFinder.slider.label')}
            </Typography>



            <Grid container spacing={5} >

                <Grid item xs={props.includeInput ? 9 : 11}>

                    <PrettoSlider
                        value={typeof props.range === 'number' ? props.range : 0}
                        valueLabelDisplay="auto"
                        onChange={props.handleSliderChange}
                        marks={marks}
                        defaultValue={10}
                        getAriaValueText={valuetext}
                        aria-labelledby="input-slider"

                    />
                </Grid>
                {props.includeInput &&
                    <Grid item xs={3}>
                        <Input
                            className={classes.input}
                            value={props.range}

                            onChange={props.handleInputSliderChange}
                            onBlur={props.handleBlur}
                            inputProps={{
                                step: 5,
                                min: 10,
                                max: 100,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                }
            </Grid>



        </div>
    );
}
