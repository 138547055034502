import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {get_intl_lang} from "../utils/commonUsedFunctions";
import {LanguageContext} from "../../providers/LanguageProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faMapLocationDot} from "@fortawesome/pro-light-svg-icons";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {colors} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    scroll: {
        position: "inherit",
        bottom: theme.spacing(2),
        right: theme.spacing(2),

    }
}));



export default function OrgDataList(props){
    const {organizationsResults, onSelectOrganization, onMouseEnter, ...rest} = props

    const {  userLanguage} = useContext(LanguageContext);
    const classes = useStyles();

    return (
        <>

            <List className={classes.root} dense={true}>

                {organizationsResults.map((org, index)=>{
                    const organization = org.organization
                    const trans = get_intl_lang(userLanguage, organization.intlOrgs)
                    const orgName = organization.name
                    const distance = org.distance.toFixed(1)
                    const description = trans?.description || '-'

                    return (
                        <>
                            <ListItem alignItems="flex-start" onMouseEnter={()=>onMouseEnter(organization)}>
                                <ListItemAvatar>
                                    <Avatar alt={orgName} style={{backgroundColor:"inherit"}}>
                                        <FontAwesomeIcon icon={faLocationDot} style={{color: "#ff4b5c", width: "100%", height: "100%"}} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography variant={'h6'}>{orgName}</Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {distance} km
                                            </Typography>
                                            { ' — ' + description}

                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={(e)=>onSelectOrganization(organization)}>
                                        <FontAwesomeIcon icon={faMapLocationDot} color={colors.red[400]} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {(index+1) !== organizationsResults.length &&
                                <Divider variant="inset" component="li"/>
                            }
                        </>

                    )

                })}


            </List>

        </>
    )

}