import React, {useContext} from 'react'


import Grid from "@material-ui/core/Grid";
import Typography from "../modules/components/Typography";
import Container from "@material-ui/core/Container";
import {LanguageContext, Text} from "../../providers/LanguageProvider";


const Instructions = (props)=>{
    const { userLanguage } = useContext(LanguageContext);

    return(
        <div style={{padding:"20px 10px"}}>
            <Container maxWidth={"lg"}>

                <Grid item xs={12} md={12}>
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        color="textPrimary"
                        gutterBottom
                        style={{ color: "black",paddingTop:"50px",fontWeight:"bold" }}
                    >
                        <Text tid="AboutUs.instructions.mainHeader" />
                        {/*How to Use it?*/}
                    </Typography>
                </Grid>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            style={{ color: "black",paddingTop:"50px",fontWeight:"bold" }}
                        >
                            <Text tid="AboutUs.instructions.firstParagraph.header" />
                        </Typography>
                        <Grid item xs={12} md={12}>
                            <Grid container direction={"column"}>
                                <Grid item xs={12}>
                                    <Typography
                                        // component="h1"
                                        variant="h6"
                                        align={userLanguage ==="ara"? "right":"justify"}
                                        color="textPrimary"
                                        gutterBottom
                                        style={{ color: "#00000096",textTransform:"none" }}
                                    >
                                        <Text tid="AboutUs.instructions.firstParagraph.body1" />
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            component="h1"
                            variant="h5"
                            align={"center"}
                            color="textPrimary"
                            gutterBottom
                            style={{ color: "black",paddingTop:"50px",fontWeight:"bold" }}
                        >
                            <Text tid="AboutUs.instructions.secondParagraph.header" />

                        </Typography>
                        <Grid item xs={12} md={12}>
                            <Typography
                                // component="h1"
                                variant="h6"
                                align={userLanguage ==="ara"? "right":"justify"}
                                color="textPrimary"
                                gutterBottom
                                style={{ color: "#00000096",textTransform:"none", }}
                            >
                                <Text tid="AboutUs.instructions.secondParagraph.body1" />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )







}

export default Instructions


//viewBox="0 0 1440 320"


// divided into three or two parts
// intro
// about us
