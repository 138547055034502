import React, {useEffect} from "react";
import AppFooter from "../modules/views/AppFooter";
import ProductHero from "../modules/views/ProductHero";

import MobileSideBar from "./MobileSideBar";

import AOS from "aos";
import "aos/dist/aos.css";

function WelcomePage() {

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
    });
  }, []);
  return (
    <React.Fragment>
      <MobileSideBar title={"homepage"} />
      <ProductHero />

      <AppFooter />
    </React.Fragment>
  );
}

export default WelcomePage;
