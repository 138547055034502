import React, {useEffect, useMemo} from "react";


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import serviceIcons, {defaultIcon} from "./serviceIcons";
import {makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";

import {faCircleInfo} from "@fortawesome/pro-light-svg-icons";
import {colors} from "@material-ui/core";

export function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = [];
    for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i === 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    var newValue = costs[j - 1];
                    if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                            costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}
//Levenshtein distance
export function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

const useStyles = makeStyles((theme) => ({

    avatar: (props)=>({
        color: "#fff",
        backgroundColor: props.color

    })


}))

ServiceIcon.propTypes = {
    service: PropTypes.object.isRequired,
    type: PropTypes.string,
    size: PropTypes.string,
    onLoad: PropTypes.func
}

ServiceIcon.defaultProps = {


}
export default function ServiceIcon(props) {
    const {service, type, shape, onIconLoad, colorProps, avatarStyles,   ...rest} = props


    const title = useMemo(()=>{

        return service?.name || ''

    }, [service])

    const dbName = useMemo(()=>{

        return service?.title || ''

    }, [service])



    // function findFilter(d, name){
    //     let words = name.match(/\b(\w+)\b/g)
    //     const found = words.some(r=> d.alias.filter(
    //             s =>  similarity(r, s) >= 0.9
    //         ).length > 0
    //     )
    //
    //     console.log(found)
    //     return found
    //
    // }

    function getCombinations(chars) {
        var result = [];
        var f = function(prefix, chars) {
            for (var i = 0; i < chars.length; i++) {
                result.push(prefix + chars[i]);
                f(prefix + chars[i], chars.slice(i + 1));
            }
        }
        f(' ', chars);
        return result;
    }

    const icon = useMemo(()=>{
        let _similarity = 0.7;
        let iconData = serviceIcons.find(s=>s.name === dbName);
        if(iconData){
            return iconData

        }

        serviceIcons.forEach(_iconData => {
            const combAliases = getCombinations( _iconData.alias ) || []
            combAliases.forEach(alias => {
                const _sim = similarity(alias, title.toLowerCase())
                if(_sim > _similarity){
                    _similarity = _sim;
                    iconData = _iconData;
                }

            })
        })

        if(!iconData){
            iconData = defaultIcon
        }

        return iconData;
    }, [title, dbName])



    const fIcon = useMemo(()=>{
        if(icon){

            return <FontAwesomeIcon icon={icon.icon}   color={colorProps ? colorProps : icon.color[icon.colorDepth]}   {...rest} />
        }

    }, [icon, rest, colorProps ])


    useEffect(()=>{
        if(icon && onIconLoad instanceof Function){
            onIconLoad(icon)
        }
    }, [icon, onIconLoad])

    const avatar = useMemo(()=>{
        if(avatarStyles){
            return <Avatar alt={title} style={avatarStyles}>{fIcon}</Avatar>
        }
        if(icon){
            return <Avatar alt={title} style={{backgroundColor: icon.color[icon.avatarColorDepth], color:"#fff" }}>{fIcon}</Avatar>

        }

        return <Avatar alt={title}  style={{ color:"#fff", backgroundColor: colors.blueGrey[100] }}>{fIcon}</Avatar>


    },[icon, fIcon, title, avatarStyles])


    return (

            <>
                {type === "avatar" ? avatar : fIcon}
            </>


    )

}


