import React from 'react';
import {InfoWindow} from "@react-google-maps/api";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

    root:{
        background: `transparent`,
        backgroundColor: 'transparent',

        padding: 10

    }
}));

export default function MapInfoWindow(props) {
    const { ...rest} = props

    const classes = useStyles( );

    return (

        <InfoWindow
            className={classes.root}

            {...rest}
        >
            <>
                {props.children}
            </>
        </InfoWindow>
    )


}