
const Data = {
  eng: [
    {
      category: "employment_and_entrepreneurship",
      faq: [
        {
          question: "Can I work in Canada as a refugee claimant?",
          answer: `If you are an asylum seeker in Canada, you are allowed to work but you
            will need a work permit in order to get a job. If you are not sure if
            you need one, consult this
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html">
              website
            </a>
            <br /> <br />
            You can apply for a work permit online or by mail. To learn how to
            apply, you can consult this
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html">
              website
            </a>
            and follow all of the steps.
            <br />
            <br />
            Also, if you would like some help applying for your work permit, there
            are different organizations who can help you with this. To find the
            organizations near you, you can go to the
            <a href="/explore">EXPLORE</a> page and select LEGAL and then WORK
            PERMIT.`,
        },
        {
          question: "Do I need a work permit? How do I get one?",
          answer: "(See above answer)",
        },
        {
          question: "I need help finding a job.",
          answer: ` There are many organizations that can help you with this. Before
            finding a job, ensure that you have a work permit. If you're not
            sure if you need one,
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html">
              click here
            </a>
            . You will also need a CV/resume. For more information regarding your
            needs with employment, go to our <a href="/explore">EXPLORE</a> page
            & select EMPLOYMENT & ENTREPRENEURSHIP. From there you will
            find organizations that can assist you with what you need.`,
        },
        {
          question: "I need help with my CV/resume",
          answer: `Building a CV is an important tool you need in order to find work in
            Canada. There are may organizations that can assist you with this. Go
            to our <a href="/explore">EXPLORE</a> page and select EMPLOYMENT &
            ENTREPRENEURSHIP. You can then choose the option for CV HELP to find
            organizations near you that can assist you with this.`,
        },
        {
          question: " What are my rights as a worker in Canada?",
          answer: `No matter what your status is, you have rights as a worker in Canada.
            You can click the links below to find out more:
            <br />
            <br />
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit/temporary/after-apply-next-steps/understand-your-rights-foreign-workers.html">
              - Your rights as a foreign worker
            </a>
            <br />
            <br />
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/rights.html">
              - Refugee Claimants' Rights
            </a>
            <br />
            <br />
            <a href="https://www.canada.ca/en/employment-social-development/services/foreign-workers/protected-rights.html">
              - Temporary Foreign Workers' Rights
            </a>`,
        },
        {
          question: "How can I start a business?",
          answer: `Starting a business is a great idea! To begin you can visit the
            Canadian government 
            <a href="https://www.canada.ca/en/services/business/start.html">
              website
            </a>
            . You will also have to register your business with your provincial
            government as well. If you require additional consultation, you can go
            to the <a href="/explore">EXPLORE</a> page and select EMPLOYMENT &
            ENTREPRENEURSHIP.`,
        },
      ],
    },
    {
      category: "legal",
      faq: [
        {
          question: "How do you claim asylum in Canada?",
          answer: `There are three (3) ways to claim refugee in Canada.
            <br />
            <br /> - First, you will have to register with the United Nations High
            Commission for Refugees (UNHCR) office in your country. They will
            register you and will be able to help you in your resettlement, if
            possible.
            <br />
            <br />
            - Second, you can get a tourist visa in your country at the Canadian
            embassy to be able to travel to Canada. Once you land in Canada, you
            can claim refuge at the border, in the airport.
            <br />
            <br />
            - Third, you can get privately sponsored by a group of Canadian
            citizens in Canada. They will pay for your resettlement if your case
            is accepted by the Canadian government.
            <br />
            <br />
            To find out more about the steps involved in the asylum application
            process,
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html">
              click here
            </a>
            .<br />
            <br />
            For organizations that deal with private sponsorship, go to the
            <a href="/explore">EXPLORE</a> page, select LEGAL and then PRIVATE
            SPONSORSHIP PROGRAM.
            <br />`,
        },
        {
          question:
            "What is the wait time to hear back about my refugee claim?",
          answer: `Federal authorities are solely responsible for application processing
            times. You can consult federal timeframes by
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-processing-times.html">
              click here
            </a>
            .<br />`,
        },
        {
          question: "How do I know if my refugee claim will be accepted?",
          answer: ` There is no guaranteed way to know if you will be accepted since a
            single decision maker of Canada's Immigration and Refugee Board
            will hear your testimony and must decide if your claim is credible.
            That is why it is essential to provide documentary proof of your story
            and to be well prepared for your hearing, and to hire a lawyer who is
            an expert in refugee law.`,
        },
        {
          question:
            " I've been accepted, do my family members qualify as well?",
          answer: ` Yes, in most cases Immigration, Refugees and Citizenship Canada (IRCC,
            formerly CIC) respects the principle of family unity. Therefore, a
            family will typically have one hearing and one decision. If you need
            legal aid or more information about your case, go to the 
            <a href="/explore">EXPLORE</a> page and select LEGAL for a list of
            organizations that can help you. <br />`,
        },
        {
          question: "My claim was refused/denied, what can I do?",
          answer: `You are allowed to appeal the decision if they reject your
            refugee/asylum claim. You have the right to remain in Canada while you
            apply for an appeal and make submissions for a Pre-Removal Risk
            Assessment. You may also make an application for permanent residence
            in Canada based upon humanitarian and compassionate grounds. You can
            consult this 
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html">
              page
            </a>
            for more information.`,
        },
        {
          question: "How can I come to Canada?",
          answer: ` If you are outside your country of origin, you must first apply to the
            United Nations High Commission for Refugees (UNHCR). You usually have
            to show that you will be economically successful in Canada. Also, the
            refugee definition is more restrictive, you do not have the right to a
            lawyer at your interview, and the waiting period could be many years.
            You must also show that you cannot be settled in the country that you
            are currently in.
            <br />
            <br />
            Overall, individuals come to Canada in multiple different ways:
            <br />- You can register at the UNHCR like we explained above. <br />-
            Another way is by applying for a tourist visa in your country at the
            Canadian embassy to be able to travel to Canada. Once you land in
            Canada, you can claim refuge at the border, in the airport. If you
            come to Canada by land, you claim asylum when you cross the border.
            <br />- Lastly, you can get privately sponsored by a group of Canadian
            citizens in Canada. They will pay for your resettlement if your case
            is accepted by the Canadian government.
            <br />
            <br />
            For organizations that deal with private sponsorship, go to the
            <a href="/explore">EXPLORE</a> page, select LEGAL and then PRIVATE
            SPONSORSHIP PROGRAM.
            <br />
            <br />
            For an expanation of the difference between government sponsored and
            privately sponsored,
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/refugee-protection/resettlement/quebec-procedures.html">
              click here
            </a>`,
        },
        {
          question: "What are the rights of refugees in Canada?",
          answer: `When an asylum-seeker first comes to Canada, he or she must make a
            claim for protection to the Canadian government in order to be granted
            refugee status. During the time when this claim is being evaluated, an
            asylum-seeker has certain rights.
            <br />
            <br />
            If a claim for protection is successful, asylum seekers are granted
            “protected persons” status, also called refugee status, and then they
            receive access to additional benefits. Accepted refugees have the same
            rights as permanent residents.
            <br />
            <br />
            Some refugees that have made their asylum claim in another country are
            resettled in Canada. These resettled refugees receive initial
            assistance (typically provided for up to one year) from either the
            federal government, the provincial government, or private sponsors.
            Resettled refugees become permanent residents and can eventually
            become citizens of Canada. If you need more information regarding
            refugee rights, please go to our <a href="/explore">EXPLORE</a> page
            and select LEGAL to find an organization who can answer your specific
            questions.`,
        },
        {
          question: "What does a PR mean? What does the CSQ mean?",
          answer: `The Quebec Selection Certificate (certificat de sélection du Québec),
            or CSQ, is a document issued by the Ministry of Immigration,
            Diversity, and Inclusion declaring that the holder has been selected
            to settle in Quebec. With a CSQ, the applicant can apply for Canadian
            permanent residence. This document is not a permanent residence visa.
            The CSQ is valid for 24 months or until Immigration, Refugees and
            Citizenship Canada (IRCC) makes a decision on a Canadian permanent
            residence application. For more information and to apply, consult this
            page.
            <br />
            <br />A permanent resident is someone who has been given permanent
            resident status by immigrating to Canada, but is not a Canadian
            citizen. Permanent residents are citizens of other countries. A person
            in Canada temporarily, like a student or foreign worker, is not a
            permanent resident. Refugees who are resettled from overseas become
            permanent residents through the Government-Assisted Refugee Program or
            the Private Sponsorship of Refugees Program.
            <br />
            <br />
            Someone who makes a refugee claim in Canada does not become a
            permanent resident at that time. To become one, the Immigration and
            Refugee Board must first approve their claim. Then, they must apply
            for and get permanent resident status. For more information and to
            apply, consult this
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-q7000-application-permanent-residence-quebec-skilled-worker-class.html">
              page
            </a>
            . <br />`,
        },
        {
          question: "How can I get a lawyer/legal aid?",
          answer: ` There are many organizations that offer legal aid. To find those
            closest to you, please go to the EXLPORE page and select LEGAL.`,
        },
      ],
    },
    {
      category: "education",
      faq: [
        {
          question: "Do I need a study permit ? How do I get one?",
          answer: ` In order to study in Canada, it is possible that you might need a
            study permit. If you are a refugee claimant on a Temporary Resident
            Visa (TRV) such as a visitor visa or work permit, you can do a
            short-term course while your temporary resident visa is valid. If you
            take a course that is more than 6 months long, you will need to apply
            for a study permit. Refugee claimants who do not have a valid
            Temporary Resident Visa also need to apply for study permits. If you
            have a valid study permit, you can legally study at a post-secondary
            institution. For more information on study permits, please
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/study-permits/refugees-protected-persons.html">
              click here
            </a>
            <br />
            <br />
            If you need help applying to school, want to know your options or just
            have questions related to studying in Canada, there are organizations
            that can help you with this. Go to our <a href="/explore">
              EXPLORE
            </a>
            page, select EDUCATION and then select ACADEMIC AID & COUNSELLING
            to find organizations near you who can help with this.`,
        },
        {
          question: "I want to apply to university. What do I do?",
          answer: `There are many organizations that can help you with this. Go to our
            <a href="/explore">EXPLORE</a> page, select EDUCATION and then
            ACADEMIC AID & COUNSELLING`,
        },
        {
          question: "How can I find a university?",
          answer: ` You can find universities near you by clicking on the city you are
            located in:
            <br />
            <a href="https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/">
              Halifax Universities
            </a>
            <br />
            <a href="https://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/students/applying-admission/choosing-institution/index.html">
              Montreal Universities
            </a>
            <br />
            <a href="https://www.ontario.ca/page/ontario-universities">
              Ontario Universities
            </a>
            <br />
            <a href="https://www.lifeincalgary.ca/schools/post-secondary">
              Calgary Universities
            </a>
            <br />
            <a href="https://www.collegealberta.info/edmonton.php">
              Edmonton Universities
            </a>
            <br />
            <a href="https://www2.gov.bc.ca/gov/content/education-training/post-secondary-education/find-a-program-or-institution/find-an-institution">
              Vancover Universities
            </a>`,
        },
        {
          question: "How can I find a vocational school?",
          answer: `To find a vocational training program in your location, please return
            to SERVICES, select EDUCATION and then VOCATIONAL. You can also visit
            <br />
            <a href="https://www.nscc.ca/learning_programs/schools/trades/index.asp">
              Vocational Schools in Nova Scotia
            </a>
            <br />
            <a href="https://www.emsb.qc.ca/emsb/schools/adult-vocational/centres">
              Vocational schools in Montreal
            </a>
            <br />
            <a href="https://www.ontariocolleges.ca/en/apply/skilled-trades">
              Vocational Schools in Ontario
            </a>
            <br />
            <a href="https://www.vocationalschools.ca/search#">
              Vocational Schools in Alberta
            </a>
            <br />
            <a href="https://www.tradestrainingbc.ca/">
              Vocational Schools in British Columbia
            </a>`,
        },
        {
          question: "How can I find a college?",
          answer: ` To find a college, please return to SERVICES, select EDUCATION and
            then COLLEGE. You will find a list of colleges in your location.
            <br />
            <a href="https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/">
              Halifax
            </a>
            <br />
            <a href="https://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/students/applying-admission/choosing-institution/index.html">
              Quebec
            </a>
            <br />
            <a href="https://www.ontariocolleges.ca/en/colleges">Ontario</a>
            <br />
            <a href="https://www.lifeincalgary.ca/schools/post-secondary">
              Calgary
            </a>
            <br />
            <a href="https://www.collegealberta.info/edmonton.php">Edmonton</a>
            <br />
            <a href="https://www.thoughtco.com/guide-to-universities-colleges-in-vancouver-3371270">
              Vancouver
            </a>`,
        },
        {
          question: "Can I get financial aid for school?",
          answer: `This works a bit differently in each province:
            <br />
            <br />
            NOVA SCOTIA:
            <br />
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules. <br />
            <br />
            As a student in Nova Scotia, you are eligible to apply for a variety
            of different loans and grants, both provincially and federally. Click
            <a href="https://novascotia.ca/studentassistance/apply/new/FundingAvailable.asp">
              here
            </a>
            to read more about the options available.
            <br />
            <br />
            QUEBEC:
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules. <br />
            <br />
            You may be eligible for certain scholarships and grants and the Aide
            financière aux études’ (AFE’s). Canadian citizens, permanent residents
            and refugees or protected persons qualify for AFE's Quebec aid
            program if they are deemed a resident of Quebec for student aid
            purposes at the time of application.
            <br />
            In this section, you will find information on AFE's
            <a href="https://www.quebec.ca/education/aide-financiere-aux-etudes/prets-bourses-temps-plein/conditions-admissibilite/">
              eligibility requirements
            </a>
            , the application process, deadlines and other important program
            details.
            <br />
            <br />
            ONTARIO:
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules. <br />
            <br />
            If you are a ‘protected person’ or a convention refugee, you are
            eligible to apply for OSAP. However, there are certain documents which
            you must have before applying. For further information on eligibility
            and specific details about OSAP
            <a href="https://osap.gov.on.ca/prodconsumption/groups/forms/documents/forms/prdr020621.pdf">
              click here
            </a>
            .
            <br />
            <br />
            ALBERTA:
            <br />
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules.
            <a href="https://studentaid.alberta.ca/before-you-apply/eligibility/">
              Alberta Student Aid
            </a>
            provides student loans for part time and full time studies. To receive
            student aid for full-time studies, you must meet all of these basic
            eligibility criteria:
            <br />- Be a Canadian citizen, permanent resident or have protected
            person status under Canada’s Immigration and Refugee Protection Act.
            Students with a study permit are not eligible. <br />
            - Be a resident of Alberta <br />
            - Be enrolled as a full-time student taking a minimum 60% of a full
            course load (40% of a full course load if you are a student with a
            permanent disability) <br />
            - Be enrolled in an eligible program at an approved post-secondary
            school
            <br />
            - Have financial need
            <br />
            <br />
            BRITISH COLUMBIA:
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules.
            <a href="https://studentaidbc.ca/apply/eligibility">
              StudentAid BC
            </a>
            is for students who need help paying for their post-secondary
            education. To receive student financial assistance, you must meet all
            of these basic eligibility criteria: <br />- Be a Canadian citizen, a
            permanent resident or have protected person status <br />- Have a
            valid Canadian social insurance number <br />- Be a resident of
            British Columbia <br />- Be pursuing full-time studies as your primary
            occupation <br />- Be enrolled in an eligible program of study at an
            eligible post-secondary institution
            <br />- Be able to demonstrate financial need <br />- Make academic
            progress and achieve satisfactory scholastic standing in each period
            of post-secondary study
            <br />
            <br />
            --------------
            <br />
            <br />
            You can check out another potential opportunity for financial aid
            <a href="https://srp.wusc.ca/">here</a>.`,
        },
        {
          question: " I would like to learn French and/or English.",
          answer: `  If you'd like to learn a language in your city, please return to
            services and choose the option: Languages and then choose the language
            you are interested in learning: English, French, IELTS or
            Conversational Classes. From there, you will find organizations near
            you that offer these classes.`,
        },
        {
          question: "Where can I learn how to code?",
          answer: `Great idea! The tech industry is booming in Canada. There are often
            bootcamps which are short 6-8 week crash courses on how to learn to
            code. To find some in your area, go to the
            <a href="/explore">EXPLORE</a> page and select EMPLOYMENT &
            ENTREPRENEURSHIP.`,
        },
        {
          question: "How can I get my Foreign Credentials recognized?",
          answer: `  To be able to practice you need to contact the respective order.
            <br />
            <br />
            PHARMACY:
            <br />
            <a href="https://www.nspharmacists.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.opq.org/">Quebec</a>
            <br />
            <a href="https://www.ocpinfo.com/">Ontario</a>
            <br />
            <a href="https://abpharmacy.ca/">Alberta</a>
            <br />
            <a href="https://www.bcpharmacists.org/">British Columbia</a>
            <br />
            <br />
            DENTISTRY:
            <br />
            <a href="http://pdbns.ca/">Nova Scotia</a>
            <br />
            <a href="http://www.odq.qc.ca/Accueil/tabid/127/language/fr-CA/Default.aspx">
              Quebec
            </a>
            <br />
            <a href="https://www.rcdso.org/en-ca/home">Ontario</a>
            <br />
            <a href="https://www.dentalhealthalberta.ca/">Alberta</a>
            <br />
            <a href="https://www.cdsbc.org/registration-renewal/dentists">
              British Columbia
            </a>
            <br />
            <br />
            LAW:
            <br />
            <a href="https://nsbs.org/">Nova Scotia</a>
            <br />
            <a href="https://www.barreau.qc.ca/en/">Quebec</a>
            <br />
            <a href="https://lso.ca/home">Ontario</a>
            <br />
            <a href="https://www.lawsociety.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.lawsociety.bc.ca/">British Columbia</a>
            <br />
            <br />
            MEDICINE:
            <br />
            <a href="https://cpsns.ns.ca/">Nova Scotia</a>
            <br />
            <a href="http://www.cmq.org/">Quebec</a>
            <br />
            <a href="https://www.cpso.on.ca/">Ontario</a>
            <br />
            <a href="http://www.cpsa.ca/">Alberta</a>
            <br />
            <a href="https://www.cpsbc.ca/">British Columbia</a>
            <br />
            <br />
            ARCHITECTURE:
            <br />
            <a href="https://nsaa.ns.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.oaq.com/">Quebec</a>
            <br />
            <a href="https://www.oaa.on.ca/">Ontario</a>
            <br />
            <a href="https://www.aaa.ab.ca/">Alberta</a>
            <br />
            <a href="https://aibc.ca/">British Columbia</a>
            <br />
            <br />
            ENGINEERING:
            <br />
            <a href="https://engineersnovascotia.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.oiq.qc.ca/Pages/accueil.aspx">Quebec</a>
            <br />
            <a href="https://www.peo.on.ca/">Ontario</a>
            <br />
            <a href="https://www.apega.ca/">Alberta</a>
            <br />
            <a href="https://www.egbc.ca/">British Columbia</a>
            <br />
            <br />
            NURSING:
            <br />
            <a href="https://www.nscn.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.oiiq.org/">Quebec</a>
            <br />
            <a href="https://www.cno.org/">Ontario</a>
            <br />
            <a href="https://nurses.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.bccnp.ca/Pages/Default.aspx">British Columbia</a>
            <br />
            <br />
            PSYCHOLOGY:
            <br />
            <a href="https://apns.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.ordrepsy.qc.ca/accueil">Quebec</a>
            <br />
            <a href="https://www.psych.on.ca/">Ontario</a>
            <br />
            <a href="https://psychologistsassociation.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.psychologists.bc.ca/">British Columbia</a>
            <br />
            <br />
            SOCIAL WORK:
            <br />
            <a href="http://nscsw.org/">Nova Scotia</a>
            <br />
            <a href="https://www1.otstcfq.org/">Quebec</a>
            <br />
            <a href="https://www.ocswssw.org/">Ontario</a>
            <br />
            <a href="https://www.acsw.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.bccollegeofsocialworkers.ca/">
              British Columbia
            </a>
            <br />
            <br />
            ACCOUNTANCY:
            <br />
            <a href="https://www.cpans.ca/web/cpans/">Nova Scotia</a>
            <br />
            <a href="https://cpaquebec.ca/en/">Quebec</a>
            <br />
            <a href="https://www.cpaontario.ca/">Ontario</a>
            <br />
            <a href="https://www.cpaalberta.ca/">Alberta</a>
            <br />
            <a href="https://www.bccpa.ca/">British Columbia</a>
            <br />
            <br />`,
        },
        {
          question:
            " Are my children allowed to go to school while my refugee claim is being processed?",
          answer: ` Yes, children of refugee claimants can attend public elementary and
            high schools free of charge. If you need help finding a school in your
            location, please go to our <a href="/explore">EXPLORE</a> page, select
            EDUCATION and then INSTITUTIONS for a list of schools in your region.`,
        },
        {
          question: "How can I find a high school?",
          answer: ` You can find high schools near you by clicking on the city you are
            located in:
            <br />
            <br />
            <a href="https://www.hrce.ca/registration">
              Halifax Public Schools Website
            </a>
            <br />
            <a href="https://www.emsb.qc.ca/emsb/admissions/registration">
              Montreal Public Schools Website
            </a>
            <br />
            <a href="https://ocdsb.ca/">Ottawa Public Schools Website</a>
            <br />
            <a href="https://www.tdsb.on.ca/Find-your/School/Registration">
              Toronto Public Schools Website
            </a>
            <br />
            <a href="https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx">
              Calgary Public Schools Website
            </a>
            <br />
            <a href="https://epsb.ca/schools/register/">
              Edmonton Public Schools Website
            </a>
            <br />
            <a href="https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx">
              Vancouver Public Schools Website
            </a>
            <br />
            <br />`,
        },
        {
          question: "How can I find an elementary school?",
          answer: `You can find elementary schools near you by clicking on the city you
            are located in:
            <br />
            <a href="https://www.hrce.ca/registration">
              Halifax Public Schools Website
            </a>
            <br />
            <a href="https://www.emsb.qc.ca/emsb/admissions/registration">
              Montreal Public Schools Website
            </a>
            <br />
            <a href="https://ocdsb.ca/">Ottawa Public Schools Website</a>
            <br />
            <a href="https://www.tdsb.on.ca/Find-your/School/Registration">
              Toronto Public Schools Website
            </a>
            <br />
            <a href="https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx">
              Calgary Public Schools Website
            </a>
            <br />
            <a href="https://epsb.ca/schools/register/">
              Edmonton Public Schools Website
            </a>
            <br />
            <a href="https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx">
              Vancouver Public Schools Website
            </a>
            <br />
            <br />`,
        },
      ],
    },
    {
      category: "health",
      faq: [
        {
          question: "Can I get health insurance?",
          answer: `Asylum seekers are covered under the
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/health-care/interim-federal-health-program/coverage-summary.html">
              Interim Federal Health Program (IFHP)
            </a>
            but once you are an accepted refugee, your health insurance will be
            provincial and works a bit differently in every province:
            <br />
            <br />
            NOVA SCOTIA:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://novascotia.ca/DHW/msi/health_cards.asp">
              this page
            </a>
            .
            <br />
            <br />
            <br />
            QUEBEC:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www.ramq.gouv.qc.ca/en/citizens/health-insurance/register">
              this page
            </a>
            . <br />
            <br />
            <br />
            ONTARIO:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www.ontario.ca/page/apply-ohip-and-get-health-card">
              this page
            </a>
            <br />
            <br />
            <br />
            ALBERTA:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www.alberta.ca/ahcip-how-to-apply.aspx">
              this page
            </a>
            .
            <br />
            <br />
            <br />
            BRITISH COLUMBIA:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/msp/bc-residents/eligibility-and-enrolment/how-to-enrol">
              this page
            </a>
            .`,
        },
        {
          question: "If I need a doctor or medical attention, where can I go?",
          answer: `To find a clinic or health care services in your area, go to our
            <a href="/explore">EXPLORE</a> page and select HEALTH.`,
        },
        {
          question: "Where can I find a doctor to assist me with my pregnancy?",
          answer: `Congratulations on your pregnancy! Go to our 
            <a href="/explore">EXPLORE</a> page, select HEALTH and then
            WOMEN'S HEALTH. This will give you all the options of
            organizations that can assist you in your area.`,
        },
        {
          question: "How can I get a family doctor?",
          answer: `If you do NOT have a heath care card, you cannot have an assigned
            family doctor- you can however, still seek medical attention at walk
            in clinics. To find a clinic or a health care service in your area, go
            to our <a href="/explore">EXPLORE</a> page and select HEALTH.
            <br />
            <br />
            If you DO have a health care card, you can apply for a doctor but this
            works differently in every province. Select your province below to get
            the right information for you:
            <br />
            <br />
            <a href="https://needafamilypractice.nshealth.ca/">NOVA SCOTIA</a>
            <br />
            <a href="https://www.quebec.ca/en/health/finding-a-resource/registering-with-a-family-doctor/">
              QUEBEC
            </a>
            <br />
            <a href="https://www.ontario.ca/page/find-family-doctor-or-nurse-practitioner.">
              ONTARIO
            </a>
            <br />
            <a href="https://www.albertahealthservices.ca/info/Page13253.aspx">
              ALBERTA
            </a>
            <br />
            <a href="https://bccfp.bc.ca/for-the-public/find-a-family-doctor/">
              BRITISH COLUMBIA
            </a>`,
        },
        {
          question: "Where can I get medication and is it free?",
          answer: `You can purchase medication at local pharmacies. Medication is not
            free, however you may be able to get a reduction on the price if you
            have private insurance.`,
        },
        {
          question: "How can I find mental-health support?",
          answer: ` There are multiple organizations that offer services to help with
            mental health. Please go to our <a href="/explore">EXPLORE</a> page,
            select HEALTH and then MENTAL HEALTH to find organizations/services in
            your area.`,
        },
      ],
    },
    {
      category: "housing",
      faq: [
        {
          question: "Where can I find a place to live?",
          answer: `There are multiple different organizations that can help you with this
            depending on your situation and your needs. To access the
            organizations in your city, go to the <a href="/explore">EXPLORE</a>
            page and select HOUSING.`,
        },
        {
          question: "What are my housing rights as a tenant?",
          answer: `  As a tenant, you have rights. Please click on your province to know
            more.
            <br />
            <br />
            <a href="https://beta.novascotia.ca/programs-and-services/residential-tenancies-program">
              Nova Scotia
            </a>
            <br />
            <a href="https://www.tal.gouv.qc.ca/en">Quebec</a>
            <br />
            <a href="https://www.ontario.ca/laws/statute/06r17">Ontario</a>
            <br />
            <a href="https://www.alberta.ca/landlords-tenants.aspx">Alberta</a>
            <br />
            <a href="https://www2.gov.bc.ca/gov/content/housing-tenancy/residential-tenancies">
              British Columbia
            </a>
            <br />
            <br />
            You can also go to the <a href="/explore">EXPLORE</a> page, select
            HOUSING and then HOUSING RIGHTS to find organizations near you that
            can help you with this subject.`,
        },
      ],
    },
    {
      category: "general_services",
      faq: [
        {
          question: `How do I get a driver's license?`,
          answer: ` Getting a driver's license is different in every province:
            <br />
            <br />
            NOVA SCOTIA: You can drive in Nova Scotia with a license from another
            country for up to 90 days, after that, you will need to get a Nova
            Scotia license. To get a driver's license in Nova Scotia, you need
            to take a full driver licence examination, which includes a knowledge,
            vision, and road tests.
            <a href="https://novascotia.ca/sns/rmv/licence/gradlic.asp">
              Click here
            </a>
            for more information.
            <br />
            <br />
            QUEBEC: If you still have a driver's license from another country,
            then you are eligible to write the theoretical and practical exam to
            obtain your Quebec driver's permit. Click
            <a href="https://saaq.gouv.qc.ca/en/drivers-licences/foreign-drivers-licence/">
              here
            </a>
            for more information. If you do not have any license, you will have to
            prove that you are going to remain in Canada for at least 6 months and
            then you will need to complete driving courses in order to obtain your
            license. For more information, click
            <a href="https://saaq.gouv.qc.ca/en/drivers-licences/obtaining-licence/passenger-vehicle-class-5/">
              here
            </a>
            .<br />
            <br />
            ONTARIO: For newcomers in Ontario, it is recommended that you start
            the process to getting your license as soon as possible.
            <a href="https://settlement.org/ontario/daily-life/transportation/driver-s-licence/how-do-i-get-an-ontario-driver-s-licence/">
              Click here
            </a>
            for more information on how to start the process.
            <br />
            <br />
            ALBERTA: If you still have a driver's license from another
            country, then you might be eligible to exchange it for an Alberta
            license but you must do it within 90 days.
            <a href="https://www.alberta.ca/exchange-non-alberta-licences.aspx#:~:text=hand%20in%20your%20valid%20licence,proof%20of%20residence%20in%20Canada">
              Click here
            </a>
            for more information on how to exchange it. If you cannot exchange
            your license,
            <a href="https://www.alberta.ca/get-drivers-licence.aspx">
              click here
            </a>
            to learn how you can get your driver's license.
            <br />
            <br />
            BRITISH COLOMBIA: If you still have a driver's license from
            another country, then you might be eligible to exchange it for a
            British Colombia license but you must apply within 90 days.
            <a href="https://www.icbc.com/driver-licensing/moving-bc/Pages/Moving-from-another-country.aspx">
              Click here
            </a>
            for more information on how to exchange it. If you cannot exchange
            your license,
            <a href="https://www.icbc.com/driver-licensing/new-drivers/Pages/default.aspx">
              Click here
            </a>
            to learn how you can get your driver's license.
            <br />`,
        },
        {
          question: "How can I get a phone plan?",
          answer: `There are different options available to you depending on what you
            need. There are pre-paid plans (or pay-as-you-go) and monthly contract
            plans. Phone plans in Canada can be a bit expensive but there are many
            different companies with many different plans and prices therefore you
            can choose the one that is best for you. Some examples of companies
            that are more affordable include Chatr, Fizz, Koodo, Virgin Mobile and
            Fido. There are also companies such as Bell, Rogers and Telus. Click
            <a href="https://moving2canada.com/cell-phone-plan-in-canada/">
              here
            </a>
            for more information on phone plans in Canada.`,
        },
        {
          question: "Where can I get furniture?",
          answer: `There are an array of different places where you can find affordable
            furniture. Some places even offer these things for free. For more
            information on where you can go, please return to services and choose
            the option: GENERAL and then choose CLOTHING & FURNITURE. You will
            be able to find what you need there.`,
        },
        {
          question: "Where can I buy clothes?",
          answer: ` There are an array of different places where you can find affordable
            clothing. Some places even offer these things for free. For more
            information on where you can go, please return to services and choose
            the option: GENERAL and then choose CLOTHING & FURNITURE. You will
            be able to find what you need there.`,
        },
        {
          question: "Where can I find free/ low-cost food?",
          answer: ` There are many places that can offer you food. Usually, you will have
            to sign up with a food bank. To find food banks in your area, go to
            our EXLPORE page and select FOOD BANKS.`,
        },
        {
          question: " What child-care is available in my province?",
          answer: `To see a list of organizations that provide services to families,
            youth and children, please go to the <a href="/explore">EXPLORE</a>
            page and select FAMILY or select GENERAL and then YOUTH. You can also
            select GENERAL and then select DAYCARE. To learn a bit more or to find
            general information for child care in your province, click
            <a href="https://findingqualitychildcare.ca/finding-child-care/child-care-in-each-province-and-territory">
              here
            </a>
            .`,
        },
        {
          question: "Where can I find a translator?",
          answer: ` If you require the translation of official documents, you will need a
            certified translator. You can find translators and organizations that
            do translation by going to the <a href="/explore">EXPLORE</a> page:
            Select GENERAL and then TRANSLATION.`,
        },
        {
          question:
            " I need help with my family expenses, can you help me get child benefits?",
          answer: ` As soon as you receive your Social Insurance Number (SIN), you can
            apply for child benefits, as well as other benefits. You can read more
            about it
            <a href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/individuals-leaving-entering-canada-non-residents/newcomers-canada-immigrants.html#tbc">
              here
            </a>
            .`,
        },
        {
          question: "How do I use public transportation?",
          answer: ` To use
            <a href="https://www.halifax.ca/transportation/halifax-transit/fares-tickets-passes">
              Halifax's
            </a>
            transportation system, you need to purchase tickets or a transit pass.
            <br />
            <br />
            In 
            <a href="http://www.stm.info/en/info/fares/opus-cards-and-other-fare-media/opus-card">
              Montreal
            </a>
            , the public transportation is called the STM and consists of the bus,
            the metro and the train. You get around using an OPUS card which can
            be purchased at any metro station.
            <br />
            <br />
            <a href="https://www.octranspo.com/en/plan-your-trip/how-to-ride/">
              OC Transpo
            </a>
            operates Ottawa's useful bus network and a light-rail system known
            as the O-train.
            <br />
            <br />
            <a href="https://ttc.ca/Fares_and_passes/index.jsp">
              The Toronto Transit Commission (TTC)
            </a>
            runs three modes – subway, streetcar and bus – which operate extensive
            routes throughout the city and suburbs. You will need a token or a
            pass to travel on TTC; day and week passes allow for unlimited rides
            on all three forms of public transport. <br />
            <br />
            In <a href="https://www.calgarytransit.com/fares-passes">Calgary</a>,
            you will need to purchase a ticket or a pass to use public
            transportation.
            <br />
            <br />
            <a href="https://www.edmonton.ca/edmonton-transit-system-ets.aspx">
              Edmonton Transit Service (ETS)
            </a>{" "}
            operates two forms of public transit throughout the city: buses and
            the Light Rail Transit (LRT) system.
            <br />
            <br />
            Compass is the reloadable fare card that works everywhere on transit
            in 
            <a href="https://www.translink.ca/Fares-and-Passes/Fare-Pricing.aspx">
              Vancouver
            </a>
            .`,
        },
        {
          question: "Can you help me find a bank?",
          answer: `To find a bank, go to our <a href="/explore">EXPLORE</a> page, select
            FINANCIAL & ACCOUNTING and then BANKING.. To find a list of all
            Canadian banks, click
            <a href="https://www.immigroup.com/news/best-bank-newcomers-canada">
              here
            </a>
            .`,
        },
        {
          question:
            "Do refugees need to do taxes? If yes, can anyone help me with this?",
          answer: ` Filing taxes is very important and they are usually filed before the
            April of the following year. To find a free tax clinic, click
            <a href="https://www.canada.ca/en/revenue-agency/campaigns/free-tax-help.html">
              here
            </a>
            . If you would like to find an organization to assist you, return to
            services and selection Financial & Accounting.`,
        },
      ],
    },
  ],
  fre: [
    {
      category: "employment_and_entrepreneurship",
      faq: [
        {
          question:
            "Puis-je travailler au Canada en tant que demandeur d'asile?",
          answer: `Si vous êtes demandeur d'asile au Canada, vous êtes autorisé à travailler mais vous
      aura besoin d'un permis de travail pour obtenir un emploi. Si vous n'êtes pas sûr si
      vous en avez besoin, consultez ceci
      <a href="https://www.canada.ca/fr/immigration-refugees-citizenship/services/work-canada/permit.html">
      site Internet
      </a>
      <br /> <br />
      Vous pouvez demander un permis de travail en ligne ou par courrier. Pour apprendre à
      postuler, vous pouvez consulter ceci
      <a href="https://www.canada.ca/fr/immigration-refugees-citizenship/services/work-canada/permit.html">
      site Internet
      </a>
      et suivez toutes les étapes.
      <br />
      <br />
      De plus, si vous souhaitez obtenir de l'aide pour demander votre permis de travail,
      sont différentes organisations qui peuvent vous aider. Pour trouver le
      organisations près de chez vous, vous pouvez accéder au
      <a href="/explore"> EXPLORE </a> page et sélectionnez LEGAL puis WORK
      PERMIS.`,
        },
        {
          question:
            "Ai-je besoin d'un permis de travail? Comment en obtenir un?",
          answer: "(Voir la réponse ci-dessus)",
        },
        {
          question: "J'ai besoin d'aide pour trouver un emploi.",
          answer: `Il existe de nombreuses organisations qui peuvent vous aider. Avant
      trouver un emploi, assurez-vous d'avoir un permis de travail. Si tu n'es pas
      sûr que si vous en avez besoin,
      <a href="https://www.canada.ca/fr/immigration-refugees-citizenship/services/work-canada/permit.html">
      cliquez ici
      </a>
      . Vous aurez également besoin d'un CV / CV. Pour plus d'informations concernant votre
      besoins avec un emploi, accédez à notre page <a href="/explore"> EXPLORER </a>
      & sélectionnez EMPLOI ET ENTREPRENEURIAT. De là, vous allez
      trouver des organisations qui peuvent vous aider avec ce dont vous avez besoin. `,
        },
        {
          question: "J'ai besoin d'aide avec mon CV / CV",
          answer: `La création d'un CV est un outil important dont vous avez besoin pour trouver du travail
      Canada. Il existe peut-être des organisations qui peuvent vous aider. Aller
      sur notre page <a href="/explore"> EXPLORE </a> et sélectionnez EMPLOI &
      ENTREPRENEURIAT. Vous pouvez ensuite choisir l'option CV HELP pour trouver
      organisations près de chez vous qui peuvent vous aider dans ce domaine. `,
        },
        {
          question: "Quels sont mes droits en tant que travailleur au Canada?",
          answer: `Quel que soit votre statut, vous avez des droits en tant que travailleur au Canada.
      Vous pouvez cliquer sur les liens ci-dessous pour en savoir plus:
      <br />
      <br />
      <a href = "https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit/temporary/after-apply-next-steps/understand-your-rights-foreign-workers .html ">
      - Vos droits en tant que travailleur étranger
      </a>
      <br />
      <br />
      <a href="https://www.canada.ca/fr/immigration-refugees-citizenship/services/refugees/help-within-canada/rights.html">
      - Droits des demandeurs d'asile
      </a>
      <br />
      <br />
      <a href="https://www.canada.ca/en/employment-social-development/services/foreign-workers/protected-rights.html">
      - Droits des travailleurs étrangers temporaires
      </a> `,
        },
        {
          question: "Comment puis-je créer une entreprise?",
          answer: `Créer une entreprise est une excellente idée! Pour commencer, vous pouvez visiter le
      Gouvernement canadien
      <a href="https://www.canada.ca/fr/services/business/start.html">
      site Internet
      </a>
      . Vous devrez également enregistrer votre entreprise auprès de votre
      gouvernement aussi. Si vous avez besoin d'une consultation supplémentaire, vous pouvez aller
      sur la page <a href="/explore"> EXPLORE </a> et sélectionnez EMPLOI &
      ENTREPRENEURIAT. `,
        },
      ],
    },
    {
      category: "legal",
      faq: [
        {
          question: "Comment demandez-vous l'asile au Canada?",
          answer: `Il existe trois (3) façons de demander le statut de réfugié au Canada.
      <br />
      <br /> - Tout d'abord, vous devrez vous inscrire auprès du Haut
      Bureau de la Commission pour les réfugiés (HCR) dans votre pays. Elles vont
      vous inscrire et pourra vous aider dans votre réinstallation, si
      possible.
      <br />
      <br />
      - Deuxièmement, vous pouvez obtenir un visa de touriste dans votre pays au Canadian
      ambassade pour pouvoir voyager au Canada. Une fois que vous avez atterri au Canada, vous
      peut demander refuge à la frontière, à l'aéroport.
      <br />
      <br />
      - Troisièmement, vous pouvez être parrainé par un groupe de Canadiens
      citoyens du Canada. Ils paieront votre réinstallation si votre cas
      est accepté par le gouvernement canadien.
      <br />
      <br />
      Pour en savoir plus sur les étapes de la demande d'asile
      processus,
      <a href="https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html">
      cliquez ici
      </a>
      . <br />
      <br />
      Pour les organisations qui traitent du parrainage privé, accédez à la
      <a href="/explore"> EXPLORE </a> page, sélectionnez LEGAL puis PRIVATE
      PROGRAMME DE PARRAINAGE.
      <br /> `,
        },
        {
          question:
            "Quel est le temps d'attente pour recevoir une réponse concernant ma demande d'asile?",
          answer: `Les autorités fédérales sont seules responsables du traitement des demandes
      fois. Vous pouvez consulter les délais fédéraux en
      <a href="https://www.canada.ca/fr/immigration-refugees-citizenship/services/application/check-processing-times.html">
      cliquez ici
      </a>
      . <br /> `,
        },
        {
          question:
            "Comment savoir si ma demande de statut de réfugié sera acceptée?",
          answer: `Il n'y a pas de moyen garanti de savoir si vous serez accepté depuis
      décideur unique de la Commission de l'immigration et du statut de réfugié du Canada
      entendra votre témoignage et devra décider si votre demande est crédible.
      C'est pourquoi il est essentiel de fournir une preuve documentaire de votre histoire
      et d'être bien préparé pour votre audience et d'embaucher un avocat
      un expert en droit des réfugiés. `,
        },
        {
          question:
            "J'ai été accepté, est-ce que les membres de ma famille sont également admissibles?",
          answer: `Oui, dans la plupart des cas, Immigration, Réfugiés et Citoyenneté Canada (IRCC,
      anciennement CIC) respecte le principe de l'unité familiale. Par conséquent, un
      la famille aura généralement une audience et une décision. Si tu as besoin
      aide juridique ou plus d'informations sur votre cas, rendez-vous sur le
      <a href="/explore"> EXPLORE </a> page et sélectionnez LEGAL pour une liste de
      organisations qui peuvent vous aider. <br /> `,
        },
        {
          question: "Ma demande a été refusée / refusée, que puis-je faire?",
          answer: `Vous êtes autorisé à faire appel de la décision s'ils rejettent votre
      demande de statut de réfugié / d'asile. Vous avez le droit de rester au Canada pendant que vous
      demander un appel et faire des soumissions pour un risque avant renvoi
      Évaluation. Vous pouvez également faire une demande de résidence permanente
      au Canada pour des motifs d'ordre humanitaire. Vous pouvez
      consulter ceci
      <a href="https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html">
      page
      </a>
      pour plus d'informations. `,
        },
        {
          question: "Comment puis-je venir au Canada?",
          answer: `Si vous êtes en dehors de votre pays d'origine, vous devez d'abord faire une demande
      Haut-commissariat des Nations Unies pour les réfugiés (HCR). Vous avez généralement
      pour montrer que vous réussirez économiquement au Canada. Également
      la définition du réfugié est plus restrictive, vous n'avez pas droit à un
      avocat lors de votre entrevue, et la période d'attente pourrait être de plusieurs années.
      Vous devez également montrer que vous ne pouvez pas être installé dans le pays où vous
      sont actuellement en.
      <br />
      <br />
      Dans l'ensemble, les personnes viennent au Canada de plusieurs façons différentes:
      <br /> - Vous pouvez vous inscrire au HCR comme nous l'avons expliqué ci-dessus. <br /> -
      Une autre façon est de demander un visa touristique dans votre pays au
      Ambassade du Canada pour pouvoir voyager au Canada. Une fois que vous atterrissez
      Canada, vous pouvez demander refuge à la frontière, à l'aéroport. Si vous
      venez au Canada par voie terrestre, vous demandez l'asile lorsque vous traversez la frontière.
      <br /> - Enfin, vous pouvez être parrainé en privé par un groupe de
      citoyens du Canada. Ils paieront votre réinstallation si votre cas
      est accepté par le gouvernement canadien.
      <br />
      <br />
      Pour les organisations qui traitent du parrainage privé, accédez à la
      <a href="/explore"> EXPLORE </a> page, sélectionnez LEGAL puis PRIVATE
      PROGRAMME DE PARRAINAGE.
      <br />
      <br />
      Pour une explication de la différence entre parrainé par le gouvernement et
      parrainé en privé,
      <a href="https://www.canada.ca/fr/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/refugee-protection/resettlement/quebec-procedures.html">
      cliquez ici
      </a> `,
        },
        {
          question: "Quels sont les droits des réfugiés au Canada?",
          answer: `Lorsqu'un demandeur d'asile arrive pour la première fois au Canada, il doit
      demande de protection auprès du gouvernement canadien afin d'être accordé
      statut de réfugié. Pendant le temps où cette réclamation est évaluée, un
      le demandeur d'asile a certains droits.
      <br />
      <br />
      Si une demande de protection aboutit, les demandeurs d'asile sont accordés
      Le statut de "personne protégée", également appelé statut de réfugié, puis
      avoir accès à des avantages supplémentaires. Les réfugiés acceptés ont la même
      droits en tant que résidents permanents.
      <br />
      <br />
      Certains réfugiés qui ont fait leur demande d'asile dans un autre pays sont
      réinstallé au Canada. Ces réfugiés réinstallés reçoivent une
      assistance (généralement fournie pour une durée maximale d'un an)
      le gouvernement fédéral, le gouvernement provincial ou des sponsors privés.
      Les réfugiés réinstallés deviennent des résidents permanents et peuvent éventuellement
      devenir citoyens du Canada. Si vous avez besoin de plus d'informations concernant
      droits des réfugiés, veuillez accéder à notre page <a href="/explore"> EXPLORER </a>
      et sélectionnez LEGAL pour trouver une organisation qui peut répondre à vos
      questions. `,
        },
        {
          question: "Que signifie un PR? Que signifie le CSQ?",
          answer: `Le certificat de sélection du Québec (certificat de sélection du Québec),
      ou CSQ, est un document délivré par le ministère de l'Immigration,
      Diversité et inclusion déclarant que le titulaire a été sélectionné
      s'installer au Québec. Avec un CSQ, le demandeur peut demander une
      résidence permanente. Ce document n'est pas un visa de résidence permanente.
      La CSQ est valable 24 mois ou jusqu'à Immigration, Réfugiés et
      Citoyenneté Canada (IRCC) prend une décision sur un permanent canadien
      demande de résidence. Pour plus d'informations et pour postuler, consultez ce
      page.
      <br />
      <br /> Un résident permanent est une personne qui a reçu
      statut de résident en immigrant au Canada, mais n'est pas un Canadien
      citoyenne. Les résidents permanents sont des citoyens d'autres pays. Une personne
      au Canada temporairement, comme un étudiant ou un travailleur étranger, n'est pas
      résident permanent. Les réfugiés réinstallés de l'étranger deviennent
      résidents permanents par le biais du Programme d'aide aux réfugiés par le gouvernement ou
      le programme de parrainage privé de réfugiés.
      <br />
      <br />
      Une personne qui présente une demande d'asile au Canada ne devient pas
      résident permanent à ce moment-là. Pour devenir un, l'Immigration et
      La Commission du statut de réfugié doit d'abord approuver sa demande. Ensuite, ils doivent postuler
      et obtenir le statut de résident permanent. Pour plus d'informations et pour
      postuler, consulter ceci
      <a href = "https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-q7000-application-permanent-residence-quebec-skilled-worker-class .html ">
      page
      </a>
      . <br /> `,
        },
        {
          question: "Comment puis-je obtenir un avocat / une aide juridique?",
          answer: `Il existe de nombreuses organisations qui offrent une aide juridique. Pour les trouver
      le plus proche de vous, rendez-vous sur la page EXLPORE et sélectionnez LEGAL.`,
        },
      ],
    },
    {
      category: "education",
      faq: [
        {
          question: "Ai-je besoin d'un permis d'études? Comment en obtenir un?",
          answer: `Pour étudier au Canada, il est possible que vous ayez besoin d'un
      permis d'études. Si vous êtes un demandeur du statut de réfugié sur un résident temporaire
      Visa (TRV) tel qu'un visa de visiteur ou un permis de travail, vous pouvez faire un
      cours de courte durée pendant que votre visa de résident temporaire est valide. Si vous
      suivre un cours de plus de 6 mois, vous devrez postuler
      pour un permis d'études. Demandeurs du statut de réfugié qui n'ont pas de
      Le visa de résident temporaire doit également demander un permis d'études. Si vous
      avoir un permis d'études valide, vous pouvez légalement étudier dans un établissement postsecondaire
      institution. Pour plus d'informations sur les permis d'études, veuillez
      <a href = "https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/study-permits/refugees-protected-persons.html ">
      cliquez ici
      </a>
      <br />
      <br />
      Si vous avez besoin d'aide pour postuler à l'école, souhaitez connaître vos options ou simplement
      avez des questions liées aux études au Canada, il y a des organisations
      cela peut vous aider. Accédez à notre <a href="/explore">
      EXPLORER
      </a>
      page, sélectionnez ÉDUCATION, puis sélectionnez AIDE ACADÉMIQUE ET CONSEIL
      pour trouver des organisations près de chez vous qui peuvent vous aider. `,
        },
        {
          question: "Je souhaite postuler à l'université. Que dois-je faire?",
          answer: `Il existe de nombreuses organisations qui peuvent vous aider. Accédez à notre
      <a href="/explore"> EXPLORER </a>, sélectionnez ÉDUCATION, puis
      AIDE ACADÉMIQUE ET CONSEIL `,
        },
        {
          question: "Comment puis-je trouver une université?",
          answer: `Vous pouvez trouver des universités près de chez vous en cliquant sur la ville où vous vous trouvez
      situé dans:
      <br />
      <a href="https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/">
      Universités d'Halifax
      </a>
      <br />
      <a href="https://www.immigration-quebec.gouv.qc.ca/fr/immigrate-settle/students/applying-admission/choosing-institution/index.html">
      Universités de Montréal
      </a>
      <br />
      <a href="https://www.ontario.ca/page/ontario-universities">
      Universités de l'Ontario
      </a>
      <br />
      <a href="https://www.lifeincalgary.ca/schools/post-secondary">
      Universités de Calgary
      </a>
      <br />
      <a href="https://www.collegealberta.info/edmonton.php">
      Universités d'Edmonton
      </a>
      <br />
      <a href="https://www2.gov.bc.ca/gov/content/education-training/post-secondary-education/find-a-program-or-institution/find-an-institution">
      Universités de Vancover
      </a> `,
        },
        {
          question: "Comment puis-je trouver une école professionnelle?",
          answer: `Pour trouver un programme de formation professionnelle dans votre région, veuillez retourner
      à SERVICES, sélectionnez ÉDUCATION puis PROFESSIONNEL. Vous pouvez également visiter
      <br />
      <a href="https://www.nscc.ca/learning_programs/schools/trades/index.asp">
      Écoles professionnelles en Nouvelle-Écosse
      </a>
      <br />
      <a href="https://www.emsb.qc.ca/emsb/schools/adult-vocational/centres">
      Écoles professionnelles à Montréal
      </a>
      <br />
      <a href="https://www.ontariocolleges.ca/en/apply/skilled-trades">
      Écoles professionnelles en Ontario
      </a>
      <br />
      <a href="https://www.vocationalschools.ca/search#">
      Écoles professionnelles en Alberta
      </a>
      <br />
      <a href="https://www.tradestrainingbc.ca/">
      Écoles professionnelles en Colombie-Britannique
      </a> `,
        },
        {
          question: "Comment puis-je trouver un collège?",
          answer: `Pour trouver un collège, veuillez retourner à SERVICES, sélectionnez ÉDUCATION et
      puis COLLEGE. Vous trouverez une liste des collèges dans votre région.
      <br />
      <a href="https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/">
      Halifax
      </a>
      <br />
      <a href="https://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/students/applying-admission/choosing-institution/index.html">
      Québec
      </a>
      <br />
      <a href="https://www.ontariocolleges.ca/en/colleges"> Ontario </a>
      <br />
      <a href="https://www.lifeincalgary.ca/schools/post-secondary">
      Calgary
      </a>
      <br />
      <a href="https://www.collegealberta.info/edmonton.php"> Edmonton </a>
      <br />
      <a href="https://www.thoughtco.com/guide-to-universities-colleges-in-vancouver-3371270">
      Vancouver
      </a> `,
        },
        {
          question: "Puis-je obtenir une aide financière pour l'école?",
          answer: `Cela fonctionne un peu différemment dans chaque province:
      <br />
      <br />
      NOUVELLE-ÉCOSSE:
      <br />
      <br />
      Dans la plupart des cas, les demandeurs d'asile devront payer des frais internationaux
      jusqu'à ce qu'ils aient été désignés comme réfugiés ou personnes protégées; tu
      devrait vérifier avec votre école pour leurs règles. <br />
      <br />
      En tant qu'étudiant en Nouvelle-Écosse, vous avez le droit de demander une variété
      de différents prêts et subventions, tant au niveau provincial que fédéral. Cliquez sur
      <a href="https://novascotia.ca/studentassistance/apply/new/FundingAvailable.asp">
      ici
      </a>
      pour en savoir plus sur les options disponibles.
      <br />
      <br />
      QUÉBEC:
      <br />
      Dans la plupart des cas, les demandeurs d'asile devront payer des frais internationaux
      jusqu'à ce qu'ils aient été désignés comme réfugiés ou personnes protégées; tu
      devrait vérifier avec votre école leurs règles. <br />
      <br />
      Vous pouvez être admissible à certaines bourses et subventions et à l'aide
      financière aux études (AFE). Citoyens canadiens, résidents permanents
      et les réfugiés ou personnes protégées sont admissibles à l'aide québécoise de l'AFE
      programme s'ils sont réputés résidents du Québec pour l'aide aux étudiants
      fins au moment de la demande.
      <br />
      Dans cette section, vous trouverez des informations sur les AFE
      <a href="https://www.quebec.ca/education/aide-financiere-aux-etudes/prets-bourses-temps-plein/conditions-admissibilite/">
      critères d'admissibilité
      </a>
      , le processus de candidature, les délais et d'autres programmes importants
      détails.
      <br />
      <br />
      ONTARIO:
      <br />
      Dans la plupart des cas, les demandeurs d'asile devront payer des frais internationaux
      jusqu'à ce qu'ils aient été désignés comme réfugiés ou personnes protégées; tu
      devrait vérifier avec votre école leurs règles. <br />
      <br />
      Si vous êtes une "personne protégée" ou un réfugié au sens de la Convention, vous êtes
      éligible pour demander le RAFEO. Cependant, certains documents
      vous devez avoir avant de postuler. Pour plus d'informations sur l'éligibilité
      et détails spécifiques sur le RAFEO
      <a href="https://osap.gov.on.ca/prodconsumption/groups/forms/documents/forms/prdr020621.pdf">
      cliquez ici
      </a>
      .
      <br />
      <br />
      ALBERTA:
      <br />
      <br />
      Dans la plupart des cas, les demandeurs d'asile devront payer des frais internationaux
      jusqu'à ce qu'ils aient été désignés comme réfugiés ou personnes protégées; tu
      devrait vérifier avec votre école leurs règles.
      <a href="https://studentaid.alberta.ca/before-you-apply/eligible/">
      Aide aux étudiants de l'Alberta
      </a>
      offre des prêts étudiants pour des études à temps partiel et à temps plein. Recevoir
      aide aux étudiants pour les études à temps plein, vous devez satisfaire à toutes ces
      critère d'éligibilité:
      <br /> - Être citoyen canadien, résident permanent ou avoir protégé
      le statut de personne en vertu de la Loi sur l’immigration et la protection des réfugiés du Canada.
      Les étudiants titulaires d'un permis d'études ne sont pas éligibles. <br />
      - Être résident de l'Alberta <br />
      - Être inscrit en tant qu'étudiant à temps plein prenant au moins 60% d'un
      charge de cours (40% d'une charge de cours complète si vous êtes un étudiant avec
      invalidité permanente) <br />
      - Être inscrit à un programme admissible dans un établissement d'enseignement postsecondaire approuvé
      école
      <br />
      - Avoir un besoin financier
      <br />
      <br />
      COLOMBIE BRITANNIQUE:
      <br />
      Dans la plupart des cas, les demandeurs d'asile devront payer des frais internationaux
      jusqu'à ce qu'ils aient été désignés comme réfugiés ou personnes protégées; tu
      devrait vérifier avec votre école leurs règles.
      <a href="https://studentaidbc.ca/apply/eligible">
      StudentAid BC
      </a>
      s'adresse aux étudiants qui ont besoin d'aide pour payer leurs études postsecondaires
      éducation. Pour bénéficier de l'aide financière aux étudiants, vous devez répondre à tous
      de ces critères d'admissibilité de base: <br /> - Être citoyen canadien,
      résident permanent ou avoir le statut de personne protégée <br /> - Avoir un
      numéro d'assurance sociale canadien valide <br /> - Être résident de
      Colombie-Britannique <br /> - Poursuivez des études à temps plein en tant que
      profession <br /> - Être inscrit à un programme d'études admissible dans un
      établissement postsecondaire admissible
      <br /> - Être capable de démontrer un besoin financier <br /> - Rendre académique
      progresser et atteindre un niveau scolaire satisfaisant à chaque période
      d'études postsecondaires
      <br />
      <br />
      --------------
      <br />
      <br />
      Vous pouvez consulter une autre opportunité potentielle d'aide financière
      <a href="https://srp.wusc.ca/"> ici </a> .`,
        },
        {
          question: "Je voudrais apprendre le français et / ou l'anglais.",
          answer: `Si vous souhaitez apprendre une langue dans votre ville, veuillez retourner à
      services et choisissez l'option: Langues puis choisissez la langue
      vous êtes intéressé à apprendre: anglais, français, IELTS ou
      Classes conversationnelles. De là, vous trouverez des organisations à proximité
      vous qui offrez ces cours. `,
        },
        {
          question: "Où puis-je apprendre à coder?",
          answer: `Super idée! L'industrie de la technologie est en plein essor au Canada. Il y a souvent
      des bootcamps qui sont de courts cours intensifs de 6 à 8 semaines sur la façon d'apprendre à
      code. Pour en trouver dans votre région, rendez-vous sur le
      <a href="/explore"> EXPLORER </a> et sélectionnez EMPLOI &
      ENTREPRENEURIAT. `,
        },
        {
          question:
            "Comment puis-je faire reconnaître mes informations d'identification étrangères?",
          answer: `Pour pouvoir pratiquer, vous devez contacter la commande respective.
      <br />
      <br />
      PHARMACIE:
      <br />
      <a href="https://www.nspharmacists.ca/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://www.opq.org/"> Québec </a>
      <br />
      <a href="https://www.ocpinfo.com/"> Ontario </a>
      <br />
      <a href="https://abpharmacy.ca/"> Alberta </a>
      <br />
      <a href="https://www.bcpharmacists.org/"> Colombie-Britannique </a>
      <br />
      <br />
      DENTISTERIE:
      <br />
      <a href="http://pdbns.ca/"> Nouvelle-Écosse </a>
      <br />
      <a href="http://www.odq.qc.ca/Accueil/tabid/127/language/fr-CA/Default.aspx">
      Québec
      </a>
      <br />
      <a href="https://www.rcdso.org/en-ca/home"> Ontario </a>
      <br />
      <a href="https://www.dentalhealthalberta.ca/"> Alberta </a>
      <br />
      <a href="https://www.cdsbc.org/registration-renewal/dentists">
      Colombie britannique
      </a>
      <br />
      <br />
      LOI:
      <br />
      <a href="https://nsbs.org/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://www.barreau.qc.ca/fr/"> Québec </a>
      <br />
      <a href="https://lso.ca/home"> Ontario </a>
      <br />
      <a href="https://www.lawsociety.ab.ca/"> Alberta </a>
      <br />
      <a href="https://www.lawsociety.bc.ca/"> Colombie-Britannique </a>
      <br />
      <br />
      MÉDICAMENT:
      <br />
      <a href="https://cpsns.ns.ca/"> Nouvelle-Écosse </a>
      <br />
      <a href="http://www.cmq.org/"> Québec </a>
      <br />
      <a href="https://www.cpso.on.ca/"> Ontario </a>
      <br />
      <a href="http://www.cpsa.ca/"> Alberta </a>
      <br />
      <a href="https://www.cpsbc.ca/"> Colombie-Britannique </a>
      <br />
      <br />
      ARCHITECTURE:
      <br />
      <a href="https://nsaa.ns.ca/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://www.oaq.com/"> Québec </a>
      <br />
      <a href="https://www.oaa.on.ca/"> Ontario </a>
      <br />
      <a href="https://www.aaa.ab.ca/"> Alberta </a>
      <br />
      <a href="https://aibc.ca/"> Colombie-Britannique </a>
      <br />
      <br />
      INGÉNIERIE:
      <br />
      <a href="https://engineersnovascotia.ca/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://www.oiq.qc.ca/Pages/accueil.aspx"> Québec </a>
      <br />
      <a href="https://www.peo.on.ca/"> Ontario </a>
      <br />
      <a href="https://www.apega.ca/"> Alberta </a>
      <br />
      <a href="https://www.egbc.ca/"> Colombie-Britannique </a>
      <br />
      <br />
      ALLAITEMENT:
      <br />
      <a href="https://www.nscn.ca/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://www.oiiq.org/"> Québec </a>
      <br />
      <a href="https://www.cno.org/"> Ontario </a>
      <br />
      <a href="https://nurses.ab.ca/"> Alberta </a>
      <br />
      <a href="https://www.bccnp.ca/Pages/Default.aspx"> Colombie-Britannique </a>
      <br />
      <br />
      PSYCHOLOGIE:
      <br />
      <a href="https://apns.ca/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://www.ordrepsy.qc.ca/accueil"> Québec </a>
      <br />
      <a href="https://www.psych.on.ca/"> Ontario </a>
      <br />
      <a href="https://psychologistsassociation.ab.ca/"> Alberta </a>
      <br />
      <a href="https://www.psychologists.bc.ca/"> Colombie-Britannique </a>
      <br />
      <br />
      TRAVAIL SOCIAL:
      <br />
      <a href="http://nscsw.org/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://www1.otstcfq.org/"> Québec </a>
      <br />
      <a href="https://www.ocswssw.org/"> Ontario </a>
      <br />
      <a href="https://www.acsw.ab.ca/"> Alberta </a>
      <br />
      <a href="https://www.bccollegeofsocialworkers.ca/">
      Colombie britannique
      </a>
      <br />
      <br />
      COMPTABILITÉ:
      <br />
      <a href="https://www.cpans.ca/web/cpans/"> Nouvelle-Écosse </a>
      <br />
      <a href="https://cpaquebec.ca/fr/"> Québec </a>
      <br />
      <a href="https://www.cpaontario.ca/"> Ontario </a>
      <br />
      <a href="https://www.cpaalberta.ca/"> Alberta </a>
      <br />
      <a href="https://www.bccpa.ca/"> Colombie-Britannique </a>
      <br />
      <br /> `,
        },
        {
          question:
            "Mes enfants sont-ils autorisés à aller à l'école pendant le traitement de ma demande d'asile?",
          answer: `Oui, les enfants des demandeurs d'asile peuvent fréquenter les écoles primaires
      lycées gratuits. Si vous avez besoin d'aide pour trouver une école dans votre
      lieu, veuillez accéder à notre page <a href="/explore"> EXPLORE </a>, sélectionnez
      EDUCATION puis INSTITUTIONS pour une liste des écoles de votre région. `,
        },
        {
          question: "Comment puis-je trouver un lycée?",
          answer: `Vous pouvez trouver des lycées près de chez vous en cliquant sur la ville où vous vous trouvez
      situé dans:
      <br />
      <br />
      <a href="https://www.hrce.ca/registration">
      Site Web des écoles publiques d'Halifax
      </a>
      <br />
      <a href="https://www.emsb.qc.ca/emsb/admissions/registration">
      Site Web des écoles publiques de Montréal
      </a>
      <br />
      <a href="https://ocdsb.ca/"> Site Web des écoles publiques d'Ottawa </a>
      <br />
      <a href="https://www.tdsb.on.ca/Find-your/School/Registration">
      Site Web des écoles publiques de Toronto
      </a>
      <br />
      <a href="https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx">
      Site Web des écoles publiques de Calgary
      </a>
      <br />
      <a href="https://epsb.ca/schools/register/">
      Site Web des écoles publiques d'Edmonton
      </a>
      <br />
      <a href="https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx">
      Site Web des écoles publiques de Vancouver
      </a>
      <br />
      <br /> `,
        },
        {
          question: "Comment puis-je trouver une école primaire?",
          answer: `Vous pouvez trouver les écoles primaires près de chez vous en cliquant sur la ville que vous
      sont situés dans:
      <br />
      <a href="https://www.hrce.ca/registration">
      Site Web des écoles publiques d'Halifax
      </a>
      <br />
      <a href="https://www.emsb.qc.ca/emsb/admissions/registration">
      Site Web des écoles publiques de Montréal
      </a>
      <br />
      <a href="https://ocdsb.ca/"> Site Web des écoles publiques d'Ottawa </a>
      <br />
      <a href="https://www.tdsb.on.ca/Find-your/School/Registration">
      Site Web des écoles publiques de Toronto
      </a>
      <br />
      <a href="https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx">
      Site Web des écoles publiques de Calgary
      </a>
      <br />
      <a href="https://epsb.ca/schools/register/">
      Site Web des écoles publiques d'Edmonton
      </a>
      <br />
      <a href="https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx">
      Site Web des écoles publiques de Vancouver
      </a>
      <br />
      <br /> `,
        },
      ],
    },
    {
      category: "health",
      faq: [
        {
          question: "Puis-je souscrire à une assurance maladie?",
          answer: `Les demandeurs d'asile sont couverts par la
      <a href = "https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/health-care/interim-federation-health-program/coverage-summary.html ">
      Programme fédéral de santé intérimaire (PFSI)
      </a>
      mais une fois que vous êtes un réfugié accepté, votre assurance maladie sera
      provincial et fonctionne un peu différemment dans chaque province:
      <br />
      <br />
      NOUVELLE-ÉCOSSE:
      <br />
      Vous êtes admissible aux soins de santé provinciaux une fois que vous
      accepté comme réfugié. Pour savoir comment vous inscrire, visitez
      <a href="https://novascotia.ca/DHW/msi/health_cards.asp">
      cette page
      </a>
      .
      <br />
      <br />
      <br />
      QUÉBEC:
      <br />
      Vous êtes admissible aux soins de santé provinciaux une fois que vous
      accepté comme réfugié. Pour savoir comment vous inscrire, visitez
      <a href="https://www.ramq.gouv.qc.ca/en/citizens/health-insurance/register">
      cette page
      </a>
      . <br />
      <br />
      <br />
      ONTARIO:
      <br />
      Vous êtes admissible aux soins de santé provinciaux une fois que vous
      accepté comme réfugié. Pour savoir comment vous inscrire, visitez
      <a href="https://www.ontario.ca/page/apply-ohip-and-get-health-card">
      cette page
      </a>
      <br />
      <br />
      <br />
      ALBERTA:
      <br />
      Vous êtes admissible aux soins de santé provinciaux une fois que vous
      accepté comme réfugié. Pour savoir comment vous inscrire, visitez
      <a href="https://www.alberta.ca/ahcip-how-to-apply.aspx">
      cette page
      </a>
      .
      <br />
      <br />
      <br />
      COLOMBIE BRITANNIQUE:
      <br />
      Vous êtes admissible aux soins de santé provinciaux une fois que vous
      accepté comme réfugié. Pour savoir comment vous inscrire, visitez
      <a href="https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/msp/bc-residents/eligible-and-enrolment/how-to-enrol">
      cette page
      </a>
      .`,
        },
        {
          question:
            "Si j'ai besoin d'un médecin ou de soins médicaux, où puis-je aller?",
          answer: `Pour trouver une clinique ou des services de santé dans votre région, rendez-vous sur notre
      <a href="/explore"> EXPLORER </a> et sélectionnez SANTÉ.`,
        },
        {
          question:
            "Où puis-je trouver un médecin pour m'aider dans ma grossesse?",
          answer: `Félicitations pour votre grossesse! Accédez à notre
      <a href="/explore"> EXPLORER </a>, sélectionnez SANTÉ, puis
      SANTÉ DES FEMMES. Cela vous donnera toutes les options de
      organisations qui peuvent vous assister dans votre région. `,
        },
        {
          question: "Comment puis-je trouver un médecin de famille?",
          answer: `Si vous n'avez PAS de carte de soins de santé, vous ne pouvez pas
      médecin de famille - vous pouvez cependant toujours consulter un médecin à pied
      dans les cliniques. Pour trouver une clinique ou un service de santé dans votre région, allez
      sur notre page <a href="/explore"> EXPLORE </a> et sélectionnez SANTÉ.
      <br />
      <br />
      Si vous avez une carte de soins de santé, vous pouvez demander un médecin, mais ceci
      fonctionne différemment dans chaque province. Sélectionnez votre province ci-dessous pour obtenir
      la bonne information pour vous:
      <br />
      <br />
      <a href="https://needafamilypractice.nshealth.ca/"> NOUVELLE-ÉCOSSE </a>
      <br />
      <a href="https://www.quebec.ca/fr/health/finding-a-resource/registering-with-a-family-doctor/">
      QUÉBEC
      </a>
      <br />
      <a href="https://www.ontario.ca/page/find-family-doctor-or-nurse-practitioner.">
      ONTARIO
      </a>
      <br />
      <a href="https://www.albertahealthservices.ca/info/Page13253.aspx">
      ALBERTA
      </a>
      <br />
      <a href="https://bccfp.bc.ca/for-the-public/find-a-family-doctor/">
      COLOMBIE BRITANNIQUE
      </a> `,
        },
        {
          question: "Où puis-je me procurer des médicaments et est-ce gratuit?",
          answer: `Vous pouvez acheter des médicaments dans les pharmacies locales. Les médicaments ne sont pas
      gratuit, mais vous pourrez peut-être obtenir une réduction sur le prix si vous
      avoir une assurance privée. `,
        },
        {
          question:
            "Comment puis-je trouver un soutien en matière de santé mentale?",
          answer: `Il existe plusieurs organisations qui proposent des services pour aider
      santé mentale. Veuillez accéder à notre page <a href="/explore"> EXPLORE </a>,
      sélectionnez SANTÉ puis SANTÉ MENTALE pour trouver des organisations / services dans
      votre région. `,
        },
      ],
    },
    {
      category: "housing",
      faq: [
        {
          question: "Où puis-je trouver un logement?",
          answer: `Il existe plusieurs organisations différentes qui peuvent vous aider
      selon votre situation et vos besoins. Pour accéder au
      organisations de votre ville, accédez à <a href="/explore"> EXPLORE </a>
      page et sélectionnez HOUSING.`,
        },
        {
          question: "Quels sont mes droits au logement en tant que locataire?",
          answer: `En tant que locataire, vous avez des droits. Veuillez cliquer sur votre province pour savoir
      plus.
      <br />
      <br />
      <a href="https://beta.novascotia.ca/programs-and-services/residential-tenancies-program">
      Nouvelle-Écosse
      </a>
      <br />
      <a href="https://www.tal.gouv.qc.ca/fr"> Québec </a>
      <br />
      <a href="https://www.ontario.ca/laws/statute/06r17"> Ontario </a>
      <br />
      <a href="https://www.alberta.ca/landlords-tenants.aspx"> Alberta </a>
      <br />
      <a href="https://www2.gov.bc.ca/gov/content/housing-tenancy/residential-tenancies">
      Colombie britannique
      </a>
      <br />
      <br />
      Vous pouvez également accéder à la page <a href="/explore"> EXPLORE </a>, sélectionnez
      LOGEMENT puis DROITS AU LOGEMENT pour trouver des organisations près de chez vous qui
      peut vous aider sur ce sujet. `,
        },
      ],
    },
    {
      category: "general_services",
      faq: [
        {
          question: `Comment obtenir un permis de conduire?`,
          answer: `Obtenir un permis de conduire est différent dans chaque province:
      <br />
      <br />
      NOUVELLE-ÉCOSSE: Vous pouvez conduire en Nouvelle-Écosse avec un permis d'un autre
      pays jusqu'à 90 jours, après cela, vous devrez obtenir un Nova
      Licence Scotia. Pour obtenir un permis de conduire en Nouvelle-Écosse, vous devez
      passer un examen complet du permis de conduire, qui comprend une connaissance,
      vision et essais routiers.
      <a href="https://novascotia.ca/sns/rmv/licence/gradlic.asp">
      Cliquez ici
      </a>
      pour plus d'informations.
      <br />
      <br />
      QUÉBEC: Si vous avez toujours un permis de conduire d'un autre pays,
      alors vous êtes éligible pour passer l'examen théorique et pratique
      obtenir votre permis de conduire du Québec. Cliquez sur
      <a href="https://saaq.gouv.qc.ca/en/drivers-licences/foreign-drivers-licence/">
      ici
      </a>
      pour plus d'informations. Si vous n'avez pas de licence, vous devrez
      prouvez que vous allez rester au Canada pendant au moins 6 mois et
      Ensuite, vous devrez suivre des cours de conduite afin d'obtenir votre
      Licence. Pour plus d'informations, cliquez sur
      <a href="https://saaq.gouv.qc.ca/en/drivers-licences/obtaining-licence/passenger-vehicle-class-5/">
      ici
      </a>
      . <br />
      <br />
      ONTARIO: Pour les nouveaux arrivants en Ontario, il est recommandé de commencer
      le processus pour obtenir votre licence dès que possible.
      <a href="https://settlement.org/ontario/daily-life/transportation/driver-s-licence/how-do-i-get-an-ontario-driver-s-licence/">
      Cliquez ici
      </a>
      pour plus d'informations sur la façon de démarrer le processus.
      <br />
      <br />
      ALBERTA: Si vous avez toujours un permis de conduire d'un autre
      pays, alors vous pourriez être admissible à l'échanger contre un Alberta
      licence mais vous devez le faire dans les 90 jours.
      <a href = "https://www.alberta.ca/exchange-non-alberta-licences.aspx#:~:text=hand%20in%20your%20valid%20licence,proof%20of%20residence%20in%20Canada" >
      Cliquez ici
      </a>
      pour plus d'informations sur la façon de l'échanger. Si vous ne pouvez pas échanger
      votre licence,
      <a href="https://www.alberta.ca/get-drivers-licence.aspx">
      cliquez ici
      </a>
      pour savoir comment obtenir votre permis de conduire.
      <br />
      <br />
      COLOMBIE BRITANNIQUE: Si vous avez toujours un permis de conduire
      un autre pays, vous pourrez peut-être l'échanger contre un
      Licence Colombie-Britannique, mais vous devez en faire la demande dans les 90 jours
      <a href="https://www.icbc.com/driver-licensing/moving-bc/Pages/Moving-from-another-country.aspx">
      Cliquez ici
      </a>
      pour plus d'informations sur la façon de l'échanger. Si vous ne pouvez pas échanger
      votre licence,
      <a href="https://www.icbc.com/driver-licensing/new-drivers/Pages/default.aspx">
      Cliquez ici
      </a>
      pour savoir comment obtenir votre permis de conduire.
      <br /> `,
        },
        {
          question: "Comment puis-je obtenir un forfait téléphonique?",
          answer: `Différentes options s'offrent à vous en fonction de ce que vous
      avoir besoin. Il existe des forfaits prépayés (ou par répartition) et des contrats mensuels
      des plans. Les forfaits téléphoniques au Canada peuvent être un peu chers, mais il y en a beaucoup
      différentes entreprises avec de nombreux plans et prix différents donc vous
      peut choisir celui qui vous convient le mieux. Quelques exemples d'entreprises
      qui sont plus abordables incluent Chatr, Fizz, Koodo, Virgin Mobile et
      Fido. Il existe également des entreprises comme Bell, Rogers et Telus. Cliquez sur
      <a href="https://moving2canada.com/cell-phone-plan-in-canada/">
      ici
      </a>
      pour plus d'informations sur les forfaits téléphoniques au Canada. `,
        },
        {
          question: "Où puis-je me procurer des meubles?",
          answer: `Il existe une multitude d'endroits où vous pouvez trouver des
      meubles. Certains endroits offrent même ces choses gratuitement. Pour plus
      informations sur où vous pouvez aller, veuillez retourner aux services et choisir
      l'option: GÉNÉRAL puis choisissez VÊTEMENTS & MEUBLES. Vous serez
      pouvoir y trouver ce dont vous avez besoin. `,
        },
        {
          question: "Où puis-je acheter des vêtements?",
          answer: `Il existe une multitude d'endroits où vous pouvez trouver des
      Vêtements. Certains endroits offrent même ces choses gratuitement. Pour plus
      informations sur où vous pouvez aller, veuillez retourner aux services et choisir
      l'option: GÉNÉRAL puis choisissez VÊTEMENTS & MEUBLES. Vous serez
      pouvoir y trouver ce dont vous avez besoin. `,
        },
        {
          question:
            "Où puis-je trouver de la nourriture gratuite / à bas prix?",
          answer: `Il existe de nombreux endroits qui peuvent vous offrir de la nourriture. Habituellement, vous aurez
      s'inscrire auprès d'une banque alimentaire. Pour trouver des banques alimentaires dans votre région, allez à
      notre page EXLPOREZ et sélectionnez BANQUES ALIMENTAIRES.`,
        },
        {
          question:
            "Quels services de garde d'enfants sont disponibles dans ma province?",
          answer: `Pour voir une liste des organisations qui fournissent des services aux familles,
      jeunes et enfants, veuillez accéder à <a href="/explore"> EXPLORER </a>
      et sélectionnez FAMILLE ou sélectionnez GÉNÉRAL puis JEUNESSE. Vous pouvez aussi
      sélectionnez GÉNÉRAL puis sélectionnez GARDE. Pour en savoir un peu plus ou pour trouver
      renseignements généraux sur la garde d'enfants dans votre province, cliquez sur
      <a href="https://findingqualitychildcare.ca/finding-child-care/child-care-in-each-province-and-territory">
      ici
      </a>
      .`,
        },
        {
          question: "Où puis-je trouver un traducteur?",
          answer: `Si vous avez besoin de la traduction de documents officiels, vous aurez besoin d'un
      traducteur agréé. Vous pouvez trouver des traducteurs et des organisations qui
      faites la traduction en accédant à la page <a href="/explore"> EXPLORE </a>:
      Sélectionnez GÉNÉRAL puis TRADUCTION. `,
        },
        {
          question:
            "J'ai besoin d'aide pour mes dépenses familiales, pouvez-vous m'aider à obtenir des allocations familiales?",
          answer: `Dès que vous recevez votre numéro d'assurance sociale (NAS), vous pouvez
      demander des allocations familiales, ainsi que d'autres prestations. Vous pouvez en savoir plus
      à propos de ça
      <a href = "https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/individuals-leaving-entering-canada-non-residents/newcomers-canada-immigrants.html #tbc ">
      ici
      </a>
      .`,
        },
        {
          question: "Comment utiliser les transports publics?",
          answer: `Pour utiliser
      <a href="https://www.halifax.ca/transportation/halifax-transit/fares-tickets-passes">
      Halifax
      </a>
      système de transport, vous devez acheter des billets ou un laissez-passer de transport en commun.
      <br />
      <br />
      Dans
      <a href="http://www.stm.info/en/info/fares/opus-cards-and-other-fare-media/opus-card">
      Montréal
      </a>
      , le transport en commun s'appelle la STM et se compose du bus,
      le métro et le train. Vous vous déplacez avec une carte OPUS qui peut
      être acheté dans n'importe quelle station de métro.
      <br />
      <br />
      <a href="https://www.octranspo.com/en/plan-your-trip/how-to-ride/">
      OC Transpo
      </a>
      exploite le réseau de bus utile d'Ottawa et un système de train léger sur rail connu
      comme l'O-train.
      <br />
      <br />
      <a href="https://ttc.ca/Fares_and_passes/index.jsp">
      La Toronto Transit Commission (TTC)
      </a>
      exécute trois modes - métro, tramway et bus - qui fonctionnent
      itinéraires à travers la ville et les banlieues. Vous aurez besoin d'un jeton ou d'un
      laissez-passer pour voyager en TTC; les pass à la journée et à la semaine permettent des trajets illimités
      sur les trois formes de transports publics. <br />
      <br />
      À <a href="https://www.calgarytransit.com/fares-passes"> Calgary </a>,
      vous devrez acheter un billet ou un pass pour utiliser public
      transport.
      <br />
      <br />
      <a href="https://www.edmonton.ca/edmonton-transit-system-ets.aspx">
      Service de transport en commun d'Edmonton (ETS)
      </a> {""}
      exploite deux formes de transport en commun dans toute la ville: les bus et
      le système de train léger sur rail (LRT).
      <br />
      <br />
      Compass est la carte tarifaire rechargeable qui fonctionne partout en transit
      dans
      <a href="https://www.translink.ca/Fares-and-Passes/Fare-Pricing.aspx">
      Vancouver
      </a>
      .`,
        },
        {
          question: "Pouvez-vous m'aider à trouver une banque?",
          answer: `Pour trouver une banque, accédez à notre page <a href="/explore"> EXPLORE </a>, sélectionnez
      FINANCIER & COMPTABLE puis BANQUE .. Pour trouver une liste de tous
      Banques canadiennes, cliquez sur
      <a href="https://www.immigroup.com/news/best-bank-newcomers-canada">
      ici
      </a>
      .`,
        },
        {
          question:
            "Les réfugiés doivent-ils payer leurs impôts? Si oui, quelqu'un peut-il m'aider?",
          answer: `La déclaration des taxes est très importante et elles sont généralement déposées avant le
      Avril de l'année suivante. Pour trouver une clinique fiscale gratuite, cliquez sur
      <a href="https://www.canada.ca/en/revenue-agency/campaigns/free-tax-help.html">
      ici
      </a>
      . Si vous souhaitez trouver une organisation pour vous aider, retournez à
      services et sélection Finance & Comptabilité. `,
        },
      ],
    },
  ],
  spa :[
    {
      category: "employment_and_entrepreneurship",
      faq: [
        {
          question: "Can I work in Canada as a refugee claimant?",
          answer: `If you are an asylum seeker in Canada, you are allowed to work but you
            will need a work permit in order to get a job. If you are not sure if
            you need one, consult this
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html">
              website
            </a>
            <br /> <br />
            You can apply for a work permit online or by mail. To learn how to
            apply, you can consult this
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html">
              website
            </a>
            and follow all of the steps.
            <br />
            <br />
            Also, if you would like some help applying for your work permit, there
            are different organizations who can help you with this. To find the
            organizations near you, you can go to the
            <a href="/explore">EXPLORE</a> page and select LEGAL and then WORK
            PERMIT.`,
        },
        {
          question: "Do I need a work permit? How do I get one?",
          answer: "(See above answer)",
        },
        {
          question: "I need help finding a job.",
          answer: ` There are many organizations that can help you with this. Before
            finding a job, ensure that you have a work permit. If you're not
            sure if you need one,
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html">
              click here
            </a>
            . You will also need a CV/resume. For more information regarding your
            needs with employment, go to our <a href="/explore">EXPLORE</a> page
            & select EMPLOYMENT & ENTREPRENEURSHIP. From there you will
            find organizations that can assist you with what you need.`,
        },
        {
          question: "I need help with my CV/resume",
          answer: `Building a CV is an important tool you need in order to find work in
            Canada. There are may organizations that can assist you with this. Go
            to our <a href="/explore">EXPLORE</a> page and select EMPLOYMENT &
            ENTREPRENEURSHIP. You can then choose the option for CV HELP to find
            organizations near you that can assist you with this.`,
        },
        {
          question: " What are my rights as a worker in Canada?",
          answer: `No matter what your status is, you have rights as a worker in Canada.
            You can click the links below to find out more:
            <br />
            <br />
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit/temporary/after-apply-next-steps/understand-your-rights-foreign-workers.html">
              - Your rights as a foreign worker
            </a>
            <br />
            <br />
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/rights.html">
              - Refugee Claimants' Rights
            </a>
            <br />
            <br />
            <a href="https://www.canada.ca/en/employment-social-development/services/foreign-workers/protected-rights.html">
              - Temporary Foreign Workers' Rights
            </a>`,
        },
        {
          question: "How can I start a business?",
          answer: `Starting a business is a great idea! To begin you can visit the
            Canadian government 
            <a href="https://www.canada.ca/en/services/business/start.html">
              website
            </a>
            . You will also have to register your business with your provincial
            government as well. If you require additional consultation, you can go
            to the <a href="/explore">EXPLORE</a> page and select EMPLOYMENT &
            ENTREPRENEURSHIP.`,
        },
      ],
    },

    {
      category: "legal",
      faq: [
        {
          question: "How do you claim asylum in Canada?",
          answer: `There are three (3) ways to claim refugee in Canada.
            <br />
            <br /> - First, you will have to register with the United Nations High
            Commission for Refugees (UNHCR) office in your country. They will
            register you and will be able to help you in your resettlement, if
            possible.
            <br />
            <br />
            - Second, you can get a tourist visa in your country at the Canadian
            embassy to be able to travel to Canada. Once you land in Canada, you
            can claim refuge at the border, in the airport.
            <br />
            <br />
            - Third, you can get privately sponsored by a group of Canadian
            citizens in Canada. They will pay for your resettlement if your case
            is accepted by the Canadian government.
            <br />
            <br />
            To find out more about the steps involved in the asylum application
            process,
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html">
              click here
            </a>
            .<br />
            <br />
            For organizations that deal with private sponsorship, go to the
            <a href="/explore">EXPLORE</a> page, select LEGAL and then PRIVATE
            SPONSORSHIP PROGRAM.
            <br />`,
        },
        {
          question:
              "What is the wait time to hear back about my refugee claim?",
          answer: `Federal authorities are solely responsible for application processing
            times. You can consult federal timeframes by
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-processing-times.html">
              click here
            </a>
            .<br />`,
        },
        {
          question: "How do I know if my refugee claim will be accepted?",
          answer: ` There is no guaranteed way to know if you will be accepted since a
            single decision maker of Canada's Immigration and Refugee Board
            will hear your testimony and must decide if your claim is credible.
            That is why it is essential to provide documentary proof of your story
            and to be well prepared for your hearing, and to hire a lawyer who is
            an expert in refugee law.`,
        },
        {
          question:
              " I've been accepted, do my family members qualify as well?",
          answer: ` Yes, in most cases Immigration, Refugees and Citizenship Canada (IRCC,
            formerly CIC) respects the principle of family unity. Therefore, a
            family will typically have one hearing and one decision. If you need
            legal aid or more information about your case, go to the 
            <a href="/explore">EXPLORE</a> page and select LEGAL for a list of
            organizations that can help you. <br />`,
        },
        {
          question: "My claim was refused/denied, what can I do?",
          answer: `You are allowed to appeal the decision if they reject your
            refugee/asylum claim. You have the right to remain in Canada while you
            apply for an appeal and make submissions for a Pre-Removal Risk
            Assessment. You may also make an application for permanent residence
            in Canada based upon humanitarian and compassionate grounds. You can
            consult this 
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html">
              page
            </a>
            for more information.`,
        },
        {
          question: "How can I come to Canada?",
          answer: ` If you are outside your country of origin, you must first apply to the
            United Nations High Commission for Refugees (UNHCR). You usually have
            to show that you will be economically successful in Canada. Also, the
            refugee definition is more restrictive, you do not have the right to a
            lawyer at your interview, and the waiting period could be many years.
            You must also show that you cannot be settled in the country that you
            are currently in.
            <br />
            <br />
            Overall, individuals come to Canada in multiple different ways:
            <br />- You can register at the UNHCR like we explained above. <br />-
            Another way is by applying for a tourist visa in your country at the
            Canadian embassy to be able to travel to Canada. Once you land in
            Canada, you can claim refuge at the border, in the airport. If you
            come to Canada by land, you claim asylum when you cross the border.
            <br />- Lastly, you can get privately sponsored by a group of Canadian
            citizens in Canada. They will pay for your resettlement if your case
            is accepted by the Canadian government.
            <br />
            <br />
            For organizations that deal with private sponsorship, go to the
            <a href="/explore">EXPLORE</a> page, select LEGAL and then PRIVATE
            SPONSORSHIP PROGRAM.
            <br />
            <br />
            For an expanation of the difference between government sponsored and
            privately sponsored,
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/refugee-protection/resettlement/quebec-procedures.html">
              click here
            </a>`,
        },
        {
          question: "What are the rights of refugees in Canada?",
          answer: `When an asylum-seeker first comes to Canada, he or she must make a
            claim for protection to the Canadian government in order to be granted
            refugee status. During the time when this claim is being evaluated, an
            asylum-seeker has certain rights.
            <br />
            <br />
            If a claim for protection is successful, asylum seekers are granted
            “protected persons” status, also called refugee status, and then they
            receive access to additional benefits. Accepted refugees have the same
            rights as permanent residents.
            <br />
            <br />
            Some refugees that have made their asylum claim in another country are
            resettled in Canada. These resettled refugees receive initial
            assistance (typically provided for up to one year) from either the
            federal government, the provincial government, or private sponsors.
            Resettled refugees become permanent residents and can eventually
            become citizens of Canada. If you need more information regarding
            refugee rights, please go to our <a href="/explore">EXPLORE</a> page
            and select LEGAL to find an organization who can answer your specific
            questions.`,
        },
        {
          question: "What does a PR mean? What does the CSQ mean?",
          answer: `The Quebec Selection Certificate (certificat de sélection du Québec),
            or CSQ, is a document issued by the Ministry of Immigration,
            Diversity, and Inclusion declaring that the holder has been selected
            to settle in Quebec. With a CSQ, the applicant can apply for Canadian
            permanent residence. This document is not a permanent residence visa.
            The CSQ is valid for 24 months or until Immigration, Refugees and
            Citizenship Canada (IRCC) makes a decision on a Canadian permanent
            residence application. For more information and to apply, consult this
            page.
            <br />
            <br />A permanent resident is someone who has been given permanent
            resident status by immigrating to Canada, but is not a Canadian
            citizen. Permanent residents are citizens of other countries. A person
            in Canada temporarily, like a student or foreign worker, is not a
            permanent resident. Refugees who are resettled from overseas become
            permanent residents through the Government-Assisted Refugee Program or
            the Private Sponsorship of Refugees Program.
            <br />
            <br />
            Someone who makes a refugee claim in Canada does not become a
            permanent resident at that time. To become one, the Immigration and
            Refugee Board must first approve their claim. Then, they must apply
            for and get permanent resident status. For more information and to
            apply, consult this
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-q7000-application-permanent-residence-quebec-skilled-worker-class.html">
              page
            </a>
            . <br />`,
        },
        {
          question: "How can I get a lawyer/legal aid?",
          answer: ` There are many organizations that offer legal aid. To find those
            closest to you, please go to the EXLPORE page and select LEGAL.`,
        },
      ],
    },
    {
      category: "education",
      faq: [
        {
          question: "Do I need a study permit ? How do I get one?",
          answer: ` In order to study in Canada, it is possible that you might need a
            study permit. If you are a refugee claimant on a Temporary Resident
            Visa (TRV) such as a visitor visa or work permit, you can do a
            short-term course while your temporary resident visa is valid. If you
            take a course that is more than 6 months long, you will need to apply
            for a study permit. Refugee claimants who do not have a valid
            Temporary Resident Visa also need to apply for study permits. If you
            have a valid study permit, you can legally study at a post-secondary
            institution. For more information on study permits, please
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/study-permits/refugees-protected-persons.html">
              click here
            </a>
            <br />
            <br />
            If you need help applying to school, want to know your options or just
            have questions related to studying in Canada, there are organizations
            that can help you with this. Go to our <a href="/explore">
              EXPLORE
            </a>
            page, select EDUCATION and then select ACADEMIC AID & COUNSELLING
            to find organizations near you who can help with this.`,
        },
        {
          question: "I want to apply to university. What do I do?",
          answer: `There are many organizations that can help you with this. Go to our
            <a href="/explore">EXPLORE</a> page, select EDUCATION and then
            ACADEMIC AID & COUNSELLING`,
        },
        {
          question: "How can I find a university?",
          answer: ` You can find universities near you by clicking on the city you are
            located in:
            <br />
            <a href="https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/">
              Halifax Universities
            </a>
            <br />
            <a href="https://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/students/applying-admission/choosing-institution/index.html">
              Montreal Universities
            </a>
            <br />
            <a href="https://www.ontario.ca/page/ontario-universities">
              Ontario Universities
            </a>
            <br />
            <a href="https://www.lifeincalgary.ca/schools/post-secondary">
              Calgary Universities
            </a>
            <br />
            <a href="https://www.collegealberta.info/edmonton.php">
              Edmonton Universities
            </a>
            <br />
            <a href="https://www2.gov.bc.ca/gov/content/education-training/post-secondary-education/find-a-program-or-institution/find-an-institution">
              Vancover Universities
            </a>`,
        },
        {
          question: "How can I find a vocational school?",
          answer: `To find a vocational training program in your location, please return
            to SERVICES, select EDUCATION and then VOCATIONAL. You can also visit
            <br />
            <a href="https://www.nscc.ca/learning_programs/schools/trades/index.asp">
              Vocational Schools in Nova Scotia
            </a>
            <br />
            <a href="https://www.emsb.qc.ca/emsb/schools/adult-vocational/centres">
              Vocational schools in Montreal
            </a>
            <br />
            <a href="https://www.ontariocolleges.ca/en/apply/skilled-trades">
              Vocational Schools in Ontario
            </a>
            <br />
            <a href="https://www.vocationalschools.ca/search#">
              Vocational Schools in Alberta
            </a>
            <br />
            <a href="https://www.tradestrainingbc.ca/">
              Vocational Schools in British Columbia
            </a>`,
        },
        {
          question: "How can I find a college?",
          answer: ` To find a college, please return to SERVICES, select EDUCATION and
            then COLLEGE. You will find a list of colleges in your location.
            <br />
            <a href="https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/">
              Halifax
            </a>
            <br />
            <a href="https://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/students/applying-admission/choosing-institution/index.html">
              Quebec
            </a>
            <br />
            <a href="https://www.ontariocolleges.ca/en/colleges">Ontario</a>
            <br />
            <a href="https://www.lifeincalgary.ca/schools/post-secondary">
              Calgary
            </a>
            <br />
            <a href="https://www.collegealberta.info/edmonton.php">Edmonton</a>
            <br />
            <a href="https://www.thoughtco.com/guide-to-universities-colleges-in-vancouver-3371270">
              Vancouver
            </a>`,
        },
        {
          question: "Can I get financial aid for school?",
          answer: `This works a bit differently in each province:
            <br />
            <br />
            NOVA SCOTIA:
            <br />
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules. <br />
            <br />
            As a student in Nova Scotia, you are eligible to apply for a variety
            of different loans and grants, both provincially and federally. Click
            <a href="https://novascotia.ca/studentassistance/apply/new/FundingAvailable.asp">
              here
            </a>
            to read more about the options available.
            <br />
            <br />
            QUEBEC:
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules. <br />
            <br />
            You may be eligible for certain scholarships and grants and the Aide
            financière aux études’ (AFE’s). Canadian citizens, permanent residents
            and refugees or protected persons qualify for AFE's Quebec aid
            program if they are deemed a resident of Quebec for student aid
            purposes at the time of application.
            <br />
            In this section, you will find information on AFE's
            <a href="https://www.quebec.ca/education/aide-financiere-aux-etudes/prets-bourses-temps-plein/conditions-admissibilite/">
              eligibility requirements
            </a>
            , the application process, deadlines and other important program
            details.
            <br />
            <br />
            ONTARIO:
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules. <br />
            <br />
            If you are a ‘protected person’ or a convention refugee, you are
            eligible to apply for OSAP. However, there are certain documents which
            you must have before applying. For further information on eligibility
            and specific details about OSAP
            <a href="https://osap.gov.on.ca/prodconsumption/groups/forms/documents/forms/prdr020621.pdf">
              click here
            </a>
            .
            <br />
            <br />
            ALBERTA:
            <br />
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules.
            <a href="https://studentaid.alberta.ca/before-you-apply/eligibility/">
              Alberta Student Aid
            </a>
            provides student loans for part time and full time studies. To receive
            student aid for full-time studies, you must meet all of these basic
            eligibility criteria:
            <br />- Be a Canadian citizen, permanent resident or have protected
            person status under Canada’s Immigration and Refugee Protection Act.
            Students with a study permit are not eligible. <br />
            - Be a resident of Alberta <br />
            - Be enrolled as a full-time student taking a minimum 60% of a full
            course load (40% of a full course load if you are a student with a
            permanent disability) <br />
            - Be enrolled in an eligible program at an approved post-secondary
            school
            <br />
            - Have financial need
            <br />
            <br />
            BRITISH COLUMBIA:
            <br />
            In most cases, refugee claimants will have to pay international fees
            until they have been designated as a refugee or protected person; you
            should check with your school for their rules.
            <a href="https://studentaidbc.ca/apply/eligibility">
              StudentAid BC
            </a>
            is for students who need help paying for their post-secondary
            education. To receive student financial assistance, you must meet all
            of these basic eligibility criteria: <br />- Be a Canadian citizen, a
            permanent resident or have protected person status <br />- Have a
            valid Canadian social insurance number <br />- Be a resident of
            British Columbia <br />- Be pursuing full-time studies as your primary
            occupation <br />- Be enrolled in an eligible program of study at an
            eligible post-secondary institution
            <br />- Be able to demonstrate financial need <br />- Make academic
            progress and achieve satisfactory scholastic standing in each period
            of post-secondary study
            <br />
            <br />
            --------------
            <br />
            <br />
            You can check out another potential opportunity for financial aid
            <a href="https://srp.wusc.ca/">here</a>.`,
        },
        {
          question: " I would like to learn French and/or English.",
          answer: `  If you'd like to learn a language in your city, please return to
            services and choose the option: Languages and then choose the language
            you are interested in learning: English, French, IELTS or
            Conversational Classes. From there, you will find organizations near
            you that offer these classes.`,
        },
        {
          question: "Where can I learn how to code?",
          answer: `Great idea! The tech industry is booming in Canada. There are often
            bootcamps which are short 6-8 week crash courses on how to learn to
            code. To find some in your area, go to the
            <a href="/explore">EXPLORE</a> page and select EMPLOYMENT &
            ENTREPRENEURSHIP.`,
        },
        {
          question: "How can I get my Foreign Credentials recognized?",
          answer: `  To be able to practice you need to contact the respective order.
            <br />
            <br />
            PHARMACY:
            <br />
            <a href="https://www.nspharmacists.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.opq.org/">Quebec</a>
            <br />
            <a href="https://www.ocpinfo.com/">Ontario</a>
            <br />
            <a href="https://abpharmacy.ca/">Alberta</a>
            <br />
            <a href="https://www.bcpharmacists.org/">British Columbia</a>
            <br />
            <br />
            DENTISTRY:
            <br />
            <a href="http://pdbns.ca/">Nova Scotia</a>
            <br />
            <a href="http://www.odq.qc.ca/Accueil/tabid/127/language/fr-CA/Default.aspx">
              Quebec
            </a>
            <br />
            <a href="https://www.rcdso.org/en-ca/home">Ontario</a>
            <br />
            <a href="https://www.dentalhealthalberta.ca/">Alberta</a>
            <br />
            <a href="https://www.cdsbc.org/registration-renewal/dentists">
              British Columbia
            </a>
            <br />
            <br />
            LAW:
            <br />
            <a href="https://nsbs.org/">Nova Scotia</a>
            <br />
            <a href="https://www.barreau.qc.ca/en/">Quebec</a>
            <br />
            <a href="https://lso.ca/home">Ontario</a>
            <br />
            <a href="https://www.lawsociety.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.lawsociety.bc.ca/">British Columbia</a>
            <br />
            <br />
            MEDICINE:
            <br />
            <a href="https://cpsns.ns.ca/">Nova Scotia</a>
            <br />
            <a href="http://www.cmq.org/">Quebec</a>
            <br />
            <a href="https://www.cpso.on.ca/">Ontario</a>
            <br />
            <a href="http://www.cpsa.ca/">Alberta</a>
            <br />
            <a href="https://www.cpsbc.ca/">British Columbia</a>
            <br />
            <br />
            ARCHITECTURE:
            <br />
            <a href="https://nsaa.ns.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.oaq.com/">Quebec</a>
            <br />
            <a href="https://www.oaa.on.ca/">Ontario</a>
            <br />
            <a href="https://www.aaa.ab.ca/">Alberta</a>
            <br />
            <a href="https://aibc.ca/">British Columbia</a>
            <br />
            <br />
            ENGINEERING:
            <br />
            <a href="https://engineersnovascotia.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.oiq.qc.ca/Pages/accueil.aspx">Quebec</a>
            <br />
            <a href="https://www.peo.on.ca/">Ontario</a>
            <br />
            <a href="https://www.apega.ca/">Alberta</a>
            <br />
            <a href="https://www.egbc.ca/">British Columbia</a>
            <br />
            <br />
            NURSING:
            <br />
            <a href="https://www.nscn.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.oiiq.org/">Quebec</a>
            <br />
            <a href="https://www.cno.org/">Ontario</a>
            <br />
            <a href="https://nurses.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.bccnp.ca/Pages/Default.aspx">British Columbia</a>
            <br />
            <br />
            PSYCHOLOGY:
            <br />
            <a href="https://apns.ca/">Nova Scotia</a>
            <br />
            <a href="https://www.ordrepsy.qc.ca/accueil">Quebec</a>
            <br />
            <a href="https://www.psych.on.ca/">Ontario</a>
            <br />
            <a href="https://psychologistsassociation.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.psychologists.bc.ca/">British Columbia</a>
            <br />
            <br />
            SOCIAL WORK:
            <br />
            <a href="http://nscsw.org/">Nova Scotia</a>
            <br />
            <a href="https://www1.otstcfq.org/">Quebec</a>
            <br />
            <a href="https://www.ocswssw.org/">Ontario</a>
            <br />
            <a href="https://www.acsw.ab.ca/">Alberta</a>
            <br />
            <a href="https://www.bccollegeofsocialworkers.ca/">
              British Columbia
            </a>
            <br />
            <br />
            ACCOUNTANCY:
            <br />
            <a href="https://www.cpans.ca/web/cpans/">Nova Scotia</a>
            <br />
            <a href="https://cpaquebec.ca/en/">Quebec</a>
            <br />
            <a href="https://www.cpaontario.ca/">Ontario</a>
            <br />
            <a href="https://www.cpaalberta.ca/">Alberta</a>
            <br />
            <a href="https://www.bccpa.ca/">British Columbia</a>
            <br />
            <br />`,
        },
        {
          question:
              " Are my children allowed to go to school while my refugee claim is being processed?",
          answer: ` Yes, children of refugee claimants can attend public elementary and
            high schools free of charge. If you need help finding a school in your
            location, please go to our <a href="/explore">EXPLORE</a> page, select
            EDUCATION and then INSTITUTIONS for a list of schools in your region.`,
        },
        {
          question: "How can I find a high school?",
          answer: ` You can find high schools near you by clicking on the city you are
            located in:
            <br />
            <br />
            <a href="https://www.hrce.ca/registration">
              Halifax Public Schools Website
            </a>
            <br />
            <a href="https://www.emsb.qc.ca/emsb/admissions/registration">
              Montreal Public Schools Website
            </a>
            <br />
            <a href="https://ocdsb.ca/">Ottawa Public Schools Website</a>
            <br />
            <a href="https://www.tdsb.on.ca/Find-your/School/Registration">
              Toronto Public Schools Website
            </a>
            <br />
            <a href="https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx">
              Calgary Public Schools Website
            </a>
            <br />
            <a href="https://epsb.ca/schools/register/">
              Edmonton Public Schools Website
            </a>
            <br />
            <a href="https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx">
              Vancouver Public Schools Website
            </a>
            <br />
            <br />`,
        },
        {
          question: "How can I find an elementary school?",
          answer: `You can find elementary schools near you by clicking on the city you
            are located in:
            <br />
            <a href="https://www.hrce.ca/registration">
              Halifax Public Schools Website
            </a>
            <br />
            <a href="https://www.emsb.qc.ca/emsb/admissions/registration">
              Montreal Public Schools Website
            </a>
            <br />
            <a href="https://ocdsb.ca/">Ottawa Public Schools Website</a>
            <br />
            <a href="https://www.tdsb.on.ca/Find-your/School/Registration">
              Toronto Public Schools Website
            </a>
            <br />
            <a href="https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx">
              Calgary Public Schools Website
            </a>
            <br />
            <a href="https://epsb.ca/schools/register/">
              Edmonton Public Schools Website
            </a>
            <br />
            <a href="https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx">
              Vancouver Public Schools Website
            </a>
            <br />
            <br />`,
        },
      ],
    },
    {
      category: "health",
      faq: [
        {
          question: "Can I get health insurance?",
          answer: `Asylum seekers are covered under the
            <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/health-care/interim-federal-health-program/coverage-summary.html">
              Interim Federal Health Program (IFHP)
            </a>
            but once you are an accepted refugee, your health insurance will be
            provincial and works a bit differently in every province:
            <br />
            <br />
            NOVA SCOTIA:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://novascotia.ca/DHW/msi/health_cards.asp">
              this page
            </a>
            .
            <br />
            <br />
            <br />
            QUEBEC:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www.ramq.gouv.qc.ca/en/citizens/health-insurance/register">
              this page
            </a>
            . <br />
            <br />
            <br />
            ONTARIO:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www.ontario.ca/page/apply-ohip-and-get-health-card">
              this page
            </a>
            <br />
            <br />
            <br />
            ALBERTA:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www.alberta.ca/ahcip-how-to-apply.aspx">
              this page
            </a>
            .
            <br />
            <br />
            <br />
            BRITISH COLUMBIA:
            <br />
            You are eligible for provincial health care once you have been
            accepted as a refugee. To find out how to register, visit
            <a href="https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/msp/bc-residents/eligibility-and-enrolment/how-to-enrol">
              this page
            </a>
            .`,
        },
        {
          question: "If I need a doctor or medical attention, where can I go?",
          answer: `To find a clinic or health care services in your area, go to our
            <a href="/explore">EXPLORE</a> page and select HEALTH.`,
        },
        {
          question: "Where can I find a doctor to assist me with my pregnancy?",
          answer: `Congratulations on your pregnancy! Go to our 
            <a href="/explore">EXPLORE</a> page, select HEALTH and then
            WOMEN'S HEALTH. This will give you all the options of
            organizations that can assist you in your area.`,
        },
        {
          question: "How can I get a family doctor?",
          answer: `If you do NOT have a heath care card, you cannot have an assigned
            family doctor- you can however, still seek medical attention at walk
            in clinics. To find a clinic or a health care service in your area, go
            to our <a href="/explore">EXPLORE</a> page and select HEALTH.
            <br />
            <br />
            If you DO have a health care card, you can apply for a doctor but this
            works differently in every province. Select your province below to get
            the right information for you:
            <br />
            <br />
            <a href="https://needafamilypractice.nshealth.ca/">NOVA SCOTIA</a>
            <br />
            <a href="https://www.quebec.ca/en/health/finding-a-resource/registering-with-a-family-doctor/">
              QUEBEC
            </a>
            <br />
            <a href="https://www.ontario.ca/page/find-family-doctor-or-nurse-practitioner.">
              ONTARIO
            </a>
            <br />
            <a href="https://www.albertahealthservices.ca/info/Page13253.aspx">
              ALBERTA
            </a>
            <br />
            <a href="https://bccfp.bc.ca/for-the-public/find-a-family-doctor/">
              BRITISH COLUMBIA
            </a>`,
        },
        {
          question: "Where can I get medication and is it free?",
          answer: `You can purchase medication at local pharmacies. Medication is not
            free, however you may be able to get a reduction on the price if you
            have private insurance.`,
        },
        {
          question: "How can I find mental-health support?",
          answer: ` There are multiple organizations that offer services to help with
            mental health. Please go to our <a href="/explore">EXPLORE</a> page,
            select HEALTH and then MENTAL HEALTH to find organizations/services in
            your area.`,
        },
      ],
    },
    {
      category: "housing",
      faq: [
        {
          question: "Where can I find a place to live?",
          answer: `There are multiple different organizations that can help you with this
            depending on your situation and your needs. To access the
            organizations in your city, go to the <a href="/explore">EXPLORE</a>
            page and select HOUSING.`,
        },
        {
          question: "What are my housing rights as a tenant?",
          answer: `  As a tenant, you have rights. Please click on your province to know
            more.
            <br />
            <br />
            <a href="https://beta.novascotia.ca/programs-and-services/residential-tenancies-program">
              Nova Scotia
            </a>
            <br />
            <a href="https://www.tal.gouv.qc.ca/en">Quebec</a>
            <br />
            <a href="https://www.ontario.ca/laws/statute/06r17">Ontario</a>
            <br />
            <a href="https://www.alberta.ca/landlords-tenants.aspx">Alberta</a>
            <br />
            <a href="https://www2.gov.bc.ca/gov/content/housing-tenancy/residential-tenancies">
              British Columbia
            </a>
            <br />
            <br />
            You can also go to the <a href="/explore">EXPLORE</a> page, select
            HOUSING and then HOUSING RIGHTS to find organizations near you that
            can help you with this subject.`,
        },
      ],
    },
    {
      category: "general_services",
      faq: [
        {
          question: `How do I get a driver's license?`,
          answer: ` Getting a driver's license is different in every province:
            <br />
            <br />
            NOVA SCOTIA: You can drive in Nova Scotia with a license from another
            country for up to 90 days, after that, you will need to get a Nova
            Scotia license. To get a driver's license in Nova Scotia, you need
            to take a full driver licence examination, which includes a knowledge,
            vision, and road tests.
            <a href="https://novascotia.ca/sns/rmv/licence/gradlic.asp">
              Click here
            </a>
            for more information.
            <br />
            <br />
            QUEBEC: If you still have a driver's license from another country,
            then you are eligible to write the theoretical and practical exam to
            obtain your Quebec driver's permit. Click
            <a href="https://saaq.gouv.qc.ca/en/drivers-licences/foreign-drivers-licence/">
              here
            </a>
            for more information. If you do not have any license, you will have to
            prove that you are going to remain in Canada for at least 6 months and
            then you will need to complete driving courses in order to obtain your
            license. For more information, click
            <a href="https://saaq.gouv.qc.ca/en/drivers-licences/obtaining-licence/passenger-vehicle-class-5/">
              here
            </a>
            .<br />
            <br />
            ONTARIO: For newcomers in Ontario, it is recommended that you start
            the process to getting your license as soon as possible.
            <a href="https://settlement.org/ontario/daily-life/transportation/driver-s-licence/how-do-i-get-an-ontario-driver-s-licence/">
              Click here
            </a>
            for more information on how to start the process.
            <br />
            <br />
            ALBERTA: If you still have a driver's license from another
            country, then you might be eligible to exchange it for an Alberta
            license but you must do it within 90 days.
            <a href="https://www.alberta.ca/exchange-non-alberta-licences.aspx#:~:text=hand%20in%20your%20valid%20licence,proof%20of%20residence%20in%20Canada">
              Click here
            </a>
            for more information on how to exchange it. If you cannot exchange
            your license,
            <a href="https://www.alberta.ca/get-drivers-licence.aspx">
              click here
            </a>
            to learn how you can get your driver's license.
            <br />
            <br />
            BRITISH COLOMBIA: If you still have a driver's license from
            another country, then you might be eligible to exchange it for a
            British Colombia license but you must apply within 90 days.
            <a href="https://www.icbc.com/driver-licensing/moving-bc/Pages/Moving-from-another-country.aspx">
              Click here
            </a>
            for more information on how to exchange it. If you cannot exchange
            your license,
            <a href="https://www.icbc.com/driver-licensing/new-drivers/Pages/default.aspx">
              Click here
            </a>
            to learn how you can get your driver's license.
            <br />`,
        },
        {
          question: "How can I get a phone plan?",
          answer: `There are different options available to you depending on what you
            need. There are pre-paid plans (or pay-as-you-go) and monthly contract
            plans. Phone plans in Canada can be a bit expensive but there are many
            different companies with many different plans and prices therefore you
            can choose the one that is best for you. Some examples of companies
            that are more affordable include Chatr, Fizz, Koodo, Virgin Mobile and
            Fido. There are also companies such as Bell, Rogers and Telus. Click
            <a href="https://moving2canada.com/cell-phone-plan-in-canada/">
              here
            </a>
            for more information on phone plans in Canada.`,
        },
        {
          question: "Where can I get furniture?",
          answer: `There are an array of different places where you can find affordable
            furniture. Some places even offer these things for free. For more
            information on where you can go, please return to services and choose
            the option: GENERAL and then choose CLOTHING & FURNITURE. You will
            be able to find what you need there.`,
        },
        {
          question: "Where can I buy clothes?",
          answer: ` There are an array of different places where you can find affordable
            clothing. Some places even offer these things for free. For more
            information on where you can go, please return to services and choose
            the option: GENERAL and then choose CLOTHING & FURNITURE. You will
            be able to find what you need there.`,
        },
        {
          question: "Where can I find free/ low-cost food?",
          answer: ` There are many places that can offer you food. Usually, you will have
            to sign up with a food bank. To find food banks in your area, go to
            our EXLPORE page and select FOOD BANKS.`,
        },
        {
          question: " What child-care is available in my province?",
          answer: `To see a list of organizations that provide services to families,
            youth and children, please go to the <a href="/explore">EXPLORE</a>
            page and select FAMILY or select GENERAL and then YOUTH. You can also
            select GENERAL and then select DAYCARE. To learn a bit more or to find
            general information for child care in your province, click
            <a href="https://findingqualitychildcare.ca/finding-child-care/child-care-in-each-province-and-territory">
              here
            </a>
            .`,
        },
        {
          question: "Where can I find a translator?",
          answer: ` If you require the translation of official documents, you will need a
            certified translator. You can find translators and organizations that
            do translation by going to the <a href="/explore">EXPLORE</a> page:
            Select GENERAL and then TRANSLATION.`,
        },
        {
          question:
              " I need help with my family expenses, can you help me get child benefits?",
          answer: ` As soon as you receive your Social Insurance Number (SIN), you can
            apply for child benefits, as well as other benefits. You can read more
            about it
            <a href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/individuals-leaving-entering-canada-non-residents/newcomers-canada-immigrants.html#tbc">
              here
            </a>
            .`,
        },
        {
          question: "How do I use public transportation?",
          answer: ` To use
            <a href="https://www.halifax.ca/transportation/halifax-transit/fares-tickets-passes">
              Halifax's
            </a>
            transportation system, you need to purchase tickets or a transit pass.
            <br />
            <br />
            In 
            <a href="http://www.stm.info/en/info/fares/opus-cards-and-other-fare-media/opus-card">
              Montreal
            </a>
            , the public transportation is called the STM and consists of the bus,
            the metro and the train. You get around using an OPUS card which can
            be purchased at any metro station.
            <br />
            <br />
            <a href="https://www.octranspo.com/en/plan-your-trip/how-to-ride/">
              OC Transpo
            </a>
            operates Ottawa's useful bus network and a light-rail system known
            as the O-train.
            <br />
            <br />
            <a href="https://ttc.ca/Fares_and_passes/index.jsp">
              The Toronto Transit Commission (TTC)
            </a>
            runs three modes – subway, streetcar and bus – which operate extensive
            routes throughout the city and suburbs. You will need a token or a
            pass to travel on TTC; day and week passes allow for unlimited rides
            on all three forms of public transport. <br />
            <br />
            In <a href="https://www.calgarytransit.com/fares-passes">Calgary</a>,
            you will need to purchase a ticket or a pass to use public
            transportation.
            <br />
            <br />
            <a href="https://www.edmonton.ca/edmonton-transit-system-ets.aspx">
              Edmonton Transit Service (ETS)
            </a>{" "}
            operates two forms of public transit throughout the city: buses and
            the Light Rail Transit (LRT) system.
            <br />
            <br />
            Compass is the reloadable fare card that works everywhere on transit
            in 
            <a href="https://www.translink.ca/Fares-and-Passes/Fare-Pricing.aspx">
              Vancouver
            </a>
            .`,
        },
        {
          question: "Can you help me find a bank?",
          answer: `To find a bank, go to our <a href="/explore">EXPLORE</a> page, select
            FINANCIAL & ACCOUNTING and then BANKING.. To find a list of all
            Canadian banks, click
            <a href="https://www.immigroup.com/news/best-bank-newcomers-canada">
              here
            </a>
            .`,
        },
        {
          question:
              "Do refugees need to do taxes? If yes, can anyone help me with this?",
          answer: ` Filing taxes is very important and they are usually filed before the
            April of the following year. To find a free tax clinic, click
            <a href="https://www.canada.ca/en/revenue-agency/campaigns/free-tax-help.html">
              here
            </a>
            . If you would like to find an organization to assist you, return to
            services and selection Financial & Accounting.`,
        },
      ],
    },
  ],
  ara: [
    {
      category: "employment_and_entrepreneurship",
      faq: [
        {
          question: "هل لي أن أعمل في كندا كطالب لجوء؟",
          answer: `إذا كنت طالب لجوء في كندا، يسمح لك بالعمل لكنك
            ستحتاج إلى تصريح عمل للحصول على وظيفة. إذا لم تكن متأكدا من
            تحتاج إلى واحد، إستشر هذا
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html>
              موقع
            </a>
            <br /> <br />
            يمكنك التقدم للحصول على تصريح عمل عبر الإنترنت أو عن طريق البريد. لتعلم كيفية
            تطبيق، يمكنك إستشارة هذا
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html>
              موقع
            </a>
            واتبع كل الخطوات.
            <br />
            <br />
            أيضا، إذا كنت ترغب في الحصول على بعض المساعدة في التقدم للحصول على تصريح العمل، هناك
            هي منظمات مختلفة تستطيع مساعدتك في هذا. للعثور على
            المنظمات القريبة منك، يمكنك الذهاب إلى
            <a href=/explore>استكشف</a> صفحة وحدد قانوني ثم ترخيص العمل.`,
        },
        {
          question: "هل أحتاج إلى تصريح عمل؟ كيف يمكنني الحصول على واحدة؟",
          answer: "(راجع الإجابة السابقة)",
        },
        {
          question: ".أحتاج إلى مساعدة لإيجاد وظيفة",
          answer: `هناك العديد من المنظمات التي يمكنها مساعدتك في هذا. قبل
            العثور على وظيفة، تأكد من الحصول على تصريح عمل. إذا لم تكن كذلك
            بالتأكيد إذا احتجت إلى واحد،
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit.html>
              انقر هنا
            </a>
            . . ستحتاج أيضا إلى CV/إستئناف. لمزيد من المعلومات حول
            الاحتياجات مع التوظيف، انتقل إلى صفحة <a href=/explore>أستكشاف</a>
            وتحديد التوظيف وتنظيم المشاريع. من هناك سوف
            العثور على المؤسسات التي يمكنها مساعدتك فيما تحتاج إليه.`,
        },
        {
          question: "أحتاج إلى مساعدة مع سيارتي الذاتية الدفع/السيرة الذاتية",
          answer: `بناء CV هو أداة مهمة تحتاج إليها للعثور على عمل
            كندا. قد تكون هناك منظمات قادرة على مساعدتك في هذا. اذهب
            إلى صفحة <a href=/explore>استكشف</a> وحدد تشغيل &
            تنظيم المشاريع. يمكنك عندئذ إختيار خيار تعليمات CV للعثور عليه
            منظمات بالقرب منك تستطيع مساعدتك في هذا.`,
        },
        {
          question: " ما هي حقوقي كعامل في كندا؟",
          answer: `مهما كانت حالتك، لديك حقوق كعامل في كندا.
            يمكنك النقر فوق الروابط أدناه لمعرفة المزيد:
            <br />
            <br />
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/permit/temporary/after-apply-next-steps/understand-your-rights-foreign-workers.html>
              - حقوقك كعامل أجنبي
            </a>
            <br />
            <br />
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/rights.html>
              - حقوق المطالبين اللاجئين
            </a>
            <br />
            <br />
            <a href=https://www.canada.ca/en/employment-social-development/services/foreign-workers/protected-rights.html>
              - حقوق العمال الأجانب المؤقتين
            </a>`,
        },
        {
          question: "كيف يمكنني بدء عمل تجاري؟",
          answer: `البدء في عمل تجاري فكرة عظيمة! للبدء، يمكنك زيارة
            الحكومة الكندية 
            <a href=https://www.canada.ca/en/services/business/start.html>
              موقع
            </a>
            . . سيتعين عليك أيضا تسجيل شركتك في مقاطعتك
            الحكومة أيضا. إذا احتجت إلى إستشارة إضافية، يمكنك الذهاب
            إلى <a href=/explore>استكشف</a> وحدد تشغيل &
            تنظيم المشاريع.`,
        },
      ],
    },
    {
      category: "legal",
      faq: [
        {
          question: "كيف تطلب اللجوء في كندا؟",
          answer: `هناك ثلاث (٣) طرق للمطالبة باللاجئين في كندا.
            <br />
            <br /> - أولا، سيتعين عليك التسجيل لدى مكتب الأمم المتحدة السامي
            مكتب مفوضية شؤون اللاجئين في بلدكم. سيفعلون
            سجل لك و سوف تكون قادرا على مساعدتك في إعادة التوطين إذا
            ممكن.
            <br />
            <br />
            - ثانيا، يمكنك الحصول على تأشيرة سياحية في بلدك في كندا
            السفارة لتكون قادرة على السفر إلى كندا. عندما تهبط في كندا
            يمكن أن يلجأ إلى الحدود، في المطار.
            <br />
            <br />
            - ثالثا، يمكنك الحصول على رعاية خاصة من قبل مجموعة من الكنديين
            المواطنين في كندا. سوف يدفعون ثمن إعادة توطينكم إذا كانت قضيتكم
            وهو مقبول من قبل الحكومة الكندية.
            <br />
            <br />
            لمعرفة المزيد عن الخطوات التي ينطوي عليها طلب اللجوء
            العملية،
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html>
              انقر هنا
            </a>
            .<br />
            <br />
            بالنسبة للمنظمات التي تتعامل مع الرعاية الخاصة، اذهب إلى
            <a href=/explore>صفحة أستكشاف</a>، حدد قانوني ثم برنامج رعاية خاص.
            <br />`,
        },
        {
          question:
              "ما هو وقت الانتظار حتى نستمع إلى طلبتي بشأن اللاجئين؟",
          answer: `والسلطات الاتحادية مسؤولة وحدها عن تجهيز الطلبات
            مرات. يمكنك مراجعة إطارات الوقت الفيدرالية بواسطة
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/application/check-processing-times.html>
              انقر هنا
            </a>
            .<br />`,
        },
        {
          question: "كيف لي أن أعرف ما إذا كان طلب اللجوء الذي تقدمت به سيقبل؟",
          answer: ` لا توجد طريقة مضمونة لمعرفة ما إذا كنت ستقبل منذ
            مجلس الهجرة واللاجئين الكندي
            سوف تسمع شهادتك و يجب أن تقرر ما إذا كانت إدعائك موثوق.
            لذلك من الضروري تقديم دليل وثائقي على قصتك
            وأن تكون مستعدا لسماعك وأن تستأجر محاميا
            خبير في قانون اللاجئين.`,
        },
        {
          question:
              " لقد تم قبولي، هل يتأهل أفراد عائلتي أيضا؟",
          answer: `نعم، في معظم الحالات الهجرة واللاجئين والمواطنة الكندية،
            فيما سبق، تحترم اللجنة مبدأ وحدة الأسرة. ولذلك، فإن
            وعادة ما تعقد العائلة جلسة واحدة وقرار واحد. إذا احتجت
            المساعدة القانونية أو المزيد من المعلومات حول قضيتك، انتقل إلى 
            <a href=/explore>صفحة أستكشاف</a> وحدد قانوني لقائمة من
            المؤسسات التي يمكنها مساعدتك. <br />`,
        },
        {
          question: "لقد رفض/أنكر دعوتي، ماذا يمكنني أن أفعل؟",
          answer: `يحق لك إستئناف القرار إذا رفضوا
            طلب اللجوء/اللجوء. لديك الحق في البقاء في كندا بينما أنت
            التقدم بطلب الاستئناف وتقديم طلب ضد خطر الترحيل قبل الترحيل
            التقييم. يمكنك أيضا تقديم طلب الإقامة الدائمة
            في كندا بناء على أسس إنسانية ورأفة. يمكنك
            إستشر هذا 
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/news/2017/03/claiming_asylum_incanadawhathappens.html>
              صفحة
            </a>
            لمزيد من المعلومات.`,
        },
        {
          question: "كيف يمكنني أن آتي إلى كندا؟",
          answer: `إذا كنت خارج بلدك الأصلي، يجب أن تتقدم أولا بطلب إلى
            مفوضية الأمم المتحدة لشؤون اللاجئين. عادة
            لإظهار أنك ستكون ناجحا اقتصاديا في كندا. كما أن
            تعريف اللاجئين أكثر تقييدا، ليس لديك الحق في
            محامي في مقابلتك، وقد تكون فترة الانتظار سنوات عديدة.
            يجب أن تظهر أيضا أنه لا يمكن أن تستقر في البلد الذي أنت فيه
            موجود حاليا.
            <br />
            <br />
            وفي الإجمال، يأتي الأفراد إلى كندا بطرق مختلفة متعددة:
            <br />- يمكنك التسجيل في المفوضية كما شرحنا أعلاه. <br />-
            طريقة أخرى هي تقديم طلب للحصول على تأشيرة سياحية في بلدك
            السفارة الكندية لتكون قادرة على السفر إلى كندا. عندما تهبط
            كندا، يمكنك طلب اللجوء عند الحدود، في المطار. إذا كنت
            تعال إلى كندا برا، تطلب اللجوء عندما تعبر الحدود.
            <br />- وأخيرا، يمكنك الحصول على رعاية خاصة من قبل مجموعة من الكنديين
            المواطنين في كندا. سوف يدفعون ثمن إعادة توطينكم إذا كانت قضيتكم
            وهو مقبول من قبل الحكومة الكندية.
            <br />
            <br />
            بالنسبة للمنظمات التي تتعامل مع الرعاية الخاصة، اذهب إلى
            <a href=/explore>صفحة أستكشاف</a>، حدد قانوني ثم برنامج رعاية خاص
            <br />
            <br />
            من أجل توسيع الإختلاف بين رعاية الحكومة و
            برعاية خاصة،
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/refugee-protection/resettlement/quebec-procedures.html>
              انقر هنا
            </a>`,
        },
        {
          question: "ما هي حقوق اللاجئين في كندا؟",
          answer: `عندما يأتي طالب اللجوء إلى كندا أولا، يجب أن يصنع
            طلب حماية للحكومة الكندية لكي تمنح
            وضع اللاجئ. خلال الوقت الذي يتم فيه تقييم هذه المطالبة، تم
            طالب اللجوء له حقوق معينة.
            <br />
            <br />
            وإذا نجح طلب الحماية، يمنح طالبو اللجوء
            وضع "الأشخاص المحميين"، الذي يسمى أيضا بوضع اللاجئ، ثم
            الحصول على مزايا إضافية. اللاجئون المقبولون لهم نفس الشيء
            حقوق كسكان دائمين.
            <br />
            <br />
            بعض اللاجئين الذين طالبوا باللجوء إلى بلد آخر هم
            أعيد توطينهم في كندا. هؤلاء اللاجئين المعاد توطينهم يتسلمون مبدئيا
            المساعدة (عادة ما تقدم لمدة تصل إلى سنة) من أي من
            الحكومة الفيدرالية، أو الحكومة الإقليمية، أو الجهات الخاصة الراعية.
            اللاجئون المعاد توطينهم يصبحون مقيمين دائمين ويمكنهم في نهاية المطاف
            كن مواطنا كندا. إذا احتجت إلى المزيد من المعلومات فيما يتعلق
            حقوق اللاجئين، الرجاء الانتقال إلى صفحة <a href=/explore>أستكشاف</a>
            وحدد قانوني للعثور على مؤسسة يمكنها الإجابة على
            أسئلة.`,
        },
        {
          question: "ماذا تعني العلاقات العامة؟ ماذا تعني مذكرة الأمن المركزي؟",
          answer: `شهادة إختيار كيبيك،
            أو CSQ هو وثيقة صادرة عن وزارة الهجرة،
            التنوع والاحتواء موضحين انه تم إختيار الحائز
            الاستقرار في كيبك. وبوجود نظام الضمان الجماعي، يمكن لمقدم الطلب أن يقدم طلبا للكندي
            الإقامة الدائمة. هذه الوثيقة ليست تأشيرة إقامة دائمة.
            يشار إلى أن قانون الضمان الاجتماعي ساري المفعول لمدة ٢٤ شهرا أو حتى الهجرة واللاجئين
            شركة الجنسية الكندية تتخذ قرارا بشأن إقامة شركة كندية دائمة
            طلب الإقامة. لمزيد من المعلومات ولتطبيق ذلك، إستشر هذا
            الصفحة.
            <br />
            <br />المقيم الدائم هو الشخص الذي أعطي إقامة دائمة
            وضع المقيمين عن طريق الهجرة إلى كندا، ولكن ليس كندي
            مواطن. والسكان الدائمون هم من مواطني بلدان أخرى. شخص
            في كندا بشكل مؤقت، مثل الطالب أو العامل الأجنبي، ليس
            مقيم دائم. اللاجئون الذين أعيد توطينهم من الخارج
            المقيمين بصفة دائمة من خلال برنامج اللاجئين الذي تدعمه الحكومة أو
            برنامج الرعاية الخاصة للاجئين.
            <br />
            <br />
            شخص ما يطالب باللاجئين في كندا لا يصبح
            مقيم دائم في ذلك الوقت. لكي تصبح واحدة، الهجرة و
            ويتعين على مجلس اللاجئين أن يوافق أولا على مطالبتهم. ثم يجب أن تنطبق
            للحصول على وضع الإقامة الدائمة. لمزيد من المعلومات
            تطبيق، إستشر هذا
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-q7000-application-permanent-residence-quebec-skilled-worker-class.html>
              صفحة
            </a>
            <br />`,
        },
        {
          question: "كيف يمكنني الحصول على محام أو مساعدة قانونية؟",
          answer: `هناك العديد من المنظمات التي تقدم المساعدة القانونية. للعثور على تلك
            أقرب إليك، الرجاء الانتقال إلى صفحة أستكشاف وحدد قانوني.`,
        },
      ],
    },
    {
      category: "education",
      faq: [
        {
          question: "هل أحتاج إلى تصريح دراسة ؟ كيف يمكنني الحصول على واحدة؟",
          answer: ` من أجل الدراسة في كندا، من الممكن أن تحتاج إلى
            تصريح الدراسة. إذا كنت لاجئا في محل اقامه مؤقته
            فيزا (TRV) مثل تأشيرة زيارة أو تصريح عمل، يمكنك القيام بذلك
            دورة قصيرة الأجل في حين أن تأشيرة الإقامة المؤقتة صالحة. إذا كنت
            خذ دورة مدتها أكثر من ٦ أشهر، ستحتاج إلى تقديم
            للحصول على تصريح الدراسة. أصحاب المطالبات من اللاجئين الذين ليس لديهم حق
            كما يجب أن تقدم تأشيرة الإقامة المؤقتة طلبا للحصول على تصاريح الدراسة. إذا كنت
            لديك تصريح دراسة صحيح، يمكنك قانونيا الدراسة في مرحلة ما بعد الثانوية
            المؤسسة. للمزيد من المعلومات حول تصاريح الدراسة، يرجى
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/temporary-residents/study-permits/refugees-protected-persons.html>
              انقر هنا
            </a>
            <br />
            <br />
             إذا كنت تحتاج إلى مساعدة في التطبيق على المدرسة، أريد أن تعرف خياراتك أو فقط
            لديها أسئلة تتعلق بالدراسة في كندا، وهناك منظمات
            يمكن أن تساعدك في هذا. انتقل إلى <a href=/explore>
             أستكشاف الصفحة
            </a>
            أختر التعليم، ثم أختر المساعدة الأكاديمية والمشورة
            لإيجاد منظمات بالقرب منك تستطيع المساعدة في هذا.`,
        },
        {
          question: "أريد التقدم للجامعة. ماذا أفعل؟",
          answer: `هناك العديد من المنظمات التي يمكنها مساعدتك في هذا. اذهب إلى
            <a href="/explore"> صفحة أستكشاف </a>، حدد التعليم ثم
            "المساعدة الأكاديمية والمشورة`,
        },
        {
          question: "كيف يمكنني العثور على جامعة؟",
          answer: ` يمكنك إيجاد جامعات قريبة منك بالنقر على المدينة التي أنت فيها
            الموجودة في:
            <br />
            <a href=https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/>
              جامعات هاليفاكس
            </a>
            <br />
            <a href=https://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/students/applying-admission/choosing-institution/index.html>
              جامعات مونتريال
            </a>
            <br />
            <a href=https://www.ontario.ca/page/ontario-universities>
              جامعات أونتاريو
            </a>
            <br />
            <a href=https://www.lifeincalgary.ca/schools/post-secondary>
              جامعات كالغاري
            </a>
            <br />
            <a href=https://www.collegealberta.info/edmonton.php>
              جامعات إدمونتون
            </a>
            <br />
            <a href=https://www2.gov.bc.ca/gov/content/education-training/post-secondary-education/find-a-program-or-institution/find-an-institution>
              جامعات فاندروا
            </a>`,
        },
        {
          question: "كيف يمكنني العثور على مدرسة مهنية؟",
          answer: `للعثور على برنامج تدريب مهني في موقعك، الرجاء العودة
            إلى الخدمات، أختر التعليم ثم المهني. يمكنك أيضا زيارة
            <br />
            <a href=https://www.nscc.ca/learning_programs/schools/trades/index.asp>
              مدارس مهنية في نوفا سكوشا
            </a>
            <br />
            <a href=https://www.emsb.qc.ca/emsb/schools/adult-vocational/centers>
              مدارس مهنية في مونتريال
            </a>
            <br />
            <a href=https://www.ontariocolleges.ca/en/apply/skilled-trades>
              مدارس مهنية في أونتاريو
            </a>
            <br />
            <a href=https://www.vocationalschools.ca/search#>
              مدارس مهنية في ألبرتا
            </a>
            <br />
            <a href=https://www.tradestrainingbc.ca/>
              مدارس مهنية في كولومبيا البريطانية
            </a>`,
        },
        {
          question: "كيف يمكنني العثور على كلية؟",
          answer: ` للعثور على كلية، الرجاء العودة إلى الخدمات، حدد التعليم، و
            ثم الكلية. ستجد قائمة بالكليات في موقعك.
            <br />
            <a href=https://discoverhalifaxns.com/explore/universities/universities-college-campuses-in-halifax/>
              هاليفاكس
            </a>
            <br />
            <a href=https://www.immigration-quebec.gouv.qc.ca/en/immigrate-settle/students/applying-admission/choosing-institution/index.html>
              كيبك
            </a>
            <br />
            <a href=https://www.ontariocolleges.ca/en/colleges>أونتاريو</a>
            <br />
            <a href=https://www.lifeincalgary.ca/schools/post-secondary>
              كالغاري
            </a>
            <br />
            <a href=https://www.collegealberta.info/edmonton.php>Edmonton</a>
            <br />
            <a href=https://www.thoughtco.com/guide-to-universities-colleges-in-vancouver-3371270>
              فانكوفر
            </a>`,
        },
        {
          question: "هل يمكنني الحصول على مساعدة مالية للمدرسة؟",
          answer: `وهذا يعمل بشكل مختلف بعض الشيء في كل ولاية:
            <br />
            <br />
            نوفا سكوتيا:
            <br />
            <br />
            وفي معظم الحالات، سيتعين على أصحاب المطالبات من اللاجئين دفع رسوم دولية
            إلى أن يتم تصنيفها كلاجئ أو شخص محمي؛ انتما
            يجب أن تتفقد مدرستك لقوانينها. <br />
            <br />
            كطالب في نوفا سكوشا، أنت مؤهل للتقدم بطلب للحصول على مجموعة متنوعة
            من القروض والمنح المختلفة، سواء على المستوى المحلي أو الفيدرالي. انقر فوق
            <a href=https://novascotia.ca/studentassistance/apply/new/FundingAvailable.asp>
              هنا
            </a>
            لقراءة المزيد حول الخيارات المتاحة.
            <br />
            <br />
            كيبيك:
            <br />
            وفي معظم الحالات، سيتعين على أصحاب المطالبات من اللاجئين دفع رسوم دولية
            إلى أن يتم تصنيفها كلاجئ أو شخص محمي؛ انتما
            يجب أن تتفقد مدرستك لقوانينها. <br />
            <br />
            قد تكون مؤهلا للحصول على بعض المنح الدراسية والمنح والمساعدات
            شركة AFE. مواطنون كنديون، مقيمون دائمون
            والأشخاص اللاجئون أو المحميون مؤهلون للحصول على مساعدة من القوات المسلحة لأفريقيا في كيبك
            برنامج إذا تم اعتبارهم مقيمين في كيبك من أجل مساعدة الطلاب
            الأغراض وقت التطبيق.
            <br />
            في هذا القسم، ستجد معلومات حول AFE
            <a href=https://www.quebec.ca/education/aide-financiere-aux-etudes/prets-bourses-temps-plein/conditions-admissibilite/>
              شروط الأهلية
            </a>
            وعملية التطبيق والمواعيد النهائية والبرنامج الهام الآخر
            التفاصيل.
            <br />
            <br />
            أونتاريو:
            <br />
            وفي معظم الحالات، سيتعين على أصحاب المطالبات من اللاجئين دفع رسوم دولية
            حتى يتم إقصائهم`,
        },
        {
          question: " أود تعلم الفرنسية و/أو الإنجليزية.",
          answer: `  إذا كنت ترغب في تعلم لغة في مدينتك، الرجاء العودة إلى
            الخدمات واختر الخيار: اللغات ثم أختر اللغة
            أنت مهتم بالتعلم: الإنجليزية أو الفرنسية أو IELTS أو
            صفوف تحادثية. من هناك ستجد منظمات قريبة
            أنت من يقدم هذه الدروس.`,
        },
        {
          question: "أين يمكنني تعلم البرمجة؟",
          answer: `فكرة عظيمة! تزدهر صناعة التكنولوجيا فى كندا. غالبا ما يكون هناك
            المهمات التي هي عبارة عن دورات قصيرة من ٦ إلى ٨ أسابيع حول كيفية تعلم كيفية
            الرمز. للبحث عن بعض في منطقتك، انتقل إلى
            <a href=/explore>استكشف</a> صفحة وحدد تشغيل &
            تنظيم المشاريع.`,
        },
        {
          question: "كيف يمكنني التعرف على أوراق اعتمادي الأجنبية؟",
          answer: `  لكي تتمكن من التدريب، يجب عليك الاتصال بالترتيب الخاص بك.
            <br />
            <br />
            صيدلية:
            <br />
            <a href=https://www.nspharmacists.ca/>نوفا سكوشا</a>
            <br />
            <a href=https://www.opq.org/>كيبك</a>
            <br />
            <a href=https://www.ocpinfo.com/>أونتاريو</a>
            <br />
            <a href=https://abpharmacy.ca/>ألبرتا</a>
            <br />
            <a href=https://www.bcpharmacists.org/>كولومبيا البريطانية</a>
            <br />
            <br />
            طب الأسنان:
            <br />
            <a href=http://pdbns.ca/>نوفا سكوشا</a>
            <br />
            <a href=http://www.odq.qc.ca/Accueil/tabid/127/language/fr-CA/Default.aspx>
              كيبك
            </a>
            <br />
            <a href=https://www.rcdso.org/en-ca/home>أونتاريو</a>
            <br />
            <a href=https://www.dentalhealthalberta.ca/>ألبرتا</a>
            <br />
            <a href=https://www.cdsbc.org/registration-renewal/dentists>
              كولومبيا البريطانية
            </a>
            <br />
            <br />
            القانون:
            <br />
            <a href=https://nsbs.org/>نوفا سكوشا</a>
            <br />
            <a href=https://www.barreau.qc.ca/en/>كيبك</a>
            <br />
            <a href=https://lso.ca/home>أونتاريو</a>
            <br />
            <a href=https://www.lawsociety.ab.ca/>ألبرتا</a>
            <br />
            <a href=https://www.lawsociety.bc.ca/>كولومبيا البريطانية</a>
            <br />
            <br />
            الطب:
            <br />
            <a href=https://cpsns.ns.ca/>نوفا سكوشا</a>
            <br />
            <a href=http://www.cmq.org/>كيبك</a>
            <br />
            <a href=https://www.cpso.on.ca/>أونتاريو</a>
            <br />
            <a href=http://www.cpsa.ca/>ألبرتا</a>
            <br />
            <a href=https://www.cpsbc.ca/>كولومبيا البريطانية</a>
            <br />
            <br />
            البنية:
            <br />
            <a href=https://nsaa.ns.ca/>نوفا سكوشا</a>
            <br />
            <a href=https://www.oaq.com/>كيبك</a>
            <br />
            <a href=https://www.oaa.on.ca/>أونتاريو</a>
            <br />
            <a href=https://www.aaa.ab.ca/>ألبرتا</a>
            <br />
            <a href=https://aibc.ca/>كولومبيا البريطانية</a>
            <br />
            <br />
            الهندسة:
            <br />
            <a href=https://engineersnovascotia.ca/>نوفا سكوشا</a>
            <br />
            <a href=https://www.oiq.qc.ca/Pages/accueil.aspx>كيبك</a>
            <br />
            <a href=https://www.peo.on.ca/>أونتاريو</a>
            <br />
            <a href=https://www.apega.ca/>ألبرتا</a>
            <br />
            <a href=https://www.egbc.ca/>كولومبيا البريطانية</a>
            <br />
            <br />
            التمريض:
            <br />
            <a href=https://www.nscn.ca/>نوفا سكوشا</a>
            <br />
            <a href=https://www.oiiq.org/>كيبك</a>
            <br />
            <a href=https://www.cno.org/>أونتاريو</a>
            <br />
            <a href=https://nurses.ab.ca/>ألبرتا</a>
            <br />
            <a href=https://www.bccnp.ca/Pages/Default.aspx>كولومبيا البريطانية</a>
            <br />
            <br />
            علم النفس:
            <br />
            <a href=https://apns.ca/>نوفا سكوشا</a>
            <br />
            <a href=https://www.ordrepsy.qc.ca/accueil>كيبك</a>
            <br />
            <a href=https://www.psych.on.ca/>أونتاريو</a>
            <br />
            <a href=https://psychologistsassociation.ab.ca/>ألبرتا</a>
            <br />
            <a href=https://www.psychologists.bc.ca/>كولومبيا البريطانية</a>
            <br />
            <br />
              العمل الاجتماعي:
            <br />
            <a href=http://nscsw.org/>نوفا سكوشا</a>
            <br />
            <a href=https://www1.otstcfq.org/>كيبك</a>
            <br />
            <a href=https://www.ocswssw.org/>أونتاريو</a>
            <br />
            <a href=https://www.acsw.ab.ca/>ألبرتا</a>
            <br />
            <a href=https://www.bccollegeofsocialworkers.ca/>
              كولومبيا البريطانية
            </a>
            <br />
            <br />
            المحاسبة:
            <br />
            <a href=https://www.cpans.ca/web/cpans/>نوفا سكوشا</a>
            <br />
            <a href=https://cpaquebec.ca/en/>كيبك</a>
            <br />
            <a href=https://www.cpaontario.ca/>أونتاريو</a>
            <br />
            <a href=https://www.cpaalberta.ca/>ألبرتا</a>
            <br />
            <a href=https://www.bccpa.ca/>كولومبيا البريطانية</a>
            <br />
            <br />`,
        },
        {
          question:
              " هل يسمح لأطفالي بالذهاب إلى المدرسة بينما تتم معالجة طلب اللجوء الخاص بي؟",
          answer: ` نعم، يمكن لأطفال المطالبين اللاجئين أن يحضروا الإبتدائية العامة
            المدارس الثانوية مجانية. إذا كنت بحاجة إلى مساعدة في العثور على مدرسة في المدرسة
            الموقع، الرجاء الانتقال إلى  </a>صفحةأستكشاف<a href=/explore>، حدد
            التعليم ثم المؤسسات للحصول على قائمة المدارس في منطقتك.`,
        },
        {
          question: "كيف يمكنني العثور على مدرسة ثانوية؟",
          answer: ` يمكنك أن تجد مدارس ثانوية بالقرب منك بالنقر على المدينة التي أنت فيها
            الموجودة في:
            <br />
            <br />
            <a href=https://www.hrce.ca/registration>
              هاليفاكس موقع المدارس العامة
            </a>
            <br />
            <a href=https://www.emsb.qc.ca/emsb/admissions/registration>
              موقع مونتريال المدارس العامة
            </a>
            <br />
            <a href=https://ocdsb.ca/>موقع أوتاوا للمدارس العامة</a>
            <br />
            <a href=https://www.tdsb.on.ca/Find-your/School/Registration>
              تورونتو العامة المدارس
            </a>
            <br />
            <a href=https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx>
              كالغاري المدارس العامة موقع
            </a>
            <br />
            <a href=https://epsb.ca/schools/register/>
              موقع مدرسة إدمونتون العامة
            </a>
            <br />
            <a href=https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx>
              فانكوفر موقع المدارس العامة
            </a>
            <br />
            <br />`,
        },
        {
          question: "كيف يمكنني العثور على مدرسة إبتدائية؟",
          answer: `يمكنك العثور على المدارس الابتدائية بالقرب منك بالنقر على المدينة التي
            في:
            <br />
            <a href=https://www.hrce.ca/registration>
              هاليفاكس موقع المدارس العامة
            </a>
            <br />
            <a href=https://www.emsb.qc.ca/emsb/admissions/registration>
              موقع مونتريال المدارس العامة
            </a>
            <br />
            <a href=https://ocdsb.ca/>موقع أوتاوا للمدارس العامة</a>
            <br />
            <a href=https://www.tdsb.on.ca/Find-your/School/Registration>
              تورونتو العامة المدارس
            </a>
            <br />
            <a href=https://cbe.ab.ca/registration/registration/Pages/High-School-Registration.aspx>
              كالغاري المدارس العامة موقع
            </a>
            <br />
            <a href=https://epsb.ca/schools/register/>
              موقع مدرسة إدمونتون العامة
            </a>
            <br />
            <a href=https://www.vsb.bc.ca/site/nwc/Pages/Default.aspx>
              فانكوفر موقع المدارس العامة
            </a>
            <br />
            <br />`,
        },
      ],
    },
    {
      category: "health",
      faq: [
        {
          question: "هل يمكنني الحصول على التأمين الصحي؟",
          answer: `طالبي اللجوء مشمولون بموجب القانون
            <a href=https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/health-care/interim-federal-health-program/coverage-summary.html>
              برنامج الصحة الاتحادي المؤقت
            </a>
            ولكن بمجرد أن تصبح لاجئا مقبولا، سيكون تأمينك الصحي
            المحافظة وتعمل بشكل مختلف في كل محافظة:
            <br />
            <br />
            :
            نوفا سكوتيا
            <br />
            أنت مؤهل للحصول على الرعاية الصحية في المقاطعات بمجرد أن تكون
            مقبولة كلاجئ. لمعرفة كيفية التسجيل، تفضل بزيارة
            <a href=https://novascotia.ca/DHW/msi/health_cards.asp>
              هذه الصفحة
            </a>
            <br />
            <br />
            <br />
            :كيبيك
            <br />
            أنت مؤهل للحصول على الرعاية الصحية في المقاطعات بمجرد أن تكون
            مقبولة كلاجئ. لمعرفة كيفية التسجيل، تفضل بزيارة
            <a href=https://www.ramq.gouv.qc.ca/en/citizens/health-insurance/register>
              هذه الصفحة
            </a>
           <br />
            <br />
            <br />
            :أونتاريو
            <br />
            أنت مؤهل للحصول على الرعاية الصحية في المقاطعات بمجرد أن تكون
            مقبولة كلاجئ. لمعرفة كيفية التسجيل، تفضل بزيارة
            <a href=https://www.ontario.ca/page/apply-ohip-and-get-health-card>
              هذه الصفحة
            </a>
            <br />
            <br />
            <br />
            :ألبرتا
            <br />
            أنت مؤهل للحصول على الرعاية الصحية في المقاطعات بمجرد أن تكون
            مقبولة كلاجئ. لمعرفة كيفية التسجيل، تفضل بزيارة
            <a href=https://www.alberta.ca/ahcip-how-to-apply.aspx>
              هذه الصفحة
            </a>
            <br />
            <br />
            <br />
            :كولومبيا البريطانية
            <br/>
            أنت مؤهل للحصول على الرعاية الصحية في المقاطعات بمجرد أن تكون
            مقبولة كلاجئ. لمعرفة كيفية التسجيل، تفضل بزيارة
            <a href=https://www2.gov.bc.ca/gov/content/health/health-drug-coverage/msp/bc-residents/eligibility-and-enrollment/how-to-enroll>
              هذه الصفحة
            </a>`,
        },
        {
          question: "إذا احتجت إلى طبيب أو عناية طبية، أين يمكنني الذهاب؟",
          answer: `للعثور على عيادة أو خدمات رعاية صحية في منطقتك، اذهب إلى
            <a href=/explore>استكشف صفحة</a> وحدد صحة.`,
        },
        {
          question: "أين يمكنني العثور على طبيب لمساعدتي في حملي؟",
          answer: `مبروك على حملك! اذهب إلى 
            <a href=/explore>استكشف</a> صفحة، حدد صحة ثم
            صحة المرأة. هذا سيمنحك كل خيارات
            المنظمات التي يمكنها مساعدتك في منطقتك.`,
        },
        {
          question: "كيف يمكنني الحصول على طبيب العائلة؟",
          answer: `إذا لم يكن لديك بطاقة رعاية صحية، لا يمكنك تعيين
            مع ذلك، لا يزال بإمكانك طلب الرعاية الطبية أثناء المشي
            في العيادات. للعثور على عيادة أو خدمة رعاية صحية في منطقتك، اذهب
            إلى صفحة <a href=/explore>استكشف</a> وحدد الصحة.
            <br />
            <br />
            إذا كان لديك بطاقة رعاية صحية يمكنك التقدم بطلب للحصول على طبيب لكن هذا
            تعمل بشكل مختلف في كل مقاطعة. حدد المقاطعة أدناه للحصول على
            المعلومات الصحيحة لك:
            <br />
            <br />
            <a href=https://needafamilypractice.nshealth.ca/>نوفا سكوشا</a>
            <br />
            <a href=https://www.quebec.ca/en/health/finding-a-resource/registering-with-a-family-doctor/>
              كيبك
            </a>
            <br />
            <a href=https://www.ontario.ca/page/find-family-doctor-or-nurse-practitioner>
              أونتاريو
            </a>
            <br />
            <a href=https://www.albertahealthservices.ca/info/Page13253.aspx>
              ألبرتا
            </a>
            <br />
            <a href=https://bccfp.bc.ca/for-the-public/find-a-family-doctor/>
              كولومبيا البريطانية
            </a>`,
        },
        {
          question: "أين يمكنني الحصول على الدواء وهل هو مجاني؟",
          answer: `يمكنك شراء الأدوية في الصيدليات المحلية. الدواء ليس
            مجانا، على أي حال يمكنك الحصول على تخفيض في السعر إذا
            لديه تأمين خاص.`,
        },
        {
          question: "كيف يمكنني الحصول على دعم الصحة العقلية؟",
          answer: ` هناك العديد من المؤسسات التي تقدم خدمات للمساعدة
            الصحة العقلية. الرجاء الانتقال إلى صفحة <a href=/explore>أستكشاف</a>،
            أختر الصحة ثم الصحة العقلية للعثور على المنظمات/الخدمات في
            منطقتك.`,
        },
      ],
    },
    {
      category: "housing",
      faq: [
        {
          question: "أين يمكنني إيجاد مكان للعيش؟",
          answer: `هناك العديد من المنظمات المختلفة التي يمكنها مساعدتك في هذا
            حسب حالتك واحتياجاتك. للوصول إلى
            المؤسسات في مدينتك، انتقل إلى <a href=/explore>أستكشاف</a>
            صفحة واختيار الإسكان.`,
        },
        {
          question: "ما هي حقوقي السكنية كمستأجر؟",
          answer: `  كمستأجر، لديك حقوق. الرجاء النقر فوق المقاطعة لمعرفة
            المزيد.
            <br />
            <br />
            <a href=https://beta.novascotia.ca/programs-and-services/residential-tenancies-program>
              نوفا سكوشا
            </a>
            <br />
            <a href=https://www.tal.gouv.qc.ca/en">كيبك</a>
            <br />
            <a href=https://www.ontario.ca/laws/statute/06r17">أونتاريو</a>
            <br />
            <a href=https://www.alberta.ca/landlords-tenants.aspx">ألبرتا</a>
            <br />
            <a href=https://www2.gov.bc.ca/gov/content/housing-tenancy/residential-tenancies>
              كولومبيا البريطانية
            </a>
            <br />
            <br />
            يمكنك أيضا الانتقال إلى <a href=/explore>صفحة أستكشاف</a>، حدد
            الإسكان ثم حقوق السكن لإيجاد منظمات بالقرب منك
            يمكن أن تساعدك في هذا الموضوع.`,
        },
      ],
    },
    {
      category: "general_services",
      faq: [
        {
          question: `كيف أحصل على رخصة القيادة؟`,
          answer: ` إن الحصول على رخصة القيادة مختلف في كل محافظة:
            <br />
            <br />
            نوفا سكوتيا: يمكنك القيادة في نوفا سكوشا بترخيص من شخص آخر
            البلد لمدة ٩٠ يوما، بعد ذلك، ستحتاج إلى الحصول على نوفا
            رخصة سكوشا. للحصول على رخصة قيادة في نوفا سكوشا، تحتاج
            لإجراء امتحان كامل لرخصة القيادة يتضمن معرفة،
            الرؤية، واختبارات الطرق.
            <a href=https://novascotia.ca/sns/rmv/license/gradlic.asp>
              انقر هنا
            </a>
            لمزيد من المعلومات.
            <br />
            <br />
            كيبيك: إذا كان لديك ترخيص قيادة من بلد آخر،
            إذن أنت مؤهل لكتابة الامتحان النظري والعملي
            الحصول على إذن السائق في كيبك. انقر فوق
            <a href=https://saaq.gouv.qc.ca/en/drivers-licenses/foreign-drivers-license/>
              هنا
            </a>
            لمزيد من المعلومات. إذا لم يكن لديك أي ترخيص، سيتعين عليك
            يثبت أنك ستبقى في كندا لمدة ٦ أشهر على الأقل
            ثم ستحتاج إلى إكمال دورات القيادة للحصول على
            الترخيص. لمزيد من المعلومات، انقر فوق
            <a href=https://saaq.gouv.qc.ca/en/drivers-licenses/obtaining-license/passenger-vehicle-class-5/>
              هنا
            </a>
            <br />
            <br />
            أونتاريو: بالنسبة للقادمين الجدد في أونتاريو، من المستحسن أن تبدأ
            عملية الحصول على الترخيص في أقرب وقت ممكن.
            <a href=https://settlement.org/ontario/daily-life/transportation/driver-s-license/how-do-i-get-an-ontario-driver-s-license/>
              انقر هنا
            </a>
            لمزيد من المعلومات حول كيفية بدء العملية.
            <br />
            <br />
            ألبرتا: إذا كان لا يزال لديك رخصة قيادة من أخرى
            البلد، ثم`,
        },
        {
          question: "كيف يمكنني الحصول على خطة الهاتف؟",
          answer: `هناك خيارات مختلفة متاحة لك اعتمادا على ما أنت عليه
            الحاجة. وهناك خطط مدفوعة مسبقا (أو الدفع أولا بأول) وعقد شهري
            الخطط. خطط الهاتف في كندا قد تكون مكلفة بعض الشيء ولكن هناك الكثير
            شركات مختلفة لديها خطط وأسعار مختلفة
            يمكن إختيار الأفضل لك. بعض الأمثلة للشركات
            و التي يمكن تحمل كلفتها أكثر من ذلك؛ شاتر، فيتز، كودو، فيرجن موبايل و
            فيدو. وهناك أيضا شركات مثل بيل وروجرز وتيلوس. انقر فوق
            <a href=https://moving2canada.com/cell-phone-plan-in-canada/>
              هنا
            </a>
            لمزيد من المعلومات حول خطط الهاتف في كندا.`,
        },
        {
          question: "أين يمكنني الحصول على الأثاث؟",
          answer: `هناك مجموعة من الأماكن المختلفة حيث يمكنك الحصول على سعر مناسب
            الأثاث. حتى ان بعض الاماكن تقدم هذه الاشياء مجانا. لمزيد من المعلومات
            معلومات حول المكان الذي يمكنك الذهاب إليه، الرجاء العودة إلى الخدمات واختيار
            الخيار: عام ثم أختر الملابس والأثاث. سوف
            كن قادرا على العثور على ما تحتاجه هناك.`,
        },
        {
          question: "أين يمكنني شراء الملابس؟",
          answer: ` هناك مجموعة من الأماكن المختلفة حيث يمكنك الحصول على سعر مناسب
            الملابس. حتى ان بعض الاماكن تقدم هذه الاشياء مجانا. لمزيد من المعلومات
            معلومات حول المكان الذي يمكنك الذهاب إليه، الرجاء العودة إلى الخدمات واختيار
            الخيار: عام ثم أختر الملابس والأثاث. سوف
            كن قادرا على العثور على ما تحتاجه هناك.`,
        },
        {
          question: "أين يمكنني العثور على طعام مجاني/منخفض التكلفة؟",
          answer: `هناك العديد من الأماكن التي يمكن أن تقدم لك الطعام. عادة، سيكون لديك
            للانضمام إلى بنك الطعام. للعثور على بنوك غذائية في منطقتك، اذهب إلى
            صفحه الاستكشاف وقم باختيار بنوك الطعام.`,
        },
        {
          question: " ما هي رعاية الأطفال المتوفرة في محافظتي؟",
          answer: `للاطلاع على قائمة المنظمات التي تقدم الخدمات للأسر،
            الشباب والأطفال، الرجاء الذهاب إلى <href=/explore">أستكشاف</a>
            وحدد العائلة أو أختر عام ثم الشباب. يمكنك أيضا
            قم بتحديد عام ثم حدد الحضانة. لمعرفة المزيد أو البحث
            معلومات عامة عن رعاية الطفل في مقاطعتك، انقر فوق
            <a href=https://findingqualitychildcare.ca/finding-child-care/child-care-in-each-province-and-territory>
              هنا
            </a>`,
        },
        {
          question: "أين يمكنني العثور على مترجم؟",
          answer: ` إذا طلبت ترجمة الوثائق الرسمية، ستحتاج إلى
            مترجم معتمد. يمكنك العثور على مترجمين ومنظمات
            قم بالترجمة بالتوجه إلى <a href=/explore>الصفحة</a>:
            حدد عام ثم ترجمة.`,
        },
        {
          question:
              " أحتاج إلى مساعدة في نفقات أسرتي، هل يمكنك مساعدتي للحصول على إستحقاقات الأطفال؟",
          answer: ` بمجرد حصولك على رقم التأمين الاجتماعي الخاص بك، يمكنك
            تنطبق على إستحقاقات الأطفال، فضلا عن الفوائد الأخرى. يمكنك قراءة المزيد
            عن
            <a href=https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/individuals-leaving-entering-canada-non-residents/newcomers-canada-immigrants.html#tbc>
              هنا
            </a>
            `,
        },
        {
          question: "كيف أستخدم وسائل النقل العام؟",
          answer: `للاستخدام
            <a href=https://www.halifax.ca/transportation/halifax-transit/fares-tickets-passes>
              هاليفاكس
            </a>
            نظام نقل، تحتاج لشراء تذاكر أو تصريح مرور.
            <br />
            <br />
            في 
            <a href=http://www.stm.info/en/info/fares/opus-cards-and-other-fare-media/opus-card>
              مونتريال
            </a>
            ، وتسمى المواصلات العامة بالاتوبيس ،
            المترو والقطار. يمكنك الذهاب باستخدام بطاقة OPUS التي يمكن
            أن يتم شراؤها من أي محطة مترو.
            <br />
            <br />
            <a href=https://www.octranspo.com/en/plan-your-trip/how-to-ride/>
              سي سبيلو
            </a>
            تشغيل شبكة الحافلات المفيدة في أوتاوا ونظام السكك الحديدية الخفيفة المعروف
            كالقطار.
            <br />
            <br />
            <a href=https://ttc.ca/Fares_and_passes/index.jsp>
              لجنة تورونتو للعبور
            </a>
            يدير ثلاثة أنساق - مترو أنفاق وشارع السكك الحديدية وحافلة - التي تعمل بكثافة
            الطرق في جميع أنحاء المدينة والضواحي. ستحتاج إلى رمز مميز أو
            المرور على السفر في مركز التدريب التقني؛ تمريرات اليوم والأسبوع تسمح بركوب غير محدودة
            بشان جميع اشكال النقل العام الثلاث. <br />
            <br />
            في <a href=https://www.calgarytransit.com/fares-passes>كالغاري</a>،
            ستحتاج إلى شراء تذكرة أو بطاقة مرور لاستخدام العامة
            النقل.
            <br />
            <br />
            <a href=https://www.edmonton.ca/edmonton-transit-system-ets.aspx>
              خدمة الترانزيت في إدمونتون
            </a>
            يدير شكلين من طرق المرور العام في جميع أنحاء المدينة: الحافلات والحافلات
            نظام النقل بالسكك الحديدية الخفيفة.
            <br />
            <br />
            البوصلة هي بطاقة الأجرة القابلة لإعادة التحميل التي تعمل في كل مكان عند العبور
            في 
            <a href=https://www.translink.ca/Fares-and-Passes/Fare-Pricing.aspx>
              فانكوفر
            </a>`,
        },
        {
          question: "هل يمكنك مساعدتي في العثور على بنك؟",
          answer: `للعثور على بنك، انتقل إلى <href=/explore>أستكشاف</a> صفحة، حدد
            المالية والمحاسبة ثم المصرفية.. للبحث عن قائمة الكل
            البنوك الكندية، انقر فوق
            <a href=https://www.immigroup.com/news/best-bank-newcomers-canada>
              هنا
            </a>
            `,
        },
        {
          question:
              "هل يحتاج اللاجئون إلى فرض ضرائب؟ إذا كان الجواب نعم، هل يمكن لأحد أن يساعدني في هذا؟",
          answer: ` إن فرض الضرائب مهم جدا وعادة ما يتم رفعها أمام
            () نيسان/أبريل من السنة التالية. للعثور على عيادة ضريبية مجانية، انقر فوق
            <a href=https://www.canada.ca/en/revenue-agency/campaigns/free-tax-help.html>
              هنا
            </a>
            . . إذا كنت ترغب في إيجاد منظمة لمساعدتك، عد إلى
            الخدمات والاختيار المالية والمحاسبة.`,
        },
      ],
    },
  ]
};

export default Data;
