import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import MapIcon from '@material-ui/icons/Map';
import clsx from "clsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLayerGroup} from "@fortawesome/pro-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
    speedDial: {


    },
}));

export default function MenuSpeedDial({hidden, className,   ...rest}) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
            <SpeedDial
                ariaLabel="Explore speed dial"
                className={clsx(classes.speedDial, className)}
                hidden={hidden}
                icon={<FontAwesomeIcon icon={faLayerGroup} size={'2x'} />}
                onClose={handleClose}
                onOpen={handleOpen}
                direction={'up'}
                open={open}
            >
                {rest.children}
            </SpeedDial>

    );
}
