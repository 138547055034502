import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ServiceIcon from "../common/ServiceIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import React, {useContext, useMemo} from "react";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import {LanguageContext} from "../../providers/LanguageProvider";


export default function SubServiceListItem({subService, onSelectSubService, checked, iconColor}) {

    const {userLanguage} = useContext(LanguageContext);
    const name = useMemo(()=>{
        return get_intl_lang(userLanguage, subService.intlMetadata)?.name || subService.name
    },  [userLanguage, subService])


    return (
        <ListItem key={`${subService.id}_item`}>
            <ListItemAvatar>

                <ServiceIcon
                    service={subService}
                    type={"avatar"}
                    colorProps={iconColor[300]}
                    avatarStyles={{backgroundColor: iconColor[50], color: "#fff"}}
                />

            </ListItemAvatar>
            <ListItemText id={`${subService.id}_item_text`} primary={name}/>
            <ListItemSecondaryAction>
                <Checkbox
                    edge="end"
                    key={`${subService.id}_checkbox`}
                    onClick={(e) => onSelectSubService(subService)}
                    checked={checked}
                    inputProps={{'aria-labelledby': subService.id}}
                />
            </ListItemSecondaryAction>
        </ListItem>
    )
}