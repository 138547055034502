import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Avatar, Button, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CardActions from "@material-ui/core/CardActions";
import ResponsiveDialog from "../auth/ResponsiveDialog";
import {uploadImageBasedOnImgTitle} from "../utils/commonUsedFunctions";
import {Text} from "../../providers/LanguageProvider";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth:"100%",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
        },
        borderRadius:"8px"
    },
    heroContent: {
        backgroundColor: "#eeeeee",
    },
    avatar:{
        width:"140px",
        height:"140px",
        border:"solid",
        marginTop:"30px",
        objectFit:"contain"
    },
     img:{
         // height:"56%",
         height:"87%",
         width:"79%",
         objectFit:"scale-down"
    }
     }
));

const OrganizationCard = (props)=>{
    const classes = useStyles();
    const [openDialog, setOpenDialog] = React.useState(false);
    const handleClickOpenDialogFromListItem = () => {
        setOpenDialog(true);
    };
    const handlePopUpClose = () => {
        setOpenDialog(false);
    };

    return (
        <Card className={classes.root}>
           <CardContent style={{padding:0, paddingBottom:0}}>
            <Grid container direction={"column"} alignItems={"center"} spacing={2}>
                <Grid  item xs={12}>
                    <Avatar
                        classes={{
                            root: classes.avatar,
                            img: classes.img,
                        }}
                        alt={props.companyName}
                        src={uploadImageBasedOnImgTitle(props.src)}
                        className={classes.avatar}
                    />
                </Grid>
                <Grid item xs={12}  style={{width:"100%"}}>
                    <Grid container direction={"row"} justify={"center"}>
                        <Grid item xs={12}>
                                    <Typography variant={"h6"} align={"center"}>{props.companyName} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <CardActions disableSpacing style={{justifyContent:"flex-end"}}>
                            <Button
                                size="small"
                                color={'primary'}
                                endIcon={<ArrowRightAltIcon/>}
                                onClick={handleClickOpenDialogFromListItem}
                            >
                                <Text tid="AboutUs.founders.learnMore" />

                            </Button>
                        </CardActions>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
               {openDialog ? (
                   <ResponsiveDialog
                       currentLanguage={props.currentLanguage}
                       open={openDialog}
                       title={"organization"}
                       onClose={handlePopUpClose}
                       content={props.content}
                       companyName={props.companyName}
                       url={props.url}
                   />
               ) : null}
           </CardContent>
        </Card>
    )



}
export default OrganizationCard
