import React, {useContext, useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Instructions from "./Instructions";
import Organizations from "./Organizations";
import AboutItem from "./AboutItem";
import {LanguageContext} from "../../providers/LanguageProvider";

import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    heroContent: {
        backgroundColor: "#eeeeee",
        padding: theme.spacing(8, 0, 6),
        overflow:"hidden"
    },
}));

const About = ()=>{
    const classes = useStyles();
    const [data,setData] =useState([])
    const { tt, userLanguage } = useContext(LanguageContext);


    useEffect(() => {
        setData(tt('AboutUs.data'))

    }, [userLanguage, tt]);

    return(
        <div>
            <Container maxWidth={"xl"} className={classes.heroContent}>
                {data.length > 0 ? data.map((comp, index)=>{
                if(comp.header === "instructions" ||
                    comp.header === "founders"){
                    if(comp.header === "instructions"){
                        return <Instructions key={index}  />
                    }else{
                        return <Organizations key={index}  />
                    }
                }else {
                    return <AboutItem data={comp} key={index} />
                }
            }) : null }
            </Container>
        </div>
    )
}

export default About
