import React, {useContext, useEffect, useState} from 'react';
import {alpha, makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';


import AutocompleteLocation from "../journey/AutocompleteLocation";
import PropTypes from "prop-types";

import {MenuOpen} from "@material-ui/icons";
import OptionsDrawer from "./OptionsDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {LinearProgress} from "@material-ui/core";

import LanguageMenu from "./LanguageMenu";
import {LanguageContext} from "../../providers/LanguageProvider";


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),


    },
    appBarShift: (props) => ({
        width: `calc(100% - ${props.drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: props.drawerWidth,

    }),

    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    statusBar: {
        position: 'fixed',
        zIndex: 1,
        top: 76,
        width: "100%",
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.down('md')]: {
            display: 'none',

        },
    },

    sectionDesktop: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    sectionMobile: {
        display: "none",
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },

    },
    drawer: {

    }
}));


SearchBar.propTypes = {

    onSelectLocationAddress: PropTypes.func.isRequired,
    selectedStatusState: PropTypes.array,
    statusBar: PropTypes.node


}
export default function SearchBar(props) {

    const {onSelectLocationAddress, drawerOpen, handleDrawerClose, handleDrawerOpen, drawerWidth, onMenuClick, className, loading, onlanguagechange} = props

    const [optionsDrawerOpen, setOptionsDrawerOpen] = useState(false)
    const theme = useTheme()
    const classes = useStyles({drawerWidth: drawerWidth});
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const { tt  } = useContext(LanguageContext);
    const input = (params) => {
        return (<InputBase
            ref={params.InputProps.ref}
            inputProps={params.inputProps}

            placeholder="Search…"
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}

        />)

    }



    useEffect(()=>{

        setOptionsDrawerOpen(mobile && drawerOpen)


    }, [mobile, drawerOpen])

    return (
        <div className={classes.grow}>


            <AppBar
                color="primary"
                className={clsx(classes.appBar,
                    {[classes.appBarShift]: optionsDrawerOpen},
                    className
                )}
            >


                <Toolbar>
                    <IconButton
                        edge="start"
                        onClick={onMenuClick}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <MenuIcon/>
                    </IconButton>

                    <Typography className={classes.title} variant="h6" noWrap>
                        {tt('explore.searchBar.title')}
                    </Typography>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon/>
                        </div>
                        <AutocompleteLocation onChange={onSelectLocationAddress} renderInput={input}/>
                    </div>
                    {props.children}
                    <div className={classes.grow}/>

                    <div className={classes.sectionDesktop}>


                        <LanguageMenu onlanguagechange={onlanguagechange} />
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            className={clsx(optionsDrawerOpen && classes.hide)}
                        >
                            <MenuOpen />
                        </IconButton>
                    </div>

                </Toolbar>
                <div className={classes.statusBar}>
                    {props.statusBar}
                </div>
                {loading &&
                    <LinearProgress color="secondary"/>
                }
            </AppBar>
            <OptionsDrawer
                width={drawerWidth}
                open={optionsDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                className={classes.drawer}
            >
                {props.drawerContent}
            </OptionsDrawer>
        </div>
    );
}
