import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import {get_intl_lang} from "../utils/commonUsedFunctions";
import {LanguageContext} from "../../providers/LanguageProvider";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function StatusBar(props) {

    const {statuses, ...rest} = props

    const [state, setState] = useState([])
    const    { userLanguage } = useContext(LanguageContext);
    const onStatusClick = (statusId) =>{
        props.onChange(statusId)

    }

    useEffect(()=>{

        setState(props.state)

    }, [props.state])

    const classes = useStyles();

    return (
        <div className={props.className} {...rest}>

            {statuses.map((status)=> {
                const name = get_intl_lang(userLanguage, status.intlMetadata)?.name || status.name;
                return (

                    <Fab
                        key={status.id}
                        variant="extended"
                        size="small"
                        color={ state.includes(status.id) ? "secondary" : "primary"}

                        aria-label="add"
                        className={classes.margin}
                        onClick={(e) => onStatusClick(status.id)}
                    >

                        {name}
                    </Fab>

                )


            })}

        </div>
    );
}
