import apiConfig from "./apiConfig";


const deleteOrganization = (ids) => apiConfig.post(`/admin/orgs/delete`, ids)

const submitOrganization = (org) => apiConfig.post('/admin/orgs', org)


export default {

    deleteOrganization,
    submitOrganization,


};
