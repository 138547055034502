import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import QuestionsData from "./Data";
import CardCategoryLink from "../bookmarks/CardCategoryLink";
import {assignIconBasedOnService} from "../utils/commonUsedFunctions";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {KeyboardArrowLeft} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Row from "./Row";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Image from "../../general/Image";
import test from "./public/faqImg.svg";
import {LanguageContext, Text} from "../../providers/LanguageProvider";

const useStyles = makeStyles((theme) => ({
  extraSmall: {
    width: 20,
    height: 20,
  },
  small: {
    width: 50,
    height: 50,
  },
  medium: {
    width: "70%",

    height: "90%",
  },
  large: {
    width: 90,
    height: 90,
  },
  circle: {
    borderRadius: "100%",
  },
  square: {
    borderRadius: theme.spacing(2),
  },
  img: {
    width: "50%",
    height: "100%",
  },
  btn: {
    "&:hover": {
      backgroundColor: "white",
    },
    backgroundColor: "white",
    width: 120,
    height: "100%",
  },

  active: {
    padding: 0,
    margin: 0,
    backgroundColor: "rgb(201,203,204)",
    width: 120,
    height: "100%",
  },
  heroContent: {
    backgroundColor: "#eeeeee",
    padding: theme.spacing(8, 0, 6),
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    paddingTop: theme.spacing(10),
  },
  cardGrid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const [showServicesGrid, setShowServicesGrid] = useState(true);
  const [service, setService] = useState(null);
  const { userLanguage  } = useContext(LanguageContext);
  const DATA = QuestionsData[userLanguage];

  const handleGridItemClicked = async (service) => {
    setService(service);
    setShowServicesGrid(false);
  };
  const handleBack = () => {
    setShowServicesGrid(true);
  };
  function createCategoryCardBasedOnService(obj) {

    const serviceData = assignIconBasedOnService(obj.category);
    return (
      <CardCategoryLink
        value={service}
        onClick={() => handleGridItemClicked(obj.category)}
        variant="outlined"
        service={{"title": obj.category}}
        align={"center"}
        liftUp
        title={serviceData.title}
        subtitle={serviceData.subtitle}
        href="#"
        fontIconClass={serviceData.icon}
        color={serviceData.color}
      />
    );
  }
  return (
    <div>
      <div className={classes.heroContent}>
        <Container maxWidth="lg">
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography
                component="h1"
                variant="h2"
                align="left"
                color="textPrimary"
                gutterBottom
              >
                <Text tid="FAQ.header" />
              </Typography>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              xs={12}
              md={6}
            >
              <Image src={test} alt="FAQ" className={classes.image} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="xl">
        <Grid container direction={"row"} justify={"center"} spacing={1}>
          {showServicesGrid && DATA? (
            DATA.map((category, i) => (
              <Grid
                key={i}
                item
                container
                alignItems="center"
                direction="column"
                xs={12}
                sm={6}
                md={4}
              >
                {createCategoryCardBasedOnService(category)}
              </Grid>
            ))
          ) : service && DATA ? (
            <div style={{ width: "100%", height: "100%" }}>
              <Grid
                container
                spacing={3}
                style={{ display: "contents" }}
              >
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    className={classes.button}
                    component="a"
                    onClick={handleBack}
                  >
                    <KeyboardArrowLeft />
                    <Text tid="FAQ.back" />
                  </Button>
                </Grid>
                <Grid item>
                  <Grid container spacing={2} direction={"column"}>
                    {service
                      ? DATA.map((item,index) =>
                          service === item.category ? (
                            <TableContainer component={Paper} key={index}>
                              <Table aria-label="collapsible table">
                                <tbody>
                                {item.faq.map((faqItem, i) => (
                                  <Row key={i} row={faqItem} />
                                ))}
                                </tbody>
                              </Table>
                            </TableContainer>
                          ) : null
                        )
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : null}
        </Grid>
      </Container>
    </div>
  );
};

export default FAQ;
