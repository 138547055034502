import React, {useContext, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import {DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import {LanguageContext} from "../../providers/LanguageProvider";


LearnMoreDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    closeButtonLabel: PropTypes.string.isRequired
};
export default function LearnMoreDialog(props) {

    const [open, setOpen] = React.useState(false);

    const {textDirection } = useContext(LanguageContext);
    useEffect(() => {

        setOpen(props.open)
    }, [props.open])


    return (




            <Dialog
                onClose={props.handleClose}
                aria-labelledby="soc-task-info-dialog"
                fullWidth={true}
                fullScreen={true}
                maxWidth={'xl'}
                open={open}>
                <DialogTitle
                    dir={textDirection}
                    onClose={props.handleClose} >{props.title}</DialogTitle>
                <DialogContent
                    dividers
                    dir={textDirection}
                >
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                            variant={'contained'}
                            onClick={props.handleClose}
                            color="primary">
                        {props.closeButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>



    );
}
