import List from "@material-ui/core/List";
import {ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";


export default function StatusList(props) {
    const { data, onStatusClick, selectedStatuses } = props
    return (
        <List>

            {data.map((status, index)=> {
                return (


                    <ListItem button key={status.name} role={undefined} dense  onClick={(e)=>onStatusClick(status.id)}>
                        <Checkbox
                            edge="start"
                            checked={selectedStatuses.indexOf(status.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': status.id }}
                        />
                        <ListItemText primary={status.name}/>
                    </ListItem>

                )


            })}

        </List>

    )

}