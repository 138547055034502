import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {styled, useTheme} from "@material-ui/core/styles";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import {Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Text} from "../../providers/LanguageProvider";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import CloseIcon from '@material-ui/icons/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
            >
              <CloseIcon />
            </IconButton>
        ) : null}
      </DialogTitle>
  );
};


export default function ResponsiveDialog(props) {
  const { onClose, open } = props;
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <BootstrapDialog
        fullScreen={props.title === "organization" || props.title === "confirmationUpdateData"? false:fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"

      >
        <BootstrapDialogTitle onClose={onClose}
            id="alert-dialog-slide-title"


        >
          {props.title}

        </BootstrapDialogTitle >
        <DialogContent >
          {props.title === "organization"?
              <div dir={props.currentLanguage === "ar"? "rtl":"lft"}>
                <ul>
                  <Typography align={props.currentLanguage === "ar"? "right":"left"} >
                    {props.content.map((item,j)=>(
                        <li key={j} id={j}>{item}</li>
                    ))}
                  </Typography>
                </ul>
              </div>
              : props.title === "confirmationUpdateData"?
                  <Typography align={props.currentLanguage === "ar"? "right":"left"} variant={"h6"}>
                    <Text tid="profile.confirmationUpdate" />
                  </Typography>
                  : props.title ==="signIn" ? <SignIn /> : <SignUp />}
        </DialogContent>
        {props.title === "organization" && props.url?
            <DialogActions style={{alignSelf:"flex-end"}}>
              <Button
                  size="small"

                  endIcon={<ArrowRightAltIcon/>}
                  href={props.url}
                  target="_blank"
              >
                <Text tid="welcome.website" />
              </Button>
            </DialogActions>:null}
      </BootstrapDialog>
    </div>
  );
}
