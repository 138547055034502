import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {CircularProgress, Fab, Zoom} from "@material-ui/core";
import ViewListIcon from '@material-ui/icons/ViewList';
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PropTypes from "prop-types";
import DrawerContent from "./DrawerContent";
import OrgDataGrid from "./OrgDataGrid";
import Drawer from "@material-ui/core/Drawer/Drawer";
import clsx from "clsx";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";


const useStyles = makeStyles((theme) => ({

    menuButton: {
        marginRight: theme.spacing(2),
    },



    sectionDesktop: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    sectionMobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },


    },

    appBar: {

        zIndex: theme.zIndex.drawer + 1,

    },

    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    drawer: {

        width: '100%',


        [theme.breakpoints.down('md')]: {
            marginBottom: '40px',
        },
    },

    circularProgress: {
        color: 'white'

    }

}));
BottomAppBar.propTypes = {
    organizationsResults: PropTypes.array.isRequired,
    onSelectServices: PropTypes.func.isRequired,
    onSelectOrganization: PropTypes.func.isRequired,
    searchingOrgs: PropTypes.bool


}
export default function BottomAppBar(props) {

    const {searchingOrgs, className} = props


    const theme = useTheme()
    const classes = useStyles();
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const menuId = 'primary-search-account-menu';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [dialog, setDialog] = React.useState(false)
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const [selectedServices, setSelectedServices] = React.useState([])

    const [optionsDrawerOpen, setOptionsDrawerOpen] = useState(false)
    const mobile = useMediaQuery(theme.breakpoints.down("md"));

    const [drawerOpen, setDrawerOpen] = useState(false)


    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };



    useEffect(()=>{

        setOptionsDrawerOpen(mobile && drawerOpen)


    }, [mobile, drawerOpen])

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem  onClick={(e)=>{
                setDialog(true)
                handleMobileMenuClose()
            }}>
                <IconButton  color="inherit">
                    <Badge badgeContent={selectedServices.length} color={'secondary'}>
                        <HomeWorkIcon />
                    </Badge>
                </IconButton>
                <p>Selected Services</p>
            </MenuItem>
        </Menu>
    );




    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem  onClick={(e)=>{
                setDialog(true)
                handleMobileMenuClose()
            }}>
                <IconButton  color="inherit">
                    <Badge badgeContent={selectedServices.length} color={'secondary'}>
                        <HomeWorkIcon />
                    </Badge>
                </IconButton>
                <p>Services</p>
            </MenuItem>

        </Menu>
    );


    const toggleDrawer = (e) => {

        if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
            return;
        }

        setDrawerOpen(!drawerOpen)

    };

    const onDrawerClick = (e)=>{

        setDrawerOpen(!drawerOpen)
    }

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const fabs = [
        {
            color: 'secondary',
            state: false,
            icon: <ViewListIcon />,
            className: classes.fabButton

        },
        {
            color: 'primary',
            state: true,
            icon: <ViewListIcon />,
            className: classes.fabButton

        },

    ];






    const onSelectOrganization = (organization) =>{
        setDrawerOpen(false)
        props.onSelectOrganization(organization)

    }


    return (
        <>
            <CssBaseline />
            <AppBar  color="secondary" className={clsx(classes.appBar, className)} >

                <Toolbar variant={'dense'}>


                    {
                        fabs.map((fab) => (
                            <Zoom
                                key={fab.color}
                                in={fab.state === drawerOpen}
                                timeout={transitionDuration}
                                style={{
                                    transitionDelay: `${fab.state === drawerOpen ? transitionDuration.exit : 0}ms`,
                                }}
                                unmountOnExit
                            >

                                    <Fab color={fab.color} onClick={onDrawerClick}
                                         className={fab.className}>
                                        {!searchingOrgs ?
                                            <Badge badgeContent={props.organizationsResults.length} max={99}
                                                   color="primary">
                                                {fab.icon}
                                            </Badge>
                                            :
                                            <CircularProgress color={'secondary'} className={classes.circularProgress}/>
                                        }
                                    </Fab>


                            </Zoom>
                        ))
                    }


                    <div className={classes.grow} />
                    {/*<div className={classes.sectionDesktop}>*/}

                    {/*    <IconButton  color="inherit" onClick={(e)=>setDialog(true)}>*/}
                    {/*        <Badge badgeContent={selectedServices.length} color={'secondary'}>*/}
                    {/*            <HomeWorkIcon />*/}
                    {/*        </Badge>*/}
                    {/*    </IconButton>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sectionMobile}>*/}
                    {/*    <IconButton*/}
                    {/*        aria-label="show more"*/}
                    {/*        aria-controls={mobileMenuId}*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        onClick={handleMobileMenuOpen}*/}
                    {/*        color="inherit"*/}
                    {/*    >*/}

                    {/*        <MoreIcon />*/}
                    {/*    </IconButton>*/}


                    {/*</div>*/}
                </Toolbar>


            </AppBar>

            <Drawer
                variant="persistent"


                anchor={'bottom'}
                open={optionsDrawerOpen}
                onClose={toggleDrawer}
                onOpen={toggleDrawer}
            >
                <DrawerContent>
                    <OrgDataGrid
                        organizationsResults={props.organizationsResults}
                        onSelectOrganization={onSelectOrganization}
                        density={'compact'}
                        pageSize={5}
                    />
                </DrawerContent>
            </Drawer>

            {renderMobileMenu}
            {renderMenu}


        </>
    );
}
