import React, {useMemo} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch,} from "react-router-dom";
import Sidebar from "./client/src/components/navbar/Navbar";

import Journey from "./client/src/components/journey/Journey";

import Bookmarks from "./client/src/components/bookmarks/Bookmarks";
import PrivateRoute from "./client/src/components/auth/PrivateRoute";
import SignIn from "./client/src/components/auth/SignIn";
import SignUp from "./client/src/components/auth/SignUp";


import SignOut from "./client/src/components/auth/SignOut";
import HomePage from "./client/src/components/welcome/WelcomePage";

import ForgotPassword from "./client/src/components/account/ForgotPassword";

import "font-awesome/css/font-awesome.min.css";
import FAQ from "./client/src/components/FAQ/FAQ";
import Enter from "./client/src/components/welcome/Enter";
import NotFound from "./client/src/components/NotFound";
import AdminConsole from "./client/src/components/adminConsole/AdminConsole";
import {LanguageProvider} from "./client/src/providers/LanguageProvider";
import About from "./client/src/components/about/About";

import {UserInfoContext} from "./client/src/providers/UserInfoContext";
import {JourneyProvider} from "./client/src/providers/JourneyProvider";
import Explore2 from "./client/src/components/explore/Explore2";

import {SnackbarProvider} from 'notistack';
import {createTheme} from "@material-ui/core";
import {themeOptions } from "./client/src/components/common/theme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import UnRegisteredAboutUs from "./client/src/components/about/UnRegisteredAboutUs";

const theme = createTheme({...themeOptions})
const App = () => {


    const exploreContainer = () => (
        <div>


        </div>
    );
    const LoginContainer = () => (
        <div>
            <Route exact path="/" render={() => <Redirect to="/welcome"/>}/>
            {/* <Route exact path={"/home"} component={Dashboard} /> */}
            <Route exact path="/signIn" component={SignIn}/>
            <Route exact path="/signUp" component={SignUp}/>
        </div>
    );
    const UnregisteredUserContainer = () => {
        return (

            <div>
                <Route exact path={"/(welcome)"} component={HomePage}/>
                <Route exact path={"/discover"} component={Explore2}/>
                <Route exact path={"/forgot"} component={ForgotPassword}/>
                <Route exact path={"/enter"} component={Enter}/>
                <Route exact path={"/about"} component={UnRegisteredAboutUs}/>
                <Route exact path={"/explore"} component={Explore2}/>
            </div>

        );
    };
    const DashboardContainer = () => (


        <Sidebar>
            <Route exact path="/" render={() => <Redirect to="/enter"/>}/>
            <JourneyProvider>
                <PrivateRoute path={"/journey"} component={Journey}/>


            </JourneyProvider>
            <PrivateRoute path={"/faq"} component={FAQ}/>
            <PrivateRoute path={"/bookmarks"} component={Bookmarks}/>
            {/* <PrivateRoute  path={"/dashboard"} component={Dashboard} /> */}

            <PrivateRoute path="/admin" component={AdminConsole}/>
            <PrivateRoute path="/signOut" component={SignOut}/>
            <PrivateRoute exact path={"/about"} component={About}/>
        </Sidebar>


    );
    return (

        <LanguageProvider>
            <ThemeProvider theme={theme}>

                <UserInfoContext>

                    <SnackbarProvider maxSnack={3}>
                        <Router>
                            <Switch>
                                <Route exact path="/" render={() => <Redirect to="/enter"/>}/>
                                <Route
                                    exact
                                    path={"/(welcome)"}
                                    component={UnregisteredUserContainer}
                                />

                                <Route exact path="/explore" component={Explore2}/>
                                <Route
                                    exact
                                    path="/discover"
                                    component={UnregisteredUserContainer}
                                />
                                {/* <Route exact path="/chatbot" component={UnregisteredUserContainer} /> */}
                                <Route exact path="/bookmarks" component={DashboardContainer}/>
                                <Route exact path="/journey" component={DashboardContainer}/>
                                <Route exact path="/faq" component={DashboardContainer}/>
                                <Route exact path="/signIn" component={LoginContainer}/>
                                <Route exact path="/signUp" component={LoginContainer}/>
                                <Route exact path="/signOut" component={DashboardContainer}/>

                                <Route exact path="/account" component={DashboardContainer}/>
                                <Route exact path="/forgot" component={UnregisteredUserContainer}/>
                                <Route exact path="/admin" component={DashboardContainer}/>

                                <Route exact path="/enter" component={UnregisteredUserContainer}/>

                                <Route
                                    exact
                                    path={"/about"}
                                    component={UnregisteredUserContainer}
                                />
                                <Route component={NotFound}/>
                            </Switch>
                        </Router>
                    </SnackbarProvider>

                </UserInfoContext>
            </ThemeProvider>
        </LanguageProvider>

    );
};
export default App;
