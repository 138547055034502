import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import journeyApi from "../../../api/journey";
import moment from "moment-timezone";
import {UserContext} from "./UserInfoContext";

import userapi from "../../../api/users";
import users from "../../../api/users";
export const JourneyContext = createContext();

export function JourneyProvider({children}) {
    const {user} = useContext(UserContext);
    const [journeyUser, setJourneyUser] = React.useState(null)

    const [journeyUserStatus, setJourneyUserStatus] = React.useState(null);
    const [completedProvincialTasks, setCompletedProvincialTasks] = React.useState([])
    const [completedSocialProvincialTasks, setCompletedSocialProvincialTasks] = React.useState([])
    const [provincialSections, setProvincialSections] = React.useState([])
    const [availableSocialTasks, setAvailableSocialTasks] = React.useState([])
    const [journeyUserStatuses, setJourneyUserStatuses] = React.useState([])

    const [socialProvincialTasks, setSocialProvincialTasks] = React.useState([])
    const [province, setProvince] = useState(null)
    const [userTimeZone, setUserTimeZone] = React.useState('America/Montreal')




    const journeyUserAddress = useMemo(()=>{
        return journeyUser?.address
    }, [journeyUser])

    const journey = useMemo(()=>{
        return journeyUser?.journey
    }, [journeyUser])

    useEffect(()=>{
        userapi.province().then((res)=>{
            setProvince(res.data)
        })
        userapi.legalStatus().then((res=>{
            setJourneyUserStatus(res.data)
        }))
    }, [])

    useEffect(()=>{
        if(province){

            journeyApi.tasks(province.id, 'social').then((res) => {
                setSocialProvincialTasks(res.data || [])
            })
            journeyApi.provincialSections(province.id).then(res => {
                setProvincialSections(res.data || [])
            })
        }
    }, [province])



    const provincialTasks = useMemo(()=>{
        if (provincialSections.length > 0) {
            let provincialTasks = []

            provincialSections.forEach(provincialSection => {

                provincialSection.legalTasks.forEach(task => {
                    provincialTasks.push(task)
                })
            })
            return provincialTasks
        }
        return []

    }, [provincialSections])


    const provincialSectionSequences = useMemo(()=>{

        if(provincialSections.length > 0){
            let s = []
            provincialSections.forEach(provincialSection => {

                s.push(provincialSection.sequence)
            })

            return s.sort()

        }

        return []
    }, [provincialSections])

    const lastProvincialSectionSequence = useMemo(()=>{

        if(provincialSectionSequences.length > 0 ){
            return provincialSectionSequences[provincialSectionSequences.length - 1]

        }
    }, [provincialSectionSequences])

    const getLastProvincialSection = useMemo(()=>{

        if(lastProvincialSectionSequence){
            return provincialSections.find(ps=> ps.sequence === lastProvincialSectionSequence)

        }

    }, [lastProvincialSectionSequence, provincialSections])

    const isJourneyComplete = useMemo(()=>{
        if(completedProvincialTasks.length > 0){
            return completedProvincialTasks.length === provincialTasks.length
        }
        return false;

    }, [completedProvincialTasks.length, provincialTasks.length])


    useEffect(() => {
        setUserTimeZone(moment.tz.guess)
    }, []);

    useEffect(() => {
        if (user) {
            setJourneyUser(user)
        }
    }, [user])


    useEffect(() => {

        if (journeyUser) {

            setJourneyUserStatuses(journeyUser.legalStatuses || [])
            setCompletedProvincialTasks(journeyUser.completedTasks)
            setCompletedSocialProvincialTasks(journeyUser.completedSocialTasks)
        }
    }, [journeyUser])


    useEffect(()=>{
        // #todo: maybe this isn't necessary ?
        users.completedTasks().then((res) => {
            setAvailableSocialTasks(res.data.availableSocialTasks || [])
        })

    }, [])


    const userCoordinates = useMemo(()=>{

        if (journeyUserAddress) {
            return {lat: journeyUserAddress.lat, lng: journeyUserAddress.lng}
        }

    }, [journeyUserAddress])


    const isSocialTaskAvailable = (socialTaskId) => {
        return availableSocialTasks.find(ast => ast.socialTask.id === socialTaskId) !== undefined
    }

    const nextProvincialSection =  (provincialSection) => {
        return provincialSections.find(ps => ps.sequence === provincialSection.sequence + 1)
    }

    const previousProvincialSection =  (provincialSection) => {
        return provincialSections.find(ps => ps.sequence === provincialSection.sequence - 1)
    }

    const removeCompletedSocialProvincialTask = (socialProvincialTaskId) =>{
        let a = completedSocialProvincialTasks.find(task => task.socialProvincialTaskId !== socialProvincialTaskId)
        setCompletedSocialProvincialTasks(a)
    }

    const getProvincialSectionById =  (provincialSectionId) => {
        return provincialSections.find(ps => ps.id === provincialSectionId)
    }


    const getProvincialSectionLegalTasks =  (provincialSectionId) => {
        return getProvincialSectionById(provincialSectionId)?.legalTasks || []
    }
    const getProvincialSectionBySequence =(sequence) => {
        return provincialSections.find(ps => ps.sequence === sequence)

    }

    const getProvincialTaskById =  (provincialTaskId) => {
        return provincialTasks.find(pt => pt.id === provincialTaskId)
    }

    const getProvincialSectionByProvincialTaskId = (provincialTaskId) => {
        const pt = getProvincialTaskById(provincialTaskId)
        if (!pt) {
            return;
        }

        const provincialSectionId = pt.provincialSectionId;

        return getProvincialSectionById(provincialSectionId);
    }

    const getCompletedSocialProvincialTaskById = (socialProvincialTaskId) => {
        return completedSocialProvincialTasks.find(task => task.socialProvincialTaskId === socialProvincialTaskId)
    }

    const getCompletedProvincialTaskById = (socialProvincialTaskId) => {
        return completedProvincialTasks.find(task => task.provincialTaskId === socialProvincialTaskId)
    }


    const getCompletedSocialTaskById = (taskId) => {
        return completedSocialProvincialTasks.find(task => task.socialProvincialTask.task.id === taskId)
    }



    const isProvincialSectionComplete = (provincialSectionId) => {
        let provincialSectionLegalTasks = getProvincialSectionLegalTasks(provincialSectionId)
        let completedSectionTasks = completedProvincialTasks.filter(userCompletedTask => userCompletedTask.provincialTask.provincialSectionId === provincialSectionId) || []
        return completedSectionTasks.length === provincialSectionLegalTasks.length
    }


    const isProvincialTaskComplete = (taskId) => {
        return completedProvincialTasks.find((task) => task.provincialTaskId === taskId) !== undefined;
    }

    const isSocialProvincialTaskComplete =  (socialProvincialId) => {
        return getCompletedSocialProvincialTaskById(socialProvincialId) !== undefined;
    }

    const isSocialTaskComplete = (taskId) => {
        return getCompletedSocialTaskById(taskId) !== undefined;
    }

    const getPreviousCompletedProvincialTask = (sequence) =>{
        return completedProvincialTasks.find(userCompletedTask => userCompletedTask.provincialTask.sequence === sequence - 1);
    }





    const provider = useMemo(() => ({
        journeyUser,
        journey,
        journeyUserAddress,
        journeyUserStatus, setJourneyUserStatus,
        journeyUserStatuses,
        userTimeZone,
        userCoordinates,
        completedProvincialTasks, setCompletedProvincialTasks,
        completedSocialProvincialTasks, setCompletedSocialProvincialTasks,
        provincialSections,
        availableSocialTasks, setAvailableSocialTasks,

        provincialTasks,
        socialProvincialTasks,
        province,
        nextProvincialSection,
        getCompletedSocialProvincialTaskById,
        isSocialProvincialTaskComplete,
        isSocialTaskAvailable,
        isSocialTaskComplete,
        isProvincialSectionComplete,
        isProvincialTaskComplete,
        previousProvincialSection,
        getProvincialSectionByProvincialTaskId,
        getProvincialTaskById,
        getProvincialSectionBySequence,
        getCompletedProvincialTaskById,
        getPreviousCompletedProvincialTask,
        removeCompletedSocialProvincialTask,

        getLastProvincialSection,
        isJourneyComplete,

        updateJourneyUser: (journeyUser) => {
            setJourneyUser(journeyUser)

        },

    }));

    return (
        <JourneyContext.Provider value={provider}>
            {children}
        </JourneyContext.Provider>
    );


}