import {useContext, useEffect, useState} from "react";
import userapi from "../api/users";
import {UserContext} from "../client/src/providers/UserInfoContext";


export default function useBookmark(){
    const [processing, setProcessing] = useState(false)

    const [bookmarks, setBookmarks] = useState([])
    const [error, setError] = useState(null)
    const {user} = useContext(UserContext);


    useEffect(()=>{
        if(user){
            setBookmarks(user.bookmarks || [])
        }

    }, [user])

    const findOrgBookmark = (orgId) =>{
        return bookmarks.find(bm => bm.organizationId === orgId);


    }

    const isOrgBookmarked = (orgId) =>{
        return findOrgBookmark(orgId) !== null;
    }



    const addBookmark = (orgId) => {
        setProcessing(true)
        userapi.addBookmark(orgId).then((res)=>{
            setBookmarks(res.data)
        }).catch(e=>{
            setError(e)
        }).finally(()=>{
            setProcessing(false)
        })
    }


    const removeBookmark = (bookmarkId) =>{
        userapi.removeBookmark(bookmarkId).then((res)=>{

            setBookmarks(res.data)
        }).catch(e=>{
            setError(e)
        }).finally(()=>{
            setProcessing(false)
        })
    }
    return {bookmarks, processing, error, addBookmark, removeBookmark, findOrgBookmark, isOrgBookmarked}
}