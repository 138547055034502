import React, {useContext, useMemo, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Avatar, Card, CardContent, CardHeader, Grid, LinearProgress, Tab, Tabs,} from "@material-ui/core";


import Paper from "@material-ui/core/Paper";

import AppBar from "@material-ui/core/AppBar";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import moment from "moment-timezone";


import {LanguageContext} from "../../providers/LanguageProvider";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import {blueGrey, lightGreen} from '@material-ui/core/colors';
import JourneyMenu from "./JourneyMenu";
import JourneyTimelineDialog from "./JourneyTimelineDialog";
import LegalTask from "./LegalTaskAccordion";
import {JourneyContext} from "../../providers/JourneyProvider";
import ProvincialFlag from "./ProvincialFlag";

import MyTasksCard from "./MyTasksCard";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import RestartJourneyDialog from "./RestartJourneyDialog";
import journeyApi from "../../../../api/journey";
import PropTypes from "prop-types";
import TasksAppBar from "./TasksAppBar";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    dialogContent: {
        margin: theme.spacing(5)
    },
    avatar: {
        backgroundColor: blueGrey[500],
    },
    journeyAvatar: {

        backgroundColor: lightGreen[500],
    },
    card: {
        height: "100%"

    },

    tab: {
        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: "#fff"
        },

    },
}));

MyJourney.propTypes = {
    onRestart: PropTypes.func.isRequired
};
export default function MyJourney(props) {

    const classes = useStyles();
    const theme = useTheme()
    const separateInfoCards = useMediaQuery(theme.breakpoints.down('md'));

    const [currentProvincialSectionSequence, setCurrentProvincialSectionSequence] = React.useState(0);

    const [restartJourneyDialog, setRestartJourneyDialog] = React.useState(false)
    const [restarting, setRestarting] = useState(false)
    const {userLanguage, tt, userLanguageISO639} = React.useContext(LanguageContext);



    const {

        journeyUserStatus,
        journey,
        provincialSections,

        journeyUserAddress,
        isProvincialSectionComplete,
        getProvincialSectionBySequence,
        userTimeZone,

        province,
        provincialTasks,

    } = useContext(JourneyContext)




    const currentProvincialSection = useMemo(()=>{

        return getProvincialSectionBySequence(currentProvincialSectionSequence + 1)
    }, [currentProvincialSectionSequence, getProvincialSectionBySequence])


    const handleTabChange = (event, sequence) => {
        setCurrentProvincialSectionSequence(sequence );

    };



    const [viewTimeline, setViewTimeline] = useState(false)


    const getFirstLetters = (str) => {
        if (!str) {
            return '';

        }
        return str.split(' ').map(word => word[0]).join('').toUpperCase();
    }

    const handleTimelineClick = (e) => {

        setViewTimeline(true)
    }


    const restartJourneyClick = (e) => {

        setRestartJourneyDialog(true)

    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }



    const onConfirmRestartJourney = (e) => {
        setRestarting(true)
        journeyApi.restart().then((res)=>{
            props.onRestart(res.data)
        }).finally(()=>{
            setRestarting(false)

        })
    }

    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={separateInfoCards ? 12 : 4} >
                    <Card raised  className={classes.card}>
                        <CardHeader

                            avatar={

                                    <Avatar variant={'rounded'} className={classes.avatar} aria-label="legal-journey">
                                        <FollowTheSignsIcon/>
                                    </Avatar>

                            }
                            action={<JourneyMenu handleTimelineClick={handleTimelineClick} restartJourneyClick={restartJourneyClick}/>}
                            title={ tt('journey.header') }
                            subheader={tt('journey.beganOnDate', moment.utc(journey?.startedOn).tz(userTimeZone).format('llll z').toString())}
                        />
                        <CardContent>
                            <Typography>
                                <span
                                    style={{fontWeight: "bold"}}>{tt('journey.landingAddress')}</span>: {journeyUserAddress?.address || province?.province.name || '-'}
                            </Typography>
                            <Typography>
                                <span
                                    style={{fontWeight: "bold"}}>{tt('journey.provinceApplication')}</span>: {get_intl_lang(userLanguage, province?.intlMetadata)?.name || province?.name || '?'}
                            </Typography>

                            {province && (
                                <Box sx={{mt: 3}}>
                                    <Grid container spacing={0} direction={'column'}
                                                        alignItems={'center'}
                                                        justifyContent={'center'}>
                                        <Grid item>
                                            <ProvincialFlag provinceId={province.id}/>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </CardContent>

                    </Card>
                </Grid>
                <Grid item xs={separateInfoCards ? 12 : 4} >

                    <MyTasksCard className={classes.card} />

                </Grid>
                <Grid item xs={separateInfoCards ? 12 : 4}  >
                    <Card raised className={classes.card}>
                        <CardHeader
                            avatar={

                                <Avatar className={classes.journeyAvatar} aria-label="legal-status"
                                        alt={get_intl_lang(userLanguage, journeyUserStatus?.status.intlMetadata)?.name}
                                        variant={'rounded'}>
                                    {getFirstLetters(get_intl_lang(userLanguage, journeyUserStatus?.status.intlMetadata)?.name).substring(0, 2) || '?'}
                                </Avatar>

                            }
                            title={

                                    get_intl_lang(userLanguage, journeyUserStatus?.status.intlMetadata)?.name || "-"

                            }
                            subheader={

                                tt('journey.status.subTitle', moment.utc(journeyUserStatus?.completedDate).tz(userTimeZone).format('llll z').toString())


                            }
                        />

                        <CardContent className={classes.cardContent}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Typography>
                                        {tt('journey.currentStatus')}: <span style={{fontWeight:'bold'}}>{get_intl_lang(userLanguage, journeyUserStatus?.status.intlMetadata)?.name || "-"}</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color='textSecondary' display="block">
                                        <em>{get_intl_lang(userLanguage, journeyUserStatus?.status.intlMetadata)?.description}</em>
                                    </Typography>
                                </Grid>
                            </Grid>



                        </CardContent>


                    </Card>
                </Grid>
                <Grid item xs={12}>

                    <Paper elevation={3}>

                        <TasksAppBar handleTimelineClick={handleTimelineClick} restartJourneyClick={restartJourneyClick} />
                        <AppBar position="static" color="default">
                            <Tabs
                                value={currentProvincialSectionSequence } // tabs are 0 - indexed
                                onChange={handleTabChange}
                                indicatorColor="secondary"
                                textColor="primary"
                                TabIndicatorProps={{style: {height: 5, backgroundColor: theme.palette.primary.lighter }}}
                                {...(separateInfoCards ? {
                                    scrollButtons: "on",
                                    variant: "scrollable"
                                } : {centered: true})}
                                key={'tabs'}
                            >
                                {provincialSections.map((provincialSection) => {

                                    const sectionName = provincialSection.sequence.toLocaleString(userLanguageISO639) + ". " + get_intl_lang(userLanguage, provincialSection.section.legalStatus.intlMetadata)?.name || '...'
                                    let isSectionComplete = isProvincialSectionComplete(provincialSection.id)


                                    let icon = <FollowTheSignsIcon/>
                                    if (isSectionComplete) {
                                        icon = <DoneAllIcon/>
                                    }
                                    return (
                                        <Tab key={provincialSection.id.toString()}
                                             className={classes.tab}
                                             label={sectionName}
                                             icon={icon}   {...a11yProps(provincialSection.sequence - 1)}
                                        />
                                    )
                                })}
                            </Tabs>
                        </AppBar>

                        {provincialTasks.map((provincialTask) => {

                            if(currentProvincialSection && currentProvincialSection.id === provincialTask.provincialSectionId && !provincialTask.parentProvincialTaskId){
                                return (
                                    <LegalTask provincialTask={provincialTask}
                                               provincialSection={currentProvincialSection}
                                    />

                                )
                            }

                        })}
                    </Paper>
                </Grid>
            </Grid>


            <JourneyTimelineDialog open={viewTimeline} onClose={(e) => {
                setViewTimeline(false)
            }}/>

            <RestartJourneyDialog
                open={restartJourneyDialog}
                title={tt('journey.menu.restart.dialog.title')}

                onClose={(e)=>setRestartJourneyDialog(false)}
                buttons={<>
                    <Button autoFocus onClick={(e)=>setRestartJourneyDialog(false)} color="primary" disabled={restarting}>
                        {tt('journey.menu.restart.dialog.cancelButtonLabel')}
                    </Button>
                    <Button onClick={onConfirmRestartJourney} disabled={restarting} color="secondary">
                        {tt('journey.menu.restart.dialog.confirmButtonLabel')}
                    </Button>
                </>}
            >

                <Typography paragraph>
                    {tt('journey.menu.restart.dialog.confirmQuestion')}
                </Typography>

                <Typography paragraph variant={'body2'}>
                    <em>{tt('journey.menu.restart.dialog.explainContent')}</em>
                </Typography>

                {restarting &&
                    <LinearProgress/>
                }
            </RestartJourneyDialog>

        </>


    );
}
