import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import servicesApi from "../../../../api/services";
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

AutocompleteLocation.propTypes = {
    provinceId: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disable: PropTypes.bool,
    searchType: PropTypes.string,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    renderInput: PropTypes.func,
    value: PropTypes.string
};

export default function AutocompleteLocation(props) {

    const classes = useStyles();
    const [value, setValue] = React.useState( null);
    const [inputValue, setInputValue] = React.useState( '');
    const [options, setOptions] = React.useState([]);

    React.useEffect(()=>{

        setValue(props.defaultValue)
    }, [props.defaultValue])


    const onSelectValue = (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        props.onChange(newValue )


    }


    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {

                servicesApi.autocomplete_search({
                    "search": request.input,
                    "searchType": props.searchType || 'cities',
                    "province": props.provinceId
                }).then(callback);
            }, 200),
        [props.provinceId, props.searchType],
    );

    React.useEffect(() => {
        let active = true;


        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(
            {input: inputValue},
            (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results.data || []];
                    }

                    setOptions(newOptions);
                }
            });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, props]);

    const defaultInput = (params) => {

        return <TextField required={props.required || false} helperText={props.helperText || ''} {...params} label={props.label || 'Location'} fullWidth/>
    }
    return (
        <Autocomplete
            id="autocomplete-location"

            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            disabled={props.disable || false}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={onSelectValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);

            }}
            renderInput={!props.renderInput ? defaultInput : props.renderInput}
            renderOption={(props) => {

                const matches =
                    props.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    props.structured_formatting.main_text,
                    matches.map((match) => [
                        match.offset,
                        match.offset + match.length,
                    ])
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>{part.text}</span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                                {props.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}
