import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from "clsx";
import {getChildren} from "./utils";
import ServicesGrid from "./ServicesGrid";
import Grow from "@material-ui/core/Grow";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBack} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ServiceIcon from "../common/ServiceIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faPeopleRoof} from "@fortawesome/pro-solid-svg-icons";
import Badge from "@material-ui/core/Badge";

import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import infromativeIcon from "./public/informativeIcon.svg";
import {LanguageContext, Text} from "../../providers/LanguageProvider";

import SuggestionCard from "./SuggestionCard";
import {Divider} from "@material-ui/core";
import SubServiceListItem from "./SubServiceListItem";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        borderRadius: "10px",


        border: "1px solid",
        borderColor: theme.palette.primary.main,

        width: 475,


    },
    services: {
        overflowY: "auto",
        '&::-webkit-scrollbar': {display: 'none'},
        '&::-ms-overflow-style': {display: 'none'},
        maxHeight: 480,
        width: "100%"
    },
    orgList: {
        maxHeight: 480,
        width: "100%",
        overflowY: "auto"

    },
    subWindow: {
        position: 'inherit',
        top: 64,
        left: "calc(100% - 10px)",
        zIndex: 98,
    },
    backArrowAvatar: {
        position: "inherit",
        top: 44,
        left: -20,
        backgroundColor: theme.palette.secondary.main
    },
    subCardContent: {
        width: 300,
        maxHeight: 400,
        overflowY: "auto",
        '&::-webkit-scrollbar': {display: 'none'},
        '&::-ms-overflow-style': {display: 'none'},
    },
    tab: {
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },

    },
}));




export default function ExploreCard({className, services, onSelectSubService, selectedServices, onSelectService, subCardShow, organizationsResults, orgResultsContent}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const theme = useTheme()
    const [selectedServiceIconProps, setSelectedServiceIconProps] = useState(null)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [selectedParent, setSelectedParent] = useState(null)

    const { tt } = useContext(LanguageContext);


    const onClickServiceItem = (s, iconColorProps) => {

        if(s.id === selectedParent?.id){
            setSelectedParent(null)
        } else {
            setSelectedParent(s)

        }
        setSelectedServiceIconProps(iconColorProps)
        onSelectService(s)

    }


    const flatDescendantList = useMemo(() => {
        // this essentially traverses all of the parent's children and makes the parent's children into a single flat array rather than nested
        let _services = []

        services.forEach(s => {
            if (s.parentCategoryId === null) {
                s['subCategories'] = getChildren(s)
                _services.push(s)
            }
        })


        return _services

    }, [services])

    const getSelectedChildren = (parentId) => {

        const children = flatDescendantList.find(s => s.id === parentId)?.subCategories || []

        return selectedServices.filter(value => children.filter(s => s.id === value).length > 0)

    }


    useEffect(()=>{
        if(!subCardShow){
            setSelectedParent(null)
        }
    }, [subCardShow])





    return (
        <Paper elevation={6} className={clsx(classes.root, className)}  >

            <AppBar position="static" color="default" style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="auto"
                    TabIndicatorProps={{style: {height: 6, backgroundColor: theme.palette.primary.lighter }}}
                    aria-label="scrollable auto tabs example"
                >
                    <Tab
                        label={<Typography variant={'h6'} style={{textTransform: "none"}} >{tt('explore.serviceCard.serviceTab.label')}</Typography>}
                        {...a11yProps(0)}
                        icon={<FontAwesomeIcon icon={faPeopleRoof}  size={'2x'} />}
                        className={classes.tab}
                        style={{ borderTopLeftRadius: "10px" }}
                        iconposition={"top"}
                    />


                        <Tab
                            label={<Typography variant={'h6'}  style={{textTransform: "none"}}>{tt('explore.serviceCard.orgTab.label')}</Typography>}
                            {...a11yProps(1)}
                            icon={<Badge badgeContent={organizationsResults} color={"secondary"}> <FontAwesomeIcon
                                icon={faBuilding} size={'2x'}/> </Badge>}
                            className={classes.tab} style={{borderTopRightRadius: "10px"}}
                            onClick={() => setSelectedParent(null)}
                            iconposition={"top"}
                        />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div className={classes.services}>

                    <ServicesGrid
                        flatDescendantList={flatDescendantList}
                        getSelectedChildren={getSelectedChildren}
                        onClickServiceItem={onClickServiceItem}
                        iconSize={"3x"}
                        selectedService={selectedParent}

                    />
                </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
                {organizationsResults > 0 ?
                    <div className={classes.orgList}>
                        {orgResultsContent}
                    </div>
                    :
                    <Grid
                        container
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <CardMedia
                            component="svg"
                            alt="Error"
                            height="150"
                            image={infromativeIcon}
                        />
                        <CardContent>
                            <Typography
                                variant={"h5"}
                                align={"center"}
                                // style={{ padding: "10px" }}
                            >

                                {tt('explore.serviceCard.orgTab.noResults.label')}
                            </Typography>
                        </CardContent>


                    </Grid>
                }
            </TabPanel>

            <Grow in={selectedParent !== null} unmountOnExit>
                <div className={classes.subWindow}>
                    <Avatar className={classes.backArrowAvatar}>
                        <IconButton onClick={() => setSelectedParent(null)}>
                            <ArrowBack style={{color: "#fff"}}/>
                        </IconButton>
                    </Avatar>

                    <Paper elevation={6}>

                        <Card>
                            <CardHeader
                                title={
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            color: "white",
                                        }}
                                        variant={"h6"}
                                        component={"h2"}
                                        align={"center"}
                                    >
                                        {selectedParent?.name}
                                    </Typography>
                                }
                                style={{backgroundColor: theme.palette.primary.main}}
                            />

                            <CardContent className={classes.subCardContent}>

                                <List dense>
                                    {selectedParent?.subCategories.map(s => {
                                        const checked = selectedServices.includes(s.id)
                                        return (
                                            <SubServiceListItem
                                                onSelectSubService={onSelectSubService}
                                                checked={checked}
                                                subService={s}
                                                iconColor={selectedServiceIconProps}
                                            />
                                        )

                                    })}
                                </List>

                            </CardContent>

                        </Card>
                    </Paper>
                </div>
            </Grow>

            {value === 1 &&
                <>
                    <Divider />
                    <SuggestionCard/>
                </>
            }
        </Paper>
    );
}
