import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import ProductHeroLayout from "./ProductHeroLayout";
import Grid from "@material-ui/core/Grid";
import img from "../../../public/pics/backgroundHomePage.svg";
import {Backdrop, CircularProgress, Hidden, Typography} from "@material-ui/core";
import {LanguageContext, Text} from "../../../providers/LanguageProvider";

import {tokenAuthenticate} from "../../auth/authenticate";
import authApi from "../../../../../api/auth";
import TokenService from "../../../../../token.service";
import usersApi from "../../../../../api/users";
import {UserContext} from "../../../providers/UserInfoContext";
import {Redirect} from "react-router-dom";
import MainDialog from "../../auth/MainDialog";
import SignUp from "../../auth/SignUp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${img})`,
    backgroundPosition: "center",
  },

  h51: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  textDecoration: "none",

  routerLink: {
    textDecoration: "none",
    color: "white",
  },
});

function ProductHero(props) {
  const { classes } = props;
  const [openDialog, setOpenDialog] = useState(false);


  // useEffect(() => {
  //   axios.get("https://northamerica-northeast1-allo-canada-prod.cloudfunctions.net/trc-bot-api");
  // }, []);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const [showCircularProgress, setCircularProgress] = useState(false)

  const {  userChange} = useContext(UserContext);
  const {  tt} = useContext(LanguageContext);
  const [authenticated, setAuthenticated] = useState(false);

  // #todo: all of this stuff is duplicated. refactor please
  function validateUserFromSocialProviders(type, getOAuthUserCallback) {
    getOAuthUserCallback(type)
        .then((oAuthUser) => {
          if (!oAuthUser.user) throw new Error();

          return oAuthUser.user.getIdToken().then(tkn => {

            return tkn;
          });
        })
        .then(async (token) => {
          setCircularProgress(true)
          authApi.authorize(token).then(token_response => {

            let access_token = token_response.data.access_token;
            let refresh_token = token_response.data.refresh_token;

            TokenService.updateLocalRefreshToken(refresh_token)
            TokenService.updateLocalAccessToken(access_token)

            usersApi.me().then(user_response => {

              const dbUser = user_response.data // user from db

              userChange(dbUser)

              setAuthenticated(true);

            })


          }).finally(e=>{
            setCircularProgress(false)
          })

        })
  }

  if (authenticated) {

    return <Redirect to={{pathname: "/journey",}}/>;
  }

  return (
    <div style={{ overflowY: "hidden", overflowX: "hidden" }}>
      {showCircularProgress &&
          <Backdrop
              open={true}
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}


          >
            <CircularProgress color="inherit" />
          </Backdrop>
      }
      <Hidden mdUp>
        <Grid
          container
          direction={"column"}
          spacing={6}
          justify={"center"}
          // alignItems={"center"}
        >
          <Grid item>
            <img
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              src={img}
              alt="increase priority"
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction={"column"}
              spacing={2}
              justify={"center"}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{

                    fontWeight: 500,
                    textTransform: "none",
                    color: "#00000096",
                  }}
                >
                  <Text tid="welcome.header" />
                </Typography>
              </Grid>
              <Grid item xs={10} sm={7} md={6} style={{ alignSelf: "center" }}>
                <Typography
                  align="center"
                  style={{
                    fontsize: 15,
                    textAlign: "center",

                    fontWeight: 300,
                    textTransform: "none",
                    color: "black",
                    padding: "inherit",
                  }}
                >
                  <Text tid="welcome.subHeader" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              // alignItems={"center"}
            >

              <Grid item>
                <Button
                    color={'primary'}
                  variant="contained"
                  size="large"
                    fullWidth
                  component="a"
                  onClick={handleClickOpen}
                >
                  <Text tid="welcome.registerForm" />
                </Button>
              </Grid>
            </Grid>

            <Grid container direction={"row"} justifyContent={'center'} spacing={3}>
              <Grid item>
                  <Button color={'secondary'}

                            fullWidth
                          variant={'contained'}
                          size={'small'}
                          style={{ fontSize: 20 }}
                          onClick={() => {
                              validateUserFromSocialProviders(
                                  "google",
                                  tokenAuthenticate
                              );
                          }}
                          startIcon={
                              <FontAwesomeIcon icon={faGoogle}  size={"4x"}/>
                          } ><Text tid="signInUp.signGoogle"/></Button>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <ProductHeroLayout backgroundClassName={classes.background}>
          <img style={{ display: "none" }} src={img} alt="increase priority" />
          <Grid
            container
            direction={"column"}
            alignContent={"center"}
            justify={"center"}
            spacing={3}
          >
            <Grid item>
            <Grid container direction={"column"} spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="h2"
                  align="center"
                  style={{

                    fontWeight: 500,
                    textTransform: "none",
                    color: "#000000b8",
                  }}
                >
                  <Text tid="welcome.header" />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={7} style={{ alignSelf: "center" }}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    fontSize: 30,

                    fontWeight: 300,
                    textTransform: "none",
                    color: "#000000b8",
                  }}
                >
                  <Text tid="welcome.subHeader" />
                </Typography>
              </Grid>
            </Grid>
            </Grid>
            <br /> <br /> <br />
            <Grid item xs={12}>
            <Grid container direction={"row"} spacing={2} justifyContent={"center"}>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  component="a"
                  onClick={handleClickOpen}
                  style={{ fontSize: 20 }}
                >
                  <Text tid="welcome.registerForm" />
                </Button>
              </Grid>


            </Grid>
              <Grid container spacing={2} direction={'row'} justifyContent={"center"}>
                <Grid item >

                    <Button color={'secondary'}

                            fullWidth
                            variant={'contained'}
                            size={'large'}
                            style={{ fontSize: 20 }}
                            onClick={() => {
                                validateUserFromSocialProviders(
                                    "google",
                                    tokenAuthenticate
                                );
                            }}
                            startIcon={
                        <FontAwesomeIcon icon={faGoogle}  size={"5x"}/>
                    } ><Text tid="signInUp.signGoogle"/></Button>
                </Grid>
              </Grid>


            </Grid>
          </Grid>
        </ProductHeroLayout>
      </Hidden>
      <MainDialog
        open={openDialog}

        title={ <Text tid="signInUp.signUp"/> }
        onClose={handleDialogClose}
      >
          <SignUp />
      </MainDialog>
    </div>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
