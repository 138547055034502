import React, {useContext, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "../modules/components/Typography";
import Link from '@material-ui/core/Link';
import {Avatar, Button, Hidden} from "@material-ui/core";

import {uploadImageBasedOnImgTitle} from "../utils/commonUsedFunctions";
import AOS from "aos";
import {LanguageContext} from "../../providers/LanguageProvider";

const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
            height:"100%",
            overflow: "hidden",
        },
        heroContent: {
            backgroundColor: "#eeeeee",
            padding: theme.spacing(8, 0, 6),
        },
        avatar:{
            width:"140px",
            height:"140px",
            border:"solid",
            marginTop:"30px",
            objectFit:"contain"
        },
        img:{
            // height:"56%",
            height:"87%",
            width:"79%",
            objectFit:"scale-down"
        },

        specialThanksBtn:{
            minWidth: 200,
            backgroundColor: "#edf3ff",
            textTransform: "none",

            [theme.breakpoints.down("sm")]: {
                fontSize: 13,
                minWidth: 120,
            },
            "&:hover":{
                backgroundColor: "#edf3ff",
            }
        },
        addAnOrganizationHereImg:{
            objectFit: 'cover',
            backgroundPosition: "center",
            height:"57%",
            width:"100%",
            borderRadius:"200px",
            backgroundRepeat:"no-repeat",
        }
    }
));

const AboutItem = (props)=>{
    useEffect(() => {
        AOS.init({
            once: true,
            duration: 1000,
        });
    }, []);
    return(
        <div style={{ width: "100%", height:"100%",padding:"26px 5px 20px 5px", overflow:"hidden"}}>
            <Container maxWidth={"lg"} style={{paddingRight:30}} >
                {props.data.imgPosition &&props.data.imgPosition === "r" ?<RightImagePosition data={props.data} /> : <LeftImagePosition data={props.data} />}
            </Container>
        </div>
    )
}
export default AboutItem

const RightImagePosition = (props) =>{
    const { userLanguage } = useContext(LanguageContext);
    const classes = useStyles();
    return(
        <div data-aos={props.data.imgSrc === "aboutUsImg"? null:"fade-left" }
        >
       <Container maxWidth={"lg"}>
        <Grid container direction={"row"} spacing={3}  alignItems={"center"}>
            <Hidden mdUp>
                {props.data.imgSrc  ?
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        md={6}
                    >
                        <Avatar
                            src={uploadImageBasedOnImgTitle(props.data.imgSrc)}
                            imgProps={{width:"100%", height:"100%"}}
                            style={props.data.imgSrc==="suggestionBox"? {width: "52%",height:"100%"}: props.data.imgSrc==="aboutUsImg"? {width:"81%", height:"100%",borderRadius:"29%"}:{width:"81%", height:"100%"}}
                        />
                    </Grid>: null
                }
            </Hidden>

            <Grid item xs={12} md={6}>
                <Grid item xs={12} md={12}>
                    <Grid container direction={"column"}
                          spacing={props.data.imgSrc === "aboutUsImg"? 0: 4}
                    >
                        <Typography
                            component="h1"
                            variant="h4"
                            align={"center"}
                            color="textPrimary"
                            gutterBottom
                            style={{ color: "black",paddingTop:"50px",fontWeight:"bold" }}
                        >
                            {props.data.header}
                        </Typography>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                                style={{ color: "#00000096",textTransform:"none",fontWeight:500  }}
                                align={userLanguage ==="ara"? "right":"justify"}
                            >
                                {props.data.body1}
                            </Typography>
                        </Grid>
                        {props.data.body2 && props.data.body2 !== ""?
                            <Grid item xs={12}>
                                <Typography
                                    align={userLanguage ==="ara"? "right":"justify"}
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                    style={{ color: "#00000096",textTransform:"none",fontWeight:500  }}
                                >
                                    {props.data.body2}
                                </Typography>
                            </Grid>
                            : null }
                        <Grid item xs={12}>
                            <Grid container direction={"row"}
                                style={{justifyContent:"center"}}
                                  justify={"center"} spacing={2}
                            >
                                {props.data.btns && props.data.btns.map((ele,index)=>{

                                    return(
                                        <Grid item key={index}>
                                        <Button
                                            size="small"
                                            variant={'contained'}
                                            color={'secondary'}
                                            href={ele.href}
                                            target="_blank"
                                        >
                                            {ele.title}
                                        </Button>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Hidden smDown>
            {props.data.imgSrc  ?
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    xs={12}
                    md={6}
                >
                    <Avatar
                            src={uploadImageBasedOnImgTitle(props.data.imgSrc)}
                            imgProps={{width:"100%", height:"100%"}}
                            style={props.data.imgSrc==="suggestionBox"? {width: "52%",height:"100%"}: props.data.imgSrc==="aboutUsImg"? {width:"81%", height:"100%",borderRadius:"29%"}:{width:"81%", height:"100%"}}

                            // style={props.data.imgSrc==="suggestionBox"? {width: "52%",height:"100%"}:{width:"81%", height:"100%"}}
                    />
                </Grid>: null
            }
            </Hidden>

        </Grid>
       </Container>
        </div>
    )
}
const LeftImagePosition = (props) =>{


    return(
        <div data-aos="fade-right" style={props.data.imgSrc ==="thankYou"? {
                backgroundColor:"#208899",borderRadius:"8px",padding:"20px 10px", paddingTop:"35px"}:
            {
                backgroundColor:"#eeeeee",borderRadius:"8px",padding:"20px 10px"}}>
         <Container maxWidth={"lg"}>
        <Grid container direction={"row"}  spacing={3} alignItems={"center"}>
            {props.data.imgSrc ?
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    xs={12}
                    md={6}
                >
                    <Avatar
                        style={props.data.imgSrc ==="thankYou"?{width:"100%",height:"80%",borderRadius:0} :{width:"100%",height:"80%"}}
                            src={uploadImageBasedOnImgTitle(props.data.imgSrc)}
                            imgProps={{width:"100%", height:"100%"}}
                            />
                </Grid>: null
            }
            <Grid item xs={12} md={6}>
                <Grid item xs={12} md={12}>
                    <Grid container direction={"column"} spacing={4}>
                        <Typography
                            component="h1"
                            variant="h4"
                            align={ "center"}
                            color="textPrimary"
                            gutterBottom
                            style={{ color: "black",paddingTop:"50px",fontWeight:"bold",textTransform:"none" }}
                        >
                            {props.data.header}
                        </Typography>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                                style={{ color: "#00000096",textTransform:"none",fontWeight:500 }}
                                align={
                                    "center"
                                }
                            >
                                {props.data.body1}
                            </Typography>
                        </Grid>
                        {props.data.link1 && props.data.link1 !== ""?
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                    style={{ color: "#00000096",textTransform:"none",fontWeight:500 }}
                                >
                                    <Link target="_blank" href={props.data.link1}> Learn About The Indigenous Land You Are On </Link>
                                </Typography>
                            </Grid>
                            : null }
                        {props.data.link2 && props.data.link2 !== ""?
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                    style={{ color: "#00000096",textTransform:"none",fontWeight:500 }}
                                >
                                    <Link target="_blank" href={props.data.link2}> Head To Network For Additional Resources </Link>
                                </Typography>
                            </Grid>
                            : null }
                            {props.data.link3 && props.data.link3 !== ""?
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                    style={{ color: "#00000096",textTransform:"none",fontWeight:500 }}
                                >
                                    <Link target="_blank" href={props.data.link3}> Read the Truth and Reconciliation Commission (TRC) </Link>
                                </Typography>
                            </Grid>
                            : null }
                            {props.data.link4 && props.data.link4 !== ""?
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                    style={{ color: "#00000096",textTransform:"none",fontWeight:500 }}
                                >
                                    <Link target="_blank" href={props.data.link4}> How To Be An Ally For Canada’s Indigenous Communities </Link>
                                </Typography>
                            </Grid>
                            : null }
                            {props.data.link5 && props.data.link5 !== ""?
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    variant="h6"
                                    color="textPrimary"
                                    gutterBottom
                                    style={{ color: "#00000096",textTransform:"none",fontWeight:500 }}
                                >
                                    <Link target="_blank" href={props.data.link5}> Learn About Canada's Colonialist History </Link>
                                </Typography>
                            </Grid>
                            : null }
                        <Grid item xs={12}>
                            <Grid container direction={"row"}
                                  style={{justifyContent:"space-evenly"}}
                                  justify={"center"}
                            >
                                {props.data.btns && props.data.btns.map((ele,index)=>{

                                    return(
                                        <Grid item key={index}>
                                            <Button
                                                variant={'contained'}
                                                size="small"
                                                color={'secondary'}
                                                href={ele.href}
                                                target="_blank"
                                            >
                                                {ele.title}
                                            </Button>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
         </Container>
        </div>
    )
}
