import React, {useContext, useEffect, useMemo, useState} from 'react';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {get_intl_lang} from "../utils/commonUsedFunctions";
import moment from "moment-timezone";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";

import {LanguageContext} from "../../providers/LanguageProvider";


import usersApi from "../../../../api/users";

import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {FormGroup, responsiveFontSizes, Switch} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {JourneyContext} from "../../providers/JourneyProvider";

import ThemeProvider from "@material-ui/styles/ThemeProvider";


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.primary.main,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    timeline: {
        paddingBottom: 100

    },
    socialTaskDot: {
        backgroundColor: theme.journey.socialTask.main
    },
    legalTaskDot: {
        backgroundColor: theme.journey.legalTask.darker

    }
}));
JourneyTimeline.propTypes = {};

const SocialTaskSwitch = withStyles(theme => ({
    switchBase: {
        color: theme.journey.socialTask.main,
        '&$checked': {
            color: theme.journey.socialTask.darker,
        },
        '&$checked + $track': {
            backgroundColor: theme.journey.socialTask.darker,
        },
    },
    checked: {},
    track: {},
}))(Switch);

const LegalTaskSwitch = withStyles(theme => ({
    switchBase: {
        color: theme.journey.legalTask.main,
        '&$checked': {
            color: theme.journey.legalTask.darker,
        },
        '&$checked + $track': {
            backgroundColor: theme.journey.legalTask.darker,
        },
    },
    checked: {},
    track: {},
}))(Switch);


export default function JourneyTimeline(props) {
    const classes = useStyles();
    const theme = useTheme()

    const timelineTheme = useMemo(() => {


        return responsiveFontSizes(theme);

    }, [theme])

    const {userLanguage,   tt} = useContext(LanguageContext);
    const [show, setShow] = useState({
        legal: true,
        social: false,
    })
    const [timelineTasks, setTimelineTasks] = useState([])
    const {
        journeyUser,
        provincialSections,
        userTimeZone,
        nextProvincialSection,
        getLastProvincialSection,
        isProvincialSectionComplete
    } = useContext(JourneyContext)


    useEffect(() => {
        usersApi.timeline().then((res) => {

            setTimelineTasks(res.data)
        })

    }, [])

    const handleChange = (event) => {
        setShow({...show, [event.target.name]: event.target.checked});
    };

    const finalProvincialSectionName = useMemo(() => {
        if (getLastProvincialSection) {

            return get_intl_lang(userLanguage, getLastProvincialSection.section.legalStatus.intlMetadata)?.name || ''

        }

    }, [getLastProvincialSection, userLanguage])

    return (
        <ThemeProvider theme={timelineTheme}>
            <Grid container>
                <Grid item xs={12}>
                    <Timeline align="alternate" className={classes.timeline}>
                        {provincialSections.map((section) => {

                            let sectionName = get_intl_lang(userLanguage, section.section.legalStatus.intlMetadata)?.name
                            let startedSection = journeyUser.legalStatuses.find((status) => status.legalStatusId === section.section.id)

                            let startedDateText = tt('journey.sections.notStarted.label');
                            let isSectionComplete = false;
                            if (startedSection) {
                                const sectionStartedOnFromNow = moment.utc(startedSection.completedDate).tz(userTimeZone).fromNow().toString()
                                const sectionStartedDate = moment.utc(startedSection?.completedDate).tz(userTimeZone).format('LLLL z').toString()

                                startedDateText = `${sectionStartedDate} - ${sectionStartedOnFromNow}`
                                isSectionComplete = isProvincialSectionComplete(section.id)

                            }
                            const nextSection = nextProvincialSection(section); // is there a following section?
                            const dotColor = startedSection ? "primary" : ""
                            return (
                                <>
                                    <TimelineItem>
                                        <TimelineOppositeContent>
                                            <Typography variant="body2" color="textSecondary">
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot
                                                color={dotColor}
                                            >
                                                <FollowTheSignsIcon/>
                                            </TimelineDot>
                                            {nextSection && (
                                                <TimelineConnector
                                                    {...(startedSection && {className: classes.secondaryTail})}
                                                />
                                            )}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Paper elevation={3} className={classes.paper}>
                                                <Typography variant="h4">
                                                    {sectionName}
                                                </Typography>
                                                <Typography>{startedDateText}</Typography>
                                            </Paper>
                                        </TimelineContent>
                                    </TimelineItem>
                                    {timelineTasks.map((timelineCompletedTask) => {
                                        if (timelineCompletedTask.provincialSectionId === section.id) {
                                            const task = timelineCompletedTask.completedTask.task
                                            const taskType = task.taskType
                                            if (!show[taskType]) {
                                                return

                                            }
                                            const taskName = get_intl_lang(userLanguage, task.intlTask)?.name || '-'
                                            const completedOn = timelineCompletedTask.completedDate;
                                            const completedFromNow = moment.utc(completedOn).tz(userTimeZone).fromNow().toString()
                                            const completedOnDate = moment.utc(completedOn).tz(userTimeZone).format('LLLL z')
                                            const completedDateText = `${completedOnDate} - ${completedFromNow}`

                                            const dotProp = {
                                                ...(taskType === 'social' ? {
                                                        className: classes.socialTaskDot,
                                                    } : {
                                                        className: classes.legalTaskDot
                                                    }
                                                )
                                            }

                                            return (
                                                <TimelineItem>
                                                    <TimelineOppositeContent>
                                                        <Typography
                                                            >
                                                            <em>{completedDateText}</em>
                                                        </Typography>
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineDot

                                                            {...dotProp}

                                                        />
                                                        <TimelineConnector
                                                            {...dotProp}
                                                        />
                                                    </TimelineSeparator>
                                                    <TimelineContent>
                                                        <Typography
                                                            style={{fontWeight: 800}}
                                                            {
                                                                ...(taskType === 'social' && {
                                                                        variant: 'body'
                                                                    }
                                                                )
                                                            }
                                                            >{taskName}</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            )
                                        }
                                    })}

                                    {!nextSection && isSectionComplete && (
                                        <TimelineItem>
                                            <TimelineOppositeContent>
                                                <Typography variant="body2" color="textSecondary">
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot
                                                    color={dotColor}
                                                >
                                                    <FollowTheSignsIcon/>
                                                </TimelineDot>

                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Paper elevation={3} className={classes.paper}>
                                                    <Typography variant="h4" component="h1">
                                                        {tt('journey.timeline.journeyCompleteMessage')}

                                                    </Typography>
                                                    <Typography variant="h6" component="h1">
                                                        {tt('journey.timeline.officialStatus',finalProvincialSectionName)}

                                                    </Typography>
                                                    <Typography>{startedDateText}</Typography>
                                                </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )}
                                </>
                            )
                        })}
                    </Timeline>
                </Grid>

                <Grid item xs={12}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<SocialTaskSwitch checked={show.social} name="social"
                                                               onChange={handleChange}/>}
                                    label={tt('journey.timeline.toggles.social.label')}
                                />
                                <FormControlLabel
                                    control={<LegalTaskSwitch checked={show.legal} name="legal"
                                                              onChange={handleChange}/>}
                                    label={tt('journey.timeline.toggles.legal.label')}
                                />
                            </FormGroup>
                        </Toolbar>

                    </AppBar>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}