import Grid from "@material-ui/core/Grid";

import ServiceItem from "./ServiceItem";
import React, {useState} from "react";


export default function ServicesGrid ({flatDescendantList, getSelectedChildren, onClickServiceItem, iconSize, selectedService}){

    return(

        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            
        >

            {flatDescendantList.map((s, i) => {
                const transitionDelay = i * 120;
                const selectedChildren = getSelectedChildren(s.id).length
                const isClicked = selectedService?.id === s.id

                return (

                        <Grid item>
                            <ServiceItem
                                service={s}
                                isSelected={isClicked}
                                onClick={(iconColorProps) => onClickServiceItem(s, iconColorProps)}
                                badgeContent={selectedChildren}
                                iconSize={iconSize}

                            />
                        </Grid>


                )


            })}
        </Grid>

    )

}