import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import journeyApi from "../../../../api/journey";
import usersApi from "../../../../api/users";
import moment from "moment";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import {TaskAltOutlined} from "@mui/icons-material";
import {JourneyContext} from "../../providers/JourneyProvider";
import {LanguageContext} from "../../providers/LanguageProvider";
import {CircularProgress} from "@material-ui/core";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import Typography from "@material-ui/core/Typography";

UndoTaskDialog.propTypes = {


    task: PropTypes.any.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFail: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string
};

export default function UndoTaskDialog(props) {

    const {task, onSuccess, onClose, open, title, onFail} = props;
    const [subTasks, setSubTasks] = useState([])

    const {completedProvincialTasks, userTimeZone, } = useContext(JourneyContext)
    const {tt, userLanguage} = useContext(LanguageContext);


    const handleCancel = () => {

        onClose()
    };


    const handleOk = () => {
        // onConfirm(task.id, task.section.id)
        handleCheckBoxClick(task.id)
    };

    const [loadingButton, setLoadingButton] = useState(false)
    const [fetchingSubTasks, setFetchingSubTasks] = useState(false)

    useEffect(() => {
        if (task && open) {
            setFetchingSubTasks(true)
            journeyApi.subTasks(task.id).then(res => {
                setSubTasks(res.data)
            }).finally(() => {
                setFetchingSubTasks(false)

            })
        }
    }, [task, open])

    const [completedSubTasks, setCompletedSubTasks] = useState([])

    useEffect(() => {
        if (subTasks.length > 0) {

            let a = []
            subTasks.forEach(subTask => {
                let completedTask = completedProvincialTasks.find(t => t.provincialTask.id === subTask.id)
                if (completedTask) {
                    a.push(completedTask)
                }
            })
            setCompletedSubTasks(a)
        }
    }, [subTasks, completedProvincialTasks])

    const handleCheckBoxClick = (legalTaskId) => {
        setLoadingButton(true)
        let request = usersApi.undoUserTask(legalTaskId)
        request.then(res => {
            if (res.status === 200) {
                onSuccess(res.data, 'undo')
            }
        }).catch(onFail).finally(() => {
            setLoadingButton(false)
        })
    }

    return (
        <Dialog
            aria-labelledby="confirmation-dialog-title"
            open={open}
            onClose={handleCancel}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                {fetchingSubTasks ? (<CircularProgress/>) : (
                    <>
                        <Typography>{tt('journey.tasks.undoTask.confirmQuestion')}</Typography>
                        {completedSubTasks.length > 0 && (
                            <>
                                <Typography>{tt('journey.tasks.undoTask.subTasksConfirm')}: </Typography>
                                <List dense={true}>
                                    {completedSubTasks.map((task, i) => {
                                        let taskName = get_intl_lang(userLanguage, task.provincialTask.task.intlTask)?.name || task.provincialTask.task.internalName;
                                        return (
                                            <ListItem key={`list-item-task-${task.id}-${i}`}>
                                                <ListItemIcon>
                                                    <TaskAltOutlined color={'success'}/>
                                                </ListItemIcon>
                                                <ListItemText

                                                              primary={taskName}
                                                              secondary={tt('journey.tasks.undoTask.subTaskCompletedOnDate', moment.utc(task.completedDate).tz(userTimeZone).format('LLLL z').toString())}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </>
                        )}

                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary" >
                    {tt('journey.tasks.undoTask.dialog.cancelButtonTitle')}
                </Button>
                <Button onClick={handleOk} color="secondary"
                        disabled={loadingButton || fetchingSubTasks}>

                    {tt('journey.tasks.undoTask.dialog.confirmButtonTitle')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

