import React, {useEffect} from "react";
import {Redirect} from "react-router-dom";
import userapi from "../../../../api/users";
const SignOut = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    let currentLang = localStorage.getItem("lang_iso");
    localStorage.clear();
    localStorage.setItem("lang_iso", currentLang);


  });
  return <Redirect to="/welcome" />;
};
export default SignOut;
