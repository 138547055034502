import Drawer from "@material-ui/core/Drawer/Drawer";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";

import Divider from "@material-ui/core/Divider";
import UserInfo from "../components/navbar/UserInfo";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography";

import {blackColor, grayColor, hexToRgb,} from "../components/navbar/mainPageStyle";
import Grid from "@material-ui/core/Grid";
import {UserContext} from "../providers/UserInfoContext";
import HamburgerMenuLinks from "../components/navbar/HamburgerMenuLinks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  drawer: {
    width: 300,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  logo: {
    position: "relative",
    // padding: "15px 15px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)",
    },
  },
  logoIcon: {
    height: 35,
    width: 35,
    color: theme.palette.primary.main,
    marginTop: 2,

    // pad
  },
  background: {
    position: "absolute",
    // zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      // zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      opacity: ".8",
    },
  },
}));
const MobileSideBarMenu = (props) => {
  const classes = useStyles();
  const {user} = useContext(UserContext);



  const brand = (
    <div className={classes.logo}>
      <List>
        <ListItem
          button
          onClick={() => {
            props.history.push({ pathname: "/journey" });
          }}
        >
          {/*<ListItemIcon>*/}
          {/*  <HomeWorkRoundedIcon className={classes.logoIcon} />*/}
          {/*</ListItemIcon>*/}
          <ListItemText>
            <Grid container direction={"row"} justify={"center"}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "white", marginTop: "9px" }}
              >
                Allo
              </Typography>
              <Typography
                variant="h5"
                style={{ color: "white", marginTop: "9px" }}
              >
                {"Canada"}
              </Typography>
            </Grid>
          </ListItemText>
        </ListItem>
      </List>

    </div>
  );

  return (
    <Drawer
      variant="temporary"
      anchor={"left"}
      open={props.open}
      classes={{
        paper: classes.drawer,
      }}
      onClose={props.handleDrawerClose}
    >
      <div style={{ zIndex: "1" }}>{brand}</div>
      <Divider />
      <div style={{ zIndex: "1" }}>
        {user  ? <UserInfo  /> : ""}
      </div>
      <div
        className={classes.background}
        // style={{ backgroundImage: "url(" + image + ")" }}
      />
      {/*<Links logged={user?true : false} color={"blue"}/>*/}
      <HamburgerMenuLinks   />

    </Drawer>
  );
};
export default withRouter(MobileSideBarMenu);
