import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "../modules/components/Typography";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import TextField from "@material-ui/core/TextField/TextField";
import {DataGrid} from '@material-ui/data-grid';
import parse from 'autosuggest-highlight/parse';


import {
    Backdrop,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Snackbar,
} from "@material-ui/core";

import {languageOptions} from "../../../../languages";

import {Alert, Autocomplete} from "@material-ui/lab";


import {get_intl_lang} from "../utils/commonUsedFunctions";
import Box from "@material-ui/core/Box";
import throttle from 'lodash/throttle';
import DialogContentText from "@material-ui/core/DialogContentText";
import useApi from "../../../../hooks/useApi";
import metadataApi from "../../../../api/metadata";
import servicesApi from "../../../../api/services";
import organizationApi from "../../../../api/organizations";
import adminApi from "../../../../api/admin";

require("dotenv").config();

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,

    },
    container: {
        borderRadius: "15px",
        paddingTop: "20px",
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#289",
        "&:hover": {
            backgroundColor: "#ff3366",
        },
        color: "white",
    },
    signUpIcon: {
        backgroundColor: "#0092d4",
    },
    radioButtonForm: {
        paddingTop: "15px",
    },
    textHeader: {
        paddingTop: "20px",
        fontWeight: "600",
    },
}));






const useFakeMutation = () => {
    return React.useCallback(
        (user) =>
            new Promise((resolve) =>
                setTimeout(() => {
                    resolve(user);
                }, 200),
            ),
        [],
    );
};



export interface RenderTree {
    id: string;
    name: string;
    children?: RenderTree[];
}


const AdminConsole = (props) => {

    const classes = useStyles();


    const [selectedServiceAddress, setSelectedServiceAddress] = useState(null);




    const [selectionModel, setSelectionModel] = React.useState([]);
    const prevSelectionModel = useRef(selectionModel);
    const [cityRows, setCityRows] = useState([])
    const [cityColumns, setCityColumns] = useState([])
    const [serviceAddressAutocompleteResults, setServiceAddressSearchAutocompleteResults] = useState([])
    const [serviceAddressInputValue, setServiceAddressInputValue] = React.useState('');

    const [organizationValues, setOrganizationValues] = useState({
        name: null,
        nameFre: null,
        email: null,
        phones: [],
        categories: [],
        address: null,
        postalCode: null,
        intlOrgs: {},
        website: null

    })

    const [snackbar, setSnackbar] =  useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [modifiedCategories, setModifiedCategories] = useState()
    const selectedSet =  useMemo(() => new Set(selectedServices), [selectedServices]);
    const [orgTableState, setOrgTableState] = React.useState({
        page: 0,
        pageSize: 50,
        rows: [],
        count: 0,
        cols: [],
 
    });

    const [rowSort, setRowSort] = useState(null)
    const [selectedOrganizationRowIds, setSelectedOrganizationRows] = useState([])
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const [loadBackDrop, setLoadBackdrop] = useState(false)
    const [serviceTagBoxSelected, setServiceTagBox] = useState({})

    const getCategories = useApi(metadataApi.getCategories)
    const getCities = useApi(metadataApi.getCities)
    const getTags = useApi(metadataApi.getTags)

    const submitOrganization = useApi(adminApi.submitOrganization)
    const organizationsQuery = useApi(organizationApi.organizationsQuery)


    useEffect(() => {
        getCategories.request()
        getCities.request()
        getTags.request()


    } , []);

    const fetchAutoCompleteQuery = useMemo(
        () =>
            throttle((request, callback) => {

                //servicesApi.autocomplete_search(request).then(callback)
                servicesApi.autocomplete_search(request).then(callback)

                //console.log(autocomplete_search.data)
                //allback(autocomplete_search.data || [])
                //autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );



    useEffect(() => {
        let active = true;

        fetchAutoCompleteQuery({"search": serviceAddressInputValue, "searchType": "address"}, (results) => {

            if (active) {
                let newOptions = [];
                if (results) {
                    newOptions = [...newOptions, ...results.data ]
                }
                setServiceAddressSearchAutocompleteResults(newOptions)
            }

        })
        return () => {
            active = false
        }
    }, [serviceAddressInputValue, fetchAutoCompleteQuery])







    useEffect(() => {

        if (selectedServiceAddress) {
            setOrganizationValues({...organizationValues, ["address"]: selectedServiceAddress.description})
        }
    }, [selectedServiceAddress])


    useEffect(() => {
        let cols = [
            {
                field: "id",
                headerName: "City ID",
                width: 150,
            },
            {
                field: "name",
                headerName: "City Name",
                width: 150,
            },
            {
                field: "province",
                headerName: "Province",
                width: 150,
            },
            {
                field: "lat",
                headerName: "Latitude",
                width: 150,
            },
            {
                field: "lng",
                headerName: "Longitude",
                width: 150,
            },
        ]
        Object.entries(languageOptions).map(([language_code, language]) => {
            if (language_code !== "eng") {

                cols.push({
                    field: `city_${language_code}`,
                    headerName: `City [${language}]`,
                    width: 150,
                    editable: true,
                    sortable: false,
                })
            }
        })

        let rows = []
        const data = getCities.data || []
        data.forEach((city) => {
            let r = {
                "id": city.id,
                "name": city.name,
                "province": city.province.name,
                "lat": city.coordinates.lat,
                "lng": city.coordinates.lng
            }
            Object.entries(languageOptions).map(([language_code, language]) => {
                if (language_code !== "eng") {
                    let trans = get_intl_lang(language_code, city.intlMetadata)
                    if (trans) {
                        r[`city_${language_code}`] = trans.name
                    } else {
                        r[`city_${language_code}`] = '-'
                    }
                }

            })
            rows.push(r)

        })


        setCityColumns(cols)
        setCityRows(rows);

    }, [getCities.data ]);






    const handleChangeNewService = (event) => {

        setOrganizationValues({...organizationValues, [event.target.name]: event.target.value});
    };


    const addServiceDescriptionTranslation = (code, description) => {

        organizationValues.intlOrgs[code] = description
        setOrganizationValues({
            ...organizationValues,
            intlOrgs: {
                ...organizationValues.intlOrgs,
                [code]: description
            }
        })

    }


    const handleNumberChange = (index, e) => {
        //let data = [...organizationValues]

        setOrganizationValues({
            ...organizationValues,
            phones: organizationValues.phones.map((phone, i) => {
                if (i === index) {
                    return {
                        ...phone,
                        [e.target.name]: e.target.value
                    }
                }
                return phone
            })
        })

    }

    const handleCloseSnackbar = () => setSnackbar(null);


    const handleExtensionChange = (index, extIndex, e) => {
        //let data = [...organizationValues]
        //organizationValues.phones[index]['extensionss'][extIndex][e.target.name] = e.target.value
        setOrganizationValues({
            ...organizationValues,
            phones: organizationValues.phones.map((phone, i) => {
                if (i === index) {
                    return {
                        ...phone,
                        extensions: organizationValues.phones[index].extensions.map((extension, extindex) => {
                            if (extindex === extIndex) {
                                return {
                                    ...extension,
                                    [e.target.name]: e.target.value
                                }
                            }
                            return extension
                        })


                    }

                }
                return phone
            })
        })

    }

    const addPhone = () => {

        setOrganizationValues({
            ...organizationValues,
            phones: [...organizationValues.phones, {
                areaCode: null,
                phone: null,
                lineType: null,
                extensions: []
            }]
        })


    }


    const addExtension = (index) => {

        setOrganizationValues({
            ...organizationValues,
            phones: organizationValues.phones.map((p, i) => {
                if (i === index) {
                    return {
                        ...p,
                        extensions: [...organizationValues.phones[i].extensions, {
                            name: null,
                            extension: null,
                            notes: null
                        }]
                    }

                }
                return p

            })
        })

    }

    const removePhoneLine = (index) => {

        const temp = [...organizationValues.phones]
        temp.splice(index, 1)
        setOrganizationValues({
            ...organizationValues,
            phones: temp
        })
    }

    const removeExtension = (phoneIndex, extensionIndex) => {

        const temp = [...organizationValues.phones[phoneIndex].extensions]
        temp.splice(extensionIndex, 1)
        setOrganizationValues({
            ...organizationValues,
            phones: organizationValues.phones.map((p, i) => {
                if (i === phoneIndex) {
                    return {
                        ...p,
                        extensions: temp
                    }

                }
                return p

            })
        })
    }


    const handleServiceSubmit = async (e) => {

        e.preventDefault();
        let orgvalues = JSON.parse(JSON.stringify(organizationValues)) //deep copy

        orgvalues.intlOrgs = Object.entries(orgvalues.intlOrgs).map(([lang, d]) => {
            return {
                "languageId": lang,
                "description": d
            }
        })
        orgvalues['serviceTags'] = Object.entries(serviceTagBoxSelected).map(([categoryId, tagsIds = []]) =>{

            return {
                "categoryId": parseInt(categoryId),
                "tagIds":  tagsIds
            }
        })

        submitOrganization.request(orgvalues).then(res => {
            setSnackbar({children: `Service ${orgvalues.name} added!`, severity: 'success'});
            setOrganizationValues({...organizationValues,  name: '', nameFre: '', intlOrgs: {}, address: null})

        }).catch(e => {

            let message = 'Error occurred while saving service'
            if (e.response && e.response.status === 400) {
                message = e.response.data.detail;
            }

            setSnackbar({children: message, severity: 'error'});

        })
    }

    const mutateRow = useFakeMutation();

    const handleCellEditCommit = useCallback(
        async (params) => {
            try {
                console.log(params);
                // Make the HTTP request to save in the backend
                const response = await mutateRow({
                    id: params.id,
                    [params.field]: params.value,
                });


            } catch (error) {

            }
        },
        [mutateRow],
    );


    // const handleFiles = async (files) => {
    //   var reader = new FileReader();
    //   reader.onload = async function(e) {
    //       // Use reader.result
    //       const fileData = await parse(reader.result, { columns: true });
    //       await axios
    //         .post("/api/services/addServicesData", {
    //           data: fileData,
    //         })
    //         .then((response) => {
    //           window.alert(
    //             response.data.data
    //           );
    //         });
    //   }
    //   reader.readAsText(files[0]);
    // }






    const parentMap = React.useMemo(() => {
        if (modifiedCategories) {
            return goThroughAllNodes(modifiedCategories);
        }

    }, [modifiedCategories, goThroughAllNodes]);

    // console.log("parentMAp", parentMap);

    function goThroughAllNodes(nodes , map  = {}) {
        if (!nodes || !nodes.children) {
            return null;
        }

        map[nodes.id] = getAllChild(nodes).splice(1);

        for (let childNode of nodes.children) {
            goThroughAllNodes(childNode, map);
        }

        return map;
    }

    // Get all children from the current node.
    function getAllChild(
        childNode: RenderTree | null,
        collectedNodes = []
    ) {
        if (childNode === null) return collectedNodes;

        collectedNodes.push(childNode.id);

        if (Array.isArray(childNode.children)) {
            for (const node of childNode.children) {
                getAllChild(node, collectedNodes);
            }
        }

        return collectedNodes;
    }

    const getChildById = (nodes, id) => {
        let array = [];
        let path = [];

        // recursive DFS
        function getNodeById(node, id, parentsPath ) {
            let result = null;

            if (node.id === id) {
                return node;
            } else if (Array.isArray(node.children)) {
                for (let childNode of node.children) {
                    result = getNodeById(childNode, id, parentsPath);

                    if (!!result) {
                        parentsPath.push(node.id);
                        return result;
                    }
                }

                return result;
            }

            return result;
        }

        const nodeToToggle = getNodeById(nodes, id, path);
        // console.log(path);

        return {childNodesToToggle: getAllChild(nodeToToggle, array), path};
    };

    function getOnChange(checked, nodes) {
        const {childNodesToToggle, path} = getChildById(modifiedCategories, nodes.id);

        let array = checked
            ? [...selectedServices, ...childNodesToToggle]
            : selectedServices
                .filter((value) => !childNodesToToggle.includes(value))
                .filter((value) => !path.includes(value));

        array = array.filter((v, i) => array.indexOf(v) === i);


        setSelectedServices(array);

        // this is to initialize the service tag checkbox for this chosen service (the value prop requires an array to exist)

    }

    useEffect(() => {
        selectedServices.forEach(sId => {
            if (!serviceTagBoxSelected[sId]) {
                setServiceTagBox((cur) => ({
                    ...cur,
                    [sId]: []
                }))
            }
        })

        let k = Object.keys(serviceTagBoxSelected)
        k.forEach((catId, tags)=>{

            if(!selectedServices.includes(catId)){
                setServiceTagBox(p=>{
                    const oldState = {...p}
                    delete oldState[catId]
                    return oldState
                })
            }


        })

    }, [selectedServices])

    const renderTree = (nodes ) => {

        const allSelectedChildren = parentMap[
            nodes.id
            ]?.every((childNodeId ) => selectedSet.has(childNodeId));
        const checked = selectedSet.has(nodes.id) || allSelectedChildren || false;

        const indeterminate =
            parentMap[nodes.id]?.some((childNodeId) =>
                selectedSet.has(childNodeId)
            ) || false;

        if (allSelectedChildren && !selectedSet.has(nodes.id)) {

            setSelectedServices([...selectedServices, nodes.id]);
        }

        return (
            <TreeItem
                key={`tree_${nodes.id}`}
                nodeId={nodes.id}
                label={
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                indeterminate={!checked && indeterminate}
                                onChange={(event) =>
                                    getOnChange(event.currentTarget.checked, nodes)
                                }
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        label={
                            <>
                                {nodes.name} {nodes.children && nodes.children.length > 0 ? ("(" + nodes.children.length + ")") : null}

                            </>
                        }
                        key={`tree_${nodes.id}`}
                    />
                }
            >
                {Array.isArray(nodes.children)
                    ? nodes.children.map((node) => renderTree(node))
                    : null}
            </TreeItem>
        );
    };




    useEffect(() => {
        if (getCategories.data?.length > 0) {
            let c = getCategories.data.map(node => toStr(node))
            let root = {
                id: "0",
                name: "Services",
                children: c.filter(n => n.parentCategoryId === null)
            }

            setModifiedCategories(root)
        }
    }, [getCategories.data])


    function toStr(node) {
        if (!node || !node.id) {
            return
        }
        if (Array.isArray(node.subCategories) && node.subCategories.length > 0) {
            node.children = node.subCategories.map(node => {
                return toStr(node)
            })
        }
        return {...node, id: node.id.toString()}
    }



    const openDeleteConfirmDialog = () => {
        setSnackbar(null)
        setDeleteDialogOpen(true)
    }


    useEffect(() => {

        let cols = [
            {
                field: "id",
                headerName: "ID",

            },
            {
                field: "name",
                headerName: "Organization",
                flex: 1,
            },
            {
                field: "nameFre",
                headerName: "Organization (French)",
                width: 240,
            },
            {
                field: "address",
                headerName: "Address",
                flex: 1,
                renderCell: (params) => {

                    return params.row.address?.address  || ''
                }
            },
            {
                field: "email",
                headerName: "Email",
                width: 180,
            },
            {
                field: "website",
                headerName: "Website",
                width: 180,
            },


            // {
            //     field: "city",
            //     headerName: "City",
            //     width: 180,
            //     type: 'singleSelect',
            //     valueOptions: citymap,
            //     editable: true,
            //     preProcessEditCellProps: (params) => {
            //         console.log(params)
            //         const editRowsModel = apiRef.current.getEditRowsModel();
            //         // const isPaidProps = editRowsModel[params.id].isPaid;
            //         // const hasError = isPaidProps.value && !params.props.value;
            //         // return { ...params.props, error: hasError };
            //     },
            // },
        ]

        setOrgTableState((prev) => ({...prev, cols: cols}))

    }, [ ])


    const deleteOrgs = () => {

        setDeleteDialogOpen(false)
        setLoadBackdrop(true)
        adminApi.deleteOrganization (selectedOrganizationRowIds.map((row, idx) => {
            return row.id
        })).then(res=>{



            let message = ""
            if(selectedOrganizationRowIds.length === 1 ){
                message = "Successfully deleted organization"
            } else {
                message = `Successfully deleted ${selectedOrganizationRowIds.length} organizations`
            }
            setSnackbar({
                children: message,
                severity: 'success'
            });
            // update data table
            setOrgTableState(prevState => ({
                ...prevState,
                count: prevState.count - selectedOrganizationRowIds.length,
                rows: prevState.rows.filter((row) => !selectedOrganizationRowIds.includes(row))
            }))


            setLoadBackdrop(false)

        }).catch(e=>{
            setSnackbar({children: `Error occurred while deleting organization`, severity: 'error'});
        }).finally(()=>{
            setLoadBackdrop(false)
        })



    }

    useEffect(()=>{

        if(organizationsQuery.data){
            setOrgTableState(prevState => ({
                ...prevState,
                count: organizationsQuery.data.total,
                rows: organizationsQuery.data.results
            }))
        }

    }, [organizationsQuery.data])

    useEffect(() => {
        setOrgTableState((prev) => ({...prev, loading: true}));
        organizationsQuery.request(orgTableState.page, orgTableState.pageSize, rowSort)

        setTimeout(() => {
            setSelectionModel(prevSelectionModel.current);
        });

    }, [orgTableState.page, orgTableState.pageSize, rowSort]);



    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleChangeServiceCheckbox = (id, event) => {

        if(event.target.value.indexOf('all') > -1) {

            setServiceTagBox((cur) => ({
                ...cur,
                [id]: serviceTagBoxSelected[id].length === getTags.data?.length ? [] : getTags.data.map(tag => tag.id)
            }));
            return
        }


        setServiceTagBox((cur) => ({
            ...cur,
            [id]: event.target.value
        }));

    };

    return (

        <React.Fragment>

            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={2}>
                    <CssBaseline/>

                    <Grid container item xs={12}>
                        <form onSubmit={handleServiceSubmit}>


                            <Typography
                                component="h1"
                                variant="h2"
                                align="left"
                                color="textPrimary"
                                gutterBottom
                                style={{color: "#00000096"}}
                            >
                                Organizations
                            </Typography>

                            <Grid item xs={12}>
                                <Typography

                                    variant="h4"
                                    align="left"
                                    color="textPrimary"
                                    gutterBottom

                                >
                                    New Organization
                                </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                onChange={handleChangeNewService}
                                                label="Organization Name"
                                                required
                                                name='name'
                                                variant='outlined'
                                                value={organizationValues.name || ''}
                                                fullWidth
                                                id="org_eng"
                                                key={"_nameEng"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                onChange={handleChangeNewService}
                                                label="Organization Name [French]"
                                                required
                                                name='nameFre'
                                                value={organizationValues.nameFre || ''}
                                                variant='outlined'
                                                fullWidth
                                                id="org_fre"
                                                key={"_nameFre"}

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                {Object.entries(languageOptions).map(([lang_code, name]) =>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            onChange={(e) => addServiceDescriptionTranslation(lang_code, e.target.value)}
                                                            value={organizationValues.intlOrgs[lang_code] || ''}
                                                            variant="outlined"
                                                            style={{direction: lang_code === "ara" ? "rtl" : ""}}
                                                            required
                                                            multiline
                                                            fullWidth
                                                            rows={4}
                                                            id={`org_intl_${lang_code}`}
                                                            label={`Description [${name}]`}

                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid item xs={8}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <Typography align={'center'} variant={"h5"}>Services Offered</Typography>
                                            <TreeView
                                                aria-label="rich object"
                                                defaultCollapseIcon={<ExpandMoreIcon/>}
                                                sx={{overflowY: 'auto', height: 400}}
                                                defaultExpanded={["0"]}
                                                defaultExpandIcon={<ChevronRightIcon/>}
                                            >
                                                {modifiedCategories ? (renderTree(modifiedCategories)) : null}
                                            </TreeView>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Divider orientation="vertical"/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align={'center'} variant={"h5"}>Service Tags</Typography>

                                            <Grid container spacing={2}>
                                                {getCategories.data?.length > 0 && selectedServices.map((c, idx) => {

                                                    const category = getCategories.data.find(cat => cat.id.toString() === c)
                                                    if (category && category.parentCategoryId !== null && serviceTagBoxSelected[category.id]) {
                                                        //const parent = getCategories.data.find(cat => cat.id === category.parentCategoryId)

                                                        return (
                                                                <Grid item xs={12}>
                                                                    <FormControl fullWidth className={classes.formControl}>
                                                                        <InputLabel
                                                                            id={`demo-multiple-chip-label_${category.id}`}>{category.name}</InputLabel>
                                                                        <Select
                                                                            labelId={`demo-multiple-chip-label_${category.id}`}
                                                                            id="demo-multiple-chip"
                                                                            multiple
                                                                            fullWidth
                                                                            value={serviceTagBoxSelected[category.id] || ''}
                                                                            onChange={e => handleChangeServiceCheckbox(category.id, e)}
                                                                            input={<Input label={category.name}/>}
                                                                            renderValue={(selected) => (
                                                                                <div className={classes.chips}>
                                                                                    {selected.map((value) => {

                                                                                        const name =getTags.data?.find(status=>status.id === value).name;
                                                                                        return (
                                                                                            <Chip key={`chip_${value}`} label={name}
                                                                                                  size={'small'}
                                                                                                  color={'primary'}
                                                                                                  className={classes.chip}/>
                                                                                    )})}
                                                                                </div>
                                                                            )}
                                                                        >
                                                                            <MenuItem value="all">
                                                                                <ListItemIcon>
                                                                                    <Checkbox
                                                                                        checked={getTags.data?.length > 0 && serviceTagBoxSelected[category.id].length === getTags.data.length}
                                                                                        indeterminate={serviceTagBoxSelected[category.id].length > 0 && serviceTagBoxSelected[category.id].length < getTags.data?.length}
                                                                                    />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary="Select All" />
                                                                            </MenuItem>

                                                                            {getTags.data?.map((status, index) => {
                                                                                let name = status.name;
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={`ls.${index}`}
                                                                                              value={status.id}>

                                                                                        <Checkbox checked={serviceTagBoxSelected[category.id].indexOf(status.id) > -1} />
                                                                                        <ListItemText primary={name} />
                                                                                    </MenuItem>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                    </FormControl>
                                                            </Grid>
                                                        )
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={7}>
                                            <Autocomplete
                                                id="service-address"

                                                getOptionLabel={(option) =>
                                                    typeof option === 'string' ? option : option.description
                                                }
                                                filterOptions={(x) => x}
                                                options={serviceAddressAutocompleteResults}
                                                autoComplete
                                                includeInputInList
                                                filterSelectedOptions
                                                value={selectedServiceAddress ? selectedServiceAddress.description : ''}
                                                onChange={(event, newValue) => {

                                                    setSelectedServiceAddress(newValue)

                                                }}
                                                onInputChange={(event, newInputValue) => {


                                                    setServiceAddressInputValue(newInputValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField variant={"outlined"} {...params} label="Type address"
                                                               fullWidth/>
                                                )}
                                                renderOption={(props, option) => {

                                                    const matches = props.structured_formatting.main_text_matched_substrings;
                                                    const parts = parse(
                                                        props.structured_formatting.main_text,
                                                        matches.map((match) => [match.offset, match.offset + match.length]),
                                                    );


                                                    return (
                                                        <div {...props}>
                                                            <Grid container alignItems="center">
                                                                <Grid item>
                                                                    <Box
                                                                        //component={LocationOnIcon}
                                                                        // sx={{ color: 'text.secondary', mr: 2 }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    {parts.map((part, index) => (
                                                                        <span
                                                                            key={`autocomplete_key_${index}`}
                                                                            style={{
                                                                                fontWeight: part.highlight ? 700 : 400,
                                                                            }}
                                                                        >
                                                                        {part.text}
                                                                      </span>
                                                                    ))}

                                                                    <Typography variant="body2">
                                                                        {props.structured_formatting.secondary_text}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    );
                                                }}
                                            />

                                        </Grid>
                                        <Grid item xs={1}>
                                            <TextField
                                                onChange={handleChangeNewService}
                                                variant="outlined"
                                                fullWidth
                                                name='email'
                                                type='email'
                                                value={organizationValues.email || ''}
                                                label="Email"
                                            >
                                            </TextField>


                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                onChange={handleChangeNewService}
                                                variant="outlined"
                                                fullWidth
                                                name='website'

                                                value={organizationValues.website || ''}
                                                label="Website"
                                            >
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant={'h5'}>
                                                Organization Phone Numbers
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button variant={'contained'} color={'primary'} onClick={addPhone}>Add
                                                Phone</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {organizationValues.phones.map((p, index) => {
                                            return (
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={1}>
                                                            <Typography variant={'h5'}>
                                                                Phone {index + 1}
                                                            </Typography></Grid>
                                                        <Grid item xs={10}>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={2}>
                                                                        <TextField
                                                                            onChange={event => handleNumberChange(index, event)}
                                                                            id="select"
                                                                            variant="outlined"
                                                                            required
                                                                            name={'lineType'}
                                                                            fullWidth
                                                                            label={'Line Type'}
                                                                            select
                                                                            value={p.lineType || ''}
                                                                        >
                                                                            <MenuItem
                                                                                value={'SERVICE'}>Service</MenuItem>
                                                                            <MenuItem value={'FIXED'}>Fixed</MenuItem>
                                                                            <MenuItem
                                                                                value={'TOLL_FREE'}>Toll-free</MenuItem>
                                                                        </TextField>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <TextField
                                                                            onChange={event => handleNumberChange(index, event)}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            value={p.areaCode || ''}
                                                                            name='areaCode'
                                                                            label="Area code"
                                                                            required={p.lineType !== 'SERVICE'}
                                                                        >
                                                                        </TextField>


                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            onChange={event => handleNumberChange(index, event)}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            value={p.phone || ''}
                                                                            name='phone'
                                                                            label="Phone"

                                                                        >
                                                                        </TextField>


                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Button variant={'contained'} color={'primary'}
                                                                                onClick={event => addExtension(index)}>Add
                                                                            Extension</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12}>

                                                                {organizationValues.phones[index].extensions.map((ext, extIndex) => {
                                                                    return (

                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={1}/>
                                                                            <Grid item
                                                                                  xs={1}>Extension {extIndex + 1}</Grid>
                                                                            <Grid item xs={1}>
                                                                                <TextField
                                                                                    onChange={event => handleExtensionChange(index, extIndex, event)}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    required
                                                                                    value={organizationValues.phones[index].extensions[extIndex].extension || ''}
                                                                                    name='extension'
                                                                                    label="Extension"

                                                                                >
                                                                                </TextField>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <TextField
                                                                                    onChange={event => handleExtensionChange(index, extIndex, event)}
                                                                                    variant="outlined"
                                                                                    required
                                                                                    fullWidth
                                                                                    value={organizationValues.phones[index].extensions[extIndex].name || ''}
                                                                                    name='name'
                                                                                    label="Extension Name"

                                                                                >
                                                                                </TextField>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <TextField
                                                                                    onChange={event => handleExtensionChange(index, extIndex, event)}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    value={organizationValues.phones[index].extensions[extIndex].notes || ''}
                                                                                    name='notes'
                                                                                    label="Notes"

                                                                                >
                                                                                </TextField>
                                                                            </Grid>
                                                                            <Grid item xs={2}>
                                                                                <Button variant={'contained'}
                                                                                        onClick={event => removeExtension(index, extIndex)}>Remove
                                                                                    Extension</Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )
                                                                })}

                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button disableElevation size={'large'}
                                                                    variant={'contained'}
                                                                    color={'primary'}
                                                                    onClick={index => removePhoneLine(index)}>Remove</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}/>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                disabled={submitOrganization.loading}
                                            >
                                                Add Organization
                                            </Button>
                                            {!!snackbar && (
                                                <Snackbar open onClose={handleCloseSnackbar}
                                                          autoHideDuration={6000}
                                                          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                                >
                                                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                                                </Snackbar>
                                            )}
                                        </Grid>

                                    </Grid>


                                </Grid>
                                <Grid item xs={12}>


                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Divider variant="middle"/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{height: 650, width: '100%'}}>
                                                <DataGrid
                                                    rows={orgTableState.rows}
                                                    columns={orgTableState.cols}
                                                    pagination
                                                    checkboxSelection
                                                    disableSelectionOnClick

                                                    sortingMode={"server"}
                                                    filterMode={"server"}
                                                    pageSize={orgTableState.pageSize}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    onSortModelChange={(p) => {
                                                        let sort = p.map((item) => {
                                                            return item.field + ":" + item.sort
                                                        })
                                                        sort = sort.join(", ")
                                                        setRowSort(sort)
                                                    }}
                                                    editMode="row"
                                                    rowCount={orgTableState.count || 0}
                                                    paginationMode="server"
                                                    onPageChange={(p) => {
                                                        setOrgTableState((prev) => ({...prev, page: p}))
                                                    }}
                                                    onPageSizeChange={(pageSize) =>
                                                        setOrgTableState((prev) => ({...prev, pageSize}))
                                                    }
                                                    onSelectionModelChange={(newSelectionModel) => {


                                                        const rowsToDelete = organizationsQuery.data?.results.filter(row => newSelectionModel.includes(row.id));

                                                        setSelectionModel(newSelectionModel);
                                                        setSelectedOrganizationRows(rowsToDelete)


                                                    }}
                                                    selectionModel={selectionModel}
                                                    loading={organizationsQuery.loading}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={11}/>
                                                <Grid item xs={1}>
                                                    <Button onClick={openDeleteConfirmDialog} variant="contained"

                                                            disabled={selectedOrganizationRowIds.length === 0}>
                                                        Delete Rows
                                                    </Button>
                                                    <Dialog
                                                        open={deleteDialogOpen}

                                                        keepMounted
                                                        onClose={handleDeleteDialogClose}
                                                        aria-describedby="alert-dialog-slide-description"
                                                    >
                                                        <DialogTitle>{"Delete Organizations"}</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-slide-description">
                                                                {selectedOrganizationRowIds.length > 1 ? (
                                                                    <Typography component="span">Do you wish to delete these {selectedOrganizationRowIds.length} organizations?</Typography>
                                                                ) : (
                                                                    <Typography component="span">Do you wish to delete this organization?</Typography>

                                                                )}

                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={e => deleteOrgs()}>Proceed</Button>
                                                            <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </form>
                    </Grid>



                    {/*<Typography className={classes.textHeader}>*/}
                    {/*    BULK UPLOAD CSV DATA*/}
                    {/*</Typography>*/}
                    {/*<ReactFileReader fileTypes={'.csv'}>*/}
                    {/*    <button className='btn'>Upload</button>*/}
                    {/*</ReactFileReader>*/}
                    {/*<Typography className={classes.textHeader}>*/}
                    {/*    DELETE SERVICES*/}
                    {/*</Typography>*/}


                    {/*<form*/}
                    {/*    className={classes.form}*/}
                    {/*    //onSubmit={handleSubmitDelete}*/}
                    {/*    //onChange={checkIfDeleteFormIsValid}*/}
                    {/*>*/}
                    {/*    <Typography>*/}
                    {/*        Enter serviceIDs you want to delete delimted by a comma. ex:*/}
                    {/*        mtl_000001,tor_000100*/}
                    {/*    </Typography>*/}
                    {/*    <Grid container spacing={2}>*/}
                    {/*        <Grid item xs={3} sm={12}>*/}
                    {/*            <TextField*/}
                    {/*                fullWidth*/}
                    {/*                label="Service IDs"*/}
                    {/*                margin="dense"*/}
                    {/*                name="Service IDs"*/}
                    {/*                variant="outlined"*/}
                    {/*                required*/}
                    {/*                //onChange={(e) => setServiceIDs(e.target.value)}*/}
                    {/*            />*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}

                    {/*    <Button*/}
                    {/*        type="submit"*/}
                    {/*        fullWidth*/}
                    {/*        variant="contained"*/}
                    {/*        color="primary"*/}
                    {/*        className={classes.submit}*/}
                    {/*        //disabled={!validDeleteForm}*/}
                    {/*    >*/}
                    {/*        DELETE SERVICES*/}
                    {/*    </Button>*/}
                    {/*</form>*/}


                </Grid>
                {!!snackbar && (
                    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}  anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                        <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                    </Snackbar>
                )}
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loadBackDrop}

                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Container>
            {/*<AppFooter />*/}


        </React.Fragment>
    );
};

export default AdminConsole;
