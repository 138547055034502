import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import TimelineIcon from "@material-ui/icons/Timeline";
import {LanguageContext} from "../../providers/LanguageProvider";
import {DeleteForever} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        //backgroundColor: theme.journey.legalTask.main,

    },
}));



export default function TasksAppBar(props) {
    const classes = useStyles();

    const {  tt} = React.useContext(LanguageContext);


    return (
        <div className={classes.root}>

            <AppBar position="static" className={classes.appBar} color={'primary'}>
                <Toolbar variant={'dense'}>

                    <Typography variant="h6" className={classes.title}  >
                        {tt('journey.tasks.legal.myTitle')}
                    </Typography>


                    <div>


                        <Button key={'timelinebutton1'} size={"small"} variant="contained" color="secondary" className={classes.menuButton}
                                onClick={props.handleTimelineClick} endIcon={<TimelineIcon/>}>
                            {tt('journey.timeline.title')}
                        </Button>
                        <Button key={'timelinebutton2'} size={"small"} variant="contained" color="secondary" className={classes.menuButton}
                                onClick={props.restartJourneyClick} endIcon={<DeleteForever />}>
                            {tt('journey.menu.restart.label')}
                        </Button>

                        {/*<Menu*/}
                        {/*    id="menu-appbar"*/}
                        {/*    anchorEl={anchorEl}*/}
                        {/*    anchorOrigin={{*/}
                        {/*        vertical: 'top',*/}
                        {/*        horizontal: 'right',*/}
                        {/*    }}*/}
                        {/*    keepMounted*/}
                        {/*    transformOrigin={{*/}
                        {/*        vertical: 'top',*/}
                        {/*        horizontal: 'right',*/}
                        {/*    }}*/}
                        {/*    open={open}*/}
                        {/*    onClose={handleClose}*/}
                        {/*>*/}
                        {/*    <MenuItem onClick={handleClose}>Profile</MenuItem>*/}
                        {/*    <MenuItem onClick={handleClose}>My account</MenuItem>*/}
                        {/*</Menu>*/}
                    </div>

                </Toolbar>
            </AppBar>
        </div>
    );
}
