import React, {useContext, useEffect, useMemo, useState} from 'react';
import {JourneyContext} from "../../providers/JourneyProvider";

import SocialTaskItem from "./SocialTaskItem";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";


MySocialTasks.propTypes = {
    socialProvincialTasks: PropTypes.array.isRequired
};
export default function MySocialTasks(props) {

    const {provincialSections, journeyUserStatus, getProvincialSectionByProvincialTaskId } = useContext(JourneyContext)
    const [socMapping, setSocMapping] = useState({})

    useEffect(()=>{

        props.socialProvincialTasks.forEach(st=>{

            const provincialTaskId = st.provincialTaskId;
            const provincialSection = getProvincialSectionByProvincialTaskId(provincialTaskId)

            if(!(ps.id in socMapping)){

                socMapping[ps.id] = provincialSection.id
            }

        })
    }, [props.socialProvincialTasks, getProvincialSectionByProvincialTaskId])

    const ps = useMemo(() => {

        return provincialSections.find(ps => ps.section.id === journeyUserStatus.legalStatusId)

    }, [provincialSections, journeyUserStatus])


    return (

        <List dense>
            {props.socialProvincialTasks.map((socialProvincialTask, i) => {

                return (<SocialTaskItem  socialProvincialTask={socialProvincialTask} provincialSection={ps}/>)
            })}
        </List>

    );
}
