import {Circle, GoogleMap, InfoWindow, Marker, useJsApiLoader} from "@react-google-maps/api";
import React, {useContext, useEffect, useState} from "react";
import SearchBar from "./SearchBar";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import mapsApi from "../../../../api/maps";
import {UserContext} from "../../providers/UserInfoContext";
import organizationsApi from "../../../../api/organizations";
import OrganizationInfoWindow from "./OrganizationInfoWindow";
import BottomAppBar from "./BottomAppBar";
import NearMeIcon from "@material-ui/icons/NearMe";
import StatusBar from "./StatusBar";
import useApi from "../../../../hooks/useApi";
import metadataApi from "../../../../api/metadata";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import MenuSpeedDial from "./MenuSpeedDial";
import {Divider, ListItem, Typography} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ServiceList from "./ServiceList";
import AllOutIcon from '@material-ui/icons/AllOut';
import StatusList from "./StatusList";
import Box from "@material-ui/core/Box";
import {CANADA, googleMapOptions} from "./mapOptions";
import MobileSideBarMenu from "../../general/MobileSideBarMenu";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useSnackbar} from "notistack";
import ExploreCard from "./ExploreCard";
import OrgDataList from "./OrgDataList";
import Avatar from "@material-ui/core/Avatar";
import OrgCard from "../journey/OrgCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/pro-light-svg-icons";
import LearnMoreOrgDialog from "./LearnMoreOrgDialog";
import {faCircleLocationArrow, faCompassDrafting, faLocationCrosshairs} from "@fortawesome/pro-solid-svg-icons";
import {LanguageContext} from "../../providers/LanguageProvider";
import useBookmark from "../../../../hooks/useBookmark";

const useStyles = makeStyles((theme) => ({
    root: {},

    speedDialActionToolTip: {
        whiteSpace: "nowrap",
        maxWidth: "none",

    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            left: theme.spacing(3),
            bottom: theme.spacing(9),
        },
        [theme.breakpoints.up('lg')]: {
            right: theme.spacing(3),
        },

    },
    servicesWindow: {
        position: "absolute",
        top: "18%",
        left: "10px",
        zIndex: 99,
        [theme.breakpoints.down('md')]: {
            display: "none"

        },

    },

    progressBar: {

        position: "absolute",
        top: "120px"

    },
    bottomAppBar: {

        top: 'auto',

        bottom: 0,
        position: "fixed",
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },

    },
    toolbar: (props) => ({
        position: "relative",
        top: 20,
        bottom: "auto",
        zIndex: 9022,


    })
}));

const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#ff8c8c',
    fillOpacity: 0.35,

}


export default function Explore2() {
    const theme = useTheme()
    const { tt, userLanguageChange } = useContext(LanguageContext);
    const classes = useStyles();
    const {user} = useContext(UserContext);
    const {  processing,  addBookmark, removeBookmark, findOrgBookmark} = useBookmark()
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY,

    })
    const containerStyle = {
        width: "100vw", height: "100vh",
    };



    const defaultRadius = 8000;
    const {enqueueSnackbar} = useSnackbar();
    const mapRef = React.useRef();
    const getCategories = useApi(metadataApi.getCategories)
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const getTags = useApi(metadataApi.getTags);

    const [circle, setCircle] = useState(null)
    const [selectedLocationAddress, setSelectedLocationAddress] = useState(null)
    const [fetchingCoordinates, setFetchingCoordinates] = useState(false)
    const [mapCoordinates, setMapCoordinates] = useState(null)
    const [selectedCoordinates, setSelectedCoordinates] = useState(null);
    const [userCoordinates, setUserCoordinates] = useState(null)
    const [selectedStatuses, setSelectedStatuses] = useState([])
    const [activeMarker, setActiveMarker] = useState(null);
    const [searchRadius, setSearchRadius] = useState(defaultRadius)
    const [selectedServiceIds, setSelectedServiceIds] = useState([])
    const [initialZoom, setInitialZoom] = useState(5)
    const [organizations, setOrganizations] = useState([])

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [circleVisible, setCircleVisible] = useState(false)
    const [searchingOrgs, setSearchingOrgs] = useState(false)

    const [hoverWindow, setHoverWindow] = useState(null)
    const [mobileMenu, setMobileMenu] = useState(false)

    const [selectedOrg, setSelectedOrg] = useState(null)
    const [showSubCard, setShowSubCard] = useState(true)
    const [learnMoreOpen, setLearnMoreOpen] = useState(false)

    useEffect(() => {
        getCategories.request()
    }, []);



    const onMapLoad = React.useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        const canadianBounds = [
            {position: {lat: 69.88385, lng: -140.9264}},
            {position: {lat: 49.38012, lng: -137.363374}},
            {position: {lat: 41.37421, lng: -56.68647}},
            {position: {lat: 68.49477, lng: -55.80757}},
        ]

        canadianBounds.map(item => {
            bounds.extend(new window.google.maps.LatLng(item.position.lat, item.position.lng));
            return item.id
        });

        //map.fitBounds(bounds)
        mapRef.current = map;

    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        mapRef.current = null
    }, [])

    const panTo = React.useCallback(({lat, lng}, zoom) => {
        if (mapRef.current) {
            mapRef.current.panTo({lat, lng});
            mapRef.current.setZoom(zoom ? zoom : 14);
        }
    }, []);

    const panToOffset = React.useCallback(({lat, lng}, zoom, offsetX, offsetY) => {
        if (mapRef.current) {

            mapRef.current.panTo({lat, lng});
            mapRef.current.setZoom(zoom ? zoom : 14);
            mapRef.current.panBy(offsetX, offsetY)

        }
    }, []);

    useEffect(() => {
        if (user?.address) {
            if (user.address.lat && user.address.lng) {
                setUserCoordinates({lat: user.address.lat, lng: user.address.lng})
            }
        }
    }, [user])


    useEffect(() => {

        getTags.request(true)
    }, [])


    const onSuccessAddressCoordinateFetch = (res) => {
        if (Array.isArray(res.data)) {
            let data = res.data;
            if (data.length === 1) {
                let cityCoordinateObjPredictions = data[0].data
                if (cityCoordinateObjPredictions.length === 1) {
                    const coordinates = cityCoordinateObjPredictions[0].geometry.location
                    setMapCoordinates(coordinates)

                }
            }
        }
    }

    const fetchAddressCoordinates = (value, callback) => {
        if (value) {
            let payload = [{
                "id": 0,
                "address": value.description,
            }]
            setFetchingCoordinates(true)

            mapsApi.handleAddressCoordinates(payload).then(callback).catch(onFailFetchCoord).finally(() => {
                setFetchingCoordinates(false)
            })
        }
    }

    const onFailFetchCoord = (e) => {
        enqueueSnackbar(tt('explore.errors.failFetchCoordinates'), {variant: 'error'})

    }

    const onSelectAddress = (a) => {

        setSelectedLocationAddress(a)
        fetchAddressCoordinates(a, (result) => {

            setFetchingCoordinates(false)
            onSuccessAddressCoordinateFetch(result)
            setInitialZoom(13)

        })
    }



    const onSelectServices = (services) => {
        setSelectedServiceIds(services)

    }


    useEffect(() => {
        if (selectedCoordinates?.lat && selectedCoordinates?.lng) {
            let serviceIds = selectedServiceIds

            let tags = selectedStatuses
            let params = {}
            if (serviceIds.length > 0) {
                params['serviceIds'] = serviceIds
            }
            if (tags.length > 0) {
                params['tags'] = tags
            }

            setSearchingOrgs(true)
            organizationsApi.queryServiceRangev2(selectedCoordinates.lat, selectedCoordinates.lng, searchRadius / 1000, params).then(onSuccessOrgResults).catch(onFailOrgFetch).finally(() => {
                setSearchingOrgs(false)
            })

        }

    }, [selectedCoordinates, searchRadius, selectedServiceIds, selectedStatuses])


    const onSuccessOrgResults = (res) => {
        if (res.data?.length > 0) {
            // only pan when there are actual results
            setOrganizations(res.data)
            panTo(selectedCoordinates, 12)
        }
    }

    const onFailOrgFetch = (e) => {

        enqueueSnackbar(tt('explore.errors.failFetchOrgs'), {variant: 'error'})

    }

    const onOrganizationMarkerClick = (orgResult) => {
        if (orgResult.organization.id === activeMarker) {
            return;
        }
        onSelectOrganization(orgResult.organization)
        setDrawerOpen(false)
        setShowSubCard(false)
    }

    const onCloseOrganizationInfoWindow = (orgId, orgCoordinates) => {
        setActiveMarker(null)
        panTo(orgCoordinates, 12)
        setHoverWindow(null)

    }


    const onSelectOrganization = (org) => {
        const coordinates = {lat: org.address.lat, lng: org.address.lng}
        setSelectedOrg({org: org, coordinates: coordinates})
        setActiveMarker(org.id)
        setCircleVisible(false)
        setHoverWindow(null)

        panToOffset(coordinates, 16, -10, -200)
    }

    const getUseGeoLocation = (callback, errorCallback) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position)
                const coordinates = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude

                }
                callback(coordinates)


            }, errorCallback);

        }
    }

    useEffect(() => {
        if(!userCoordinates){

            getUseGeoLocation((coordinates) => {
                setSelectedCoordinates(coordinates)
                panTo(coordinates, 11)

            }, onGeolocationServiceError)
        }


    }, [initialZoom, userCoordinates, panTo])

    useEffect(() => {
        if (userCoordinates && !mapCoordinates) {
            setMapCoordinates(userCoordinates)
            setSelectedCoordinates(userCoordinates)
            setInitialZoom(11)
        }
    }, [userCoordinates, mapCoordinates])

    const onNearMeClick = () => {

        getUseGeoLocation((coordinates) => {

            setSelectedCoordinates(coordinates)
            panTo(coordinates, initialZoom)
        }, onGeolocationServiceError)
    }

    const onGeolocationServiceError = (e) =>{
        if(e.code === 2){
            enqueueSnackbar("Positioning service is unavailable", {variant: 'error'})
        }
    }

    const onStatusClick = (statusId) => {
        if (selectedStatuses.includes(statusId)) {
            const _newStatus = selectedStatuses.filter(_statusId => _statusId !== statusId)

            setSelectedStatuses(_newStatus)
        } else {
            setSelectedStatuses(prevState => [...prevState, statusId])

        }

    }





    const onSelectSubService = (service) => {

        if (selectedServiceIds.findIndex(s => s === service.id) === -1) {
            setSelectedServiceIds(prevState => [
                ...prevState,
                service.id

            ])
        } else {
            let _selectedServices = selectedServiceIds.filter(s => s !== service.id)
            setSelectedServiceIds(_selectedServices)

        }

    }


    const onMenuClick = () => {

        setMobileMenu(true)
    }


    useEffect(() => {

        if (desktop) {

            setDrawerOpen(false)
        }

    }, [desktop])


    const onMarkerMouseOver = id => {
        if (activeMarker !== id) {
            setHoverWindow(id)

        }

    }

    const onMarkerMouseOut = id => {
        setHoverWindow(null)

    }


    const orgListResults = (
        <OrgDataList
            organizationsResults={organizations}
            onMouseEnter={(a) => console.log('')}
            onSelectOrganization={onSelectOrganization}
        />

    )

    const exploreTab = (
        <ExploreCard
            className={classes.servicesWindow}
            onSelectService={() => {

                setShowSubCard(true)
            }}
            selectedServices={selectedServiceIds}
            subCardShow={showSubCard}
            onSelectSubService={onSelectSubService}
            orgResultsContent={orgListResults}
            organizationsResults={organizations.length}
            services={getCategories.data || []}
        />

    )

    const statusBar = (

        <StatusBar
            statuses={getTags.data || []}
            state={selectedStatuses}
            onChange={onStatusClick}
        />
    )

    const speedDial = (
        <MenuSpeedDial className={classes.speedDial} hidden={false}>
            <SpeedDialAction
                className={classes.speedDialActionToolTip}
                key={'nearme'}
                icon={<FontAwesomeIcon icon={faCircleLocationArrow} size={'lg'} />}
                tooltipTitle={<Typography>{tt('explore.speedDial.nearMe')}</Typography>}
                tooltipOpen
                tooltipPlacement={mobile ? 'right' : 'left'}
                onClick={onNearMeClick}
            />
            {selectedCoordinates &&
                <SpeedDialAction
                    key={'circle'}
                    icon={<FontAwesomeIcon icon={faCompassDrafting}
                                           size={'lg'}  {...(circleVisible && {color: theme.palette.secondary.main})}    />}
                    tooltipTitle={tt('explore.speedDial.radius')}
                    tooltipOpen
                    tooltipPlacement={mobile ? 'right' : 'left'}
                    onClick={() => {

                        setCircleVisible(!circleVisible)
                        if (!circleVisible) {
                            panTo(selectedCoordinates, initialZoom)
                        }

                        //setSearchRadius(defaultRadius)
                    }}
                />
            }
        </MenuSpeedDial>

    )


    const onLearnMore = () => {
        setLearnMoreOpen(true)
    }


    // const paperRef = useRef(null)
    //
    // const [paperDim, setPaperDim] = useState({width: 500, height: 600})
    //
    // useEffect(()=>{
    //
    //     if(paperRef.current){
    //         console.log(paperRef.current)
    //         console.log(paperRef.current?.getBoundingClientRect())
    //         console.log(paperRef.current?.clientHeight)
    //         setPaperDim({width: 500, height: paperRef.current?.clientHeight})
    //     }
    //
    //
    // },[]  )
    const onlanguagechange = (lang) => {

        userLanguageChange(lang)
    }
    return (

        <div className={classes.root}>


            <MobileSideBarMenu
                fromExplore={true}
                open={mobileMenu}

                handleDrawerClose={() => setMobileMenu(false)}
            />


            <SearchBar
                className={classes.appBar}
                onMenuClick={onMenuClick}
                loading={fetchingCoordinates || searchingOrgs}
                onSelectLocationAddress={onSelectAddress}
                selectedStatusState={selectedStatuses}
                statusBar={statusBar}
                drawerWidth={233}
                handleDrawerOpen={() => setDrawerOpen(true)}
                handleDrawerClose={() => setDrawerOpen(false)}
                drawerOpen={drawerOpen}
                onlanguagechange={onlanguagechange}
                drawerContent={
                    <>
                        <ListItem>
                            <ListItemText primary={<Typography component={'div'}>
                                <Box sx={{letterSpacing: 6, m: 1, textAlign: "center"}} fontWeight={'bold'}>{tt('explore.mobileStatusBar.legal.label')}</Box>
                            </Typography>}/>
                        </ListItem>
                        <StatusList
                            data={getTags.data || []}
                            onStatusClick={onStatusClick}
                            selectedStatuses={selectedStatuses}/>
                        <Divider/>
                        <ListItem>
                            <ListItemText primary={<Typography component={'div'}>

                                <Box sx={{letterSpacing: 6, m: 1, textAlign: "center"}}
                                     fontWeight={'bold'}>{tt('explore.mobileStatusBar.services.label')}</Box>

                            </Typography>}/>
                        </ListItem>
                        <ServiceList
                            services={getCategories.data || []}
                            selectedServices={selectedServiceIds}
                            onApplyServiceSelection={onSelectSubService}
                        />
                    </>
                }


            />


            {exploreTab}
            {/*{serviceCard}*/}


            {speedDial}
            {isLoaded &&
                <div>
                    <GoogleMap
                        id="map"

                        mapContainerStyle={containerStyle}
                        initialCenter={CANADA}
                        center={mapCoordinates || CANADA}
                        options={googleMapOptions}
                        zoom={initialZoom}
                        onLoad={onMapLoad}
                        onUnmount={onUnmount}
                        onDragStart={() => {
                            //setShowSubCard(false)

                        }}
                        onClick={() => {

                            if (drawerOpen) {
                                setDrawerOpen(false)
                            }

                            setShowSubCard(false)


                        }}
                    >

                        {selectedCoordinates && (
                            <Marker
                                name={'userPosition'}
                                position={selectedCoordinates}

                                title={'You'}
                                draggable
                                icon={{
                                    path: "M192 512s192-208 192-320C384 86 298 0 192 0S0 86 0 192C0 304 192 512 192 512zm0-256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z",
                                    fillColor: "blue",
                                    fillOpacity: 0.9,
                                    strokeWeight: 1,
                                    rotation: 0,
                                    scale: 0.06,
                                    anchor: new window.google.maps.Point(210, 501),
                                }}
                                onDragEnd={((t) => {
                                    const coordinates = t.latLng.toJSON()
                                    setSelectedCoordinates(coordinates)
                                    setMapCoordinates(coordinates)
                                })}
                            />
                        )}

                        <Circle
                            center={mapCoordinates}
                            radius={searchRadius}
                            options={circleOptions}
                            editable={true}
                            onUnmount={(circle) => setCircle(null)}
                            draggable={false}
                            onLoad={(circle) => setCircle(circle)}
                            onCenterChanged={() => {
                                if (circle) {
                                    setSelectedCoordinates({lat: circle['center'].lat(), lng: circle['center'].lng()})
                                }
                            }}
                            onRadiusChanged={() => {
                                if (circle) {
                                    setSearchRadius(parseInt(circle['radius']))
                                }
                            }}
                            visible={circleVisible}
                        />


                        {organizations.map((orgResult) => {
                            if (orgResult.organization.address) {
                                const id = orgResult.organization.id;
                                const distance = orgResult.distance;
                                const orgName = orgResult.organization.name;
                                const coordinates = {
                                    lat: orgResult.organization.address.lat,
                                    lng: orgResult.organization.address.lng,
                                }
                                return (
                                    <Marker
                                        key={id}
                                        position={coordinates}
                                        onMouseOver={() => onMarkerMouseOver(id)}
                                        onMouseOut={() => onMarkerMouseOut(id)}
                                        onClick={(e) => onOrganizationMarkerClick(orgResult)}
                                        animation={2}
                                    >
                                        {id === activeMarker &&
                                            <OrganizationInfoWindow
                                                organizationResult={orgResult}
                                                onLearnMoreClick={onLearnMore}
                                                onCloseClick={(e) => onCloseOrganizationInfoWindow(id, coordinates)}>
                                                <OrgCard
                                                    organization={orgResult.organization}
                                                    showContent={desktop}
                                                    avatar={
                                                        <Avatar aria-label="recipe" className={classes.orgAvatar}>
                                                            <FontAwesomeIcon icon={faLocationDot} style={{
                                                                color: "#ff4b5c",
                                                                width: "100%",
                                                                height: "100%"
                                                            }}/>
                                                        </Avatar>}
                                                />
                                            </OrganizationInfoWindow>

                                        }

                                        {id === hoverWindow && (
                                            <InfoWindow>
                                                <div>
                                                    <h5>{orgName}</h5>
                                                    <h6>{distance.toFixed(1)} kilometers</h6>

                                                </div>
                                            </InfoWindow>

                                        )}


                                    </Marker>
                                );
                            }
                        })}


                        {/*{selectedOrg &&*/}
                        {/*    <div     ref={paperRef}              >*/}
                        {/*        <InfoBox*/}
                        {/*            position={selectedOrg.coordinates}*/}

                        {/*            options={{ closeBoxURL: '', enableEventPropagation: true, pixelOffset: new window.google.maps.Size(-paperDim.width / 2, -610), }}*/}

                        {/*        >*/}

                        {/*                <Paper elevation={8} style={{width: paperDim.width,   overflowY: "auto", height: 600}}  variant={"elevation"}    >*/}
                        {/*                    <OrgCard*/}
                        {/*                        organization={selectedOrg.org}*/}
                        {/*                        showContent={desktop}*/}
                        {/*                        avatar={*/}
                        {/*                            <Avatar aria-label="recipe" className={classes.orgAvatar}>*/}
                        {/*                                <FontAwesomeIcon icon={faLocationDot}*/}
                        {/*                                                 style={{color: "#ff4b5c", width: "100%", height: "100%"}}/>*/}
                        {/*                            </Avatar>}*/}
                        {/*                    />*/}
                        {/*                </Paper>*/}

                        {/*        </InfoBox>*/}
                        {/*    </div>*/}

                        {/*}*/}


                    </GoogleMap>
                </div>
            }


            <BottomAppBar
                className={classes.bottomAppBar}
                onSelectLocationAddress={onSelectAddress}
                onSelectServices={onSelectServices}
                organizationsResults={organizations}
                searchingOrgs={searchingOrgs || fetchingCoordinates || !isLoaded} // fab loading spinner
                onSelectOrganization={onSelectOrganization}
            />

            {selectedOrg?.org &&
                <LearnMoreOrgDialog
                    open={learnMoreOpen}
                    title={selectedOrg.org.name}
                    handleClose={() => setLearnMoreOpen(false)}
                >
                    <OrgCard
                        organization={selectedOrg.org}
                        showContent={true}
                        showTitle={false}
                        avatar={
                            <Avatar aria-label="recipe" className={classes.orgAvatar}>
                                <FontAwesomeIcon icon={faLocationDot}
                                                 style={{color: "#ff4b5c", width: "100%", height: "100%"}}/>
                            </Avatar>}
                    />
                </LearnMoreOrgDialog>
            }

        </div>
    )
}