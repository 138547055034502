import React, {useContext} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Card from "@material-ui/core/Card/Card";
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {makeStyles} from "@material-ui/core/styles";
import Interweave from 'interweave';
import {LanguageContext} from "../../providers/LanguageProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    boxShadow: "none",
  },
  googleMapStyle: {
    margin: "auto",
  },

  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minWidth: 200,
    borderRadius: "15px",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  avatar: {
    backgroundColor: "#0191d5",
  },
  gridItemStyling: {
    maxWidth: 300,
    height: 150,
    textAlign: "center",
    borderRadius: "15px",
    transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
    "&:hover": {
      zIndex: 6,
      border: "3px solid black",
      borderRadius: "15px",
      boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
      transform: "scale(1.04)",
    },
  },

  test: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  btn: {
    "&:hover": {
      backgroundColor: "white",
    },
    backgroundColor: "white",
    maxWidth: 165,
    height: "100%",
    // padding:0,
    // margin: 0
  },

  active: {
    padding: 0,
    margin: 0,
    backgroundColor: "white",
    width: 160,
    height: "100%",
    zIndex: 100,
    border: "3px solid #0191d5",
    borderRadius: "15px",
    transition: theme.transitions.create("z-index"),
  },
  button: {
    minWidth: 80,
    backgroundColor: "#0191d5",
    textTransform: "none",

    borderRadius: "15px",
  },
  cardProduct: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    borderRadius: 0,
    boxShadow: "none",
    background: "transparent",
    "& .card-product__content": {
      padding: theme.spacing(2, 2, 0, 2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 0, 0, 2),
      },
    },
    "& .card-product__media": {
      [theme.breakpoints.up("sm")]: {
        height: 170,
        width: 170,
        "& img": {
          height: 170,
          width: 170,
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column !important",
      "& .card-product__content": {
        flex: "1 1 100%",
      },
      "& .card-product__media": {
        flex: "1 1 100%",
        width: "100%",
      },
    },
  },
  // image: {
  //     objectFit: 'cover',
  // },
  blogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  // button: {
  //     marginTop: theme.spacing(1),
  //     alignSelf: 'flex-start',
  // },
  blogTitle: {
    fontWeight: 700,
  },
  author: {
    margin: theme.spacing(1, 0),
  },
  title: {
    fontWeight: "bold",
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
  },
}));

export default function Row(props) {
  const [open, setOpen] = React.useState(false);
  const { userLanguage } = useContext(LanguageContext);
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell
          component="th"
          scope="row"
          style={userLanguage === "ar" ? {
            fontWeight: 700,
            fontSize: "18px",
            paddingBottom: "15px",

            textAlign: "right",
            width: "100%"
          } : {
            fontWeight: 700,
            fontSize: "18px",
            paddingBottom: "15px",

            textAlign: "left",
            width: "100%"
          }
          }>
          {props.row ? props.row.question : null}
        </TableCell>
        <TableCell align={"right"}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <td>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Card className={classes.root}>
          <Box border={0}>
            <Grid container alignItems={"center"} className={classes.root}>
              <CardContent className={classes.content} style={

                userLanguage === "ar" ?
             {
                fontSize: "16px",
                paddingBottom: "20px",

                lineHeight:"24px",
                textAlign:"right",
                width:"100%"
              }
              : {
                fontSize: "16px",
                paddingBottom: "20px",

                lineHeight:"24px",
                textAlign:"left",
                width:"100%"
                }
              }>
                  <Interweave content= {props.row.answer} />
              </CardContent>
            </Grid>
          </Box>
        </Card>
      </Collapse>
        </td>
      </TableRow>
    </React.Fragment>
  );
}
