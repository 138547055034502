import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";

import {Link, Redirect, withRouter} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import {LanguageContext, Text} from "../../providers/LanguageProvider";

import {UserContext} from "../../providers/UserInfoContext";

import TokenService from "../../../../token.service";
import {checkEmailFormat} from "../utils/commonUsedFunctions";

import usersApi from "../../../../api/users";
import authApi from "../../../../api/auth";
import {tokenAuthenticate} from "./authenticate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faGoogle} from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
    error: {

        color: 'red'
    }
}));


export async function login(email, password) {

    const formData = new FormData();
    // OAuth2 expects form data, not JSON data
    formData.append('username', email);
    formData.append('password', password);

    return authApi.login(formData)

}


function SignIn(props) {
    const {alternateOptions} = props
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authenticated, setAuthenticated] = useState(false);
    const [errors, setErrors] = useState({email: "", password: ""});


    const [generalError, setGeneralError] = useState('')
    const [showCircularProgress, setCircularProgress] = useState(false)

    const {userChange} = useContext(UserContext);
    const {tt, userLanguageChange} = useContext(LanguageContext);


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };


    // #todo: all of this stuff is duplicated. refactor please
    function validateUserFromSocialProviders(type, getOAuthUserCallback) {
        getOAuthUserCallback(type)
            .then((oAuthUser) => {
                if (!oAuthUser.user) throw new Error();

                return oAuthUser.user.getIdToken().then(tkn => {

                    return tkn;
                });
            })
            .then(async (token) => {
                setCircularProgress(true)
                authApi.authorize(token).then(token_response => {

                    let access_token = token_response.data.access_token;
                    let refresh_token = token_response.data.refresh_token;

                    TokenService.updateLocalRefreshToken(refresh_token)
                    TokenService.updateLocalAccessToken(access_token)

                    usersApi.me().then(user_response => {

                        const dbUser = user_response.data // user from db

                        userChange(dbUser)

                        setAuthenticated(true);

                    })


                }).finally(e => {
                    setCircularProgress(false)
                })

            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {email: email, password: password};
        const errors = {};
        errors.email = !checkEmailFormat(user.email) ? tt('signInUp.errors.invalidEmail') : "";
        errors.password = password === "" || password == null ? "Please enter a password" : "";
        if (errors.email === "" && errors.password === "") {
            setGeneralError('')
            login(user.email, user.password).then((res) => {


                const decodedToken = res.data.access_token
                const refreshToken = res.data.refresh_token
                TokenService.updateLocalRefreshToken(refreshToken)
                TokenService.updateLocalAccessToken(decodedToken)
                setCircularProgress(true)
                usersApi.me().then((user) => {
                    userLanguageChange(user.data.language)
                    userChange(user.data)
                    setAuthenticated(true)
                })

            }).catch(e => {

                if (e.response?.status === 401) {
                    setGeneralError(tt('signInUp.errors.invalidLogin'))
                } else {

                    setGeneralError(tt('signInUp.errors.unknownError'))
                }

            }).finally(() => {
                setCircularProgress(false)
            })
        } else {
            setErrors(errors);
        }
    };


    if (authenticated) {

        return <Redirect to={{pathname: "/journey",}}/>;
    }


    return (
        <div>
            {/*<Hidden smDown>*/}
            {/*    <MobileSideBar close={props.close} signIn={true}/>*/}
            {/*</Hidden>*/}
            <Container maxWidth="xs">
                <div>
                    {showCircularProgress ?
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    overflow: "hidden",
                                    position: "relative",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: "50%"
                                }}>
                                <CircularProgress style={{width: "55px", height: "55px", color: "#208899"}}
                                                  disableShrink/>
                            </div>
                        </div>
                        :
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={<Text tid="signInUp.email"/>}
                                        name="email"
                                        autoComplete="email"
                                        onChange={handleEmailChange}
                                        value={email}
                                        error={errors.email !== ""}
                                        helperText={errors.email}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={<Text tid="signInUp.password"/>}
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={handlePasswordChange}
                                        value={password}
                                        error={errors.password !== ""}
                                        helperText={errors.password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox value="remember" color="primary"/>}
                                                label={<Text tid="signInUp.rememberMe"/>}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Link
                                                to="/forgot"
                                                className={classes.routerLink}
                                            >
                                                <Text tid="signInUp.forgotPassword"/>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color={'primary'}
                                    >
                                        <Text tid="signInUp.signIn"/>
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button color={'secondary'}
                                            fullWidth
                                            variant={'outlined'}
                                            onClick={() => {
                                                validateUserFromSocialProviders(
                                                    "google",
                                                    tokenAuthenticate
                                                );
                                            }}
                                            size={'large'} startIcon={
                                        <FontAwesomeIcon icon={faGoogle} color='secondary' size={"2x"}
                                                         />
                                    }><Text tid="signInUp.signGoogle"/></Button>
                                </Grid>
                                <Grid item xs={12} className={classes.error} styles={{textAlign: "center"}}>
                                    <p>{generalError}</p></Grid>
                            </Grid>
                            <Grid container spacing={3} justifyContent="center" alignItems={'center'}
                                  direction={'column'}>
                            </Grid>
                        </form>
                    }
                </div>
            </Container>
        </div>
    );
}

export default withRouter(SignIn);
