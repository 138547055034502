import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import {Hidden} from "@material-ui/core";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {withRouter} from "react-router-dom";


import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import Badge from "@material-ui/core/Badge";
import {useTheme, withStyles} from "@material-ui/core/styles";
import {grayColor, hexToRgb} from "./mainPageStyle";
import Tour from "reactour";
import {Text} from "../../providers/LanguageProvider";

import apiConfig from "../../../../api/apiConfig";
import {UserContext} from "../../providers/UserInfoContext";
import SignoutDialog from "../common/SignoutDialog";


export async function completeTour( ) {


  return apiConfig
      .patch(`/users/me/tour/complete` )

}


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    // marginTop: theme.spacing(1),
    color: "white",

    textAlign: "center",
  },
  fontColorTypography: {
    color: "white",

  },
  shapeContainer: {
    marginLeft: "33%",
    // marginTop: theme.spacing(2)
  },
  containerLongRidingName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  longRidingName: {
    color: "white",

    minHeight: "fit-content",
    textAlign: "center",
  },
  item: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const UserInfo = withRouter((props) => {

  const [openConfirmationMsg, setConfirmationMsg] = useState(false)
  const theme = useTheme()


  const {user, userChange} = useContext(UserContext);
  const setTourComplete = async (e) => {
      await completeTour().then(res=>{
        userChange(res.data)
      }).finally(()=>{

      })
  }

  const handleClosingOpenConfirmation = (signOut)=>{
    setConfirmationMsg(false)
    if(signOut){
      props.history.push({ pathname: "/signOut" });
    }

  }
  const steps = [
    {
      selector: ".settings",
      content: <Text tid="welcome.languageInstructions" />,
    },
    {
      selector: ".signOutLogo",
      content: <Text tid="welcome.singOutInstructions" />,
    },
  ];



  return (
    <div>
      {/*<Hidden smDown>*/}

      {/*    <Tour*/}
      {/*      steps={steps}*/}
      {/*      isOpen={user && !user.tourComplete || false}*/}
      {/*      onRequestClose={  setTourComplete}*/}
      {/*      rounded={10}*/}
      {/*    />*/}

      {/*</Hidden>*/}

      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              // eslint-disable-next-line
              alt={user?.firstName}
              src={user?.photoUrl}

              style={{ height: 40, width: 40 }}
            >
              <img
                  className="MuiAvatar-img"
                  src={user?.photoUrl}
                  referrerPolicy={'no-referrer'}
               alt={user?.firstName}/>
            </Avatar>
          </StyledBadge>
        </ListItemAvatar>
        <ListItemText
          style={{ color: "white" }}
          primary={
            user ? user.firstName + " " + user.lastName : null
          }
          secondary={
            <React.Fragment>
              {/*<CSSTransition nodeRef={nodeRef}>*/}
              {/*<Tooltip title="Profile" className={"profile"}>*/}
              {/*  <IconButton*/}
              {/*    className={"settings"}*/}
              {/*    size="small"*/}
              {/*    aria-label="profile"*/}
              {/*    onClick={() => {*/}
              {/*      props.history.push({*/}
              {/*        pathname: "/account",*/}
              {/*        state: user ? user : null,*/}
              {/*      });*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <PersonIcon style={{ color: "white" }} />*/}
              {/*  </IconButton>*/}
              {/*</Tooltip>*/}
              <Tooltip title="Sign out" className={"signOut"}>
                <IconButton
                  size="small"
                  aria-label="sing out"
                  onClick={() => {
                    setConfirmationMsg(true)
                    // props.history.push({ pathname: "/signOut" });
                  }}
                >
                  <ExitToAppIcon
                    className={"signOutLogo"}
                    style={{ color: "white" }}
                  />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider
        variant={"middle"}
        style={{ backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)" }}
      />
      <SignoutDialog
          open={openConfirmationMsg}
          onConfirm={handleClosingOpenConfirmation}
          handleClose={(e)=>setConfirmationMsg(false)}
      />
    </div>
  );
});

UserInfo.propTypes = {
  className: PropTypes.string,
};

export default UserInfo;
