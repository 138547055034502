import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';


const styles = (theme) => ({
    root: {
        color: theme.palette.common.white,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            height: '70vh',
            minHeight: 650,
            maxHeight: 1300,
            width:"100vw"
        },
    },
    sectionAbout:{
        color: theme.palette.common.white,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            height: '80vh',
            minHeight: 650,
            maxHeight: 1300,
            width:"100vw"
        },
        [theme.breakpoints.down('sm')]: {
            height: '80vh',
            minHeight: 650,
            maxHeight: 1300,
            width:"100vw"
        },

    },
    container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(14),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    backdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.5,
        zIndex: -1,
    },
    background: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: -2,
        // marginTop: 80
    },
    background2: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: -2,
        // marginTop: 80
    },
    // backgroundMobile: {
    //     position: 'absolute',
    //     left: 0,
    //     right: 0,
    //     top: 0,
    //     bottom: 0,
    //     // backgroundSize: 'cover',
    //     // backgroundRepeat: 'no-repeat',
    //     zIndex: -2,
    //     // marginTop: 80
    // },
    arrowDown: {
        position: 'absolute',
        bottom: theme.spacing(1),
        backgroundColor:"#6dff3300",
        // backgroundColor:"#ff3366",
        // left:theme.spacing(115),
        marginLeft: "49%",

    },
});

function ProductHeroLayout(props) {
    const { backgroundClassName, children, classes } = props;

    return (
        <section className={props.about ?classes.sectionAbout : classes.root}>
            {/*<Container className={classes.container}>*/}
                {children}
                {/*<div classsName={classes.backdrop} />*/}
            {/*backgroundMobile*/}
                <div className={clsx(classes.background, backgroundClassName)} />

            {/*</Container>*/}
        </section>
    );
}

ProductHeroLayout.propTypes = {
    backgroundClassName: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHeroLayout);