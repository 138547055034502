import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";


ProvincialFlag.propTypes = {
    size: PropTypes.number,
    provinceId: PropTypes.string.isRequired,


};

export function getFlagSVG(provinceId, size) {
    if (size !== undefined) {

        return import(`../../../../flags/${provinceId}_${size}.svg`).catch(() => {
            return import(`../../../../flags/${provinceId}.svg`)

        })
    } else {
        return import(`../../../../flags/${provinceId}.svg`);

    }

}

export default function ProvincialFlag(props) {


    const [svg, setSvg] = useState('')
    const [size, setSize] = useState(props.size || 160)

    useEffect(() => {

        if (props.provinceId) {
            getFlagSVG(props.provinceId, size).then((module) => {

                setSvg(module)

            }).catch(() => {


            })

        }


    }, [props.provinceId, size])


    return (
        (svg ? (
            <img src={svg.default} alt={props.provinceId} width={size}/>
        ) : (
            <div></div>
        ))
    )

}