import {useGridSlotComponentProps} from "@material-ui/data-grid";
import {makeStyles} from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";

export default function CustomPagination() {
    const {state, apiRef} = useGridSlotComponentProps();
    const useStyles = makeStyles({
        root: {
            display: 'flex',
        },
    });

    const classes = useStyles();

    return (
        <Pagination
            className={classes.root}
            color="primary"
            count={state.pagination.pageCount}
            page={state.pagination.page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}
