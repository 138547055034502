import React, {useContext, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {colors, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import {KeyboardArrowLeft} from "@material-ui/icons";
import CardMedia from "@material-ui/core/CardMedia";
import Image from "../../general/Image";
import BookMarkCard from "./BookMarkCard";
import CardCategoryLink from "./CardCategoryLink";
import "font-awesome/css/font-awesome.min.css";
import test from "./mind-map.svg";
import infromativeIcon from "./informativeIcon.svg";
import {LanguageContext, Text} from "../../providers/LanguageProvider";
import {assignIconBasedOnService, get_intl_lang} from "../utils/commonUsedFunctions";


import useApi from "../../../../hooks/useApi";
import metadataApi from "../../../../api/metadata";
import usersApi from "../../../../api/users";
import userapi from "../../../../api/users";
import {UserContext} from "../../providers/UserInfoContext";

const useStyles = makeStyles((theme) => ({
    root: {
        border: "none",
        boxShadow: "none",
        // flexGrow: 1,
        // height:"100%",
        // width:"100%"
    },
    googleMapStyle: {
        margin: "auto",
    },

    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: "#eeeeee",
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        width: "100%",
        height: "100%",
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        minWidth: 200,
        borderRadius: "10px",
        // border: '3px solid black',
        // boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        // boxShadow: "0 19px 60px rgba(0,0,0,0.90)",

        // border: '3px solid black',
        // transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
        // "&:hover": {
        //     zIndex: 6,
        //     border: '3px solid black',
        //     borderRadius: "15px",
        //     boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
        //     transform: 'scale(1.04)',
        //
        // },
    },
    cardMedia: {
        paddingTop: "56.25%", // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    avatar: {
        backgroundColor: "#0191d5",
    },
    gridItemStyling: {
        maxWidth: 300,
        height: 150,
        textAlign: "center",
        borderRadius: "10px",
        // border: '3px solid black',
        // boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        // boxShadow: "0 19px 60px rgba(0,0,0,0.90)",

        // border: '3px solid black',
        transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
        "&:hover": {
            zIndex: 6,
            border: "3px solid black",
            borderRadius: "10px",
            boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
            transform: "scale(1.04)",
        },
    },

    test: {
        overflow: "hidden",
        width: "100%",
        height: "100%",
    },
    btn: {
        "&:hover": {
            backgroundColor: "white",
        },
        backgroundColor: "white",
        maxWidth: 165,
        height: "100%",
        // padding:0,
        // margin: 0
    },

    active: {
        padding: 0,
        margin: 0,
        backgroundColor: "white",
        width: 160,
        height: "100%",
        zIndex: 100,
        border: "3px solid #0191d5",
        borderRadius: "10px",
        transition: theme.transitions.create("z-index"),
    },
    button: {
        minWidth: 80,
        backgroundColor: "#298",
        textTransform: "none",

        borderRadius: "10px",
    },
    cardProduct: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        borderRadius: 0,
        boxShadow: "none",
        background: "transparent",
        "& .card-product__content": {
            padding: theme.spacing(2, 2, 0, 2),
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(0, 0, 2, 2),
            },
        },
        "& .card-product__media": {
            [theme.breakpoints.up("sm")]: {
                height: 170,
                width: 170,
                "& img": {
                    height: 170,
                    width: 170,
                },
            },
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column !important",
            "& .card-product__content": {
                flex: "1 1 100%",
            },
            "& .card-product__media": {
                flex: "1 1 100%",
                width: "100%",
            },
        },
    },
    blogContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        paddingLeft: theme.spacing(2),
    },
    blogTitle: {
        fontWeight: 700,
    },
    author: {
        margin: theme.spacing(1, 0),
    },
    title: {
        fontWeight: "bold",
    },
    image: {
        backgroundColor: "#eeeeee",
        [theme.breakpoints.down("sm")]: {
            maxWidth: 500,
        },
    },
}));

export default function Bookmarks(props) {
    const classes = useStyles();
    const [viewAll, setViewAll] = useState(false);
    const [category, setCategory] = useState(null);
    const getCategories = useApi(metadataApi.getCategories)
    const getUserBookmarks = useApi(usersApi.getUserBookmarks)
    const {user, updateUserBookmarks, findOrgBookmark} = useContext(UserContext);
    const { userLanguage } = useContext(LanguageContext);
    function createCategoryCardBasedOnService(category) {

        if (!category) {
            return
        }
        const total = getBookmarksForcategory(category).length
        const serviceData = assignIconBasedOnService(category.title);
        const name = get_intl_lang(userLanguage, category.intlMetadata)?.name || category.name;
        return (

            <CardCategoryLink

                onClick={() => handleGridItemClicked(category)}
                variant="outlined"
                align={"center"}
                liftUp
                service={category}
                title={name}
                subtitle={''}
                total={total}
                href="#"
                fontIconClass={serviceData.icon}
                color={serviceData.color}
            />

        );
    }

    const deleteEvent = (bm) => {
        console.log(bm)
        // metadataApi.deleteUserBookmark(geoService.id).then((res) => {
        //     let updatedBookmarks = getUserBookmarks.data?.filter(b => b.id !== geoService.id)
        //     getUserBookmarks.setData(updatedBookmarks);
        // })
        //
        userapi.removeBookmark(bm.id).then((res)=>{
            console.log(res.data)
            updateUserBookmarks(res.data)
            //setIsBookmarked(false)

        }).finally(()=>{
           // setLoading(false)
        })
    };

    const handleViewAll = async (e) => {
        setViewAll(true)
        setCategory(null)
    }

    const handleGridItemClicked = (category) => {
        setCategory(category);
        setViewAll(false);
    };

    useEffect(() => {
        getCategories.request()

    }, []);

    const handleBack = () => {
        setCategory(null)
        setViewAll(false)
    };

    const getBookmarksForcategory = (category) => {
        return user?.bookmarks.filter(bookmark =>  bookmark.organization.organizationServices.find(os=>os.service.parentCategoryId===category.id) ) || []
    }

    const getUserBookmark = (category) => {
        if (category && user?.bookmarks.length > 0) {
            const foundBookmarks = user.bookmarks.filter(bookmark => bookmark.organization.organizationServices.find(os=>os.service.parentCategoryId===category.id) )

                return foundBookmarks?.map(bookmark => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}

                            className={classes.gridItem}
                        >
                            <BookMarkCard
                                className={classes.cardProduct}
                                changeSize={true}
                                userBookmark={bookmark}
                                deleteEvent={deleteEvent}
                            />
                        </Grid>
                    )
                })

        }

        return (

            <Grid
                container
                alignItems={"center"}
                justify={"center"}
            >
                <Card style={{justifyContent: "center"}}>
                    <Grid
                        container
                        direction={"column"}
                        alignItems={"center"}
                        justify={"center"}
                    >
                        <CardMedia
                            component="svg"
                            alt="Error"
                            height="300"
                            image={infromativeIcon}
                        />
                        <CardContent>
                            <Typography
                                variant={"h5"}
                                align={"center"}
                                style={{padding: "20px"}}
                            >
                                {<Text tid="bookmarks.noBookmarks"/>}
                            </Typography>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>


        )

    }

    return (
        <div>
            <div className={classes.heroContent}>
                <Container maxWidth={"lg"}>
                    <Grid container justify="space-between" spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                component="h1"
                                variant="h2"
                                align="left"
                                color="textPrimary"
                                gutterBottom
                                style={{color: "#00000096"}}
                            >
                                <Text tid="bookmarks.title"/>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container

                            alignItems="center"
                            xs={12}
                            md={6}
                        >
                            <Image
                                src={test}
                                alt="TheFront Company"
                                className={classes.image}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container className={classes.cardGrid} maxWidth="xl">
                <Grid container direction={"row"} justify={"center"} spacing={1}>
                    {!category && !viewAll && (
                        getCategories.data?.map((category, i) => {
                            if (!category.parentCategoryId) {
                                return (
                                    <Grid
                                        id={i}
                                        key={i}
                                        item
                                        container
                                        alignItems="center"
                                        direction="column"
                                        xs={12}
                                        sm={6}
                                        md={3}
                                    >
                                        {createCategoryCardBasedOnService(category)}
                                    </Grid>
                                );
                            }
                        })
                    )}

                    {(category || viewAll) ? (
                        <div style={{width: "100%", height: "100%"}}>
                            <Typography variant={'h2'}>
                                {category?.name}
                            </Typography>
                            <Grid
                                container
                                spacing={3}
                                style={{display: "contents"}}
                            >
                                <Grid item>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        size="large"
                                        className={classes.button}
                                        component="a"
                                        onClick={handleBack}
                                    >
                                        <KeyboardArrowLeft/>
                                        <Text tid="bookmarks.back"/>
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Grid container spacing={3}>

                                        {category &&
                                            getUserBookmark(category)
                                        }


                                        {viewAll && (


                                            user?.bookmarks.length > 0 ? (
                                                user.bookmarks.map((bookmark, index) => {

                                                    return (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={6}
                                                            lg={4}
                                                            key={index}
                                                            // data-aos="fade-up"
                                                            className={classes.gridItem}
                                                        >
                                                            <BookMarkCard
                                                                className={classes.cardProduct}

                                                                changeSize={true}

                                                                userBookmark={bookmark}

                                                                deleteEvent={deleteEvent}
                                                                id={index}
                                                            />


                                                        </Grid>
                                                    )
                                                })
                                            ) : (<Grid
                                                container
                                                alignItems={"center"}
                                                justify={"center"}
                                            >
                                                <Card style={{justifyContent: "center"}}>
                                                    <Grid
                                                        container
                                                        direction={"column"}
                                                        alignItems={"center"}
                                                        justify={"center"}
                                                    >
                                                        <CardMedia
                                                            component="svg"
                                                            alt="Error"
                                                            height="300"
                                                            image={infromativeIcon}
                                                        />
                                                        <CardContent>
                                                            <Typography
                                                                variant={"h5"}
                                                                align={"center"}
                                                                style={{padding: "20px"}}
                                                            >
                                                                {<Text tid="bookmarks.noBookmarks"/>}
                                                            </Typography>
                                                        </CardContent>
                                                    </Grid>
                                                </Card>
                                            </Grid>)


                                        )}


                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    ) : null}
                </Grid>


                {!(viewAll || category) ? (
                    <Grid

                        item
                        container
                        alignItems="center"
                        direction="column"
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <CardCategoryLink
                            value={"view_all"}
                            onClick={handleViewAll}
                            variant="outlined"
                            align={"center"}
                            liftUp
                            title={<Text tid="bookmarks.viewAll"/>}
                            subtitle={''}
                            href="#"
                            fontIconClass={"fa fa-list"}
                            color={colors.green}
                        />
                    </Grid>
                ) : null}

            </Container>
        </div>
    );
}

function checkUserBookmarksForGivenService(service, arr) {
    let result = false;

    arr.forEach(function (bookmark) {

        if (service.id === bookmark.id) {
            result = true;
        }
    });


    return result;
}
