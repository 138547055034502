import axios from "axios";
import TokenService from "../token.service";

import React from "react";

export const apiUrl = process.env.REACT_APP_API_URL || "http://host.docker.internal:3098"
const apiConfig = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
});


apiConfig.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            config.headers["Authorization"] = `Bearer ${token}`; // for Node.js Express back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiConfig.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;


        if (err?.response?.status === 401 && !originalConfig._retry && !(originalConfig.url === '/auth/refresh' || originalConfig.url === '/users/me/logout')) {

            originalConfig._retry = true;
            try {
                const rs = await apiConfig.post("/auth/refresh", {
                    access_token: TokenService.getLocalRefreshToken(),
                }).catch(e => {
                    window.location.pathname = '/welcome'
                })


                const tokens = rs.data;

                TokenService.updateLocalAccessToken(tokens.access_token);


                return apiConfig(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
        return Promise.reject(err);
    }
);


export default apiConfig;
