import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import Typography from "@material-ui/core/Typography";
import moment from "moment/moment";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {AccordionActions, Grid, Tab, Tabs} from "@material-ui/core";
import TabPanel from "./TabPanel";
import Button from "@material-ui/core/Button";
import SocialTasksTabContent from "./SocialTasksTabContent";
import Divider from "@material-ui/core/Divider";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import {LanguageContext} from "../../providers/LanguageProvider";
import {makeStyles, useTheme} from "@material-ui/core/styles";

import journeyApi from "../../../../api/journey";
import usersApi from "../../../../api/users";
import UndoTaskDialog from "./UndoTaskDialog";

import OrganizationContent from "./OrganizationContent";
import {JourneyContext} from "../../providers/JourneyProvider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchOrgsDialog from "./SearchOrgsDialog";
import TaskSummary from "./TaskSummary";
import LearnMoreDialog from "./LearnMoreDialog";
import SearchIcon from '@material-ui/icons/Search';
import {useSnackbar} from "notistack";
import TaskContent from "./TaskContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faLandmark, faList} from "@fortawesome/pro-solid-svg-icons";
import {UserContext, UserInfoContext} from "../../providers/UserInfoContext";

const useStyles = makeStyles((theme) => ({

    heading: {
        fontSize: theme.typography.pxToRem(17), flexBasis: '66.66%', flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(13),
        color: theme.palette.text.secondary,
        fontStyle: 'italic'
    },
    completionIcon: {


        display: 'flex',
        marginTop: 2,
        marginRight: 4
    },
    tab: {
        '&.Mui-selected': {

        },

    },

}));

LegalTaskAccordion.propTypes = {
    provincialTask: PropTypes.object.isRequired,

    provincialSection: PropTypes.object.isRequired,
};


function LegalTaskAccordion(props) {
    const theme = useTheme()
    const classes = useStyles(theme)


    const {
        getCompletedProvincialTaskById,
        userTimeZone,
        setCompletedSocialProvincialTasks,

        setCompletedProvincialTasks,
        setAvailableSocialTasks,
        isProvincialTaskComplete,
        getPreviousCompletedProvincialTask,
        journeyUserStatus,
        setJourneyUserStatus,
        completedProvincialTasks,
        provincialTasks
    } = useContext(JourneyContext)
    const {userLanguage, tt, textDirection} = useContext(LanguageContext);


    const [isButtonClickable, setIsButtonClickable] = useState(false)

    const [loadingButton, setLoadingButton] = useState(false)
    const [confirmUndoTaskDialogOpen, setConfirmUndoTaskDialogOpen] = useState(false)
    const [value, setValue] = React.useState(0)

    const [expanded, setExpanded] = React.useState(false); // only load org content when the accordion has been opened

    const [searchOrgs, setSearchOrgs] = useState(false)

    const [learnMoreDialogOpen, setLearnMoreDialogOpen] = React.useState(false)


    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {enqueueSnackbar} = useSnackbar();
    const completedProvincialTaskObj = useMemo(() => {

        return getCompletedProvincialTaskById(props.provincialTask.id)
    }, [getCompletedProvincialTaskById, props.provincialTask.id])

    const previousCompletedProvincialTask = useMemo(() => {

        return getPreviousCompletedProvincialTask(props.provincialTask.sequence)
    }, [getPreviousCompletedProvincialTask, props.provincialTask.sequence])


    const taskCategory = useMemo(() => {
        return props.provincialTask.task.categories.find(cat => cat.tagId === props.provincialSection.section.id)?.categoryId

    }, [props.provincialTask, props.provincialSection])

    const completedDateStr = useMemo(() => {
        if (completedProvincialTaskObj?.completedDate) {
            return moment.utc(completedProvincialTaskObj.completedDate).tz(userTimeZone).format('llll z').toString()
        }
        return '?'

    }, [completedProvincialTaskObj, userTimeZone])

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`, 'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    const isTaskComplete = useMemo(() => {

        return isProvincialTaskComplete(props.provincialTask.id)
    }, [props.provincialTask.id, isProvincialTaskComplete])



    const organizationContent = (
        <OrganizationContent
            provincialSection={props.provincialSection}
            serviceId={taskCategory}
            task={props.provincialTask}

        />
    )



    const socialTabContent = (
        <SocialTasksTabContent
            provincialSection={props.provincialSection}
            socialTasks={props.provincialTask.socialTasks}
            legalTask={props.provincialTask}
            isLegalTaskComplete={isTaskComplete}
        />

    )


    const taskTranslations = useMemo(() => {

        return get_intl_lang(userLanguage, props.provincialTask.task?.intlTask)
    }, [userLanguage, props.provincialTask])

    const label = useMemo(() => {
        return taskTranslations?.name || props.provincialTask.task.internalName || '?';

    }, [taskTranslations, props.provincialTask])

    const content = useMemo(() => {
        return taskTranslations?.content

    }, [taskTranslations])

    const description = useMemo(() => {

        return taskTranslations?.description

    }, [taskTranslations])

    useEffect(() => {

        setIsButtonClickable(previousCompletedProvincialTask == null && props.provincialTask.sequence !== 1)

    }, [props.provincialTask, previousCompletedProvincialTask])


    const handleTaskChangeButton = (e) => {
        e.stopPropagation()

        if (isTaskComplete) {
            setConfirmUndoTaskDialogOpen(true)

        } else {

            setLoadingButton(true)
            usersApi.completeUserTask(props.provincialTask.id).then(res => {
                if (res.status === 200) {
                    onTaskChange(res.data, 'complete')

                }

            }).catch(onTaskChangeFailure).finally(() => {
                setLoadingButton(false)
            })
        }


    }


    const handleChangeSubTab = useCallback((event, index) => {

        setValue(index)
    }, [])


    const onCloseDialogConfirm = () => {
        setConfirmUndoTaskDialogOpen(false)

    }


    const searchOrgsHandle = (e) => {

        setSearchOrgs(true)
    }


    const learnMore = (e) => {
        e.stopPropagation()
        setLearnMoreDialogOpen(true)

    }

    const onTaskChange = (responseData, type) => {

        setCompletedProvincialTasks(responseData.completedProvincialTasks || [])
        setAvailableSocialTasks(responseData.availableSocialTasks || [])
        setCompletedSocialProvincialTasks(responseData.completedSocialProvincialTasks || [])

        // did the legal status change?
        if (journeyUserStatus !== null && journeyUserStatus.legalStatusId !== responseData.status.legalStatusId) {
            setJourneyUserStatus(responseData.status)
            enqueueSnackbar(tt('journey.statusChangeSnack.generalLabel'), {variant: 'warning'})
        }

        // are we done the journey?
        if (type === 'complete' && completedProvincialTasks.length + 1 === provincialTasks.length) {
            enqueueSnackbar(tt('journey.journeyCompleteSnack.label'), {
                variant: 'success',
                anchorOrigin: {vertical: "top", horizontal: 'center'}
            })

        }

        setConfirmUndoTaskDialogOpen(false)


    }

    const onTaskChangeFailure = (reason) => {

        enqueueSnackbar(tt('journey.tasks.taskChangeSnack.onError.label'), {
            variant: 'error',
            autoHideDuration: 3000
        })
        setConfirmUndoTaskDialogOpen(false)
    }


    return (

        <Accordion square
            // defaultExpanded={lastCompletedTask && lastCompletedTask.provincialTaskId === props.provincialTask.id}
                   expanded={expanded}
                   TransitionProps={{unmountOnExit: true}}
                   onChange={(e) => setExpanded(!expanded)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-label="Expand"
                aria-controls={`additional-actions1-content-${props.provincialTask.id}`}
                id={`additional-actions1-header-${props.provincialTask.id}`}
                key={props.provincialTask.id.toString()}
            >
                {isTaskComplete ?
                    <FontAwesomeIcon icon={faCircleCheck} style={{color: theme.palette.success.main}} size={'lg'}
                                     className={classes.completionIcon}/> :
                    <AssignmentLateOutlinedIcon color={'warning'}/>}
                <Typography className={classes.heading}

                            {...(textDirection === "rtl" && {align: 'left'})}
                            marginLeft={2}>
                    {label}
                </Typography>
                <Typography


                    className={classes.secondaryHeading}
                    sx={{color: 'textSecondary'}}> {isTaskComplete && completedDateStr}</Typography>

            </AccordionSummary>
            <AccordionDetails>


                <Grid container spacing={0} key={`grid-container-${props.provincialTask.id}`}>


                    <TaskContent description={description} content={content} onLearnMoreClick={learnMore}/>


                    <Grid item xs={12}>


                        <Tabs
                            value={value}
                            onChange={handleChangeSubTab}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                        >
                            <Tab
                                value={0}
                                className={classes.tab}
                                icon={<FontAwesomeIcon icon={faLandmark}/>}
                                iconposition={"top"}
                                label={tt('journey.tasks.tabs.organizations.label')}
                                {...a11yProps(`org-tab-${props.provincialTask.id}`)}
                            />
                            <Tab
                                value={1}
                                className={classes.tab}
                                icon={<FontAwesomeIcon icon={faList}/>}
                                iconposition={"top"}
                                label={tt('journey.tasks.tabs.socialTasks.label')}
                                {...a11yProps(`soc-tab-${props.provincialTask.id}`)}
                            />
                        </Tabs>
                        <TabPanel value={value} index={0}>

                            {!mobile ? (
                                organizationContent
                            ) : (
                                <Button variant={'contained'} startIcon={<SearchIcon/>} fullWidth size={'large'}
                                        onClick={searchOrgsHandle}>{tt('journey.tasks.legal.tabs.org.searchOrgButtonLabel')}</Button>
                            )}

                        </TabPanel>
                        <TabPanel value={value} index={1}>

                            {socialTabContent}
                        </TabPanel>


                    </Grid>
                </Grid>

            </AccordionDetails>
            <Divider/>
            <AccordionActions>
                <Button disabled={isButtonClickable || loadingButton}
                        variant="contained"
                        fullWidth


                        {...(isTaskComplete ? {color: "secondary"} : {color: "primary"})}

                        onClick={handleTaskChangeButton}>
                    <Typography
                        variant={'h6'}>  {isTaskComplete ? tt('journey.tasks.undoTask.undoTaskButtonLabel') : tt('journey.tasks.completeTask.completeTaskButtonLabel')} </Typography>
                </Button>
            </AccordionActions>

            <UndoTaskDialog task={props.provincialTask}
                            open={confirmUndoTaskDialogOpen}
                            title={label}
                            onClose={onCloseDialogConfirm}
                            onFail={onTaskChangeFailure}
                            onSuccess={onTaskChange}/>


            <SearchOrgsDialog
                open={searchOrgs}
                onClose={(e) => setSearchOrgs(false)}
                taskName={label}
            >
                {organizationContent}
            </SearchOrgsDialog>
            <LearnMoreDialog open={learnMoreDialogOpen} handleClose={(e) => setLearnMoreDialogOpen(false)}
                             title={label}
                             closeButtonLabel={tt('journey.tasks.learnMore.dialog.learnMoreCloseButtonLabel')}>
                <TaskSummary content={content || ''}/>
            </LearnMoreDialog>

        </Accordion>

    )

}

function isEqu(prev, next) {

    return prev.provincialTask.id === next.provincialTask.id && prev.provincialSection.id === next.provincialSection.id;
}

export default React.memo(LegalTaskAccordion, isEqu)