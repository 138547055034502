import React, {useContext, useEffect, useMemo, useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MapInfoWindow from "./MapInfoWindow";
import OrgCard from "../journey/OrgCard";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {responsiveFontSizes} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Avatar from "@material-ui/core/Avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faBookmarkSlash, faLocationDot} from "@fortawesome/pro-solid-svg-icons";
import Button from "@material-ui/core/Button";
import SchoolIcon from "@material-ui/icons/School";
import {LanguageContext} from "../../providers/LanguageProvider";
import Grid from "@material-ui/core/Grid";
import {UserContext} from "../../providers/UserInfoContext";
import IconButton from '@material-ui/core/IconButton';
import useBookmark from "../../../../hooks/useBookmark";
import MoreVertIcon from "@material-ui/icons/MoreVert";
const useStyles = makeStyles((theme) => ({

    root:{

        position: "absolute",
        zIndex: 9021
    },
    iconBar: {


    },
    avatar: {
        backgroundColor: "#289",
        width: 40,
        height: 40,
    },
    orgAvatar: {
        backgroundColor: "#fff",
        width: 40,
        height: 40,
    },
    toolbar:  (props)=> ({
        position: "relative",





    }),
    btn: {
        width: "98%"
    },
    bookmarkButton: {
        position: "absolute",
        left: -20
    }
}));

OrganizationInfoWindow.propTypes = {
    organizationResult: PropTypes.object.isRequired,
    onLearnMoreClick: PropTypes.func

}

export default function OrganizationInfoWindow(props) {
    const {organizationResult, onLearnMoreClick, ...rest} = props
    const {user} = useContext(UserContext);
    const theme = useTheme()
    const classes = useStyles( );
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const {  processing,  addBookmark, removeBookmark, findOrgBookmark} = useBookmark()
    const organization = useMemo(()=>{

        return organizationResult?.organization

    }, [organizationResult])


    const [isBookmarked, setIsBookmarked] = useState(false)

    const windowTheme = useMemo(() => {


        return responsiveFontSizes(theme);

    }, [theme])

    const { tt  } = useContext(LanguageContext);

    const distance = useMemo(()=>{

        return organizationResult?.distance

    }, [organizationResult])

    const coordinates = useMemo(()=>{

        if(organization){

            return { lat: organization.address?.lat, lng: organization.address?.lng }
        }

    }, [organization])

    useEffect(()=>{
        const bm = findOrgBookmark(organization.id);
        if(bm){

            setIsBookmarked(true)

        } else {
            setIsBookmarked(false)
        }

    }, [organization, findOrgBookmark])


    const handleBookmark = ()=>{
        const bm = findOrgBookmark(organization.id)
        if(bm){
            removeBookmark(bm.id)
        } else {
            addBookmark(organization.id)

        }
    }


    return (
        (coordinates ?
            <ThemeProvider theme={windowTheme}>
                <MapInfoWindow
                    position={coordinates}


                    {...rest}
                >


                    <OrgCard
                        organization={organization}
                        showContent={desktop}
                        preContent={<>
                            {desktop &&
                                <div className={classes.bookmarkButton}>
                                    {isBookmarked ?
                                            <IconButton
                                                aria-controls="customized-menu"
                                                aria-haspopup="true"
                                                variant="contained"
                                                color={'secondary'}
                                                onClick={handleBookmark}
                                            >
                                                <FontAwesomeIcon icon={faBookmarkSlash}/>
                                            </IconButton>
                                            :
                                            <IconButton
                                                aria-controls="customized-menu"
                                                aria-haspopup="true"
                                                variant="contained"
                                                color={'primary'}
                                                onClick={handleBookmark}
                                            >
                                                <FontAwesomeIcon icon={faBookmark}/>
                                            </IconButton>
                                    }
                                </div>
                            }
                        </>}
                        avatar={
                        <Avatar aria-label="recipe" className={classes.orgAvatar}>
                            <FontAwesomeIcon icon={faLocationDot} style={{color: "#ff4b5c", width: "100%", height: "100%"}} />
                        </Avatar>}
                    />
                    {!desktop &&

                        <Grid
                            container

                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <Grid item  xs>
                                <Button
                                    className={classes.btn}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="small"
                                    onClick={onLearnMoreClick}
                                    startIcon={<SchoolIcon/>}
                                >
                                    {tt("explore.orgCard.learnMore.label")}
                                </Button>
                            </Grid>
                            {user &&

                                <>

                                    <Grid item  xs>
                                        {isBookmarked ?
                                            <Button
                                                className={classes.btn}
                                                variant="contained"
                                                color="secondary"
                                                fullWidth
                                                size="small"
                                                onClick={handleBookmark}
                                                startIcon={<FontAwesomeIcon icon={faBookmarkSlash}/>}
                                            >
                                                {tt("explore.orgCard.bookmark.remove")}
                                            </Button>
                                            :
                                            <Button
                                                className={classes.btn}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                size="small"
                                                onClick={handleBookmark}
                                                startIcon={<FontAwesomeIcon icon={faBookmark}/>}
                                            >
                                                {tt("explore.orgCard.bookmark.add")}
                                            </Button>
                                        }
                                    </Grid>
                                </>
                            }
                        </Grid>
                    }

                </MapInfoWindow>

            </ThemeProvider>
          : null
    ))


}