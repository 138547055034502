import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {arSD, DataGrid, enUS, esES, frFR} from "@material-ui/data-grid";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {enUS as coreEnUS, esES as coreEsES, frFR as coreFrFR} from "@material-ui/core/locale";
import {createTheme} from "@material-ui/core";
import {LanguageContext} from "../../providers/LanguageProvider";
import {useTheme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/styles";
import PropTypes from "prop-types";
import CustomPagination from "../journey/orgUtils/CustomPagination";


const useStyles = makeStyles(
    (theme) => ({
        root: {
            border: 0,
            color:
                theme.palette.type === 'light'
                    ? 'rgba(0,0,0,.85)'
                    : 'rgba(255,255,255,0.85)',

            WebkitFontSmoothing: 'auto',
            letterSpacing: 'normal',
            '& .MuiDataGrid-columnsContainer': {
                backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
            },
            '& .MuiDataGrid-iconSeparator': {
                display: 'none',
            },
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                borderRight: `1px solid ${
                    theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
            },
            '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                borderBottom: `1px solid ${
                    theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                }`,
            },
            '& .MuiDataGrid-cell': {
                color:
                    theme.palette.type === 'light'
                        ? 'rgba(0,0,0,.85)'
                        : 'rgba(255,255,255,0.65)',
            },
            '& .MuiPaginationItem-root': {
                borderRadius: 0,
            },

        },
    })
);


OrgDataGrid.propTypes = {
    organizationsResults: PropTypes.array,

    onSelectOrganization: PropTypes.func.isRequired

}
OrgDataGrid.defaultProps = {
    organizationsResults:  [],


}
export default function OrgDataGrid (props) {
    const {organizationsResults, onSelectOrganization, ...rest} = props
    const {tt, userLanguage,  userLanguageISO639} = useContext(LanguageContext);
    const theme = useTheme()

    const classes = useStyles(theme);
    const [selectionModel,  ] = React.useState([]);
    const dataGridLang = {
        "en": [enUS, coreEnUS],
        "fr": [frFR, coreFrFR],
        "ar": [arSD],
        "es": [esES, coreEsES]
    }

    useEffect(() => {

        let cols = [
            {
                field: "name",

                renderHeader: (params)=>{
                    return <strong>{tt('journey.tasks.orgFinder.gridColumns.organizationLabel')}</strong>
                },
                flex: 1,
                renderCell: (params) => {
                    return params.row.organization.nameFre || params.row.organization.name
                }
            },
            {
                type: 'number',
                field: "distance",
                renderHeader: (params) => {
                    return <strong>{tt('journey.tasks.orgFinder.gridColumns.distanceLabel')}</strong>
                },

                width: 140,
                valueFormatter: (params) => {
                    if (params.row.distance == null) {
                        return '-';
                    }
                    const valueFormatted = Number(params.row.distance).toFixed(1).toLocaleString(userLanguageISO639)
                    return `${valueFormatted} km`;
                },
            },
        ]

        setOrgTableState((prev) => ({...prev, cols: cols}))

    }, [tt, userLanguageISO639])

    const [orgTableState, setOrgTableState] = React.useState({
        page: 0,
        rows: [],
        count: 0,
        cols: [],

    });

    useEffect(()=>{
        setOrgTableState(prevState => ({...prevState,
            rows: organizationsResults,
            count: organizationsResults.length
        }))

    }, [organizationsResults])

    const [selectedRow, setSelectedRow] = useState(null)

    const dataGridTheme = useMemo(() => {
        let localizations = dataGridLang[userLanguage] ?? [enUS, coreEnUS]

        return createTheme(
            {
                palette: {
                    primary: {main: theme.palette.primary.main},
                    secondary: {main: theme.palette.secondary.main}
                },

            },

            ...localizations
        )

    }, [userLanguage, dataGridLang, theme])

    const defaultComponents = {


        Pagination: CustomPagination,

    }


    return (

        <ThemeProvider theme={dataGridTheme}>
            <DataGrid
                {...rest}
                className={classes.root}
                rows={orgTableState.rows}
                columns={orgTableState.cols}
                pagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                rowCount={orgTableState.count || 0}
                onPageChange={(p) => {
                    setOrgTableState((prev) => ({...prev, page: p}))
                }}
                onPageSizeChange={(pageSize) =>
                    setOrgTableState((prev) => ({...prev, pageSize}))
                }
                loading={false}
                components={defaultComponents}
                onRowClick={(newSelectionModel) => {
                    setSelectedRow(newSelectionModel.row)
                    props.onSelectOrganization(newSelectionModel.row.organization)
                }}
                selectionModel={selectionModel}
            />
        </ThemeProvider>

    )

}