import apiConfig from "./apiConfig";
import qs from "qs";


const queryServiceRangev2 = (lat, lng, range, params) => {


    return apiConfig.get(`/orgs/search/lat/${lat}/lng/${lng}/range/${range}`, {
        headers: {

            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 0
        },
        params: params,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
    })
}


const organizationsQuery = (page, pageSize, sort) => apiConfig.get('/orgs', {
    params: {
        'sort': sort,
        'pageSize': pageSize,
        'page': page
    }
})

export default {

    queryServiceRangev2,
    organizationsQuery

};
