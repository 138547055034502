import React, {useContext, useEffect, useMemo, useState} from "react";

import useApi from "../../../../hooks/useApi";
import organizationApi from "../../../../api/organizations";

import {arSD, DataGrid, enUS, esES, frFR,} from "@material-ui/data-grid";
import {enUS as coreEnUS, esES as coreEsES, frFR as coreFrFR} from "@material-ui/core/locale";


import {LanguageContext} from "../../providers/LanguageProvider";
import {createTheme} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";

import ThemeProvider from "@material-ui/styles/ThemeProvider";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import CustomToolbar from "./orgUtils/CustomToolbar";
import CustomLoadingOverlay from "./orgUtils/CustomLoadingOverlay";
import CustomPagination from "./orgUtils/CustomPagination";
import OrgNoRowsOverlay from "./orgUtils/OrgNoRowsOverlay";
import {useTheme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProminentSlider from "./ProminentSlider";


const useStyles = makeStyles(theme=>({
    headerCell: {
        backgroundColor: '#def2ff',
        color: 'white',
        fontWeight: 'bold',
    },

}));

OrganizationFinderTool.propTypes = {
    legalStatus: PropTypes.string.isRequired,
    serviceId: PropTypes.number,
    taskId: PropTypes.number.isRequired,
    onSelectOrganization: PropTypes.func.isRequired,
    coordinates: PropTypes.object.isRequired,
    userAddress: PropTypes.string,
    density: PropTypes.string,

};
OrganizationFinderTool.defaultProps = {
    density: 'compact'

}
function OrganizationFinderTool(props) {

    const theme = useTheme()

    const classes = useStyles(theme);
    const [selectionModel ] = React.useState([]);
    const {tt,   userLanguageISO639} = useContext(LanguageContext);

    const orgRange = useApi(organizationApi.queryServiceRangev2)
    const [searchRange, setSearchRange] = useState(10)
    const [selectedRow, setSelectedRow] = useState(null)
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedCoordinates, setSelectedCoordinates] = useState(null)
    const [orgTableState, setOrgTableState] = React.useState({
        page: 0,
        pageSize: 50,
        rows: [],
        count: 0,
        cols: [],

    });

    const dataGridLang = {
        "en": [enUS, coreEnUS],
        "fr": [frFR, coreFrFR],
        "ar": [arSD],
        "es": [esES, coreEsES]
    }

    const dataGridTheme = useMemo(() => {
        let localizations = dataGridLang[userLanguageISO639] ?? dataGridLang['en']

        return createTheme(
            {
                palette: {
                    primary: {main: theme.palette.primary.main},
                    secondary: {main: theme.palette.secondary.main}
                },

            },

            ...localizations
        )

    }, [userLanguageISO639, dataGridLang])

    useEffect(() => {

        let cols = [
            {
                field: "name",

                renderHeader: (params)=>{
                  return <strong>{tt('journey.tasks.orgFinder.gridColumns.organizationLabel')}</strong>
                },
                width: 240,
                renderCell: (params) => {
                    return params.row.organization.nameFre || params.row.organization.name
                }
            },
            {
                type: 'number',
                field: "distance",
                renderHeader: (params)=>{
                    return <strong>{tt('journey.tasks.orgFinder.gridColumns.distanceLabel')}</strong>
                },

                width: 140,
                valueFormatter: (params) => {
                    if (params.row.distance == null) {
                        return '-';
                    }
                    const valueFormatted = Number(params.row.distance).toFixed(1).toLocaleString(userLanguageISO639)
                    return `${valueFormatted} km`;
                },
            },

            {
                field: "website",
                renderHeader: (params) => {
                    return <strong>{tt('journey.tasks.orgFinder.gridColumns.websiteLabel')}</strong>
                },
                width: 140,
                renderCell: (params) => {
                    return params.row.organization.website || '-'
                }
            },
            {
                field: "email",
                renderHeader: (params) =>{
                    return <strong>{tt('journey.tasks.orgFinder.gridColumns.emailLabel')}</strong>
                },

                width: 180,
                renderCell: (params) => {
                    return params.row.organization.email || '-'
                }
            },
        ]

        setOrgTableState((prev) => ({...prev, cols: cols}))

    }, [ tt])

    useEffect(() => {

        if (orgRange.data) {
            setOrgTableState(prevState => ({
                ...prevState,
                count: orgRange.data?.length || 0,
                rows: orgRange.data || []
            }))

        }

    }, [orgRange.data])

    useEffect(() => {



        setOrgTableState((prev) => ({...prev, loading: true}));

        let params = {
            tags: [props.legalStatus]
        }

        if (props.serviceId) {
            params['serviceIds'] = props.serviceId
        }

        orgRange.request(props.coordinates.lat, props.coordinates.lng, searchRange, params).finally(() => {

            setOrgTableState((prev) => ({...prev, loading: false}));
        })


    }, [props.legalStatus, props.serviceId, searchRange, props.coordinates]);


    useEffect(() => {

        if (selectedRow && selectedRow.organization.address.lat && selectedRow.organization.address.lng) {
            setSelectedCoordinates({
                lat: selectedRow.organization.address?.lat,
                lng: selectedRow.organization.address?.lng,
            })
        }


    }, [selectedRow])

    const handleSliderChange = (event, newValue) => {
        // the slider initializes the search function

        setSearchRange(newValue)

    };

    const handleInputChange = (event) => {
        setSearchRange(event.target.value === '' ? '' : Number(event.target.value));
    };


    const handleBlur = () => {
        if (searchRange < 0) {
            searchRange(10);
        } else if (searchRange > 100) {
            searchRange(100);
        }
    };

    const defaultComponents = {

        LoadingOverlay: CustomLoadingOverlay,
        Pagination: CustomPagination,
        NoRowsOverlay: () => {
            return OrgNoRowsOverlay(tt('journey.tasks.orgFinder.noResults'))
        },
        NoResultsOverlay: () => (
            <></>
        )
    }


    const components = useMemo(()=>{
        if(!mobile){
            return {...defaultComponents,  Toolbar: CustomToolbar }
        }

        return defaultComponents
    }, [defaultComponents, mobile])

    return (
        <Grid container >
            <Grid item xs={12}>

                <ProminentSlider handleSliderChange={handleSliderChange}
                                                   handleInputSliderChange={handleInputChange}
                                                   range={searchRange}
                                                   includeInput={!mobile}
                                                   handleBlur={handleBlur}/>
            </Grid>
            <Grid item xs={12}>
                <div style={{height: 400}}>

                    <ThemeProvider theme={dataGridTheme}>
                        <DataGrid
                            classes={{ headerCell: classes.headerCell, row: classes.row }}
                            rows={orgTableState.rows}
                            columns={orgTableState.cols}

                            pagination
                            pageSize={orgTableState.pageSize}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            rowCount={orgTableState.count || 0}
                            density={props.density}
                            onPageChange={(p) => {
                                setOrgTableState((prev) => ({...prev, page: p}))
                            }}
                            onPageSizeChange={(pageSize) =>
                                setOrgTableState((prev) => ({...prev, pageSize}))
                            }
                            loading={orgRange.loading}
                            components={components}
                            onRowClick={(newSelectionModel) => {
                                setSelectedRow(newSelectionModel.row)
                                props.onSelectOrganization(newSelectionModel.row.organization, props.taskId)
                            }}
                            selectionModel={selectionModel}
                        />
                    </ThemeProvider>
                </div>
            </Grid>
            <Grid item xs>
                {
                    props.userAddress ? (
                        <Typography variant={'body1'}>

                            {tt('journey.tasks.orgFinder.withResults.withAddress',
                                orgTableState.count.toFixed(0).toLocaleString(userLanguageISO639),
                                searchRange.toFixed(0).toLocaleString(userLanguageISO639),
                                  props.userAddress

                            )}

                        </Typography>
                    ) : (
                        <Typography>

                            {tt('journey.tasks.orgFinder.withResults.noAddress', orgTableState.count.toFixed(0).toLocaleString(userLanguageISO639))}
                        </Typography>
                    )

                }
            </Grid>
        </Grid>
    );
}


function isEqual(prev, next){


    return prev.serviceId === next.serviceId
        && prev.taskId === next.taskId
        && prev.coordinates.lat === next.coordinates.lat
        && prev.coordinates.lng === next.coordinates.lng
        && prev.userAddress === next.userAddress
        && prev.density === next.density
        && prev.legalStatus === next.legalStatus

}

export default React.memo(OrganizationFinderTool, isEqual)