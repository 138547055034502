import React, {useContext, useMemo, useState} from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import {LanguageContext} from "../../providers/LanguageProvider";

import ServiceIcon from "../common/ServiceIcon";

import Badge from "@material-ui/core/Badge";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    btn: {
        "&:hover": {
            backgroundColor: "white",
        },
        backgroundColor: "white",
        width: 120,
        height: "100%",

        // padding:0,
        // margin: 0
    },

    active: {
        padding: 0,
        margin: 0,
        backgroundColor: "rgb(201,203,204)",
        // backgroundColor: 'white',
        width: 120,
        height: "100%",
        // zIndex: 100,
        // border: '3px solid #0191d5',
        // borderRadius:"15px",
        // transition: theme.transitions.create('z-index'),
    },
}));
ServiceItem.propTypes = {
    service: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    badgeContent: PropTypes.number,
    iconSize: PropTypes.string,
    isSelected: PropTypes.bool,
    onIconLoad: PropTypes.func

}

ServiceItem.defaultProps = {
    iconSize: "3x",
    isSelected: false,
    badgeContent: 0

}
export default function ServiceItem(props) {
    const {service, onClick, badgeContent, iconSize, isSelected} = props
    const classes = useStyles();

    const {userLanguage} = useContext(LanguageContext);


    const name = useMemo(()=>{
        return get_intl_lang(userLanguage, service.intlMetadata)?.name || service.name

    }, [userLanguage, service])




    const [selectedIconColorProps, setSelectedIconColorProps] = useState(null)

    return (
        <Card
            style={{
                bottom: "1px solid",

                boxShadow: "none",
                borderRadius: "1px",
                width: 120,
                height: 120,
                // flexBasis:"0%"
            }}
        >
            <CardActionArea
                className={ classes.btn }
                style={{backgroundColor: isSelected && selectedIconColorProps ? selectedIconColorProps[50] : ''}}

                value={service}
                onClick={()=>onClick(selectedIconColorProps)}
            >
                <Grid
                    container
                    spacing={0}
                    justifyContent={"center"}
                    alignItems={"center"}
                    // xs={12}
                >
                    <Grid
                        item
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        xs={12}
                    >
                    <Badge badgeContent={badgeContent}  color={'secondary'}>
                        <ServiceIcon
                            service={service}
                            size={iconSize}
                            onIconLoad={(icon)=>{

                                setSelectedIconColorProps(icon.color)

                            }}
                        />
                    </Badge>


                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            align={"center"}

                        >
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
}

