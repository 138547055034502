import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {Card, CardContent, Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GoogleMapForBookmarks from "../bookmarks/GoogleMapForBookmarks";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import DeleteCategoryDialog from "./DeleteCategoryDialog";
import onlineService from "./onlinelocation.svg";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PhoneIcon from "@material-ui/icons/Phone";
import LanguageRoundedIcon from "@material-ui/icons/LanguageRounded";
import RoomIcon from "@material-ui/icons/Room";
import {LanguageContext, Text} from "../../providers/LanguageProvider";
import {get_intl_lang} from "../utils/commonUsedFunctions";

const useStyles = makeStyles((theme) => ({
    root: {
        transition:
            "box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease",
        "&:hover": {
            boxShadow:
                "0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important",
            transform: "translate3d(0,-5px,0)",
        },
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        // width: 360,
        border: "3px solid #298",
        borderRadius: "10px",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    wrapper: {
        position: "relative",
    },
    overMap: {
        position: "absolute",
        top: "10%",
        left: "10px",
        zIndex: 99,
        overflow: "scroll",
    },
    overMapSearchBar: {
        position: "absolute",
        top: "10px",
        left: "10px",
        zIndex: 99,
        // overflow: "scroll",
        width: "300px",
    },
    overMapUnsigned: {
        position: "absolute",
        top: "10px",
        left: "10px",
        zIndex: 99,
        overflow: "scroll",
        marginTop: "5%",
    },

    OverBelowMap: {
        position: "absolute",
        bottom: "0px",
        marginLeft: "38%",
        marginBottom: "10%",
        zIndex: 99,
    },

    directions: {
        position: "absolute",
        top: "10px",
        right: "10px",
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
    },
    avatar: {
        backgroundColor: "#298",
        width: 40,
        height: 40,
    },
    //slideUp drawer stuff
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    radio: {
        "&$checked": {
            color: "black",
        },
    },
    checked: {
        backgroundColor: "#ff3366",
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3",
        },
    },

    card: {
        borderRadius: "10px",
        width: 100,
        height: 100,
        backgroundColor: "white",
        padding: 0,
        border: "3px solid #298",
    },
    test: {
        overflow: "hidden",
        width: "100%",
        height: "100%",
    },
    btn: {
        "&:hover": {
            backgroundColor: "white",
        },
        backgroundColor: "white",
        width: 120,
        height: "100%",
    },

    active: {
        padding: 0,
        margin: 0,
        backgroundColor: "rgb(201,203,204)",
        width: 120,
        height: "100%",
    },
    button: {
        minWidth: 200,
        backgroundColor: "#298",
        textTransform: "none",

        borderRadius: "10px",
    },
    suggestionButton: {
        minWidth: 150,
        backgroundColor: "#298",
        textTransform: "none",

        borderRadius: "10px",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    OverMapMobile: {
        position: "absolute",
        bottom: "40px",
    },
    mobileResults: {
        [theme.breakpoints.down("sm")]: {
            alignItems: "center",
        },
    },
    alert: {
        top: 30,
    },
    tooltip: {
        position: "relative",
        padding: "5px",
        margin: "0px",
        marginBottom: "2px",
        "pointer-events": "none",
        zIndex: "unset",
    },
    popper: {
        zIndex: "unset",
    },
}));

/**
 * Component to display the product card
 *
 * @param {Object} props
 */
const BookMarkCard = (props) => {
    const {
        mediaClassName,
        withShadow,
        noShadow,
        noBorder,
        noBg,
        liftUp,
        cardContent,

        align,
        className,
        changeSize,
        ...rest
    } = props;

    const classes = useStyles();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const {userLanguage} = useContext(LanguageContext);



    const [orgName, setOrgname] = useState("N/A");
    const [description, setDescription] = useState("N/A");


    
    useEffect(() => {
        
        
        if (props.userBookmark.organization) {


            
            setOrgname(props.userBookmark.organization.name)
            if (userLanguage === "fre" && props.userBookmark.organization.nameFre) {
                setOrgname(props.userBookmark.organization.nameFre)
            }


            const descTranslation = get_intl_lang(userLanguage, props.userBookmark.organization.intlOrgs)
            if(descTranslation){
                setDescription(descTranslation.description )
            }

        }





    }, [userLanguage, props.userBookmark.organization]);

    function getDirection(address) {
        window.open(
            `https://www.google.com/maps/search/?api=1&query=${address}`,
            "_blank"
        );
    }

    const handleDeleteDialogClose = async (newValue, index) => {
        if (newValue === true) {
            props.deleteEvent(props.userBookmark);
        }
        setOpenDeleteDialog(false);
    };
    const handleOpenDialog = () => {
        setOpenDeleteDialog(true);
    };
    return (
        <div align={"center"}>

            <Card className={classes.root}>
                <CardHeader
                    style={{padding: "10px"}}
                    action={
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={handleOpenDialog}
                        >
                            {<HighlightOffIcon/>}
                        </IconButton>
                    }
                    avatar={
                        <Avatar variant={"circle"} style={{width: 100, height: 100}}>
                            { props.userBookmark.organization.address && props.userBookmark.organization.address.lng ? (
                                <GoogleMapForBookmarks
                                    changeSize={changeSize}
                                    id={props.id}
                                    coordinates={props.userBookmark.organization.address ? { lat: props.userBookmark.organization.address.lat, lng: props.userBookmark.organization.address.lng }  : null}

                                />
                            ) : (
                                <img height={"100%"} width={"100%"} src={onlineService}/>
                            )}
                        </Avatar>
                    }
                    title={
                        <Typography gutterBottom variant="h6">
                            {orgName}
                        </Typography>
                    }
                />
                <Divider/>
                <CardContent
                    style={{
                        maxHeight: "200px",
                        height: "100%",
                        marginBottom: "30px",
                        overflowY: "scroll",
                    }}
                >
                    <List>
                        {props.userBookmark.organization.address &&
                            <ListItem key={'address_list_item'}>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography gutterBottom variant="subtitle2">
                                            <Text tid="bookmarks.address"/>
                                        </Typography>
                                    }
                                    secondary={props.userBookmark.organization.address.address}
                                />
                            </ListItem>
                        }
                        <Divider variant="middle" component="li"/>

                        {props.userBookmark.organization.phoneNumbers.length > 0 || props.userBookmark.organization.email ? (
                            <ListItem key={'contact_list_item'}>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography gutterBottom variant="subtitle2">
                                            <Text tid="explore.contact"/>
                                        </Typography>
                                    }
                                    secondary={
                                        <ul key={'ul_contact'} >
                                            {props.userBookmark.organization.email && (
                                                <ul>
                                                    <li key={'org_email'}>Email:</li>
                                                    <ul>
                                                        <li key={'org_email_value'}>{props.userBookmark.organization.email}</li>
                                                    </ul>
                                                </ul>
                                            )}
                                            {props.userBookmark.organization.phoneNumbers.length > 0 &&

                                                props.userBookmark.organization.phoneNumbers.map((phone, index)=>{


                                                    return (
                                                        <ul key={`ul_phone_${phone.id}`}>
                                                            <li key={`phone_${phone.id}`}>Phone:</li>
                                                            <ul key={'ul_phones'}>
                                                                <li key={`phone_${phone.id}_${index}`}>1-({phone.areaCode})-{phone.phone}</li>
                                                                {phone.extensions.map(ext=>{
                                                                    return (
                                                                        <ul key={`ul_extension_${ext.id}`}>
                                                                            <li key={`extension_${ext.id}`}>#{ext.extension} / {ext.name}</li>
                                                                        </ul>
                                                                    )
                                                                })}
                                                            </ul>

                                                        </ul>
                                                    )

                                                }
                                            )}
                                        </ul>



                                    }
                                />
                            </ListItem>
                        ) : null}
                        <Divider variant="middle" component="li"/>


                        <ListItem key={'description_list_item'}>
                            <ListItemText
                                primary={
                                    <Typography gutterBottom variant="subtitle2">
                                        <Text tid="bookmarks.description"/>
                                    </Typography>
                                }
                                secondary={description}
                            />
                        </ListItem>

                    </List>
                </CardContent>
                <DeleteCategoryDialog
                    classes={{paper: classes.paper}}
                    open={openDeleteDialog}
                    index={props.id ? props.id : null}
                    onClose={handleDeleteDialogClose}
                />
            </Card>
            <div
                style={{
                    display: "grid",
                    backgroundColor: "transparent",
                    marginTop: "-35px",
                }}
            >
                <Grid
                    container
                    direction={"row"}
                    justify={"center"}
                    alignItems={"center"}
                    style={{display: "flex"}}
                >
                    {props.userBookmark.organization.website && (
                        <Grid item>
                            <IconButton
                                href={props.userBookmark.organization.website}
                                target="_blank"
                                rel="noopener"
                            >
                                <Tooltip
                                    classes={{
                                        tooltip: classes.tooltip,
                                        popper: classes.popper,
                                    }}
                                    PopperProps={{
                                        placement: "bottom",
                                        disablePortal: false,
                                        modifiers: {
                                            flip: {enabled: false},
                                            preventOverflow: {
                                                enabled: false,
                                                boundariesElement: "scrollParent",
                                            },
                                            arrow: {enabled: false},
                                        },
                                    }}
                                    open={true}
                                    placement={"bottom"}
                                    title={<Text tid="bookmarks.website"/>}
                                >
                                    <Avatar className={classes.avatar}>
                                        <LanguageRoundedIcon
                                            style={{color: "white", width: 25, height: 25}}
                                        />
                                    </Avatar>
                                </Tooltip>
                            </IconButton>
                            {/*</Tooltip>*/}
                        </Grid>
                    )}

                    {props.userBookmark.organization.phoneNumbers.length === 1 &&(
                        <Grid item>
                            <IconButton href={`tel:+1-${props.userBookmark.organization.phoneNumbers[0].areaCode}-${props.userBookmark.organization.phoneNumbers[0].phone}`}>
                                <Tooltip
                                    classes={{
                                        tooltip: classes.tooltip,
                                        popper: classes.popper,
                                    }}
                                    PopperProps={{
                                        placement: "bottom",
                                        disablePortal: false,
                                        modifiers: {
                                            flip: {enabled: false},
                                            preventOverflow: {
                                                enabled: false,
                                                boundariesElement: "scrollParent",
                                            },
                                            arrow: {enabled: false},
                                        },
                                    }}
                                    open={true}
                                    placement={"bottom"}
                                    title={<Text tid="bookmarks.call"/>}
                                >
                                    <Avatar className={classes.avatar}>
                                        <PhoneIcon
                                            style={{color: "white", width: 25, height: 25}}
                                        />
                                    </Avatar>
                                </Tooltip>
                            </IconButton>
                        </Grid>
                    )}


                    {props.userBookmark.organization.address && (
                        <Grid item>
                            <IconButton onClick={() => getDirection(props.userBookmark.organization.address.address)}>
                                <Tooltip
                                    classes={{
                                        tooltip: classes.tooltip,
                                        popper: classes.popper,
                                    }}
                                    PopperProps={{
                                        placement: "bottom",
                                        disablePortal: false,
                                        modifiers: {
                                            flip: {enabled: false},
                                            preventOverflow: {
                                                enabled: false,
                                                boundariesElement: "scrollParent",
                                            },
                                            arrow: {enabled: false},
                                        },
                                    }}
                                    open={true}
                                    placement={"bottom"}
                                    title={<Text tid="bookmarks.address"/>}
                                >
                                    <Avatar className={classes.avatar}>
                                        <RoomIcon
                                            style={{color: "white", width: 25, height: 25}}
                                        />
                                    </Avatar>
                                </Tooltip>
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
};

BookMarkCard.defaultProps = {
    align: "left",
};

BookMarkCard.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
    /**
     * External classes for the media
     */
    mediaClassName: PropTypes.string,
    /**
     * Whether to show custom shadow
     */
    withShadow: PropTypes.bool,
    /**
     * Whether to render the card without shadow
     */
    noShadow: PropTypes.bool,
    /**
     * Whether to hide the card borders
     */
    noBorder: PropTypes.bool,
    /**
     * Whether to show transparent background
     */
    noBg: PropTypes.bool,
    /**
     * Whether to lift up on hover
     */
    liftUp: PropTypes.bool,
    /**
     * The content alignment
     */
    align: PropTypes.oneOf(["left", "right", "center"]),
};

export default BookMarkCard;
