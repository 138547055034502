import React, {useEffect, useState} from "react";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";

const libraries = ["places"]

const center = {
    lat:43.6529,lng:-79.3849
}
const options = {

    disableDefaultUI: true,
    zoomControl: true
}
const GoogleMapForBookmarks = (props)=>{
    console.log(props)
    const [marker, setMarker ]= useState(null)

    const {isLoaded,loadError}= useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries,
    })
    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, [])


    useEffect(()=>{
        if(props.coordinates){
            if(marker){
                if(props.coordinates.lat !== marker.lat
                    || props.coordinates.lng !== marker.lng){
                    setMarker(props.coordinates)
                }
            }else {
                setMarker(props.coordinates)
            }
        }
    },[marker, props.coordinates])

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";


    return(
        <div>
            <GoogleMap
                id="map"

                zoom={12}
                center={props.coordinates?props.coordinates :center}
                options={options}
                onLoad={onMapLoad}


            >
                {marker?
                    <Marker
                        key={props.id}
                        position={{lat: marker.lat, lng: marker.lng}}
                        // onClick={()=>{
                        //     setSelectedLocation(marker)
                        //     panTo({lat: marker.lat, lng: marker.lng},15)
                        // }}
                        // label={""+(index+1)}
                        animation={2}
                    />
                :""}
            </GoogleMap>
        </div>
    )
}

export default GoogleMapForBookmarks
