import React from 'react'

import Typography from '@material-ui/core/Typography'

import {makeStyles} from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid";

import clsx from "clsx";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    invalid: {
        // padding: theme.spacing(3, 2),
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    routerLink: {
        textDecoration: 'none',
        color: '#00BCD4'
    },
    root: {
        overflow:"hidden",
        // maxWidth: 1100,
        width: '100%',
        margin: '0 auto',
        // padding: theme.spacing(6, 2),
        // [theme.breakpoints.up('sm')]: {
        //     padding: theme.spacing(12, 2),
        // },
    },
    fullWidth: {
        maxWidth: '100%',
    },
    disablePadding: {
        padding: 0,
    },
    narrow: {
        maxWidth: 800,
    },
    formContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: 500,
        margin: `0 auto`,
    },
    section: {

        paddingTop: 0,
        paddingBottom: 0,
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
}))

export default function NotFound () {
    const classes = useStyles()
    return (
        <div className={classes.root}>
        <section
            className={classes.section}
        >
          <div className={classes.formContainer}>
            <Grid
                container
                spacing={2}
                className={clsx(
                    'section-header',
                    classes.root,
                )}
            >
                    <Grid item xs={12} className="section-header__label-wrapper">
                        <Typography
                            variant="h1"
                            color="primary"
                            component="p"
                            align={'center'}
                            style={{color:"#298"}}
                        >
                            {"404"}
                        </Typography>
                    </Grid>
                <Grid item xs={12} className="section-header__title-wrapper">
                    <Typography
                        variant={"h3"}
                        align={'center'}
                        className={clsx(
                            'section-header__title',
                            classes.title,
                            // titleClasses ? titleClasses : {},
                        )}
                        color="textPrimary"
                    >
                        {"Uh Oh"}
                    </Typography>
                </Grid>

                    <Grid item xs={12} className="section-header__subtitle-wrapper">
                        <Typography
                            variant={ 'h5'}
                            align={'center'}
                            color={'textSecondary'}
                            className="section-header__subtitle"
                        >
                            { <span>  This page does not exist!</span>}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className="section-header__cta-wrapper">
                        <Grid
                            container
                            justify={"center"}
                            alignItems="center"
                            wrap="nowrap"
                            className="section-header__cta-container"
                        >
                                <div
                                    className={clsx(
                                        'section-header__cta-item-wrapper',
                                        classes.cta,
                                    )}
                                >
                                    <Button
                                        size="large"
                                        variant="contained"
                                        style={{backgroundColor:"#298"}}
                                        href={'/welcome'}
                                    >
                                        Go Back
                                    </Button>
                                </div>
                        </Grid>
                    </Grid>

            </Grid>
          </div>
        </section>
        </div>
    )
}
