
import {CardContent, CardHeader, colors, ListItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import PhoneIcon from "@material-ui/icons/Phone";
import {ArrowForwardTwoTone, ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Link from "@material-ui/core/Link";
import LanguageIcon from "@material-ui/icons/Language";
import React, {useContext, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {LanguageContext} from "../../providers/LanguageProvider";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import Typography from "@material-ui/core/Typography";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ServicesOffered from "../explore/ServicesOffered";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
    root:{
      border: "none",
      boxShadow:"none"
    },
    listRoot: {
        width: '100%',

        backgroundColor: theme.palette.background.paper,
    },
    phoneIcon: {
        color: colors.green[300]
    },
    webIcon: {
        color: colors.blue[300]

    },
    homeIcon: {
        color: colors.deepOrange[300]

    }

}));



OrgCard.propTypes = {
    organization: PropTypes.object.isRequired,
    showTitle: PropTypes.bool,
    showContent: PropTypes.bool,
    avatar: PropTypes.element,
    preContent: PropTypes.node

};
OrgCard.defaultProps = {
    showContent: true,
    showTitle: true,
};
export default function OrgCard(props) {

    const [openList, setOpenList] = useState(false)
    const [openExtensionList, setOpenExtensionList] = useState(false)
    const [openServiceCollapse, setOpenServiceCollapse] = useState(false)

    const {tt, userLanguage} = useContext(LanguageContext);

    const address = useMemo(() => {
        return props.organization.address?.address

    }, [props.organization])

    const orgTranslation = useMemo(() => {

        return get_intl_lang(userLanguage, props.organization.intlOrgs)

    }, [userLanguage, props.organization])

    const description = useMemo(() => {

        return orgTranslation?.description

    }, [orgTranslation])

    const servicesOffered = useMemo(() => {

        return props.organization.organizationServices || []

    }, [props.organization])

    const handleClickPhoneCollapse = () => {
        setOpenList(!openList);
    };

    const handleClickExtensionCollapse = () => {
        setOpenExtensionList(!openExtensionList);
    };

    const classes = useStyles();

    const getDirections = (a) => {
        window.open(
            `https://www.google.com/maps/search/?api=1&query=${a}`,
            "_blank"
        );

    }
    const openWebAddress = (a) => {
        window.open(
            a,
            "_blank"
        );
    }


    const handleClick = () => {
        setOpenServiceCollapse(!openServiceCollapse);
    };

    return (


        <Card className={classes.root} square={true}>
            {props.showTitle &&
                <CardHeader
                    {...(props.avatar && {avatar: props.avatar})}
                    title={<Typography variant={'h6'}>{props.organization.name}</Typography>}
                    subheader={


                        <Link variant={'subtitle2'} component={'button'} onClick={(e) => getDirections(address)}>
                            {address}
                        </Link>

                    }
                />
            }
            {props.preContent}
            <CardContent>
                {description &&
                    <>
                        <Typography variant={"body1"}>
                            {description}
                        </Typography>


                    </>
                }

                {props.showContent &&
                    <>
                        <p><Divider/></p>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"

                        >
                            {!props.showTitle &&
                                <ListItem button>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faHome} className={classes.homeIcon}/>
                                    </ListItemIcon>
                                    <ListItemText primary={address} onClick={(e) => getDirections(address)}/>
                                </ListItem>
                            }

                            {props.organization.email &&
                                <ListItem button>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Link
                                        href={`mailto:${props.organization.email}`}>{props.organization.email}</Link>}/>
                                </ListItem>
                            }
                            {props.organization.phoneNumbers.length > 1 ? (
                                <>
                                    <ListItem onClick={handleClickPhoneCollapse} button>
                                        <ListItemIcon>
                                            <PhoneIcon className={classes.phoneIcon}/>
                                        </ListItemIcon>
                                        <ListItemText primary={tt('journey.orgCard.phones.label')}/>
                                        {openList ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>

                                    <Collapse in={openList} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>

                                            {props.organization.phoneNumbers.map(phone => {
                                                return (
                                                    <ListItem button className={classes.nested2}>
                                                        <ListItemIcon>
                                                            <ArrowForwardTwoTone/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Link
                                                            href={`tel:+1-${phone.areaCode}-${phone.phone}`}>{`1-(${phone.areaCode})-${phone.phone}`}</Link>}/>
                                                    </ListItem>
                                                )
                                            })}


                                        </List>
                                    </Collapse>
                                </>

                            ) : (props.organization.phoneNumbers.map(phone => {
                                return (
                                    <>
                                        <ListItem onClick={handleClickExtensionCollapse} button>
                                            <ListItemIcon>
                                                <PhoneIcon className={classes.phoneIcon}/>
                                            </ListItemIcon>
                                            <ListItemText primary={<Link
                                                href={`tel:+1-${phone.areaCode}-${phone.phone}`}>{`1-(${phone.areaCode})-${phone.phone}`}</Link>}/>
                                            {phone.extensions.length > 0 && (openExtensionList ? <ExpandLess/> :
                                                <ExpandMore/>)}


                                        </ListItem>
                                        {phone.extensions.length > 0 &&
                                            (
                                                <Collapse in={openExtensionList} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        {phone.extensions.map(ext => {
                                                            return (
                                                                <ListItem button className={classes.nested}>
                                                                    <ListItemIcon>
                                                                        <ArrowForwardTwoTone/>
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={`#${ext.extension} / ${ext.name || '-'}`}/>
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </List>
                                                </Collapse>
                                            )
                                        }
                                    </>
                                )
                            }))}

                            {props.organization.website &&
                                <ListItem button>
                                    <ListItemIcon>
                                        <LanguageIcon className={classes.webIcon}/>
                                    </ListItemIcon>
                                    <ListItemText primary={props.organization.website}
                                                  onClick={(e) => openWebAddress(props.organization.website)}/>
                                </ListItem>
                            }


                            <Divider variant="inset" component="li"/>
                            <ListItem alignItems="flex-start" button onClick={handleClick}>
                                <ListItemIcon>
                                    <EmojiPeopleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={tt('journey.orgCard.servicesOffered.label')}

                                />
                                {openServiceCollapse ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={openServiceCollapse} timeout="auto" unmountOnExit>
                                <ServicesOffered servicesOffered={servicesOffered}/>
                            </Collapse>
                        </List>
                    </>
                }
            </CardContent>
        </Card>


    )
}