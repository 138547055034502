import React, {createContext, useContext, useEffect, useMemo, useState} from "react";

import {dictionaryList, languageOptions} from "../../../languages";
import 'moment/locale/en-ca';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ar';

import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';

if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined'
          ? args[number]
          : match
          ;
    });
  };
}
// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: "eng",
  dictionary: flattenObject(dictionaryList["eng"]),
  rawDictionary: dictionaryList["eng"],
  userLanguageISO639: "en",
  textDirection: "ltr",
  userLanguageChange: (selected) => {}

})

// it provides the language context to app
export function LanguageProvider({ children }) {
  const defaultLanguage = "eng"
  const [userLanguage, setUserLanguage] = useState(localStorage.getItem("lang_iso") || defaultLanguage);
  const [textDirection, setTextDirection] = useState("ltr")
  const dictionary = useMemo(()=>{

    return flattenObject(dictionaryList[userLanguage])
  }, [userLanguage])

  const userLanguageISO639 = useMemo(()=>{
    return iso_to_abbr(userLanguage)

  }, [userLanguage])

  useEffect(()=>{

    if(userLanguage === "ara"){
      setTextDirection("rtl")
    } else {
      setTextDirection("ltr")

    }

    moment.locale(userLanguageISO639)
    momentTZ.locale(userLanguageISO639)

  }, [userLanguageISO639, userLanguage])


  const provider = {
    userLanguage,
    textDirection,
    dictionary,
    userLanguageISO639,
    rawDictionary: dictionaryList[userLanguage],


    userLanguageChange: (selected) => {

      const newLanguage = languageOptions[selected] ? selected : "eng";
      setUserLanguage(newLanguage);

      localStorage.setItem("lang_iso", newLanguage);


    },
    tt: (tid, ...f) => {

      if(typeof dictionary[tid] === "string" && f !== undefined){
        return dictionary[tid].format(...f)

      }
      return dictionary[tid] || tid

    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

function iso_to_abbr(lang){

  let map = {
    'eng': 'en',
    'fre': 'fr',
    'ara': 'ar',
    'spa': 'es'

  }

  if (map[lang]) {
    return map[lang];

  }

  return 'en';


}

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null && !Array.isArray(ob[i])) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}
// get text according to id & current language
export function Text({ tid , formats}) {
  const languageContext = useContext(LanguageContext);

  let flattenned_dictionary = languageContext.dictionary;

  if(formats){

    return flattenned_dictionary[tid].format(...formats) || tid
  }

  return flattenned_dictionary[tid] || tid;

  //return languageContext.dictionary[tid] || tid;
}
