class TokenService {
    getLocalRefreshToken() {
        return localStorage.getItem("refreshToken")
    }
    getLocalAccessToken() {
       return localStorage.getItem("token")
    }
    updateLocalAccessToken(token) {
        localStorage.setItem("token", token);
    }
    updateLocalRefreshToken(token) {
        localStorage.setItem("refreshToken", token);
    }

}
export default new TokenService();
