import apiConfig from "./apiConfig";

const getCategories = () => apiConfig.get("/metadata/categories")
const getCities = () => apiConfig.get("/metadata/cities")
const deleteUserBookmark = (bookmarkId) => apiConfig.delete(`/users/me/bookmarks/${bookmarkId}`)

const getTags = (legal) => apiConfig.get('/metadata/tags', {params: {'legal': legal}})

const getProvinces = () => apiConfig.get("/metadata/provinces")

export default {
    getCategories,
    getCities,
    deleteUserBookmark,
    getTags,
    getProvinces
};
