import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {LanguageContext, Text} from "../../providers/LanguageProvider";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import suggestionBox1 from "./public/suggestionBox.svg";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cardMedia: {
        backgroundColor: theme.palette.primary.alternate
    },

}));

export default function SuggestionCard(props){
    const classes = useStyles();
    const { tt } = useContext(LanguageContext);
    return (
        <Card
            square={true}
            style={{
                display: "flex",
                border: "none"
            }}
        >
            <div>
                <CardContent style={{ flex: "1 0 auto" }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography
                                style={{ fontWeight: "bold",fontSize:"1.1rem" }}
                            >

                                {tt('explore.serviceCard.orgTab.suggestionBox.label')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"

                                component="a"
                                onClick={()=>{}}
                            >
                                {tt('explore.serviceCard.orgTab.suggestionBox.addSuggestionButton.label')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="contained"
                                size="small"
                                component="a"
                                href={
                                    "https://form.jotform.com/211682383054252"
                                }
                                target="_blank"
                            >

                                {tt('explore.serviceCard.orgTab.suggestionBox.addOrgButton.label')}

                            </Button>
                        </Grid>
                    </Grid>


                </CardContent>

            </div>
            <CardMedia
                style={{ width: 180, marginLeft: "auto" }}
                image={suggestionBox1}
                className={classes.cardMedia}
            />

        </Card>

    )

}