import Drawer from "@material-ui/core/Drawer/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Divider} from "@material-ui/core";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({

    drawer: (props) => ({

        width: props.drawerWidth,
        flexShrink: 0,

    }),

    drawerPaper: (props) => ({

        width: props.drawerWidth,
        height: 'calc(100% - 48px)' // 48px is the app bar height

    }),
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },


}));


export default function OptionsDrawer(props) {

    const {open, width, handleDrawerClose, className} = props
    const theme = useTheme()
    const classes = useStyles({drawerWidth: width});


    return (
        <Drawer
            className={clsx(classes.drawer, className)}

            variant="persistent"
            anchor="right"
            open={open}

            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </div>
            <Divider/>
            {props.children}

        </Drawer>

    )

}