import apiConfig from "./apiConfig";

const sections = () => apiConfig.get("/journey/sections");
const provincialSections = (provinceId) => apiConfig.get(`/journey/province/${provinceId}/sections`)





const subTasks = (taskId) => apiConfig.get(`/journey/legal/task/${taskId}/subsequent`)
const tasks = (provinceId, taskType) => apiConfig.get(`/journey/province/${provinceId}/tasks`, {
    params: {
        'taskType': taskType

    }

})
const restart = () => apiConfig.delete(`/journey/restart`)

const start = (form) => apiConfig.post(`/journey/start`, form)

export default {
    provincialSections,
    sections,


    subTasks,
    start,
    tasks,
    restart
};
