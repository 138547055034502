import React, {useContext, useState} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import {TextField} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import RouteIcon from "@mui/icons-material/Route";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";
import ExploreRoundedIcon from "@material-ui/icons/ExploreRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import BookmarksRoundedIcon from "@material-ui/icons/BookmarksRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import classNames from "classnames";

import GTranslateIcon from "@material-ui/icons/GTranslate";
import {defaultFont, hexToRgb, whiteColor,} from "./mainPageStyle";
import {languageOptions} from "../../../../languages";
import {LanguageContext, Text} from "../../providers/LanguageProvider";
import Hidden from "@material-ui/core/Hidden";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import SignoutDialog from "../common/SignoutDialog";
import {UserContext} from "../../providers/UserInfoContext";
import MainDialog from "../auth/MainDialog";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSelect-icon": {
      color: "white",
      top: "calc(50% - 17px)",
    },
    "& .MuiInputBase-inputMarginDense": {
      paddingTop: "1px !important",
    },
    "& .MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup":
      {
        backgroundColor: "#596769 !important",
      },
    fontweight: "bold",
    color: "white",
    backgroundColor: "#333333",
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    position: "unset",
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "295px",
    zIndex: "4",
    overflowScrolling: "touch",
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px",
    },
  },
  whiteFont: {
    color: whiteColor,
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor,
    },
    width: "100%",
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    // display: "block",
    padding: "16px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,

    "&:hover ": {
      backgroundColor: theme.palette.secondary.main,

    },
  },

  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
  },
  itemIconRTL: {
    marginRight: "3px",
    marginLeft: "15px",
    float: "right",
  },
  itemText: {

    margin: "0",
    lineHeight: "30px",

    color: whiteColor,
  },
  itemTextRTL: {
    textAlign: "right",
  },
  icon: {
    color: "white",
  },
  itemDropMenu: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    // display: "block",
    padding: "6px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
  },
}));

const HamburgerMenuLinks = (props) => {

  const theme = useTheme()
  const classes = useStyles(theme);

  const [openConfirmationMsg, setConfirmationMsg] = useState(false);
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [signInOpen, setSignInOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false)
  const { user  } = useContext(UserContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClosingOpenConfirmation = (signOut) => {
    setConfirmationMsg(false);
    if (signOut) {
      props.history.push({ pathname: "/signOut" });
    }
  };
  // set selected language by calling context method

  const pages = [
    {
      title: <Text tid="welcome.home" />,
      href: "/welcome",
      icon: <HomeRoundedIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.signIn" />,
      value: "signIn",
      href: "/signIn",
      icon: <LockOpenRoundedIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.signUp" />,
      value: "signUp",
      href: "/signUp",
      icon: <AssignmentIndRoundedIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.explore" />,
      href: "/discover",
      icon: <ExploreRoundedIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.aboutUs" />,
      href: "/about",
      icon: <InfoRoundedIcon className={classes.icon} />,
      // signOut:true
    },
  ];

  const LoggedInUserpages = [

    {
      title: <Text tid="welcome.journey" />,
      href: "/journey",
      icon: <RouteIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.bookmarks" />,
      href: "/bookmarks",
      icon: <BookmarksRoundedIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.FAQ" />,
      href: "/faq",
      icon: <LiveHelpRoundedIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.explore" />,
      href: "/explore",
      icon: <ExploreRoundedIcon className={classes.icon} />,
    },
    {
      title: <Text tid="welcome.aboutUs" />,
      href: "/about",
      icon: <InfoRoundedIcon className={classes.icon} />,
      // signOut:true
    },
    {
      title: <Text tid="welcome.signOut" />,
      href: "/signOut",
      icon: <ExitToAppRoundedIcon className={classes.icon} />,
      signOut: true,
    },
  ];

  const onItemClick = (value) =>{

    if(value === 'signIn'){
      setSignInOpen(true)

    } else {
      setSignUpOpen(true)

    }

  }
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1;
  }
  return (
    <div className={classes.sidebarWrapper}>
      <List className={classes.list}>
        {user
          ? LoggedInUserpages.map((page, key) => {
              let listItemClasses = classNames({
                [" " + classes['primary']]: activeRoute(page.href),
              });
              const whiteFontClasses = classNames({
                [" " + classes.whiteFont]: activeRoute(page.href),
              });
              return (
                <ListItem
                  key={page.href}
                  button
                  className={classes.itemLink + listItemClasses}
                  onClick={() => {
                    if (page.signOut) {
                      setConfirmationMsg(true);
                    } else {
                      props.history.push(page.href);
                    }
                  }}
                  // key={page.title}
                >
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText

                    className={classes.itemText}

                  >
                    <Typography variant={'h6'}>{page.title}</Typography>
                  </ListItemText>
                </ListItem>
              );
            })
          : pages.map((page) => {
              var listItemClasses = classNames({
                [" " + classes['primary']]: activeRoute(page.href),
              });

              if (page.value === "signIn" || page.value === "signUp") {
                return (
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                    onClick={(e) => onItemClick(page.value)}
                    key={page.href}
                  >
                    <ListItemIcon className={classes.icon}>
                      {page.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={                    <Typography variant={'h6'}>{page.title}</Typography>}
                      className={classes.itemText}

                    ></ListItemText>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses}
                    onClick={() => {
                      props.history.push(page.href);
                    }}
                    key={page.href}
                  >
                    <ListItemIcon className={classes.icon}>
                      {page.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={                    <Typography variant={'h6'}>{page.title}</Typography>}
                      className={classes.itemText}

                    ></ListItemText>
                  </ListItem>
                );
              }
            })}

        <Hidden mdUp>
          <ListItem className={classes.itemDropMenu}>
            <ListItemIcon>
              <GTranslateIcon className={classes.icon} />
            </ListItemIcon>
            <TextField
              margin="dense"
              name="status"
              onChange={(e)=>userLanguageChange(e.target.value)}
              required
              select
              style={{ width: "70%" }}
              SelectProps={{ native: true }}
              value={userLanguage}
              InputProps={{
                className: classes.root,
                disableUnderline: true,
              }}
              // style={{width:"120%",color:"black"}}
            >
              {Object.entries(languageOptions).map(([id, name]) => (
                <option
                  style={{ color: "white", fontSize: 18 }}
                  key={id}
                  value={id}
                >
                  {name}
                </option>
              ))}
            </TextField>
          </ListItem>
        </Hidden>

        <SignoutDialog open={openConfirmationMsg} handleClose={(e)=>setConfirmationMsg(false)} onConfirm={handleClosingOpenConfirmation} />


        <MainDialog
            key={"signin"}
            open={signInOpen}
            fullScreen={fullScreen}
            title={<Text tid="welcome.signIn" />}
            onClose={(e)=>setSignInOpen(false)}
        >
          <SignIn />
        </MainDialog>


        <MainDialog
            key={"signup"}
            open={signUpOpen}
            fullScreen={fullScreen}
            title={  <Text tid="welcome.signUp" />}
            onClose={(e)=>setSignUpOpen(false)}
        >
          <SignUp />
        </MainDialog>



      </List>
    </div>
  );
};
export default withRouter(HamburgerMenuLinks);
