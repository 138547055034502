import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import AppBar from "../../components/modules/components/AppBar";
import Toolbar, {styles as toolbarStyles,} from "../../components/modules/components/Toolbar";
import {TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {boxShadow, defaultFont, drawerWidth, transition,} from "../../public/material-kit-react";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";
import {withRouter} from "react-router-dom";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import MobileSideBarMenu from "../../general/MobileSideBarMenu";
import {languageOptions} from "../../../../languages";
import {LanguageContext, Text} from "../../providers/LanguageProvider";
import MainDialog from "../auth/MainDialog";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSelect-icon": {
      color: "white",
      top: "calc(50% - 17px)",
    },
    "& .MuiInputBase-inputMarginDense": {
      paddingTop: "1px !important",
    },
    "& .MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup":
      {
        backgroundColor: "#596769 !important",
      },
    fontweight: "bold",
    color: "white",

    // icon:{color:"white"}
  },
  title: {
    fontSize: 24,

    textTransform: "none",
    color: "white",
  },
  placeholder: toolbarStyles(theme).root,
  welcomeToolbar: {
    minHeight: "unset",
    justifyContent: "space-between",
    backgroundColor:  theme.palette.primary.main,
    height: "fit-content",
  },
  toolbar: {
    justifyContent: "space-between",
    backgroundColor: "transparent",
    height: "fit-content",
  },
  left: {
    flex: 1,
    marginLeft: theme.spacing(9),
  },
  leftSmall: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    justifyContent: "flex-end",
    marginRight: theme.spacing(12),
    marginTop: theme.spacing(2),
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    height: "100vh",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    ...transition,
  },
  appResponsive: {
    margin: "20px 10px",
  },
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    zIndex: "1029",
    color: "#555555",
    border: "0",
    borderRadius: "3px",
    transition: "all 150ms ease 0s",
    display: "block",
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "grey",
      },
    },
  },
  language: {
    width: 100,
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "250px",
    minHeight: "50px",
  },
  flex: {
    flex: 1,
  },
  input: {
    color: "white",
  },
  icon: {
    color: "white",
  },
  manager: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    display: "inline-block",
    marginRight: theme.spacing(2),
  },
}));

function MobileSideBar(props) {

  const classes = useStyles();

  const [signInOpen, setSignInOpen] = useState(false)
  const [signUpOpen, setSignUpOpen] = useState(false)
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);


 
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };



  return (
    <div>
      {props.title ? (
        props.title === "homepage" ? (
          <Hidden smDown implementation="css">
            <AppBar position="fixed">
              <Toolbar className={classes.welcomeToolbar}>
                <div className={classes.left}>
                  <Button href="/welcome" style={{ textTransform: "none" }}>
                    <Typography
                      align={"left"}
                      variant="h5"
                      style={{ color: "white", marginTop: "9px" }}
                    >
                      <span
                        color={"white"}
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "9px",
                        }}
                      >
                        Allo
                      </span>
                      Canada
                    </Typography>
                  </Button>
                </div>
                <Toolbar>
                  <div className={classes.manager}>
                    <Button
                      ariant="contained"
                      style={{
                        color: "white",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      href="/about"
                    >
                      <Text tid="welcome.aboutUs" />
                    </Button>
                  </div>
                  <div className={classes.manager}>
                    <Button
                      ariant="contained"
                      style={{
                        color: "white",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      href="/welcome"
                    >
                      <Text tid="welcome.home" />
                    </Button>
                  </div>
                  <div className={classes.manager}>
                    <Button
                      ariant="contained"
                      style={{
                        color: "white",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      href="/discover"
                    >
                      <Text tid="welcome.explore" />
                    </Button>
                  </div>
                  <div className={classes.manager}>
                    <Button
                      ariant="contained"
                      style={{
                        color: "white",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      onClick={(e) => setSignInOpen(true)}

                    >
                      <Text tid="welcome.signIn" />
                    </Button>
                  </div>
                  <div className={classes.manager}>
                    <Button
                      ariant="contained"
                      style={{
                        color: "white",
                        textTransform: "none",
                        padding: "6px",
                        fontWeight: "bold",
                      }}
                     onClick={(e) => setSignUpOpen(true)}

                    >
                      <Text tid="welcome.signUp" />
                    </Button>
                  </div>
                  <div style={{ marginRight: "5px", display: "inline-block" }}>
                    <TextField
                      fullWidth
                      margin="dense"
                      name="status"
                      onChange={(e) => userLanguageChange(e.target.value)}
                      required
                      select
                      InputProps={{
                        className: classes.root,
                        disableUnderline: true,
                      }}
                      style={{ width: "120%", color: "black" }}
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={userLanguage}
                    >
                      {Object.entries(languageOptions).map(([id, name]) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </TextField>
                  </div>



                </Toolbar>
              </Toolbar>
            </AppBar>
            <div className={classes.placeholder} />
          </Hidden>
        ) : props.title === "dashboard" ? (
          <Hidden smDown implementation="css">
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar className={classes.container}>

                <Button
                  ariant="contained"
                  style={{ color: "black", textTransform: "none" }}
                  onClick={() => {
                    props.history.push({ pathname: "/signOut" });
                  }}
                >
                  <Text tid="welcome.signOut" />
                </Button>
                <TextField
                  fullWidth
                  margin="dense"
                  name="status"
                  onChange={(e) => userLanguageChange(e.target.value)}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props

                  style={{ width: "120%", color: "black" }}
                  value={userLanguage}
                  InputProps={{
                    className: classes.root,
                  }}
                >
                  {Object.entries(languageOptions).map(([id, name]) => (
                    <option style={{ color: "white" }} key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </TextField>
              </Toolbar>
            </AppBar>
          </Hidden>
        ) : null
      ) : null}
      <Hidden mdUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.welcomeToolbar}>
            <Hidden mdUp>
              <div className={classes.leftSmall}>
                {props.signIn || props.signUp ? (
                  <Link
                    variant="h6"
                    underline="none"
                    color="inherit"
                    className={classes.title}
                    href="/journey"
                  >
                    {props.signIn ? "Sign In" : "Sign Up"}
                  </Link>
                ) : (
                  <Button href="/journey" style={{ textTransform: "none" }}>
                    <Typography
                      align={"left"}
                      variant="h5"
                      style={{ color: "white", marginTop: "9px" }}
                    >
                      <span
                        color={"white"}
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          marginTop: "9px",
                        }}
                      >
                        Allo
                      </span>
                      Canada
                    </Typography>
                  </Button>
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              {props.signIn || props.signUp ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={props.close}
                >
                  <HighlightOffRoundedIcon
                    style={{ color: "white" }}
                    fontSize={"large"}
                  />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <Menu style={{ color: "white" }} />
                </IconButton>
              )}
            </Hidden>
            <Hidden mdUp implementation="js">
              <MobileSideBarMenu


                open={mobileOpen}
                handleDrawerClose={handleDrawerToggle}
              />
            </Hidden>
          </Toolbar>
        </AppBar>
        <div className={classes.placeholder} />
      </Hidden>

      <MainDialog
          key={"signin"}
          open={signInOpen}

          title={<Text tid="welcome.signIn" />}
          onClose={(e)=>setSignInOpen(false)}
      >
        <SignIn />
      </MainDialog>


      <MainDialog
          key={"signup"}
          open={signUpOpen}

          title={  <Text tid="welcome.signUp" />}
          onClose={(e)=>setSignUpOpen(false)}
      >
        <SignUp />
      </MainDialog>
    </div>
  );
}
export default withRouter(MobileSideBar);
