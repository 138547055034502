import React, {useContext, useEffect, useMemo, useState} from "react";
import Paper from "@material-ui/core/Paper";

import {create} from 'jss';
import rtl from 'jss-rtl'
import {
    Backdrop,
    CardContent,
    CardHeader,
    CircularProgress,
    createTheme,
    InputAdornment,
    jssPreset,
    MenuItem,
    StylesProvider
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core/styles";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useApi from "../../../../hooks/useApi";

import metadata from "../../../../api/metadata";
import Button from "@material-ui/core/Button";

import MyJourney from "./MyJourney";
import journeyApi from "../../../../api/journey";
import {JourneyContext} from "../../providers/JourneyProvider";

import moment from "moment";
import {LanguageContext} from "../../providers/LanguageProvider";
import enFNS from 'date-fns/locale/en-US'

import {AccountCircle} from "@material-ui/icons";
import {FlagCircle} from "@mui/icons-material";

import AutocompleteLocation from "./AutocompleteLocation";

import ProvincialFlag from "./ProvincialFlag";
import Typography from "@material-ui/core/Typography";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {themeOptions} from "../common/theme";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
    cardGrid: {


        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),

    },


}));


const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export default function Journey(props) {
    const classes = useStyles();
    const {textDirection, tt, userLanguage, userLanguageISO639 } = useContext(LanguageContext);



    const provincesQuery = useApi(metadata.getProvinces)


    const {
        journeyUser,
        journeyUserAddress,
        journeyUserStatus,
        province,
        updateJourneyUser

    } = useContext(JourneyContext)


    const [provincialSections, setProvincialSections] = React.useState([])
    const [tags, setTags] = React.useState([])

    const [journeyStarted, setJourneyStarted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loadingProvincialSections, setLoadingProvincialSections] = useState(false)
    const [datePickerLocale, setDatePickerLocale] = useState(enFNS)



    const journeyTheme = useMemo(() => {

        let mainTheme = createTheme({

            ...themeOptions,
            direction: textDirection

        })

        return createTheme(mainTheme, {})



    }, [textDirection])


    useEffect(() => {
        import(`date-fns/locale/${userLanguageISO639}`).then(s => {

            setDatePickerLocale(s.default)

        }).catch(() => {


        })

    }, [userLanguageISO639])

    const [form, setFormState] = useState({
        firstName: null,
        lastName: null,
        province: null,
        dob: new Date(),
        address: null,
        legalStatusId: null
    })

    const handleInputChange = (e) => {
        setFormState({...form, [e.target.name]: e.target.value});
    };


    useEffect(() => {

        if (form.province) {
            setLoadingProvincialSections(true)
            journeyApi.provincialSections(form.province).then(res => {

                let _sections = res.data
                setProvincialSections(_sections)

            }).finally(() => {
                setLoadingProvincialSections(false)

            })

        }

    }, [form.province])


    useEffect(() => {
        if (provincialSections.length > 0) {

            let first = provincialSections.find(s => s.sequence === 1);
            if (first) {
                setFormState(prevState => ({
                    ...prevState,
                    legalStatusId: first.section.legalStatus.id
                }))

            }
            let _tags = provincialSections.map(s => s.section.legalStatus)
            setTags(_tags)

        }

    }, [provincialSections])

    useEffect(() => {
        if (journeyUserStatus) {
            setFormState(prevState => ({
                ...prevState,
                legalStatusId: journeyUserStatus.status.id
            }))
        }
    }, [journeyUserStatus])


    const defaultErrors = {
        firstName: null,
        lastName: null,
        legalStatusId: null,
        province: null,
        address: null

    }

    const [errors, setErrors] = useState(defaultErrors);


    useEffect(() => {
        provincesQuery.request()

    }, [])


    const handleOnProvinceChange = (e) => {

        setFormState(prevState => ({
            ...prevState,
            address: '',
            province: e.target.value
        }))

    }

    const handleOnLegalStatusChange = (e) => {
        setFormState(prevState => ({
            ...prevState,
            legalStatusId: e.target.value
        }))
    }

    useEffect(() => {
        let err = {}

        if (form.firstName?.trim().length === 0) {
            err.firstName = tt('journey.journeyForm.firstName.helperText')
        }

        if (form.lastName?.trim().length === 0) {
            err.lastName = tt('journey.journeyForm.lastName.helperText')
        }

        if (!form.legalStatusId || form.legalStatusId.length === 0) {
            err.legalStatusId = tt('journey.journeyForm.legalStatus.helperText')
        }

        if (!form.address) {
            err.address = tt('journey.journeyForm.address.helperText')

        }


        setErrors(err)
    }, [form])

    const handleOnDobChange = (v) => {

        setFormState(prevState => ({
            ...prevState,
            dob: v
        }))
    }

    useEffect(() => {
        if (journeyUser) {
            setFormState(prevState => ({
                ...prevState,
                address: journeyUserAddress?.address || '',
                province: province?.id || '',
                firstName: journeyUser.firstName,
                lastName: journeyUser.lastName
            }))
            setJourneyStarted(journeyUser.journey !== null)
        }
    }, [journeyUser, journeyUserAddress, province])


    const onStartJourneyClick = (e) => {
        e.preventDefault()
        setSubmitting(true)
        journeyApi.start(form).then(res => {
            if (res.status === 200) {

                updateJourneyUser(res.data)
                setJourneyStarted(true)
            }
        }).finally(() => {
            setSubmitting(false);
        })

    }

    const onAddressChange = (input) => {


        setFormState(prevState => ({
            ...prevState,
            address: input?.description || ''
        }))


    }


    const onConfirmRestartJourney = (data) => {
        setJourneyStarted(false)


    }


    return (
        <ThemeProvider theme={journeyTheme}>

            <StylesProvider jss={jss}>
                <div dir={textDirection}>
            {!journeyUser ? (
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={true}

                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            ) : (!journeyStarted ? (
                    <Container className={classes.cardGrid} maxWidth="md">
                        <Paper>

                            <Card>
                                <CardHeader title={tt('journey.journeyForm.title')} />
                                <CardContent>
                                    <form className={classes.root} noValidate autoComplete="off">
                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    value={form.firstName || ''}
                                                    fullWidth
                                                    id="outlined-error-helper-text"
                                                    label={tt('journey.journeyForm.firstName.label')}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircle/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    name={'firstName'}
                                                    onChange={handleInputChange}
                                                    error={errors.firstName !== undefined}
                                                    helperText={errors.firstName || tt('journey.journeyForm.firstName.helperText')}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    value={form.lastName || ''}
                                                    fullWidth
                                                    id="outlined-error-helper-text"
                                                    label={tt('journey.journeyForm.lastName.label')  }
                                                    name={'lastName'}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccountCircle/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={errors.lastName !== undefined}
                                                    helperText={errors.lastName || tt('journey.journeyForm.lastName.helperText')  }
                                                    onChange={handleInputChange}


                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                                         libInstance={moment}
                                                                         locale={datePickerLocale}>
                                                    <KeyboardDatePicker
                                                        margin="normal"

                                                        id="date-picker-dialog"
                                                        disableFuture={true}
                                                        label={tt('journey.journeyForm.dob.label')}
                                                        format="yyyy-MM-dd"
                                                        value={form.dob}
                                                        helperText={tt('journey.journeyForm.dob.helperText')}
                                                        required
                                                        onChange={handleOnDobChange}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12}>

                                                <TextField

                                                    id="demo-simple-select"
                                                    fullWidth
                                                    select

                                                    value={form.province || ''}
                                                    label={tt('journey.journeyForm.province.label') }
                                                    required
                                                    helperText={tt('journey.journeyForm.province.helperText' )}
                                                    onChange={handleOnProvinceChange}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <FlagCircle/>
                                                            </InputAdornment>
                                                        ),
                                                    }}

                                                >
                                                    {provincesQuery.loading ? (<MenuItem value=''>-</MenuItem>) : (

                                                        provincesQuery.data?.map(prov => {

                                                            let label = get_intl_lang(userLanguage, prov?.intlMetadata)?.name || prov?.name
                                                            return (<MenuItem key={prov.id}
                                                                              value={prov.id}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item>
                                                                        <ProvincialFlag size={50} provinceId={prov.id}/>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Typography>
                                                                            {label}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </MenuItem>)
                                                        })

                                                    )}


                                                </TextField>

                                            </Grid>

                                            <Grid item xs={12}>


                                                <TextField
                                                    id="legal-status-helper"
                                                    select
                                                    label={tt('journey.journeyForm.legalStatus.label')}
                                                    disabled={tags.length === 0 || loadingProvincialSections}
                                                    value={form.legalStatusId || ''}
                                                    fullWidth

                                                    required
                                                    onChange={handleOnLegalStatusChange}

                                                >
                                                    {tags.length === 0 ? (<MenuItem value=''>-</MenuItem>) : (
                                                        tags.map(tag => {
                                                            const name = get_intl_lang(userLanguage, tag.intlMetadata)?.name
                                                            return (<MenuItem key={`st-key-${tag.id}`}
                                                                              value={tag.id}>{name}</MenuItem>)
                                                        })
                                                    )}
                                                </TextField>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <AutocompleteLocation provinceId={form.province}
                                                                      label={tt('journey.journeyForm.address.label')}
                                                                      onChange={onAddressChange}
                                                                      required={true}
                                                                      helperText={tt('journey.journeyForm.address.helperText')}
                                                                      disable={!form.province}
                                                                      defaultValue={form.address}/>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" color="primary" size="large" fullWidth
                                                        disabled={Object.keys(errors).length !== 0 || submitting}
                                                        onClick={onStartJourneyClick}>

                                                    {submitting ? (tt('journey.journeyForm.submitButton.submittingLabel')) : (tt('journey.journeyForm.submitButton.label') )}
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </form>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Container>
                ) : (
                    <Container className={classes.cardGrid} maxWidth="xl">

                        <MyJourney onRestart={onConfirmRestartJourney}/>


                    </Container>
                )
            )}
                </div>
            </StylesProvider>
        </ThemeProvider>
    )
};
