import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import PropTypes from "prop-types";


RestartJourneyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,

    onClose: PropTypes.func.isRequired,
    buttons: PropTypes.element.isRequired
};


export default function RestartJourneyDialog(props) {
    const {open, title, buttons} = props

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (


        <Dialog
            maxWidth={mobile ? 'md' : 'sm'}
            fullWidth
            open={open}
            onClose={props.onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                {buttons}
            </DialogActions>
        </Dialog>

    );
}
