import React, {useContext} from 'react';
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {Alert} from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import SchoolIcon from "@material-ui/icons/School";
import {LanguageContext} from "../../providers/LanguageProvider";
import PropTypes from "prop-types";


TaskContent.propTypes = {
    description: PropTypes.string,
    content: PropTypes.string,
    onLearnMoreClick: PropTypes.func.isRequired
};
export default  function TaskContent(props){
    const {description, onLearnMoreClick, content} = props
    const { tt} = useContext(LanguageContext);
    return (

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Typography
                            style={{fontWeight: 'bold'}}>{tt('journey.tasks.descriptionLabel')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {description ?  (
                            <ReactMarkdown remarkPlugins={[remarkGfm]} children={description}/>
                        ) : (<Alert severity={'warning'}>{tt('journey.tasks.noDescriptionAvailable')}</Alert>

                        )}
                    </Grid>
                </Grid>
                {content &&
                    <Grid item>
                        <Divider/>
                        <Button color={'primary'}
                                startIcon={<SchoolIcon/>}
                                variant={'contained'} fullWidth size={'small'}
                                onClick={onLearnMoreClick}>{tt('journey.tasks.learnMore.button.label')}</Button>
                    </Grid>
                }
            </Grid>


    )
}
