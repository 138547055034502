import apiConfig from "./apiConfig";

const users = () => apiConfig.get("/users");
const me = () => apiConfig.get("/users/me")
const getUserBookmarks = () => apiConfig.get("/users/me/bookmarks")

const addBookmark = (organizationId) => apiConfig
    .post("/users/me/bookmarks", {
        "organizationId": organizationId,

    })

const removeBookmark = (bookmarkId) => apiConfig.delete(`/users/me/bookmarks/${bookmarkId}`)
const province = () => apiConfig.get(`/users/me/province`)
const createUser = (user) => apiConfig.post("/users", user)

const logout = () => apiConfig.post(`/users/me/logout`)
const legalStatus = () => apiConfig.get(`/users/me/status`)
const completeUserTask = (taskId) => apiConfig.post(`/users/me/tasks/legal/${taskId}/complete`)
const undoUserTask = (taskId) => apiConfig.post(`/users/me/tasks/legal/${taskId}/undo`)
const undoUserSocialTask = (taskId) => apiConfig.post(`/users/me/tasks/social/${taskId}/undo`)
const completeUserSocialTask = (taskId) => apiConfig.post(`/users/me/tasks/social/${taskId}/complete`)
const timeline = () => apiConfig.get(`/users/me/timeline`)
const completedTasks = () => apiConfig.get(`/users/me/tasks`)


export default {
    users,
    me,
    addBookmark,
    removeBookmark,
    getUserBookmarks,
    createUser,
    logout,
    province,
    legalStatus,
    completeUserTask,
    timeline,
    completeUserSocialTask,
    undoUserSocialTask,
    completedTasks,
    undoUserTask
};
