import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import OrgCard from "./OrgCard";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import userapi from "../../../../api/users";
import React, {useContext, useEffect, useMemo, useState} from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import {LanguageContext} from "../../providers/LanguageProvider";
import useBookmark from "../../../../hooks/useBookmark";


export default function OrgCardDialog({org, handleCloseOrgCardDialog, open}){
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { tt} = useContext(LanguageContext);



    const [isBookmarked, setIsBookmarked] = useState(false)

    const {  processing,  addBookmark, removeBookmark, findOrgBookmark} = useBookmark()

    useEffect(()=>{
        const bm = findOrgBookmark(org.id);
        if(bm){

            setIsBookmarked(true)

        } else {
            setIsBookmarked(false)
        }

    }, [org, findOrgBookmark])



    const handleBookmark = ()=>{
        const bm = findOrgBookmark(org.id)
        if(bm){
            removeBookmark(bm.id)
        } else {
            addBookmark(org.id)

        }
    }
    return (
        <Dialog
            onClose={handleCloseOrgCardDialog}
            aria-labelledby="simple-dialog-title"

            fullScreen={mobile}
            open={open}>
            <DialogTitle

                onClose={handleCloseOrgCardDialog}>{org.name}</DialogTitle>
            <DialogContent>
                <OrgCard
                    organization={org}
                    showTitle={false}></OrgCard>
            </DialogContent>
            <DialogActions>

                <Button
                    variant={'contained'}
                    onClick={handleBookmark}
                    disabled={processing}
                    color={isBookmarked  ? "secondary" : "primary"}>
                    {isBookmarked  ? <>Remove Bookmark</>   :    <>Bookmark</> }

                </Button>


                <Button
                    variant={'contained'}
                    onClick={handleCloseOrgCardDialog}
                    color="primary">
                    {tt('journey.orgCard.dialog.closeOrgCardLabel')}
                </Button>

            </DialogActions>
        </Dialog>
    )
}