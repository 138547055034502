import React, {useContext} from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {withRouter} from "react-router-dom";
import UserInfo from "../../components/navbar/UserInfo";
import Hidden from "@material-ui/core/Hidden";
import {boxShadow, defaultFont, transition,} from "../../public/material-kit-react";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import AppNavBar from "./AppNavBar";
import Links from "./HamburgerMenuLinks";
import {blackColor, grayColor, hexToRgb, whiteColor} from "./mainPageStyle";
import Grid from "@material-ui/core/Grid";
import {Text} from "../../providers/LanguageProvider";
import {UserContext} from "../../providers/UserInfoContext";

const drawerWidth = 286;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    //new
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#9c9c9c",
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  logoIcon: {
    height: 35,
    width: 35,
    color: theme.palette.primary.main,
    marginTop: 2,
  },
  customizeToolbar: {
    minWidth: 36,
    backgroundColor: "#0092d4",
  },
  icon: {
    color: "#0191d5",
    height: 40,
    width: 40,
  },
  //new
  menuButton: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: "white",
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "grey",
      },
    },
  },
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  background: {
    position: "absolute",
    // zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      // zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: blackColor,
      // background:"#c0c0c0",
      opacity: ".8",
    },
  },
  logo: {
    position: "relative",
    // padding: "15px 15px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)",
    },
  },
  logoLink: {
    ...defaultFont,
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "30px",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: whiteColor,
    },
  },
}));

const Sidebar = withRouter((props) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [open] = React.useState(false);
  const brand = (
    <div className={classes.logo}>
      <List>
        <ListItem
          button
          onClick={() => {
            props.history.push({ pathname: "/journey" });
          }}
        >
          <ListItemText>
            <Grid container direction={"row"} justifyContent={"center"}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "white", marginTop: "9px" }}
              >
                Allo
              </Typography>
              <Typography
                variant="h5"
                style={{ color: "white", marginTop: "9px" }}
              >
                {"Canada"}
              </Typography>
            </Grid>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );

  const pages = [
    {
      title: <Text tid="welcome.journey" />,
      href: "/journey",
    },

    {
      title: <Text tid="welcome.bookmarks" />,
      href: "/bookmarks",
    },
    {
      title: <Text tid="welcome.FAQ" />,
      href: "/faq",
      icon: <LiveHelpRoundedIcon />,
    },

  ];
  return (
    <div className={classes.root}>
      <AppNavBar   pages={pages} />
      <Hidden smDown implementation="css">
        <Drawer
          open
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
        >
          <div style={{ zIndex: "1" }}>{brand}</div>
          <Divider />
          <div style={{ zIndex: "1" }}>
            <UserInfo />
          </div>
          <div className={classes.background} />
          <Links logged={!!(user && user.firstName)} color={"blue"} />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      </Hidden>
      <Hidden mdUp>
        <main>
          <div className={classes.toolbar} />
          {props.children}
        </main>
      </Hidden>
    </div>
  );
});

export default Sidebar;
