import React, {useContext, useEffect} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";

import devbloc from "../../../public/devblocImg.png";
import CanadRedCross from "../../../public/CanadainRedCrossImg2.png";
import RefugeeCenter from "../../../public/TRCfromSource.png";
import {LanguageContext, Text} from "../../../providers/LanguageProvider";
import {Divider} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import AOS from "aos";
// function Copyright() {
//   return (
//     <React.Fragment>
//       {"© "}
//       <Link color="inherit" href="/welcome">
//         AlloCanada
//       </Link>{" "}
//       {new Date().getFullYear()}
//     </React.Fragment>
//   );
// }

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "beige",
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
  },
  iconsWrapper: {
    // height: 350,
    // width:"360px",
  },
  icons: {
    display: "flex",
  },
  icon: {
    width: 120,
    height: 100,
  },
  list: {
    margin: 0,
    listStyle: "none",
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  text: {
    textTransform: "none",
    fontWeight:"bold"
  },
  logo:{
    opacity:0.7,
    "&:hover":{
      opacity: 1
    }
  }

}));

export default function AppFooter() {
  const theme = useTheme();
  const classes = useStyles();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { userLanguage } = useContext(LanguageContext);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
      <Container  className={classes.container} maxWidth={"lg"}>
        <Grid container direction={"row"} justifyContent={"center"} style={{width:"100%"}}   data-aos="fade-up">
          <Grid item xs={12} sm={12} md={4} style={{width:"100%"}}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              style={{width:"100%"}}
            >
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    marked={userLanguage === "ara" ? "center": "left"}
                    align={userLanguage === "ara" ? "center": "left"}
                    gutterBottom
                    className={classes.text}

                  >
                    <Text tid="welcome.footer" />{" "}
                  </Typography>
                 </Grid>
                  <Grid item  xs={12}>
                    <Grid container direction={"row"} justifyContent={"center"}>
                      <Grid item  xs={4} md={4} sm={2}
                            className={classes.logo}
                            style={{width:"100%",height:"100%", alignSelf: "center"}}
                      >
                        <a
                            href={"http://www.devbloc.ca/"}
                        >
                          <img
                              style={{alignSelf:"center"}}
                              src={devbloc}
                              alt="DevBloc"
                              width={"50%"}
                              height={"50%"}
                          />
                        </a>
                      </Grid>
                      <Grid item xs={6} md={6} sm={4}
                            style={{width:"100%",height:"100%",alignSelf: "center"}}
                            className={classes.logo}
                      >
                        <a
                            href={"https://www.therefugeecentre.org/"}
                            style={isSm? {
                              width: "100%",
                              height: "100%",
                            }:{
                              marginLeft: "10px",
                              width: "100%",
                              height: "100%",
                            }}
                        >
                          <img
                              src={RefugeeCenter}
                              alt="Refugee Center"
                              style={{alignSelf:"center"}}
                              width={"100%"}
                              height={"100%"}
                          />
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Divider orientation={"vertical"}
                     style={{height:"50%",marginTop:"50px"}}/>
          </Hidden>
              <Grid item md={2} xs={12}>
                <Grid container direction={"column"} justifyContent={"flex-start"}>
                  <Grid item  xs={12}>
                    <Typography
                        variant="h6"
                        // marked={"left"}
                        marked={userLanguage === "ara" ? "center": "left"}
                        align={userLanguage === "ara" ? "center": "left"}
                        gutterBottom
                        className={classes.text}
                        style={isSm? {marginLeft:"0px"}:{marginLeft:"20px"}}
                    >
                      <Text tid="welcome.partnership" />{" "}
                    </Typography>
                  </Grid>
                    <Grid item xs={6} md={10} sm={3}
                          style={ isSm? {width:"100%",height:"100%",alignSelf: "center"
                          }:{ width:"100%",height:"100%", }}
                          className={classes.logo}
                    >
                      <a
                          href="https://www.redcross.ca/"
                      >
                        <img
                            style={
                              isSm?
                              {
                              marginTop:"0px",
                              marginLeft:"0px"
                            }:
                                  {
                                    marginLeft:"20px",
                                    paddingTop:"10px"
                                  }
                            }
                            src={CanadRedCross}
                            alt="CanadRedCross"
                            width={"100%"}
                            height={"100%"}
                        />
                      </a>
                    </Grid>
                </Grid>
              </Grid>
          <Hidden smDown>
            <Divider orientation={"vertical"}
                     style={{height:"50%",marginTop:"50px"}}/>
          </Hidden>
          <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              style={isSm? {marginLeft:"0px"}:{marginLeft:"30px"}}
          >
            <Typography
                variant="h6"
                align={userLanguage === "ara" ? "center": "left"}
                marked={userLanguage === "ara" ? "center": "left"}
                gutterBottom
                className={classes.text}
            >
              <Text tid="welcome.contactUs" />
            </Typography>
            <div align={userLanguage === "ara" ? "center": "left"}>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <span style={ { fontWeight: "bold"} }> Email: </span>
                allocanada@therefugeecentre.org
              </li>
            </ul>
            </div>

          </Grid>

        </Grid>
      </Container>
  );
}
