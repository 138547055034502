export function getChildren( node){
    if(node.subCategories.length === 0){
        return node

    }
    return node.subCategories.map(cat => {
        return getChildren(cat)
    })

}
