import React, {createContext, useEffect, useState} from "react";
import useApi from "../../../hooks/useApi";
import userapi from "../../../api/users";

export const UserContext = createContext({
    user: null,
    findOrgBookmark: (orgId) => {}
    //updateUserBookmarks: (bookmarks) => {},
    //userChange: (userObject) => {}

});
export function UserInfoContext({ children }) {
    const [user, setUser] = useState(null);

    const activeUser = useApi(userapi.me)



    const findOrgBookmark = (orgId) =>{
        return user?.bookmarks.find(bm => bm.organizationId === orgId);


    }

    useEffect(() => {
        if(localStorage.getItem('user')){

            activeUser.request()
        }




    }, []);

    useEffect(() => {
        if (activeUser.data) {
            setUser(activeUser.data)


            localStorage.setItem("user", JSON.stringify(activeUser.data))
        }
    }, [activeUser])


    const updateUserBookmarks = (bookmarks) =>{
        if(user){
            let u = user
            u.bookmarks = bookmarks

            setUser(u)
        }

    }


    const provider = {
        user,
        findOrgBookmark,
        updateUserBookmarks,
        userChange: (userObject) => {
            setUser(userObject);
            localStorage.setItem("user", JSON.stringify(userObject))


        },
    };
    return (
        <UserContext.Provider value={provider}>
            {children}
        </UserContext.Provider>
    );
}

