import React, {useContext, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import JourneyTimeline from "./JourneyTimeline";
import PropTypes from "prop-types";
import {LanguageContext} from "../../providers/LanguageProvider";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

JourneyTimelineDialog.propTypes = {

    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired

};
export default function JourneyTimelineDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {textDirection, tt } = useContext(LanguageContext);

    useEffect(() => {

        setOpen(props.open)

    }, [props.open])


    return (

        <Dialog fullScreen open={open} onClose={props.onClose} TransitionComponent={Transition}                     dir={textDirection}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {tt('journey.timeline.title')}
                    </Typography>

                </Toolbar>
            </AppBar>
            <JourneyTimeline/>
        </Dialog>

    );
}
