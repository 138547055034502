import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import {withRouter} from "react-router-dom"
import styles from "./headerStyle"
import Button from "@material-ui/core/Button";
import AppToolbar from "./AppToolbar";
import MobileSideBarMenu from "../../general/MobileSideBarMenu";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(styles);

function AppNavBar(props) {
     const [mobileOpen, setMobileOpen] = React.useState(false);
     const classes = useStyles();
     const handleDrawerToggle = () => {
         setMobileOpen(!mobileOpen);
     };
    function makeBrand() {
        let currentPage = {};
        props.pages.map(prop => {
            if (window.location.href.indexOf(prop.href) !== -1) {
                currentPage.name = prop.title;
                currentPage.href = prop.href
            }
            return null;
        });
        if(currentPage){
            return (
                <Button href={currentPage?.href}  size={"large"} >
                    <Typography variant={'h5'}> {currentPage?.name  } </Typography>
                </Button>
            )
        }
    }
    return (
        <AppBar position="absolute" className={classes.appBar} style={{
            boxShadow:"none",
            backgroundColor:"#eeeeee",
            zIndex:0
        }}>
            <Toolbar>
                <div className={classes.flex}>

                    <Hidden smDown>
                            {makeBrand()}
                    </Hidden>
                 </div>
                 <Hidden smDown implementation="css">
                   <AppToolbar />
                 </Hidden>
                 <Hidden mdUp implementation="css">
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                         >
                        <Menu />
                        </IconButton>
                 </Hidden>
                  <Hidden mdUp implementation="js">
                    <MobileSideBarMenu
                        logged={props.title === "dashboard"}
                        open ={mobileOpen}
                        handleDrawerClose={handleDrawerToggle}
                    />
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

AppNavBar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object)
};

export default withRouter(AppNavBar)
