import OrganizationFinderTool from "./OrganizationFinderTool";

import React, {useContext,  useState} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";

import {useTheme} from "@material-ui/core/styles";
import {JourneyContext} from "../../providers/JourneyProvider";

import OrgCardDialog from "./OrgCardDialog";

OrganizationContent.propTypes = {
    serviceId: PropTypes.number,
    provincialSection: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,

};


 function OrganizationContent(props) {

    const theme = useTheme();

    const {userCoordinates, journeyUserAddress} = useContext(JourneyContext)
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedOrgCard, setSelectedOrgCard] = useState(null)
    const onSelectOrganization = (org) => {
        setSelectedOrgCard(org)

    }


    const handleCloseOrgCardDialog = () =>{
        setSelectedOrgCard(null)
    }


    return (
        <>

            <OrganizationFinderTool
                legalStatus={props.provincialSection.section.id}
                serviceId={props.serviceId}
                density={mobile ? "compact" : "standard"}
                taskId={props.task.id}
                userAddress={journeyUserAddress.address}
                onSelectOrganization={onSelectOrganization}
                coordinates={userCoordinates}/>

            {selectedOrgCard && (
                <OrgCardDialog open={true} org={selectedOrgCard}  handleCloseOrgCardDialog={handleCloseOrgCardDialog}/>
            )}


        </>
    )

}



function propsAreEqual(prev, next) {

    return prev.serviceId === next.serviceId && prev.provincialSection.id === next.provincialSection.id && prev.task.id === next.task.id;
}
export default React.memo(OrganizationContent, propsAreEqual)