import apiConfig, {apiUrl} from "./apiConfig";
import axios from "axios";

const login = (formData) => axios.post(`${apiUrl}/auth/login`, formData, {headers: {"Content-Type": "multipart/form-data"}});
const refresh = () => apiConfig.post("/auth/refresh")
const authorize = (token) => apiConfig.post("/auth/authorize", {"access_token": token})
export default {

    login,
    refresh,
    authorize
};
