/* eslint-env react */
import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import TokenService from "../../../../token.service";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (<Route {...rest} render={(props) =>// eslint-disable-next-line
            TokenService.getLocalAccessToken() ?
                (<Component {...props} logged={true}/>)
                : (
                    <Redirect to='/welcome'/>)}/>
    )
}
export default PrivateRoute
