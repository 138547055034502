import React, {useContext, useState} from "react";

import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {checkEmailFormat} from "../utils/commonUsedFunctions";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import img from "./public/key.svg"
import Container from "@material-ui/core/Container";

import {LanguageContext} from "../../providers/LanguageProvider";

import * as firebase from 'firebase/app';

import 'firebase/auth';


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#eeeeee",
    backgroundPosition: "center",
    boxShadow:"unset"
  },
  paper: {
    margin: theme.spacing(31, 4),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(4,4),
    },
    boxShadow:"unset",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#008597",
    width: 50,
    height: 50,
  },
  error: {
    color: "red",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },

  submitContainer: {
    marginBottom: theme.spacing(3),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [errors, setErrors] = useState( {email: null});
  const [disable, setDisable] = useState(false);
  const [generalError, setGeneralError] = useState('')
  const {tt, userLanguageISO639} = useContext(LanguageContext);

  const submitHandler = async () => {
    const errors = {};
    errors['email'] = !checkEmailFormat(email) ? tt('signInUp.errors.invalidEmail') : null;
    if (!errors.email ) {
      setDisable(true)
      setGeneralError('')


      // web version 8 (namespaced)
      firebase.auth().languageCode = userLanguageISO639
      firebase.auth().sendPasswordResetEmail(email).then(()=>{

          setEmailSent(true)
        }).catch(e=>{
            console.log(e)
            setGeneralError(tt('signInUp.errors.accountNotFoundEmail'))

        }).finally(()=>{
          setDisable(false)
        })

    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      <Container maxWidth={"lg"}>
      <Grid container className={classes.root} direction={"row"} alignContent={"center"} justify={"center"}>
        <Grid item xs={false} sm={false} md={false} lg={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
        >
          <div className={classes.paper}>
            {emailSent ? (
              <Grid
                container
                justify={"center"}
                direction={"column"}
                spacing={4}
                alignItems={"center"}
                style={{ marginTop: "80px", padding: "30px" }}
              >
                <Grid item>
                  <CheckCircleRoundedIcon
                    style={{ width: 100, height: 100, color: "#298" }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant={"h5"} align={"center"}>
                    {tt('forgotPassword.emailOnTheWay')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button
                      color="primary"
                      variant="contained"
                      size="large"

                      href={"/welcome"}
                  >
                    {tt('forgotPassword.backButtonLabel')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction={"column"}
                alignItems={"center"}
                spacing={5}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction={"column"}
                    alignItems={"center"}
                    spacing={3}
                  >
                    <Grid item xs={12}>
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon style={{ width: 30, height: 30 }} />
                      </Avatar>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction={"column"}
                        alignItems={"center"}
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            align={"center"}
                          >
                            {tt('signInUp.forgotPassword')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            xs={3}
                            md={3}
                            align={"center"}
                          >
                            {tt('forgotPassword.instruction')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{width:"100%"}}>
                    <TextField
                      name="email"
                      required
                      error={!errors.email}
                      placeholder={tt('signInUp.email')}
                      fullWidth
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        //handleDisable(e.target.value);
                      }}
                      helperText={errors.email}
                      autoFocus
                    />
               </Grid>
               <Grid item xs={12} className={classes.submitContainer}>
                  <Grid container>
                    <Grid
                      container
                      direction={"row"}
                      spacing={2}
                      justify={"center"}
                    >
                      <Grid item>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="large"

                          component="a"
                          href={"/welcome"}
                        >
                          {tt('forgotPassword.backButtonLabel')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          size={'large'}
                          onClick={submitHandler}
                          disabled={disable || email === ''}

                        >
                          {tt('forgotPassword.getResetLinkLabel')}
                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>
                 {generalError && (
                     <Grid container justifyContent="center">
                       <Grid item>
                         <div className={classes.error}><p>{ generalError }</p></div>
                       </Grid>

                     </Grid>
                 )}
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
      </Container>
    </div>
  );
};

export default withRouter(ForgotPassword);

