import React, {useContext} from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/pro-solid-svg-icons";
import {languageOptions} from "../../../../languages";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {LanguageContext} from "../../providers/LanguageProvider";
import Button from "@material-ui/core/Button";


export default function LanguageMenu({onlanguagechange}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { tt,  userLanguageISO639, userLanguageChange } = useContext(LanguageContext);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const onChange = (lang) =>{
        handleClose()

        onlanguagechange(lang)
    }
    return (
        <div style={{display:"block"}}>

            <Button
                onClick={handleClick}
                style={{   color:"#fff", border:"none"}}
                variant={"outlined"}
                size={"small"}

                startIcon={<FontAwesomeIcon icon={faLanguage } />}


            >
                <Typography   variant="h6"  >
                    {userLanguageISO639}
                </Typography>
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {Object.entries(languageOptions).map(([id, name]) => (

                    <MenuItem onClick={(e)=>onChange(id)}>{name}</MenuItem>
                ))}
            </Menu>
        </div>
    );
}
