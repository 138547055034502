import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import {JourneyContext} from "../../providers/JourneyProvider";
import {LanguageContext} from "../../providers/LanguageProvider";
import {CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {blueGrey} from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MySocialTasks from "./MySocialTasks";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {Skeleton} from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faClipboard, faGavel, faList} from "@fortawesome/pro-solid-svg-icons";


const useStyles = makeStyles((theme) => ({
    taskAvatar: {

        backgroundColor: blueGrey[500],
    },
    legalTaskIcon: {
        backgroundColor: theme.journey.legalTask.main,

    },
    socialTaskIcon: {

        backgroundColor: theme.journey.socialTask.main,
    },
    card: {
        height: "100%"

    }
}));


export default function MyTasksCard(props) {
    const classes = useStyles();


    const theme = useTheme();
    const {

        completedProvincialTasks,
        completedSocialProvincialTasks,
        provincialTasks,
        availableSocialTasks,
        socialProvincialTasks,
        journeyUser
    } = useContext(JourneyContext)

    const {userLanguageISO639, tt } = useContext(LanguageContext);
    const [percentCompletedProvincialTasks, setPercentCompletedProvincialTasks] = useState(0)
    const [percentCompletedSocialProvincialTasks, setPercentCompletedSocialProvincialTasks] = useState(0)

    useEffect(() => {
        if (provincialTasks.length > 0) {
            setPercentCompletedProvincialTasks(Math.round((completedProvincialTasks.length / provincialTasks.length) * 100))
        }
    }, [completedProvincialTasks, provincialTasks])


    useEffect(() => {
        if (availableSocialTasks.length > 0) {
            setPercentCompletedSocialProvincialTasks(Math.round((completedSocialProvincialTasks.length / availableSocialTasks.length) * 100))
        }
    }, [completedSocialProvincialTasks, availableSocialTasks])
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false)


    const viewSocialTasks = (e) => {

        setOpen(true)

    }

    const closeSocialTaskDialog = (e) => {
        setOpen(false)

    }

    return (
        <Card raised className={classes.card}>
            <CardHeader
                avatar={
                    !journeyUser ?
                        (
                            <Skeleton animation="wave" variant="circle" width={40} height={40}/>
                        ) : (
                            <Avatar className={classes.taskAvatar} variant={'rounded'} aria-label="legal-tasks">

                                <FontAwesomeIcon icon={faClipboard}/>
                            </Avatar>
                        )
                }

                title={
                    !journeyUser ? (
                        <Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>
                    ) : (
                        tt('journey.tasks.myTitle')
                    )
                }
                subheader={tt('journey.tasks.subTitle')}
            />

            <CardContent  >

                <List dense>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.legalTaskIcon}>
                                <FontAwesomeIcon icon={faGavel}/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={tt('journey.tasks.legal.label')}
                            secondary={tt('journey.tasks.legal.percentComplete', percentCompletedProvincialTasks.toLocaleString(userLanguageISO639))}
                        />

                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar className={classes.socialTaskIcon}>
                                <FontAwesomeIcon icon={faList}/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={tt('journey.tasks.social.label')}
                            secondary={tt('journey.tasks.social.percentComplete', percentCompletedSocialProvincialTasks.toLocaleString(userLanguageISO639))}
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title={tt('journey.tasks.social.tooltip.label')} placement="right">
                                <IconButton edge="end" aria-label="help" onClick={viewSocialTasks}>
                                    <FontAwesomeIcon icon={faCircleQuestion}/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

            </CardContent>


            <React.Fragment>
                <Dialog
                    onClose={closeSocialTaskDialog}
                    aria-labelledby="soc-task-info-dialog"
                    fullWidth={true}
                    fullScreen={fullScreen}
                    maxWidth={'xl'}
                    open={open}>
                    <DialogTitle
                        onClose={closeSocialTaskDialog}>{tt('journey.tasks.social.myTitle')}</DialogTitle>
                    <DialogContent dividers>
                        {socialProvincialTasks.length === 0 ? (
                            <Typography>{tt('journey.tasks.social.noSocialTasks')}</Typography>) : (
                            <MySocialTasks socialProvincialTasks={socialProvincialTasks}/>)}

                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus
                                variant={'contained'}
                                onClick={closeSocialTaskDialog}
                                color="primary">
                            {tt('journey.tasks.social.dialog.closeButtonLabel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>


        </Card>

    );
}
