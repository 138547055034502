import React, {useContext, useEffect, useMemo, useState} from "react";
import {Grid, ListItem, ListItemIcon, ListItemSecondaryAction} from "@material-ui/core";

import OrganizationContent from "./OrganizationContent";
import PropTypes from "prop-types";
import {get_intl_lang} from "../utils/commonUsedFunctions";
import {LanguageContext} from "../../providers/LanguageProvider";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import {JourneyContext} from "../../providers/JourneyProvider";

import usersApi from "../../../../api/users";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SocialTaskDialog from "./SocialTaskDialog";
import Button from "@material-ui/core/Button";

import LearnMoreDialog from "./LearnMoreDialog";
import TaskSummary from "./TaskSummary";
import SearchOrgsDialog from "./SearchOrgsDialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/styles";
import moment from "moment";
import TaskContent from "./TaskContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion} from "@fortawesome/pro-solid-svg-icons";

SocialTaskItem.propTypes = {
    socialProvincialTask: PropTypes.object.isRequired,
    provincialSection: PropTypes.object.isRequired,

};

const useStyles = makeStyles((theme) => ({

    unaffected: {
        flip: false,
        textAlign: 'right',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(13),
        color: theme.palette.text.secondary,
        fontStyle: 'italic'
    },
}));

export default function SocialTaskItem(props) {


    const theme = useTheme();
    const classes = useStyles(theme)
    const {userLanguage, tt, userLanguageISO639} = useContext(LanguageContext);
    const [isAvailable, setIsAvailable] = useState(false)
    const [isComplete, setIsComplete] = useState(false)
    const [disable, setDisable] = useState(false)
    const [open, setOpen] = useState(false)
    const [completedSocialProvincialTaskObj, setCompletedSocialProvincialTaskObj] = useState(null)

    const [learnMoreDialogOpen, setLearnMoreDialogOpen] = React.useState(false)
    const [searchOrgs, setSearchOrgs] = useState(false)

    const {
        isSocialProvincialTaskComplete,
        isSocialTaskAvailable,
        getCompletedSocialProvincialTaskById,
        setCompletedSocialProvincialTasks,
        isProvincialTaskComplete,
        userTimeZone,
        getProvincialTaskById
    } = useContext(JourneyContext)


    useEffect(()=>{

    }, [])


    useEffect(() => {
        const completedTobj = getCompletedSocialProvincialTaskById(props.socialProvincialTask.id)
        const isAvailable = isSocialTaskAvailable(props.socialProvincialTask.socialTaskId)
        setCompletedSocialProvincialTaskObj(completedTobj)
        setIsAvailable(isAvailable)

    }, [props.socialProvincialTask,  isSocialTaskAvailable, getCompletedSocialProvincialTaskById])

    useEffect(()=>{
        setIsComplete(isSocialProvincialTaskComplete(props.socialProvincialTask.id))

    }, [isSocialProvincialTaskComplete, props.socialProvincialTask])

    const completedDateStr = useMemo(() => {
        if (completedSocialProvincialTaskObj?.completedDate) {

            return moment.utc(completedSocialProvincialTaskObj.completedDate).tz(userTimeZone).format('llll z').toString()
        }
        return ''

    }, [completedSocialProvincialTaskObj, userTimeZone])



    const task = useMemo(() => {

        return props.socialProvincialTask.task;

    }, [props.socialProvincialTask])

    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const taskCategory = useMemo(() => {
        return props.socialProvincialTask.task.categories.find(cat => cat.tagId === props.provincialSection.section.id)?.categoryId

    }, [props.socialProvincialTask, props.provincialSection])


    const organizationContent = (

         <OrganizationContent
            provincialSection={props.provincialSection}
            serviceId={taskCategory}
            task={props.socialProvincialTask}

        />

    )


    // either get the specific translation object (for that particular province and task), or get the default one that belongs to the task
    const taskTranslationObject = useMemo(() => {

        return get_intl_lang(userLanguage, task.intlTask)

    }, [userLanguage, task, props.socialProvincialTask])

    const provincialTaskTranslationObject = useMemo(() => {

        return get_intl_lang(userLanguage, props.socialProvincialTask.intlTask)

    }, [userLanguage, task, props.socialProvincialTask])



    const taskName = useMemo(()=>{

        return provincialTaskTranslationObject?.name || taskTranslationObject?.name || task.internalName
    }, [taskTranslationObject, task, provincialTaskTranslationObject])


    const label = useMemo(()=>{

        let label = <Typography style={{fontWeight:'bold'}}>{props.socialProvincialTask.sequence.toLocaleString(userLanguageISO639) + '. ' + taskName}</Typography>
        if (!isAvailable && label !== '') {
            const provincialTask = getProvincialTaskById(props.socialProvincialTask.provincialTaskId)

            const provincialTaskName = get_intl_lang(userLanguage, provincialTask.task?.intlTask)?.name

            label =  <Typography>{label}<em>{tt('journey.tasks.social.notAvailableLabel').format(provincialTaskName)}</em></Typography>;
        }

        return  label;
    }, [task, isAvailable, tt, taskName, props.number, getProvincialTaskById])

    const content = useMemo(() => {
        // merge default task content as well as social task content
        let c = ''

        if(taskTranslationObject?.content) {
            c += taskTranslationObject.content
        }

        if(provincialTaskTranslationObject?.content){
            c += '\n\n---\n\n' + provincialTaskTranslationObject.content
        }

        return c


    }, [provincialTaskTranslationObject, taskTranslationObject])

    const description = useMemo(() => {
        return provincialTaskTranslationObject?.description || taskTranslationObject?.description
    }, [provincialTaskTranslationObject, taskTranslationObject])



    const onToggleSocialTaskCheckbox = (e, isComplete) => {
        const socialProvincialTaskId = props.socialProvincialTask.id

        let request;
        if (!isComplete) {
            request = usersApi.completeUserSocialTask(socialProvincialTaskId)

        } else {

            request = usersApi.undoUserSocialTask(socialProvincialTaskId)

        }
        setDisable(true)
        request.then((res) => {

            setCompletedSocialProvincialTasks(res.data.completedSocialTasks)

        }).finally(() => {
            setDisable(false)

        })

    }


    const onSocialTaskInfoClick = () => {

        setOpen(true)

    }

    const onSocialTaskInfoDialogClose = () => {
        setOpen(false)

    }



    const isLegalTaskComplete = useMemo(() => {

        return isProvincialTaskComplete(props.socialProvincialTask.provincialTaskId);

    }, [props.socialProvincialTask, isProvincialTaskComplete])


    const learnMore = (e) => {

        setLearnMoreDialogOpen(true)

    }

    const searchOrgsHandle = (e) => {

        setSearchOrgs(true)
    }




    return (
        <>
            <ListItem

                value={props.socialProvincialTask.id}
                key={props.socialProvincialTask.id.toString()}
                role={undefined}
                disabled={disable || !isLegalTaskComplete || !isAvailable}

                dense
               >
                <ListItemIcon>
                    <Checkbox
                        onClick={(e) => onToggleSocialTaskCheckbox(e, isComplete)}
                        edge="start"
                        checked={isComplete}
                        tabIndex={-1}
                        disabled={disable || !isLegalTaskComplete || !isAvailable}
                        disableRipple

                    />
                </ListItemIcon>
                <ListItemText

                    primary={<Typography>{label}</Typography>}
                    secondary={<Typography
                        className={classes.secondaryHeading}
                        sx={{color: 'textSecondary'}}> {isComplete && completedDateStr}</Typography>
                }
                />
                <ListItemSecondaryAction>
                    <Tooltip title={tt('journey.tasks.social.list.moreInfoIcon.tooltip')} aria-label="view-info">
                        <IconButton edge="end" aria-label="view-info"
                                    onClick={onSocialTaskInfoClick}>
                            <FontAwesomeIcon icon={faCircleQuestion}/>
                        </IconButton>
                    </Tooltip>

                </ListItemSecondaryAction>
            </ListItem>
            <SocialTaskDialog
                socialProvincialTask={props.socialProvincialTask}
                provincialSection={props.provincialSection}
                open={open}
                title={label}
                closeButtonLabel={tt('journey.tasks.social.list.dialog.goBackButton')}
                onClose={onSocialTaskInfoDialogClose}
            >

                <Grid container spacing={2}>


                    <TaskContent description={description} content={content}  onLearnMoreClick={learnMore} />

                    <Grid item xs={12}>
                        {mobile ? (
                            <Button variant={'contained'} startIcon={<SearchIcon/>} fullWidth size={'large'}
                                    onClick={searchOrgsHandle}>{tt('journey.tasks.legal.tabs.org.searchOrgButtonLabel')}</Button>

                        ) : (
                            <>
                                <Grid item xs={3}>
                                    <Typography
                                        style={{fontWeight: 'bold'}}>{tt('journey.tasks.orgFinder.label')}</Typography>
                                </Grid>
                                {organizationContent}
                            </>

                        )}
                    </Grid>


                </Grid>
            </SocialTaskDialog>
            <LearnMoreDialog open={learnMoreDialogOpen} handleClose={(e) => setLearnMoreDialogOpen(false)}
                             closeButtonLabel={tt('journey.tasks.learnMore.dialog.learnMoreCloseButtonLabelGoBackAlternate')}
                             title={taskName}>
                <TaskSummary content={content || ''}/>
            </LearnMoreDialog>


            <SearchOrgsDialog
                open={searchOrgs}
                onClose={(e) => setSearchOrgs(false)}
                taskName={taskName}
            >
                {organizationContent}
            </SearchOrgsDialog>
        </>


    )

}