import {List} from "@material-ui/core";
import React, {useContext} from "react";

import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import {LanguageContext} from "../../providers/LanguageProvider";

import SocialTaskItem from "./SocialTaskItem";

SocialTasksTabContent.propTypes = {
    socialTasks: PropTypes.array.isRequired,

    provincialSection: PropTypes.object.isRequired,
    legalTask: PropTypes.object.isRequired,
    isLegalTaskComplete: PropTypes.bool
};

function SocialTasksTabContent(props) {
    const {    tt} = useContext(LanguageContext);



    return (
            <>
                {props.socialTasks.length === 0 ? (
                    <Typography variant={'body1'} align={'center'}
                                >{tt('journey.tasks.legal.tabs.soc.noSocialTasks')}</Typography>
                ) : (
                    <List dense>
                        {props.socialTasks.map((socialProvincialTask, i) => {
                            return (
                                <SocialTaskItem provincialSection={props.provincialSection}

                                                socialProvincialTask={socialProvincialTask}/>
                            );
                        })}
                    </List>
                )}
            </>




    )
}

export default React.memo(SocialTasksTabContent)