import apiConfig from "./apiConfig";

const autocomplete_search = (params) => apiConfig.get(`/maps/geo/s`, {params: params})
const get_address_coordinates = (searchInput) => apiConfig.post(`/maps/address/coordinates`, [{"address": searchInput}])

export default {

    autocomplete_search,
    get_address_coordinates
};
