import React, {useMemo} from "react";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function ServicesOffered(props) {
    const {servicesOffered} = props
    const classes = useStyles();


    const services = useMemo(() => {

        if (servicesOffered) {
            let s = {}
            let _s = []
            for (let i in servicesOffered) {

                const service = servicesOffered[i]
                const id = service.serviceId
                if (id in s) {
                    s[id].tags.push(service.tag)
                } else {
                    s[id] = {
                        service: service.service,
                        tags: [service.tag]
                    }
                }
            }

            for (let [id, serviceObj] of Object.entries(s)) {
                _s.push(serviceObj)

            }

            return _s
        }

        return []

    }, [servicesOffered])

    return (

        <div>
            <List>
                {services.map(s => {

                    return (
                        <ListItem alignItems="flex-start">
                            <ListItemText

                                primary={s.service.name}
                                secondary={<div className={classes.root}>

                                    {s.tags.map(t => {
                                        return (

                                            <Chip
                                                variant="outlined"
                                                size="small"
                                                label={t.name}

                                            />
                                        )
                                    })}


                                </div>}
                            />
                        </ListItem>
                    )

                })}
            </List>
        </div>


    )

}