import React, {useContext, useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import Typography from "../modules/components/Typography";
import Container from "@material-ui/core/Container";
import OrganizationCard from "./OrganizationCard";
import AOS from "aos";
import {LanguageContext, Text} from "../../providers/LanguageProvider";

const Organizations = (props)=>{

    const { userLanguage, tt    } = useContext(LanguageContext);
    const [organizationsInfo,setOrganizationsInfo] = useState(null);
    useEffect(() => {
        AOS.init({
            once: true,
            duration: 1000,
        });
    }, []);

    useEffect(() => {

        setOrganizationsInfo(tt('AboutUs.founders.organizationsInfo'))
    }, [userLanguage, tt]);




    return(
        <div style={{height:"100%", width:"100%", padding:"20px 0px"}}>
            <Container maxWidth={"lg"}>
                <Grid container spacing={5} direction={"column"}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            component="h1"
                            variant="h4"
                            align="center"
                            color="textPrimary"
                            gutterBottom
                            style={{ color: "black",paddingTop:"50px",fontWeight:"bold",textTransform:"none" }}
                        >
                            <Text tid="AboutUs.founders.mainHeader" />
                        </Typography>
                    </Grid>
                    <div data-aos="fade-up">
                    <Grid item xs={12} md={12}>
                       <Grid container spacing={3}  alignItems={"center"} justify={"center"}>
                           {organizationsInfo && organizationsInfo.map((ele,index)=>{
                              return<Grid  key={index} item xs={9} md={3}>
                                   <OrganizationCard
                                       currentLanguage={props.currentLanguage}
                                       companyName={ele.companyName}
                                       url={ele.url}
                                       src={ele.src}
                                       content={ele.content}
                                   />
                               </Grid>
                           })}
                       </Grid>
                    </Grid>
                    </div>
                </Grid>
            </Container>
        </div>
    )
}
export default Organizations;
