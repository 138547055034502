import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {colors, Grid, Typography} from '@material-ui/core';
import IconAlternate from "./IconAlternate";
import CardBase from "./CardBase";
import 'font-awesome/css/font-awesome.min.css';
import ServiceIcon from "../common/ServiceIcon";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',

    },
    fontWeight700: {
        fontWeight: 700,
    },
    categoryIconButton: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
}));

/**
 * Component to display the category link card
 *
 * @param {Object} props
 */
const CardCategoryLink = props => {
    const {
        fontIconClass,
        color,
        title,
        subtitle,
        href,
        align,
        className,
        service,
        iconAlternateProps,
        titleProps,
        subtitleProps,
        total,

        ...rest
    } = props;

    const classes = useStyles();

    let justifyGrid = 'flex-start';
    if (align === 'center') {
        justifyGrid = 'center';
    } else if (align === 'right') {
        justifyGrid = 'flex-end';
    }
    return (
        <CardBase
            className={clsx('card-category-link', classes.root, className)}
            {...rest}
        >
            <Grid container spacing={2} className="card-category-link__wrapper">
                {  fontIconClass && !service &&
                <Grid item container justifyContent={justifyGrid} xs={12}>
                    <IconAlternate
                        fontIconClass={fontIconClass}
                        color={color}
                        size="medium"
                        {...iconAlternateProps}
                    />

                </Grid>
                }
                { service &&
                    <Grid item container justifyContent={justifyGrid} xs={12}>
                        <Badge badgeContent={total} color={'secondary'}>
                            <ServiceIcon service={service}   size={'3x'}/>
                        </Badge>

                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        align={align}
                        className={classes.fontWeight700}
                        {...titleProps}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h6"
                        align={align}

                    >

                    </Typography>
                </Grid>
            </Grid>
        </CardBase>
    );
};

CardCategoryLink.defaultProps = {
    align: 'left',
    href: '#',
    iconAlternateProps: {},
    titleProps: {},
    subtitleProps: {}
};

CardCategoryLink.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
    /**
     * Promo title to show inside the card
     */
    title:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    /**
     * Promo subtitle to show inside the card
     */
    subtitle: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.object
    ]),
    /**
     * Promo description to show inside the card
     */
    href: PropTypes.string.isRequired,
    /**
     * Promo font icon class name to show inside the card
     */
    fontIconClass: PropTypes.string,
    /**
     * Promo icon color to show inside the card
     */
    color: PropTypes.oneOf([
        colors.red,
        colors.pink,
        colors.purple,
        colors.deepPurple,
        colors.indigo,
        colors.blue,
        colors.lightBlue,
        colors.cyan,
        colors.teal,
        colors.green,
        colors.lightGreen,
        colors.lime,
        colors.yellow,
        colors.amber,
        colors.orange,
        colors.deepOrange,
        colors.brown,
        colors.grey,
        colors.blueGrey,
        "#f50057",
        "#229988d1"
    ]).isRequired,
    /**
     * The content alignment
     */
    align: PropTypes.oneOf(['left', 'right', 'center']),
    /**
     * Additional props to pass to the IconAlternate component
     */
    iconAlternateProps: PropTypes.object,
    /**
     * Additional props to pass to the title Typography component
     */
    titleProps: PropTypes.object,
    /**
     * Additional props to pass to the subtitle Typography component
     */
    subtitleProps: PropTypes.object,
};

export default CardCategoryLink;
