import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

        marginBottom: "90px",
        height: "280px",
        [theme.breakpoints.down('sm')]: {
            marginBottom: '80px',
        },

    },


}));

export default function DrawerContent(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

                {props.children}


        </div>
    );
}
