import List from "@material-ui/core/List";
import {ListItem, ListItemIcon} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import React, {useContext, useMemo} from "react";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {makeStyles} from "@material-ui/styles";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import ServiceIcon from "../common/ServiceIcon";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import {getChildren} from "./utils";
import {LanguageContext} from "../../providers/LanguageProvider";
import {get_intl_lang} from "../utils/commonUsedFunctions";

const useStyles = makeStyles((theme) => ({

    nested: {
        paddingLeft: theme.spacing(5),
    },
    itemIcon: {


        display: 'flex',
        marginLeft: -11,
        justifyContent: 'center'
    }
}));
export default function ServiceList(props) {
    const {services,  selectedServices,  onApplyServiceSelection} = props
    const theme = useTheme()
    const classes = useStyles(theme);
    const [open, setOpen] = React.useState([]);

    const { userLanguage} = useContext(LanguageContext);



    const flatDescendantList = useMemo(()=>{
        // this essentially traverses all of the parent's children and makes the parent's children into a single flat array rather than nested
        let _services = []
        if(services?.length > 0){
            services.forEach(s => {
                if(s.parentCategoryId === null ){
                    s['subCategories'] = getChildren(s)
                    _services.push(s)
                }
            })

        }

        return _services


    }, [services])

    const onClickParentService = (id) => {
        if(open.includes(id)){
            setOpen( open.filter(_id => _id !== id ))

        } else {
            setOpen(prevState => [...prevState, id])

        }

    }

    const getSelectedChildren = (parentId) =>{

        const children = flatDescendantList.find(s => s.id === parentId)?.subCategories || []

        return selectedServices.filter(value => children.filter(s => s.id === value).length > 0      )

    }


    return (
        <Paper  elevation={0}>

            <List>

                {flatDescendantList.map((service, index)=>{
                    const selectedChildren = getSelectedChildren(service.id).length
                    const name = get_intl_lang(userLanguage, service.intlMetadata)?.name || service.name
                    return (
                        <>
                            <ListItem button key={service.id} role={undefined} dense onClick={(e)=>onClickParentService(service.id)}>
                                <ListItemIcon  className={classes.itemIcon}>
                                    <Badge badgeContent={selectedChildren} color="secondary">
                                        <ServiceIcon service={service} size={"lg"}  />
                                    </Badge>

                                </ListItemIcon>
                                <ListItemText primary={name} />
                                {open.includes(service.id) ? <ExpandLess /> : <ExpandMore />}

                            </ListItem>
                            <Collapse in={open.includes(service.id) } timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {service.subCategories.map(subService=>{

                                        const name = get_intl_lang(userLanguage, subService.intlMetadata)?.name || subService.name
                                        return (

                                            <ListItem button className={classes.nested} role={undefined} dense onClick={(e)=>onApplyServiceSelection(subService)}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={selectedServices.includes(subService.id) }
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': subService.id }}
                                                />
                                                <ListItemText primary={name}/>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Collapse>
                        </>
                    )
                })}
            </List>
        </Paper>

    )

}