import React from "react";
import {colors} from "@material-ui/core";

import {
    faAddressCard,
    faBaby,
    faBook,
    faBooks,
    faBrain,
    faBriefcaseClock,
    faBuildingColumns,
    faBuildingFlag,
    faBullhorn,
    faCanFood,
    faChartUser,
    faChildReaching,
    faCircleInfo,
    faClipboardMedical,
    faCogs,
    faCommentSmile,
    faCompass,
    faEnvelopeOpenDollar,
    faFamily,
    faFemale,
    faFileContract,
    faFileUser,
    faGavel,
    faGlobe,
    faGlobeAfrica,
    faHandHoldingDollar,
    faHandHoldingHeart,
    faHandsHoldingChild,
    faHashtagLock,
    faHeadSideBrain,
    faHeartPulse,
    faHouseChimneyMedical,
    faHouseCircleExclamation,
    faLandmarkDome,
    faLanguage,
    faLaptopFile,
    faMarker,
    faMoneyBill,
    faNoteMedical,
    faPersonChalkboard,
    faPiggyBank,
    faPrescription,
    faPuzzlePiece,
    faQuestion,
    faRainbow,
    faReceipt,
    faScaleBalanced,
    faSportsball,
    faStethoscope,
    faTaxiBus,
    faTooth,
    faTreeCity,
    faUserGraduate,
    faUserNurse,
    faUsers,
    faUserTie,
    faVenus,
    faVirusCovid,
    faWheelchair
} from "@fortawesome/pro-light-svg-icons";

export const defaultIcon =
    {
        icon: faCircleInfo,
        color: colors.blueGrey,
        colorDepth: 500,
        avatarColorDepth: 50,
    }


const serviceIcons = [

    // GENERAL

    {
        name: 'general',
        alias: ['general services'],
        icon: faCircleInfo,
        color: colors.blueGrey,
        colorDepth: 500,
        avatarColorDepth: 50,

    },
    {
        name: 'orientation',
        alias: ['general services'],
        icon: faCompass,
        color: colors.blueGrey,
        colorDepth: 500,
        avatarColorDepth: 50,

    },
    {
        name: 'library',
        alias: ['library', 'books'],
        icon: faBooks,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'transportation',
        alias: ['transportation'],
        icon: faTaxiBus,
        color: colors.blueGrey,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'clothing_furniture',
        alias: ['clothing', 'clothing & furniture'],
        icon: faUserTie,
        color: colors.blueGrey,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'culture',
        alias: ['culture'],
        icon: faGlobeAfrica,
        color: colors.blue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'youth',
        alias: ['youth', 'young people'],
        icon: faChildReaching,
        color: colors.purple,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'translators',
        alias: ['translators', 'translation'],
        icon: faLanguage,
        color: colors.blueGrey,
        colorDepth: 300,
        avatarColorDepth: 50,
    },


    // HOUSING
    {
        name: 'housing',
        alias: ['housing', 'houses', 'home', 'homes', 'apartments', 'apartment'],
        icon: faTreeCity,
        color: colors.brown,
        colorDepth: 500,
        avatarColorDepth: 50,
    },

    {
        name: 'housing_women',
        alias: [''],
        icon: faVenus,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },


    // HEALTH
    {
        name: 'health',
        alias: ['health'],
        icon: faHeartPulse,
        color: colors.red,
        colorDepth: 500,
        avatarColorDepth: 50,
    },
    {
        name: 'public_clinic',
        alias: ['public clinic', 'public clinics'],
        icon: faHouseChimneyMedical,
        color: colors.red,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'womens_health',
        alias: ['womens health', "women's health"],
        icon: faVenus,
        color: colors.red,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'mental health',
        alias: ["mental health"],
        icon: faHeadSideBrain,
        color: colors.red,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'health_insurance',
        alias: ["health insurance", "ramq"],
        icon: faClipboardMedical,
        color: colors.red,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'disabilities',
        alias: ["disabilities", "handicapped", "handicap wheelchair"],
        icon: faWheelchair,
        color: colors.red,
        colorDepth: 300,
        avatarColorDepth: 50,
    },




    /// family
    {
        name: 'family',
        alias: ['family', 'families',],
        icon: faFamily,
        color: colors.lightBlue,
        colorDepth: 500,
        avatarColorDepth: 50,
    },
    {
        name: 'family_support',
        alias: ['family support',],
        icon: faHandHoldingHeart,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    {
        name: 'family_activities',
        alias: ['family activities' ],
        icon: faSportsball,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'family_child_benefits',
        alias: ['family child benefits'],
        icon: faHandsHoldingChild,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'family_daycare',
        alias: ['daycare'],
        icon: faBaby,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'family_institutions',
        alias: ['family institutions', 'institutions'],
        icon: faBuildingFlag,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    /// EDUCATION

    {
        name: 'education',
        alias: ['school', 'education', 'university', 'edu', 'schooling'],
        icon: faUserGraduate,
        color: colors.purple,
        colorDepth: 500,
        avatarColorDepth: 50,
    },
    {
        name: 'education_institutions',
        alias: ['education institutions', 'academic institutions'],
        icon: faBuildingColumns,
        color: colors.purple,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    {
        name: 'education_workshops_and_skills_training',
        alias: ['education workshops', 'educational workshops'],
        icon: faPersonChalkboard,
        color: colors.purple,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'education_financial_aid',
        alias: ['education financial aid'],
        icon: faHandHoldingDollar,
        color: colors.purple,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'education_academic_aid',
        alias: ['academic aid & counselling', 'academic aid and counselling'],
        icon: faHandHoldingDollar,
        color: colors.purple,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'education_foreign_credentials',
        alias: [],
        icon: faGlobe,
        color: colors.purple,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'education_tutoring',
        alias: ['tutoring'],
        icon: faBook,
        color: colors.purple,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    // LANGUAGES
    {
        name: 'languages',
        alias: ['languages', 'language', 'foreign'],
        icon: faLanguage,
        color: colors.lightBlue,
        colorDepth: 500,
        avatarColorDepth: 50,
    },
    {
        name: 'languages_english',
        alias: ['english', 'english language'],
        icon: faLanguage,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'languages_french',
        alias: ['french', 'french language'],
        icon: faLanguage,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'languages_ielts',
        alias: ['ielts'],
        icon: faLanguage,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'languages_conversational',
        alias: ['conversation', 'conversational'],
        icon: faCommentSmile,
        color: colors.lightBlue,
        colorDepth: 300,
        avatarColorDepth: 50,
    },


    /// LEGAL

    {
        name: 'legal',
        alias: ['legal', 'law' ],
        icon: faScaleBalanced,
        color: colors.deepOrange,
        colorDepth: 500,
        avatarColorDepth: 50,
    },
    {
        name: 'legal_study_permit',
        alias: ['study permit' ],
        icon: faLaptopFile,
        color: colors.deepOrange,
        colorDepth: 300,
        avatarColorDepth: 50,
    },


    {
        name: 'legal_women',
        alias: [ ],
        icon: faFemale,
        color: colors.deepOrange,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'legal_refugee_claimant_application',
        alias: ['refugee claim', 'refugee claimant'],
        icon: faFileContract,
        color: colors.deepOrange,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'legal_work_permit',
        alias: ['refugee claim', 'refugee claimant'],
        icon: faFileUser,
        color: colors.deepOrange,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    {
        name: 'legal_housing_rights',
        alias: ['housing rights'],
        icon: faHouseCircleExclamation,
        color: colors.deepOrange,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'legal_workers_rights',
        alias: [ ],
        icon: faBullhorn,
        color: colors.deepOrange,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'legal_private_sponsorship_program',
        alias: ['private sponsorship program'],
        icon: faUsers,
        color: colors.deepOrange,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    // EMPLOYMENT
    {
        name: 'employment',
        alias: ['employment', 'entrepreneurship', 'entrepreneurship', 'employment & entrepreneurship', 'work', 'job', 'jobs'],
        icon: faBriefcaseClock,
        color: colors.brown,
        colorDepth: 500,
        avatarColorDepth: 50,
    },

    {
        name: 'job_placement',
        alias: ['job placement'],
        icon: faAddressCard,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'cv_help',
        alias: ['cv help', 'resume help'],
        icon: faMarker,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'workshops_and_training',
        alias: ['workshops', 'skills & training', 'skills and training'],
        icon: faPersonChalkboard,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'work permit',
        alias: ['work permit', 'work permits'],
        icon: faFileUser,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'business consultation',
        alias: ['business consultation'],
        icon: faChartUser,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'workers rights',
        alias: ['worker right', "workers rights",  "worker's rights"],
        icon: faBullhorn,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'social insurance number',
        alias: ['social insurance number', 'sin'],
        icon: faHashtagLock,
        color: colors.brown,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'finance',
        alias: ['financial', 'finance', 'accounting', 'financial  & accounting'],
        icon: faMoneyBill,
        color: colors.green,
        colorDepth: 300,
        avatarColorDepth: 50,

    },


    /// FINANCIAL
    {
        name: 'financial aid',
        alias: ['financial aid'],
        icon: faHandHoldingDollar,
        color: colors.green,
        colorDepth: 300,
        avatarColorDepth: 50,

    },
    {
        name: 'taxes',
        alias: ['taxes'],
        icon: faEnvelopeOpenDollar,
        color: colors.green,
        colorDepth: 300,
        avatarColorDepth: 50,

    },
    {
        name: 'banking',
        alias: ['banking'],
        icon: faPiggyBank,
        color: colors.green,
        colorDepth: 300,
        avatarColorDepth: 50,

    },
    {
        name: 'financial_child_benefits',
        alias: ['child benefits'],
        icon: faPuzzlePiece,
        color: colors.green,
        colorDepth: 300,
        avatarColorDepth: 50,

    },


    // LGBTQI
    {
        name: 'lgbtqi+',
        alias: ['lgbtqi', 'gay', 'lesbians', 'gay community', 'lgbtqi+'],
        icon: faRainbow,
        color: colors.pink,
        colorDepth: 500,
        avatarColorDepth: 50,
    },
    {
        name: 'lgbtqi+_other',
        alias: [],
        icon: faQuestion,
        color: colors.pink,
        colorDepth: 500,
        avatarColorDepth: 50,
    },

    // COVID

    {
        name: 'covid',
        alias: ['covid', 'covid19'],
        icon: faVirusCovid,
        color: colors.red,
        colorDepth: 500,
        avatarColorDepth: 50,
    },


    // foreign credentials
    {
        name: 'foreign_credentials',
        alias: ['foreign credentials' ],
        icon: faGlobe,
        color: colors.teal,
        colorDepth: 500,
        avatarColorDepth: 50,
    },

    {
        name: 'engineer',
        alias: ['engineering', 'engineer'],
        icon: faCogs,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    {
        name: 'nurse',
        alias: ['nurse', 'nurses'],
        icon: faUserNurse,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'architect',
        alias: ['architect', 'architects', 'architecture' ],
        icon: faLandmarkDome,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    {
        name: 'lawyer',
        alias: ['lawyer', 'lawyers' ],
        icon: faGavel,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'accountant',
        alias: ['accountant', 'accountants' ],
        icon: faReceipt,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'dentist',
        alias: ['dentists', 'dentist', 'tooth' ],
        icon: faTooth,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'social_worker',
        alias: ['social worker' ],
        icon: faHandHoldingHeart,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'psychologist',
        alias: ['psychologist' ],
        icon: faBrain,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'doctor',
        alias: ['doctor', 'md', 'medical practitioner' ],
        icon: faStethoscope,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },

    {
        name: 'foreign_credentials_pharmacist',
        alias: ['pharmacist' ],
        icon: faNoteMedical,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },
    {
        name: 'pharmacy',
        alias: ['pharmacy' ],
        icon: faPrescription,
        color: colors.teal,
        colorDepth: 300,
        avatarColorDepth: 50,
    },






    // general

    {
        name: 'foodbanks',
        alias: ['food bank', 'food banks' ],
        icon: faCanFood,
        color: colors.blueGrey,
        colorDepth: 300,
        avatarColorDepth: 50,
    },




    {
        name: 'women',
        alias: ['women', 'woman'],
        icon: "",
        color: colors.pink,
        colorDepth: 300,
        avatarColorDepth: 50,
    },


]

export default serviceIcons;