import React from "react";
import {colors} from "@material-ui/core";
import aboutUsImg from "../about/static/team.svg";
import thankYou from "../about/static/partnership.svg";
import suggestionBox from "../explore/public/suggestionBox.svg";
import refugeeCenter from '../../public/refugeeCenterLogo.png';
import devBloc from '../../public/devblocaFromSource.png';
import resources from "../../public/resources.png";
import {Text} from "../../providers/LanguageProvider";

export function uploadImageBasedOnImgTitle(img){
    switch (img){
        case "aboutUsImg":
            return aboutUsImg
        case "thankYou":
            return thankYou
        case "suggestionBox":
            return suggestionBox
        case "refugeeCenter":
            return refugeeCenter
        case "devBloc":
            return devBloc
        case "resources":
            return resources
        default: return img
    }
}

export function get_intl_lang(languageId, intl){
    if(intl){
        for(let i = 0; i < intl.length; i++){
            if(intl[i].languageId === languageId){
                return intl[i];
            }
        }

    }


}

export function assignIconBasedOnService(serviceId) {

        switch (serviceId) {
            case "health":
                return {
                    color: colors.deepOrange,
                    icon: "fa fa-heartbeat",
                    title: <Text tid="bookmarks.health" />,
                    subtitle: "View thousands of bookmarked services of Health.",
                };
                // break;
            case "legal":
                return {
                    color: colors.brown,
                    icon: "fa fa-balance-scale",
                    title: <Text tid="bookmarks.legal" />,
                    subtitle: "View thousands of bookmarked services of Legal",
                };
                // break;
            case "general_services":
                return {
                    color: colors.pink,
                    icon: "fa fa-cogs",
                    title: <Text tid="bookmarks.generalServices" />,
                    subtitle: "View thousands of bookmarked services of General Services",
                };
                // break;
            case "lgbtqi+":
                return {
                    color: colors.lightBlue,
                    icon: "fa fa-transgender-alt",
                    title: <Text tid="bookmarks.LGBTQ" />,
                    subtitle: "View thousands of bookmarked services of LGBTQ+ ",
                };
                // break;
            case "financial_and_accounting":
                return {
                    color: colors.purple,
                    icon: "fa fa-money",
                    title: <Text tid="bookmarks.financialAccounting" />,
                    subtitle: "View thousands of bookmarked services of Financial & Accounting",
                };
                // break;
            case "education":
                return {
                    color: colors.amber,
                    icon: "fa fa-graduation-cap",
                    title: <Text tid="bookmarks.education" />,
                    subtitle: "View thousands of bookmarked services of Education.",
                };
                // break;
            case "family":
                return {
                    color: colors.indigo,
                    icon: "fa fa-home",
                    title: <Text tid="bookmarks.family" />,
                    subtitle: "Choose thousands of video creating Family.",
                };
                // break;
            case "employment_and_entrepreneurship":
                return {
                    color: colors.blueGrey,
                    icon: "fa fa-briefcase",
                    title: <Text tid="bookmarks.employmentEntrepreneurship" />,
                    subtitle: "View thousands of bookmarked services of Employment & Entrepreneurship",
                };
                // break;
            case "housing":
                return {
                    color: colors.teal,
                    icon: "fa fa-building",
                    title: <Text tid="bookmarks.housing" />,
                    subtitle: "View thousands of bookmarked services of Housing",
                };
                // break;
            case "languages":
                return {
                    color: colors.cyan,
                    icon: "fa fa-language",
                    title: <Text tid="bookmarks.languages" />,
                    subtitle: "View thousands of bookmarked services of Languages",
                };
                // break;
            case "foreign_credentials":
                return {
                    color: "#229988d1",
                    icon: "fa fa-atlas",
                    title: <Text tid="bookmarks.foreignCredentials" />,
                    subtitle: "View thousands of bookmarked services of Foreign Credentials",
                };
                // break;
            case "covid_19":
                return {
                    color: "#f50057",
                    icon: "fa fa-virus",
                    title: <Text tid="bookmarks.covid" />,
                    subtitle: "View thousands of bookmarked services of Foreign Credentials",
                };
                // break;
            default:
                return {
                    color: "#f50057",
                    icon: "fa fa-info",
                    title: 'Unknown',
                    subtitle: "View thousands of bookmarked services of Foreign Credentials",
                };

    }
}


export function checkEmailFormat(email) {
    /* eslint-disable */
    email = email.trim();
    const emailFormat = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return email.match(emailFormat);
}
