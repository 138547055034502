import eng from "./eng.json";
import fre from "./fre.json";
import spa from './spa.json';
import ara from './ara.json';

export const dictionaryList = { eng, fre, spa, ara };

export const languageOptions = {
  eng: "English",
  fre: "Français",
  spa: "Español",
  ara: "العربيه"
};

